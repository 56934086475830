import { List } from 'antd';
import { convertFromHTML, ContentState, EditorState } from 'draft-js';
import lodash from 'lodash';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { MEETING_ACTIVTY_TYPE } from '../../../configs/activityTypes';
import { SERVER_TASK_TYPES_DETAILS_ENDPOINT } from '../../../configs/endpoints';
import ConnectedDetailedTasksList from '../../../containers/detailedTasksList/DetailedTasksList';
import { axioService, GET } from '../../../services/axioService';
import TodoShareUserItem from '../../todo/todoShareUserItem/TodoShareUserItem';
import MeetingEventTitle from '../meetingEventTitle/MeetingEventTitle';
import './MeetingSummary.scss';

/** interface to describe MeetingSummary props */
interface MeetingSummaryProps {
  taskId: string;
  projectId: string;
  sectionId: string;
  counter: number;
}

const MeetingSummary: React.FC<MeetingSummaryProps> = (
  props: MeetingSummaryProps
) => {
  const { taskId, projectId, sectionId, counter } = props;

  /** manages the objective state */
  const [objective, setObjective] = React.useState<any>(
    EditorState.createEmpty()
  );

  /** manages the people state */
  const [people, setPeople] = React.useState<any[]>([]);

  /** manages the file list state */
  const [fileList, setFileList] = React.useState<any[]>([]);

  /** manages the agenda list state */
  const [agenda, setAgenda] = React.useState<string[]>([]);

  const [link, setLink] = React.useState<string>('');

  /** fetch event data from server */
  React.useEffect(() => {
    const fetchMeetingData = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASK_TYPES_DETAILS_ENDPOINT,
          { type: MEETING_ACTIVTY_TYPE.value, task_id: taskId },
          true
        );
        if (response.data.event?.description) {
          setObjective(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.event?.description as any) as any
              )
            )
          );
        }
        if (response.data.event?.attendees) {
          setPeople(response.data.event?.attendees);
        }

        if (response.data.event?.type_info?.medium_properties?.link) {
          setLink(response.data.event?.type_info?.medium_properties?.link);
        }

        if (response.data.attachments) {
          setFileList(response.data.attachments);
        }
        if (response.data.objectives) {
          setAgenda(
            lodash.filter(
              response.data.objectives.map((iter: any) => iter.title || ''),
              (iter: string) => iter !== ''
            )
          );
        }
      } catch (Exception) {
        console.error(Exception);
      }
    };

    fetchMeetingData();
  }, [counter]);

  return (
    <div className="MeetingSummary-container">
      <div className="MeetingSummary-item-container">
        <div className="MeetingSummary-item-header">
          <MeetingEventTitle
            color="#6392f1"
            icon={'fas fa-clipboard-list'}
            title="Task &amp; Sub Task"
            subtitle={'Complete your meeting assignments'}
          />
        </div>
        <div className="MeetingSummary-item-body">
          <ConnectedDetailedTasksList
            taskId={taskId}
            projectId={projectId}
            sectionId={sectionId}
          />
        </div>
      </div>
      <div className="MeetingSummary-item-container">
        <div className="MeetingSummary-item-header">
          <MeetingEventTitle
            color="#a662f0"
            icon={'fas fa-crosshairs'}
            title="Meeting Objective"
            subtitle={'Meeting Agenda and Discussions'}
          />
        </div>
        <div className="MeetingSummary-item-body">
          <div className="MeetingSummary-field MeetingSummary-field-objective">
            <div className="MeetingSummary-field-title">Objective</div>
            <div className="MeetingSummary-field-body">
              <Editor
                readOnly={true}
                editorState={objective}
                toolbar={{
                  options: [],
                }}
                placeholder="No objective is set"
              />
            </div>
          </div>
          {link !== '' && (
            <div className="MeetingSummary-field">
              <div className="MeetingSummary-field-title">Join Url</div>
              <div className="MeetingSummary-field-body MeetingSummary-join-link">
                <a target="blank" href={link}>
                  {link}
                </a>
              </div>
            </div>
          )}
          <div className="MeetingSummary-field">
            <div className="MeetingSummary-field-title MeetingSummary-objective">
              Agenda
            </div>
            <div className="MeetingSummary-field-body">
              <List
                itemLayout="horizontal"
                dataSource={agenda}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<div className="MeetingSummary-hypen"> - </div>}
                      title={item}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="MeetingSummary-row">
        <div className="MeetingSummary-item-container MeetingSummary-shared-item">
          <div className="MeetingSummary-item-header">
            <MeetingEventTitle
              color="#6ab556"
              icon={'fas fa-paperclip'}
              title="Attachments"
              subtitle={'All Attached Media Files'}
            />
          </div>
          <div className="MeetingSummary-item-body">
            <List
              itemLayout="horizontal"
              dataSource={fileList}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<i className="fas fa-file" />}
                    title={
                      <a href={item.src} target="blank">
                        {item.name}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
        <div className="MeetingSummary-item-container MeetingSummary-shared-item">
          <div className="MeetingSummary-item-header">
            <MeetingEventTitle
              color="#fcac4d"
              icon={'fas fa-user-friends'}
              title="Members"
              subtitle={'All Attended Members'}
            />
          </div>
          <div className="MeetingSummary-item-body">
            {people.length === 0 && (
              <List itemLayout="horizontal" dataSource={[]} />
            )}
            {lodash.map(people, (invitee: any, index: number) => (
              <TodoShareUserItem
                key={'invitee_' + index}
                id={invitee.email}
                avatar={invitee.avatar || ''}
                name={invitee.name || 'Guest'}
                email={invitee.email || ''}
                pending={false}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                deleteHandler={() => {}}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingSummary;
