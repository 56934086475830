import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import { TimeReminderObj } from '../../../../store/ducks/tasks';
import TaskReminderViewItem from './taskReminderViewItem/TaskReminderViewItem';

interface TaskReminderViewProps {
  values: TimeReminderObj[];
  scheduleDate: moment.Moment;
  changeHandler: (requested: TimeReminderObj[]) => void;
}

const TaskReminderView: React.FC<TaskReminderViewProps> = (
  props: TaskReminderViewProps
) => {
  const { values, scheduleDate, changeHandler } = props;

  const deleteHandler = (requested: string) => {
    changeHandler(
      values.map((iter) => {
        return iter.id === requested ? { ...iter, deleted: true } : iter;
      })
    );
  };

  return (
    <div className="TaskReminderView-container">
      {lodash
        .orderBy(lodash.filter(values, { deleted: false }), ['before'], 'desc')
        .map((reminder, index) => (
          <TaskReminderViewItem
            key={'TaskReminder-view-' + index}
            id={reminder.id}
            deleteHandler={deleteHandler}
            scheduleDate={moment(scheduleDate).subtract(
              reminder.before,
              'minute'
            )}
          />
        ))}
    </div>
  );
};

export default TaskReminderView;
