import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './TodoSearchPage.scss';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';
import ConnectedTodoSearchContent from '../../../containers/todoSearchContent/TodoSearchContent';
import { Redirect, useParams } from 'react-router-dom';

const TodoSearchPage: React.FC = () => {
  const { keyword } = useParams<{ keyword: string }>();
  return keyword !== '' ? (
    <ConnectedTodoSearchContent keyword={keyword} />
  ) : (
    <Redirect to="/" />
  );
};

export default withTaskSider(withTodoLayout(TodoSearchPage));
