import { Button, InputNumber, Select } from 'antd';
import React from 'react';
import './TaskReminderCustomSelect.scss';

interface TaskReminderCustomSelectProps {
  confirmHandler: (requested: number) => void;
  cancelHandler: () => void;
}

const INTERVAL_OPTIONS = [
  { label: 'Minutes', value: 1 },
  { label: 'Hours', value: 60 },
  { label: 'Days', value: 1440 },
  { label: 'Weeks', value: 10080 },
];

const TaskReminderCustomSelect: React.FC<TaskReminderCustomSelectProps> = (
  props: TaskReminderCustomSelectProps
) => {
  const { cancelHandler, confirmHandler } = props;
  const [number, setNumber] = React.useState<number>(10);
  const [interval, setInterval] = React.useState<number>(1);

  const onNumberChange = (value: number | undefined) => setNumber(value || 1);

  const onSelectChange = (value: number) => setInterval(value);

  const tickHandler = () => {
    confirmHandler(number * interval);
  };

  return (
    <div className="TaskReminderCustomSelect-container">
      <InputNumber
        size="small"
        min={1}
        value={number}
        className="TaskReminderCustomSelect-number"
        onChange={onNumberChange as any}
      />
      <Select
        size="small"
        className="TaskReminderCustomSelect-interval"
        dropdownClassName="TaskReminderCustomSelect-dropdown"
        options={INTERVAL_OPTIONS}
        value={interval}
        onChange={onSelectChange}
      ></Select>
      <Button
        shape="circle"
        onClick={tickHandler}
        className="TaskReminderCustomSelect-confirm"
      >
        <i className="fas fa-check" />
      </Button>
      <Button
        shape="circle"
        className="TaskReminderCustomSelect-cancel"
        onClick={cancelHandler}
      >
        <i className="fas fa-times" />
      </Button>
    </div>
  );
};

export default TaskReminderCustomSelect;
