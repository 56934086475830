import React from 'react';
import {
  Modal,
  Input,
  Button,
  Switch,
  Avatar,
  Divider,
  Alert,
  message,
} from 'antd';
import './ShareProject.scss';
import { connect } from 'react-redux';
import { Store } from 'redux';
import {
  CollaboratorObj,
  getCollaborators,
  setCollaborator,
} from '../../store/ducks/collaborators';
import { getSessionUserInfo } from '../../store/ducks/session';
import TodoShareUserItem from '../../components/todo/todoShareUserItem/TodoShareUserItem';
import {
  fixProjectOrders,
  getProjects,
  ProjectObj,
  setProject,
  updateProjects,
} from '../../store/ducks/projects';
import { axioService, GET, POST } from '../../services/axioService';
import {
  LOCAL_INBOX_ENDPOINT,
  SERVER_INVITATIONS_ENDPOINT,
  SERVER_PROJECTS_REVOKE_ENDPOINT,
  SERVER_TODO_BASE_ENDPOINT,
} from '../../configs/endpoints';
import lodash from 'lodash';
import { deleteSectionsBasedOnProjects } from '../../store/ducks/sections';
import {
  deleteTasksBasedOnProjects,
  removeUserAssignedTasks,
} from '../../store/ducks/tasks';
import { useHistory } from 'react-router-dom';
import { syncService } from '../../services/syncService';

/** interface to describe ShareProject props */
interface ShareProjectProps {
  projectInfo: ProjectObj;
  visible: boolean;
  collaboratorList: CollaboratorObj[];
  userInfo: any;
  childrenProjects: ProjectObj[];
  updateProjectsActionCreator: typeof updateProjects;
  fixProjectOrdersActionCreator: typeof fixProjectOrders;
  deleteSectionsBasedOnProjectsActionCreator: typeof deleteSectionsBasedOnProjects;
  deleteTasksBasedOnProjectsActionCreator: typeof deleteTasksBasedOnProjects;
  setProjectActionCreator: typeof setProject;
  setCollaboratorActionCreator: typeof setCollaborator;
  removeUserAssignedTasksActionCreator: typeof removeUserAssignedTasks;
  closeHandler: () => void;
}

/** the share project component */
const ShareProject: React.FC<ShareProjectProps> = (
  props: ShareProjectProps
) => {
  const {
    visible,
    userInfo,
    projectInfo,
    childrenProjects,
    updateProjectsActionCreator,
    fixProjectOrdersActionCreator,
    deleteTasksBasedOnProjectsActionCreator,
    deleteSectionsBasedOnProjectsActionCreator,
    closeHandler,
    setProjectActionCreator,
    removeUserAssignedTasksActionCreator,
    collaboratorList,
  } = props;

  const addressInputRef = React.useRef<any>(null);
  const shareInputRef = React.useRef<any>(null);
  const history = useHistory();
  const { confirm } = Modal;

  const [invitationText, setInvitationText] = React.useState<string>('');
  const [publicLink, setPublicLink] = React.useState<string>('');
  const [invitationList, setInvitationList] = React.useState<any>([]);
  const [errorMsg, setErrorMsg] = React.useState<string>('');

  // React cycles
  React.useEffect(() => {
    /** sync any new projects that are not synced */
    syncService();

    const fetchInvitations = async () => {
      try {
        /** fetch the existing invitations related to the project */
        const response = await axioService(
          GET,
          `${SERVER_TODO_BASE_ENDPOINT}/project/${projectInfo.id}/invitations`,
          {},
          true
        );

        /** remove the public link related invitation obj */
        const invitations = lodash.filter(
          response.data.data,
          (iterInvitation: any) => iterInvitation.guest_email
        );

        /** set the invitation list of the project */
        setInvitationList(invitations);

        /** find the public link if exists */
        const publicLinkObj = lodash.find(response.data.data, {
          guest_email: null,
        });

        /** if exists, set the public link */
        if (publicLinkObj) {
          setPublicLink(publicLinkObj.token);
        }
      } catch (Exception) {
        console.error(Exception);
      }
    };

    /** only if visible, fetch the invitations on rest */
    if (visible) {
      fetchInvitations();
    }

    setTimeout(() => {
      /** sets the focus on the input */
      if (addressInputRef.current) {
        addressInputRef.current.focus();
      }
    }, 100);
  }, [visible]);

  // on change handlers
  /**
   * invitationTextChangeHandler updates invitation text state on change
   * @param {React.ChangeEvent<HTMLInputElement>} event - the on change event
   */
  const invitationTextChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInvitationText(event.target.value);
  };

  // action handlers
  const inviteHandler = async () => {
    try {
      if (invitationText !== '') {
        const invitations = lodash.map(
          invitationList,
          (iterInvitation: any) => iterInvitation.guest_email
        );
        const onBoardedUsers = [...projectInfo.sharedWith, ...invitations];
        if (onBoardedUsers.includes(invitationText.trim())) {
          setErrorMsg('The user is already present or invited.');
        } else {
          const response = await axioService(
            POST,
            SERVER_INVITATIONS_ENDPOINT,
            {
              subject_type: 'project',
              subject_id: projectInfo.id,
              guest_email: invitationText.trim(),
            },
            true
          );
          setInvitationText('');
          setErrorMsg('');
          setInvitationList([response.data, ...invitationList]);
        }
      }
    } catch (exception) {
      /** output the error on the console */
      console.error(exception);

      /** sets the related error msg */
      setErrorMsg(
        exception.response.data?.errors?.guest_email ||
          'Something unexpected happended. Try again later!'
      );
    }
  };

  /** handles the request on toggle of public link */
  const publicLinkHandler = async () => {
    if (publicLink === '') {
      try {
        /** generate the public link token from the server */
        const response = await axioService(
          POST,
          SERVER_INVITATIONS_ENDPOINT,
          { subject_type: 'project', subject_id: projectInfo.id },
          true
        );

        /** set the response public link */
        setPublicLink(response.data.token);
      } catch (Exception) {
        console.error(Exception);
      }
    } else {
      /** destroy the existing token so that public link do not exist */
      try {
        await axioService(
          POST,
          `${SERVER_INVITATIONS_ENDPOINT}/${publicLink}/acceptance`,
          { status: 4 },
          true
        );
        /**resets the public link */
        setPublicLink('');
      } catch (Exception) {
        console.error(Exception);
      }
    }
  };

  const removeInvitation = async (invitationId: string) => {
    const invitationObj = lodash.find(invitationList, { id: invitationId });
    try {
      await axioService(
        POST,
        `${SERVER_INVITATIONS_ENDPOINT}/${invitationObj.token}/acceptance`,
        { status: 4 },
        true
      );
      setInvitationList(
        lodash.filter(
          invitationList,
          (iterInvitationObj: any) => iterInvitationObj.id !== invitationId
        )
      );
    } catch (Exception) {
      console.error(Exception);
    }
  };

  /** handles the request of closing error alert */
  const onErrorClose = () => setErrorMsg('');

  const copyTaskOnClipboard = () => {
    if (shareInputRef.current) {
      shareInputRef.current.select();
      document.execCommand('Copy');
      shareInputRef.current.blur();
      message.success('Copied');
    }
  };

  const removeFromProject = async (requestedUserId: string) => {
    try {
      /** make axioService  */
      await axioService(
        POST,
        SERVER_PROJECTS_REVOKE_ENDPOINT,
        {
          project_id: projectInfo.id,
          user_id: requestedUserId,
        },
        true
      );
      /** remove the requested user */
      setProjectActionCreator({
        ...projectInfo,
        sharedWith: lodash.filter(
          projectInfo.sharedWith,
          (iterUserId: string) => iterUserId !== requestedUserId
        ),
      });
      removeUserAssignedTasksActionCreator(requestedUserId, projectInfo.id);
    } catch (exception) {
      console.error(exception);
    }
  };

  /** manages the project leave request */
  const projectLeaveHandler = async () => {
    try {
      /** make the leave request to server  */
      await axioService(
        POST,
        SERVER_PROJECTS_REVOKE_ENDPOINT,
        {
          project_id: projectInfo.id,
          user_id: userInfo.id,
        },
        true
      );
      setProjectActionCreator({
        ...projectInfo,
        synced: true,
        deleted: true,
      });

      /** warning: syncing sub projects deletion as well */
      const tmpSubProjects = childrenProjects.map((iterProj: ProjectObj) => ({
        ...iterProj,
        synced: false,
        parent: projectInfo.parent,
        order: parseFloat(iterProj.order + '.' + childrenProjects.length),
      }));
      updateProjectsActionCreator(tmpSubProjects);

      /** fix the order after project deletion */
      fixProjectOrdersActionCreator(projectInfo.parent);

      const interestedProjIds = [projectInfo.id];

      /** remove sections associated with the projects */
      deleteSectionsBasedOnProjectsActionCreator(interestedProjIds, true);

      /** remove tasks associated with the projects */
      deleteTasksBasedOnProjectsActionCreator(interestedProjIds, true);

      history.push(LOCAL_INBOX_ENDPOINT);

      /** closes the shared modal */
      closeHandler();
    } catch (exception) {
      console.error(exception);
    }
  };

  const config = {
    className: 'ShareProject-modal-container',
    title: <div className="ShareProject-leave-modal-title">Leave Project</div>,
    content: (
      <div>
        Are you sure you want to leave the project{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {projectInfo.title}
        </span>{' '}
        ?
      </div>
    ),
    width: 500,
    onOk() {
      projectLeaveHandler();
    },
  };

  const projectLeaveRequestHandler = () => {
    confirm(config);
  };

  return (
    <Modal
      className="ShareProject-modal"
      title={
        <div className="ShareProject-modal-title">
          Share
          <div
            className="ShareProject-dot"
            style={{ background: projectInfo?.color }}
          />
          <div className="ShareProject-project-title">{projectInfo.title}</div>
          with
        </div>
      }
      visible={visible}
      onOk={closeHandler}
      onCancel={closeHandler}
      width={600}
      footer={[
        publicLink !== '' && (
          <div className="ShareProject-link-container" key="share-link">
            <div className="ShareProject-link-input">
              <Input
                ref={shareInputRef}
                value={window.location.host + '/invitation/' + publicLink}
              />
            </div>
            <div>
              <Button onClick={copyTaskOnClipboard}>Copy Link</Button>
            </div>
          </div>
        ),
        <div className="ShareProject-switch-container" key="toggle">
          <Switch checked={publicLink !== ''} onChange={publicLinkHandler} />
          <div className="ShareProject-switch-title">Enable Shareable Link</div>
        </div>,
        <Button key="back" onClick={closeHandler}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={closeHandler}>
          Done
        </Button>,
      ]}
    >
      <div className="ShareProject-container">
        <Input
          value={invitationText}
          size="large"
          placeholder="Enter email address"
          onChange={invitationTextChangeHandler}
          onPressEnter={inviteHandler}
          ref={addressInputRef}
          suffix={
            <button onClick={inviteHandler}>
              <i className="fas fa-arrow-right" />
            </button>
          }
        />
        {errorMsg !== '' && (
          <Alert
            message="Oops"
            description={errorMsg}
            type="error"
            closable
            onClose={onErrorClose}
          />
        )}
        {
          /** TODO: change the flag variable to appropiate one */
          projectInfo.sharedWith.length < 2 && invitationList.length < 1 ? (
            <div className="ShareProject-empty-body">
              <div>
                <img src="/bulb.svg" width="180" alt="" />
              </div>
              <div className="ShareProject-subtitle">No Share Yet</div>
              <div className="ShareProject-paragraph">
                This project is your own project and not shared yet.
              </div>
              <div className="ShareProject-paragraph">
                If you want, you can share this project with your partners by
                using email.
              </div>
            </div>
          ) : (
            <div className="ShareProject-users-body">
              <div className="ShareProject-user-item">
                <Avatar size="large" src={userInfo.avatar}>
                  <div>{userInfo?.name?.slice(0, 2)}</div>
                </Avatar>
                <div className="ShareProject-user-description">
                  <div className="ShareProject-user-name">Me</div>
                  <div className="ShareProject-user-email">
                    {userInfo.email || ''}
                  </div>
                </div>
                <div className="ShareProject-user-owner">Owner</div>
                <div
                  className="ShareProject-user-delete"
                  onClick={projectLeaveRequestHandler}
                >
                  <i className="fas fa-sign-out-alt"></i>
                </div>
              </div>
              <Divider />
              {lodash.map(invitationList, (invitation: any) => (
                <TodoShareUserItem
                  key={invitation?.id}
                  id={invitation?.id || ''}
                  avatar={invitation?.invitee?.avatar || ''}
                  name={invitation?.invitee?.name || 'Guest'}
                  email={invitation?.guest_email || ''}
                  pending={true}
                  deleteHandler={removeInvitation}
                />
              ))}
              {lodash
                .without(projectInfo.sharedWith, userInfo.id)
                .map((iterCollaboratorId: string) => {
                  const collaborator = lodash.find(collaboratorList, {
                    id: iterCollaboratorId,
                  });
                  return (
                    <TodoShareUserItem
                      key={collaborator?.id}
                      id={collaborator?.id || ''}
                      avatar={collaborator?.avatar || ''}
                      name={collaborator?.name || ''}
                      email={collaborator?.email || ''}
                      pending={false}
                      deleteHandler={removeFromProject}
                    />
                  );
                })}
            </div>
          )
        }
      </div>
    </Modal>
  );
};

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  collaboratorList: CollaboratorObj[];
  userInfo: any;
  childrenProjects: ProjectObj[];
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<ShareProjectProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const immediateChildrenProjects = lodash.filter(getProjects(state), {
    deleted: false,
    parent: parentProps.projectInfo.id,
  });
  const result = {
    collaboratorList: getCollaborators(state),
    userInfo: getSessionUserInfo(state),
    childrenProjects: immediateChildrenProjects,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setCollaboratorActionCreator: setCollaborator,
  setProjectActionCreator: setProject,
  updateProjectsActionCreator: updateProjects,
  fixProjectOrdersActionCreator: fixProjectOrders,
  deleteSectionsBasedOnProjectsActionCreator: deleteSectionsBasedOnProjects,
  deleteTasksBasedOnProjectsActionCreator: deleteTasksBasedOnProjects,
  removeUserAssignedTasksActionCreator: removeUserAssignedTasks,
};

/** connect ShareProject to the redux store */
const ConnectedShareProject = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareProject);

/** the default export */
export default ConnectedShareProject;
