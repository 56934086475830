import React from 'react';
import './ProjectItem.scss';
import { Button, Dropdown } from 'antd';
import clsx from 'clsx';
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd';
import { DropTarget, DropTargetConnector } from 'react-dnd';
import { useHistory, useLocation } from 'react-router-dom';
import ProjectOptionsPanel from '../../components/todo/projects/projectOptionsPanel/ProjectOptionsPanel';
import { connect } from 'react-redux';
import {
  fixTaskOrders,
  getTaskById,
  getPendingTasksCountByProjectId,
  setTask,
} from '../../store/ducks/tasks';
import { Store } from 'redux';
import { getProjectById, ProjectObj } from '../../store/ducks/projects';
import {
  disablePointerEventsOnMainLayout,
  transferSubTaskListByParent,
} from '../../services/utility';
import store from '../../store';
import { LOCAL_PRE_PROJECT_ENDPOINT } from '../../configs/endpoints';
import ConnectedAddProject from '../addProject/AddProject';

export interface ProjectItemProps {
  projectId: string;
  projectInfo: ProjectObj;
  path: number;
  color: string;
  label: string;
  count: number;
  canDrop: boolean;
  isOver: boolean;
  isDropped: boolean;
  connectDropTarget: ConnectDropTarget;
}

const ProjectItem: React.FC<ProjectItemProps> = (props: ProjectItemProps) => {
  const {
    projectId,
    projectInfo,
    color,
    label,
    count,
    path,
    isOver,
    canDrop,
    connectDropTarget,
  } = props;
  const history = useHistory();
  const location = useLocation();

  /** manages the visibility of project more panel */
  const [visible, setVisible] = React.useState<boolean>(false);

  /** manages the visiblity of modal */
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  /** handles the request of closing modal */
  const modalCloseHandler = () => setIsModalOpen(false);

  /** handles the request of opening modal */
  const modalOpenHandler = () => {
    setIsModalOpen(true);
  };

  /** handles the navigation */
  const navigationHandler = () =>
    history.push(LOCAL_PRE_PROJECT_ENDPOINT + projectId);

  /** handles the keyboard enter request */
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigationHandler();
    }
  };

  return (
    <div
      ref={connectDropTarget}
      className={clsx({
        'ProjectItem-container': true,
        'ProjectItem-container--active':
          location.pathname === LOCAL_PRE_PROJECT_ENDPOINT + projectId,
        'ProjectItem-container--first': path === 1,
        'ProjectItem-container--second': path === 2,
        'ProjectItem-container--third': path === 3,
        'ProjectItem-container--fourth': path === 4,
        'ProjectItem-container-item-can-drop': canDrop,
        'ProjectItem-container-item-over': isOver,
      })}
      onClick={navigationHandler}
      onContextMenu={(event: any) => {
        event.preventDefault();
        setVisible(true);
        disablePointerEventsOnMainLayout(true);
      }}
      onDoubleClick={modalOpenHandler}
      onKeyPress={handleKeyPress}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div className="ProjectItem-dot" style={{ background: color }} />
      <div
        className={clsx({
          'ProjectItem-label': true,
          'ProjectItem-label--first': path === 1,
          'ProjectItem-label--second': path === 2,
          'ProjectItem-label--third': path === 3,
          'ProjectItem-label--fourth': path === 4,
        })}
      >
        {label}
        {projectInfo.sharedWith.length > 1 && (
          <i className="fas fa-share-alt ProjectItem-share"></i>
        )}
      </div>
      <div className="ProjectItem-count">{count}</div>
      <div
        className={clsx({
          'ProjectItem-more-btn-container': true,
          'ProjectItem-more-btn-container--active': visible,
        })}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown
            overlay={
              <ProjectOptionsPanel
                projectInfo={projectInfo}
                panelCloseHandler={() => {
                  setVisible(false);
                  disablePointerEventsOnMainLayout(false);
                }}
              />
            }
            placement="bottomRight"
            trigger={['click']}
            visible={visible}
            onVisibleChange={(requestedVisibility: boolean) => {
              setVisible(requestedVisibility);
              disablePointerEventsOnMainLayout(requestedVisibility);
            }}
          >
            <Button
              className="ProjectItem-more-btn"
              shape="circle"
              size="small"
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
          </Dropdown>
        </div>
        <ConnectedAddProject
          initialUserInput={projectInfo}
          visible={isModalOpen}
          closeHandler={modalCloseHandler}
        />
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  count: number;
  projectInfo: ProjectObj;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<ProjectItemProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const result = {
    projectInfo: getProjectById(state, parentProps.projectId),
    count: getPendingTasksCountByProjectId(state, parentProps.projectId),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect ProjectItem to the redux store */
const ConnectedProjectItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectItem);

/** wrap the component to work as a drop zone */

/** allow default import */
export default DropTarget(
  'task',
  {
    drop: (props, monitor) => {
      const item = monitor.getItem();
      const { projectId: requestedProjectId } = props as any;
      const { id, projectId } = item.node;

      if (id && id !== '' && requestedProjectId !== projectId) {
        /** retrieve the task details from store */
        const taskInfo = getTaskById((store as any).getState(), id);

        /** retrieve existing task section and parent */
        const { sectionId, parent } = taskInfo;

        /** transfer the sub tasks to the desired project */
        transferSubTaskListByParent(id, requestedProjectId);

        /** update the existing task */
        store.dispatch(
          setTask({
            ...taskInfo,
            projectId: requestedProjectId,
            sectionId: '',
            assignedTo: getProjectById(
              (store as any).getState(),
              requestedProjectId
            )?.sharedWith?.includes(taskInfo.assignedTo)
              ? taskInfo.assignedTo
              : '',
            parent: '',
            order: -1,
          })
        );

        /** fix the order of the previous task list */
        store.dispatch(fixTaskOrders(projectId, sectionId, parent));

        /** fix the order of the current task list */
        store.dispatch(fixTaskOrders(requestedProjectId, '', ''));
      }
    },
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    isDropped: monitor.didDrop(),
  })
)(ConnectedProjectItem);
