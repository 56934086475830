import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Store } from 'redux';
import { ACCOUNT_APP_LOGOUT_ENDPOINT } from '../../configs/endpoints';
import { getSessionToken, resetSession } from '../../store/ducks/session';
import './SessionDestroy.scss';

/** interface to describe SessionDestroy props*/
interface SessionDestroyProps {
  token: string;
  resetSessionActionCreator: typeof resetSession;
}

const SessionDestroy = (props: SessionDestroyProps) => {
  const { resetSessionActionCreator } = props;
  const location = useLocation();

  /** destroys the session */
  React.useEffect(() => {
    const clearSession = async () => {
      await resetSessionActionCreator();
      window.location.href = ACCOUNT_APP_LOGOUT_ENDPOINT + location.search;
    };

    clearSession();
  }, []);

  return (
    <Spin tip="Signing out of todo">
      <div className="SessionDestroy-container"></div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  resetSessionActionCreator: resetSession,
};

/** connect SessionDestroy to the redux store */
const ConnectedSessionDestroy = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionDestroy);

export default ConnectedSessionDestroy;
