import React from 'react';
import ConnectedIteratorBasedTaskList from '../iteratorBasedTaskList/IteratorBasedTaskList';
import { getSearchTasksCount, TaskObj } from '../../store/ducks/tasks';
import './TodoSearchContent.scss';
import { connect } from 'react-redux';
import { Store } from 'redux';

interface TodoSearchContentProps {
  keyword: string;
  searchCount: number;
}

const TodoSearchContent: React.FC<TodoSearchContentProps> = (
  props: TodoSearchContentProps
) => {
  const { searchCount, keyword } = props;
  const searchIterator = (taskItem: TaskObj) => {
    return taskItem.title.includes(keyword);
  };
  return (
    <div className="TodoSearchContent-container">
      <div className="TodoSearchContent-title-container">
        <h2>
          <span style={{ color: 'black' }}>Search result for</span> {keyword}
        </h2>
        <h5>{searchCount} tasks found</h5>
      </div>
      <ConnectedIteratorBasedTaskList
        iterator={searchIterator}
        preventExpanded={true}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  searchCount: number;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoSearchContentProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const result = {
    searchCount: getSearchTasksCount(state, parentProps.keyword),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoSearchContent to the redux store */
const ConnectedTodoSearchContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoSearchContent);

export default ConnectedTodoSearchContent;
