import { Select } from 'antd';
import React from 'react';
import './SettingsDropdown.scss';

/** interface to describe the option object */
interface OptionObj {
  label: string;
  value: string;
}

/** interface to describe the SettingsDropdown props */
interface SettingsDropdownProps {
  fieldName: string;
  options: OptionObj[];
  selected: string;
  onChangeHandler: (value: string) => void;
}

const SettingsDropdown: React.FC<SettingsDropdownProps> = (
  props: SettingsDropdownProps
) => {
  const { fieldName, options, onChangeHandler, selected } = props;
  const { Option } = Select;
  return (
    <div className="SettingsDropdown-container">
      <div className="SettingsDropdown-fieldname">{fieldName}</div>
      <div className="SettingsDropdown-dropdown-container">
        <Select
          className="SettingsDropdown-dropdown"
          value={selected}
          onChange={onChangeHandler}
          showSearch
        >
          {options.map((option) => (
            <Option
              className="SettingsDropdown-option"
              key={option.value}
              value={option.value}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SettingsDropdown;
