import { Dropdown, Input } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AttachmentInputView from '../../components/todo/attachmentInputView/AttachmentInputView';
import FileInput from '../../components/todo/fileInput/FileInput';
import {
  AttachmentObj,
  CommentObj,
  setComment,
} from '../../store/ducks/comments';
import { Picker } from 'emoji-mart';
import './AddTaskComment.scss';
import { axioService, POST } from '../../services/axioService';
import {
  SERVER_COMMENTS_ENDPOINT,
  SERVER_FILE_UPLOAD_ENDPOINT,
} from '../../configs/endpoints';
import { increaseCommentCount } from '../../store/ducks/tasks';

/** interface AddTaskComment props */
interface AddTaskCommentProps {
  taskId: string;
  setCommentActionCreator: typeof setComment;
  increaseCommentCountActionCreator: typeof increaseCommentCount;
}

/** constants */
const INITIAL_COMMENT: CommentObj = {
  id: '',
  taskId: '',
  title: '',
  timestamp: '',
  attachments: [],
  commenter: {},
};

const AddTaskComment = (props: AddTaskCommentProps) => {
  const {
    taskId,
    setCommentActionCreator,
    increaseCommentCountActionCreator,
  } = props;

  const [text, setText] = React.useState<string>('');
  const [attachments, setAttachments] = React.useState<any>([]);
  const [attachmentVisible, setAttachmentVisible] = React.useState<boolean>(
    false
  );
  const [emojiPickerVisible, setEmojiPickerVisible] = React.useState<boolean>(
    false
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const addCommentHandler = async () => {
    if (text === '' && attachments.length === 0) return;

    /** create the form data request */
    const modifiedAttachments: AttachmentObj[] = await Promise.all(
      attachments.map(
        async (attachment: any): Promise<AttachmentObj> => {
          const request = new FormData();
          request.append('visibility', 'private');
          request.append('attachment', attachment);

          const response: any = await axioService(
            POST,
            SERVER_FILE_UPLOAD_ENDPOINT,
            request,
            true
          );

          return {
            name: attachment.name,
            download_url: response.data?.url,
            url: response.data?.url,
          };
        }
      )
    );
    const request = {
      content: text,
      subject_type: 'task',
      subject_id: taskId,
      attachments: modifiedAttachments,
    };

    const response: any = await axioService(
      POST,
      SERVER_COMMENTS_ENDPOINT,
      request,
      true
    );

    setCommentActionCreator({
      ...INITIAL_COMMENT,
      id: response.data.id,
      taskId,
      title: text,
      timestamp: moment((response as any)?.commented_at).format(
        'YYYY-MM-DD HH:mm'
      ),
      attachments: modifiedAttachments,
    });
    increaseCommentCountActionCreator(taskId);

    setText('');
    setAttachments([]);
    setAttachmentVisible(false);
  };

  const toggleAttachmentHandler = () =>
    setAttachmentVisible(!attachmentVisible);

  const toggleEmojiPickerHandler = () => {
    setAttachmentVisible(false);
    setEmojiPickerVisible(!emojiPickerVisible);
  };

  const addFilesHandler = (files: any) => {
    setAttachments([...attachments, ...files]);
  };

  const deleteByIndex = (deletedIndex: number) => {
    const tmpAttachments = [...attachments];
    tmpAttachments.splice(deletedIndex, 1);
    setAttachments(tmpAttachments);
  };

  const addEmoji = (selectedEmoji: any) => {
    const emoji = selectedEmoji.native;
    setText(text + emoji);
  };

  const propagateEmojiVisibility = (requestedVisibility: boolean) =>
    setEmojiPickerVisible(requestedVisibility);

  return (
    <div className="AddTaskComment-container">
      <div className="AddTaskComment-body-container">
        <div className="AddTaskComment-attachments-container">
          {attachments.map((iterAttachment: any, index: number) => (
            <AttachmentInputView
              deleteByIndex={deleteByIndex}
              key={index + ' ' + iterAttachment.path}
              name={iterAttachment.name}
              size={iterAttachment.size}
              index={index}
            />
          ))}
        </div>
        {!attachmentVisible ? (
          <Input
            value={text}
            onChange={onChangeHandler}
            placeholder="Write your comments here"
            onPressEnter={addCommentHandler}
          />
        ) : (
          <FileInput addFilesHandler={addFilesHandler} />
        )}
      </div>

      <div
        onClick={toggleAttachmentHandler}
        className={clsx({
          'AddTaskComment-options-item': true,
          'AddTaskComment-options-item-active': attachmentVisible,
        })}
      >
        <i className="fas fa-paperclip"></i>
      </div>
      <Dropdown
        overlay={
          <Picker
            onSelect={addEmoji}
            set="google"
            include={['people', 'activity', 'places']}
          />
        }
        placement="topRight"
        trigger={['click']}
        visible={emojiPickerVisible}
        onVisibleChange={propagateEmojiVisibility}
      >
        <div
          className={clsx({
            'AddTaskComment-options-item': true,
            'AddTaskComment-options-item-active': emojiPickerVisible,
          })}
          onClick={toggleEmojiPickerHandler}
        >
          <i className="fas fa-smile"></i>
        </div>
      </Dropdown>
      <div
        onClick={addCommentHandler}
        className={clsx({
          'AddTaskComment-send': true,
          'AddTaskComment-options-item': true,
          'AddTaskComment-options-item-active':
            text !== '' || attachments.length > 0,
        })}
      >
        <i className="fas fa-paper-plane"></i>
      </div>
    </div>
  );
};

/** map props to actions */
const mapDispatchToProps = {
  setCommentActionCreator: setComment,
  increaseCommentCountActionCreator: increaseCommentCount,
};

/** connect AddTaskComment to the redux store */
const ConnectedAddTaskComment = connect(
  null,
  mapDispatchToProps
)(AddTaskComment);

export default ConnectedAddTaskComment;
