import lodash from 'lodash';
import moment from 'moment';
import { v1, v4, v5 } from 'uuid';
import store from '../store';
import { getProjectById } from '../store/ducks/projects';
import { getTasks, TaskObj, updateTasks } from '../store/ducks/tasks';

/**
 * generates a new uuid
 * @returns {string} - the generated unique uuid
 */
export const generateNewUUID = (): string => {
  return v5(v4(), v1());
};

/**
 * compares and checks whether two tree nodes are equal
 * @param treeNodeA - the first tree node
 * @param treeNodeB - the second tree node
 * @returns {boolean} - true if tree nodes are equal; otherwise false
 */
export const customTreeNodeComparator = (treeNodeA: any, treeNodeB: any) => {
  return (
    treeNodeA.id === treeNodeB.id &&
    treeNodeA.parent === treeNodeB.parent &&
    treeNodeA.order === treeNodeB.order &&
    treeNodeA.expanded === treeNodeB.expanded
  );
};

/**
 * flatDataBasedOnHeightIterator recursively iterates through data and adds rows that satisfy the height condition
 * @param {any} data - the rows of data to be filterd
 * @param {any} filteredRows - the rows of data after filter
 * @param {string} parent - the current parent id of iterator
 * @param {number} height - the current height of iterator
 * @param {number} maxheight - the maximum height that is allowed
 */
const flatDataBasedOnHeightIterator = (
  data: any,
  filteredRows: any,
  parent: string,
  height: number,
  maxheight: number
) => {
  const tmpContainer = lodash.filter(data, { parent });
  if (tmpContainer.length === 0 || height > maxheight) {
    return;
  }
  tmpContainer.forEach((node: any) => {
    flatDataBasedOnHeightIterator(
      data,
      filteredRows,
      node.id,
      height + 1,
      maxheight
    );
    filteredRows.push(node);
  });
  return;
};

/**
 * getFlatDataBasedOnHeight filters the input data based on their height. Height starts from 0.
 * @param {any} data - the input data
 * @param {number} maxheight - the maximum height that is allowed. 0 represents only root rows.
 * @returns {any} - filtered rows
 */
export const getFlatDataBasedOnHeight = (data: any, maxheight: number): any => {
  const filteredRows: any = [];
  flatDataBasedOnHeightIterator(data, filteredRows, '', 0, maxheight);
  return filteredRows;
};

/**
 * recursively iterates the dataset to find the maximum height
 * @param data - the input dataset
 * @param currentParent - the current parent of the iterator
 * @param {number} currentHeight - the current height of the iterator
 * @returns {number} - returns the max height based on current parent
 */
const maxHeightIterator = (
  data: any,
  currentParent: string,
  currentHeight: number
): number => {
  const tmpContainer = lodash.filter(data, { parent: currentParent });
  if (tmpContainer.length === 0) {
    return currentHeight;
  }
  let maxHeight = currentHeight;
  tmpContainer.forEach((iterObj: any) => {
    const childHeight = maxHeightIterator(data, iterObj.id, currentHeight + 1);
    if (maxHeight < childHeight) {
      maxHeight = childHeight;
    }
  });
  return maxHeight;
};

/**
 * returns the max height or depth of root node
 * @param {any} data - the input data set
 * @param {string} rootId - the root or start node id
 * @returns {number} - returns the max height or depth of the root node
 */
export const getMaxHeight = (data: any, rootId: string): number => {
  return maxHeightIterator(data, rootId, 0);
};

/**
 * iterates the tree flat data to look and push favourite items
 * @param {FavouriteIteratorType[]} data - the flat data
 * @param {number} currentParent - the current iterator parent
 * @param {FavouriteIteratorType[]} favourties - the favourites container to push into
 */
const favouriteIterator = <FavouriteIteratorType>(
  data: FavouriteIteratorType[],
  currentParent: string,
  favourties: FavouriteIteratorType[]
) => {
  const tmpContainer = lodash.orderBy(
    lodash.filter(data, { parent: currentParent }),
    ['order'],
    ['asc']
  );
  if (tmpContainer.length === 0) {
    return;
  }
  tmpContainer.forEach((iterObj: any) => {
    if (iterObj.isFavourite) {
      favourties.push(iterObj);
    }
    favouriteIterator(data, iterObj.id, favourties);
  });
  return;
};

/**
 * returns the list of favourites with the tree order
 * @param {FavouriteType[]} data - the flat rows of data
 * @returns {FavouriteType[]} - the list of favourite items
 */
export const getFavourites = <FavouriteType>(
  data: FavouriteType[]
): FavouriteType[] => {
  const favourites: FavouriteType[] = [];
  favouriteIterator<FavouriteType>(data, '', favourites);
  return favourites;
};

/**
 * iterator for the transfer sub tasks list
 * @param {TaskObj[]} transferTasks - the tasks that will be transfered
 * @param {TaskObj[]} currentTasks - the current tasks at the store
 * @param {string} parentId - the parent task id
 * @param {string} projectId - the project id where the tasks will be transfered
 */
const transferSubTasksListIterator = (
  transferTasks: TaskObj[],
  currentTasks: TaskObj[],
  parentId: string,
  projectId: string
) => {
  const immediateTasks = lodash.filter(currentTasks, { parent: parentId });
  immediateTasks.forEach((iterTask) => {
    transferSubTasksListIterator(
      transferTasks,
      currentTasks,
      iterTask.id,
      projectId
    );
    transferTasks.push({
      ...iterTask,
      synced: false,
      projectId,
      assignedTo: getProjectById(
        (store as any).getState(),
        projectId
      )?.sharedWith.includes(iterTask.assignedTo)
        ? iterTask.assignedTo
        : '',
      sectionId: '',
    });
  });
};

/**
 * transfers the sub tasks to the requested project id
 * @param {string} parentId - the requested parent id
 * @param {string} projectId - the requested project id
 */
export const transferSubTaskListByParent = (
  parentId: string,
  projectId: string
) => {
  const currentTasks = getTasks((store as any).getState());
  const transferTasks: TaskObj[] = [];
  transferSubTasksListIterator(
    transferTasks,
    currentTasks,
    parentId,
    projectId
  );
  (store as any).dispatch(updateTasks(transferTasks));
};

/**
 * complete sub tasks list iterator
 * @param {TaskObj[]} completeTasks - the tasks that will be completed
 * @param {TaskObj[]} currentTasks - the current tasks at the store
 * @param {string} parentId - the parent task id
 */
const completeSubTasksListIterator = (
  completeTasks: TaskObj[],
  currentTasks: TaskObj[],
  parentId: string
) => {
  const immediateTasks = lodash.filter(currentTasks, { parent: parentId });
  immediateTasks.forEach((iterTask) => {
    completeSubTasksListIterator(completeTasks, currentTasks, iterTask.id);
    completeTasks.push({ ...iterTask, synced: false, completed: true });
  });
};

/**
 * completes the sub tasks given the parent id
 * @param {string} parentId - the requested parent id
 */
export const completeSubTaskListByParent = (parentId: string) => {
  const currentTasks = getTasks((store as any).getState());
  const completeTasks: TaskObj[] = [];
  completeSubTasksListIterator(completeTasks, currentTasks, parentId);
  (store as any).dispatch(updateTasks(completeTasks));
};

/**
 * returns the children projects given a parent and a data table
 * @param  {ItemType[]} dataTable- the dataTable holding the valid rows
 * @param  {string} parentId - the parent id
 * @param  {ItemType[]} children - the initial projects to include
 * @returns  {ItemType[]} the filtered children projects that are under the parent id
 */
export const getChildItemsFromParent = <ItemType>(
  dataTable: ItemType[],
  parentId: string,
  children: ItemType[]
): ItemType[] => {
  /** filter the immediate child ids */
  const filteredRows: any = lodash.filter(dataTable, {
    parent: parentId,
  });

  /** iterate and add the next child ids recursively */
  return filteredRows.reduce(
    (accumulator: ItemType[], currentValue: ItemType) => {
      return getChildItemsFromParent(
        dataTable,
        (currentValue as any).id,
        accumulator
      );
    },
    [...children, ...filteredRows]
  );
};

/**
 * returns the parents given the immediate parent id
 * @param {ItemType[]} dataTable - the dataTable holding the valid rows
 * @param {string} immediateParentId - the immediate parent id
 * @param {ItemType[]} parents - the initial parents to include
 * @returns {ItemType[]} - the existing parent that are above the immediate parent id
 */
export const getParentsFromChild = <ItemType>(
  dataTable: ItemType[],
  immediateParentId: string,
  parents: ItemType[]
): ItemType[] => {
  if (immediateParentId === '') {
    return parents;
  } else {
    const parentInfo: any = lodash.find(dataTable, { id: immediateParentId });
    return getParentsFromChild(dataTable, (parentInfo as any)?.parent || '', [
      parentInfo,
      ...parents,
    ]);
  }
};

/**
 * generate the date series in interval month in given interval
 * @param {moment.Moment} startDate - the start date
 * @param {moment.Moment} endDate - the end date
 * @returns {string[]} - the series that satisfies the condition
 */
export const generateMonths = (
  startDate: moment.Moment,
  endDate: moment.Moment
): string[] => {
  const months = [];
  const currentDate = startDate.clone();
  while (endDate.diff(currentDate, 'months') >= 0) {
    months.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'month');
  }
  return months;
};

/**
 * generate the date series in interval day in given interval
 * @param {moment.Moment} startDate - the start date
 * @param {moment.Moment} endDate - the end date
 * @returns {string[]} - the series that satisfies the condition
 */
export const generateDays = (
  startDate: moment.Moment,
  endDate: moment.Moment
): string[] => {
  const days = [];
  const currentDate = startDate.clone();
  while (endDate.diff(currentDate, 'days') >= 0) {
    days.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'day');
  }
  return days;
};

/**
 * disable pointer events on main layout based on isDisable flag
 * @param {boolean} isDisable - the requested flag
 */
export const disablePointerEventsOnMainLayout = (isDisable: boolean) => {
  if (isDisable) {
    document
      .getElementsByClassName('withTodoLayout-layout-container')[0]
      ?.classList.add('withTodoLayout-layout-container--disabled');
  } else {
    document
      .getElementsByClassName('withTodoLayout-layout-container')[0]
      ?.classList.remove('withTodoLayout-layout-container--disabled');
  }
};

/**
 * disable pointer events on task sider based on isDisable flag
 * @param {boolean} isDisable - the requested flag
 */
export const disablePointerEventsOnTaskSider = (isDisable: boolean) => {
  if (isDisable) {
    document
      .getElementsByClassName('withTaskSider-container')[0]
      ?.classList.add('withTaskSider-container--disabled');
  } else {
    document
      .getElementsByClassName('withTaskSider-container')[0]
      ?.classList.remove('withTaskSider-container--disabled');
  }
};
