import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './TodoProject.scss';
import TodoProjectContent from '../../../containers/todoProjectContent/TodoProjectContent';
import { useParams } from 'react-router-dom';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';

const TodoProject: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return <TodoProjectContent projectId={id} />;
};

export default withTaskSider(withTodoLayout(TodoProject));
