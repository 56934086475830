import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './TodoLabelPage.scss';
import { useParams } from 'react-router-dom';
import ConnectedTodoLabelContent from '../../../containers/todoLabelContent/TodoLabelContent';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';

const TodoLabelPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return <ConnectedTodoLabelContent labelId={id} />;
};

export default withTaskSider(withTodoLayout(TodoLabelPage));
