import moment from 'moment';
import React from 'react';
import MomentTimePicker from '../../general/momentTimePicker/MomentTimePicker';
import './RecurrenceTimePicker.scss';

/** interface to describe the RecurrenceDayPicker props */
interface RecurrenceDayPickerProps {
  index: number;
  values: moment.Moment[];
  onChangeHandler: (requested: moment.Moment[]) => void;
}

const RecurrenceTimePicker: React.FC<RecurrenceDayPickerProps> = (
  props: RecurrenceDayPickerProps
) => {
  const { index, values, onChangeHandler } = props;

  /**
   * modifyHandler handles the on change request of time picker
   * @param {moment.Moment | null} value - the on changed value
   */
  const modifyHandler = (value: moment.Moment | null) => {
    const tmp = [...values];
    if (value) {
      tmp.splice(index, 1, value);
      onChangeHandler(tmp);
    } else {
      tmp.splice(index, 1);
      onChangeHandler(tmp);
    }
  };

  return (
    <MomentTimePicker
      selectedTime={values[index]}
      timeChangeHandler={modifyHandler}
      use12HoursFormat={true}
    />
  );
};

export default RecurrenceTimePicker;
