import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import { FlexObj } from './session';

/** interface for Meeting State */
export interface MeetingState {
  total: number;
  upcoming: number;
  cancel: number;
  meetings: FlexObj[];
}

/** The reducer name */
export const reducerName = 'meeting';

// actions
/** action types */
export const SET_MEETING_INFO = 'virtunus/reducer/meeting/SET_MEETING_INFO';
export const SET_MEETINGS = 'virtunus/reducer/meeting/SET_MEETINGS';

/** interface for SET_MEETING_INFO action */
export interface SetMeetingInfoAction extends AnyAction {
  total: number;
  upcoming: number;
  cancel: number;
  type: typeof SET_MEETING_INFO;
}

/** interface for SET_MEETINGS action */
export interface SetMeetingsAction extends AnyAction {
  meetings: FlexObj[];
  type: typeof SET_MEETINGS;
}

/** Create type for meeting reducer actions */
export type MeetingActionTypes =
  | SetMeetingInfoAction
  | SetMeetingsAction
  | AnyAction;

// action creators

/** set meeting counts action creator
 * @param {MeetingState} requestedCounts - requested counts to be set
 * @returns {SetSessionTokenAction} - an action to set the requested counts in store
 */
export const setMeetingInfo = (requestedCounts: {
  total: number;
  upcoming: number;
  cancel: number;
}): SetMeetingInfoAction => ({
  ...requestedCounts,
  type: SET_MEETING_INFO,
});

/**
 * set meetings action creator
 * @param {FlexObj} meetings - the requested meetings to set
 * @returns {SetMeetingsAction} - an action to set the requested meetings in store
 */
export const setMeetings = (meetings: FlexObj[]): SetMeetingsAction => ({
  meetings,
  type: SET_MEETINGS,
});

// the reducer

/** Create an immutable meeting state */
export type ImmutableMeetingState = SeamlessImmutable.ImmutableObject<
  MeetingState
>;

/** initial meeting state */
const initialState: ImmutableMeetingState = SeamlessImmutable({
  total: 0,
  upcoming: 0,
  cancel: 0,
  meetings: [],
});

/** the meeting reducer function */
export default function reducer(
  state: ImmutableMeetingState = initialState,
  action: MeetingActionTypes
): ImmutableMeetingState {
  switch (action.type) {
    case SET_MEETING_INFO:
      return SeamlessImmutable({
        ...state.asMutable({ deep: true }),
        total: action.total,
        upcoming: action.upcoming,
        cancel: action.cancel,
      });
    case SET_MEETINGS:
      return SeamlessImmutable({
        ...state.asMutable({ deep: true }),
        meetings: action.meetings,
      });
    default:
      return state;
  }
}

// selectors

/** returns the meeting state
 * @param {Partial<Store>} state - the redux store
 * @return { string } - the existing meeting state
 */
export function getMeetingCountObj(state: Partial<Store>): MeetingState {
  return (state as any)[reducerName];
}
