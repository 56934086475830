import { Button } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import './TaskReminderViewItem.scss';

interface TaskReminderViewItemProps {
  scheduleDate: moment.Moment;
  id: string;
  deleteHandler: (requested: string) => void;
}

const TaskReminderViewItem: React.FC<TaskReminderViewItemProps> = (
  props: TaskReminderViewItemProps
) => {
  const { scheduleDate, id, deleteHandler } = props;

  const clickHandler = () => {
    deleteHandler(id);
  };

  return (
    <div
      className={clsx({
        'TaskReminderViewItem-container': true,
        'TaskReminderViewItem-passed': scheduleDate.isBefore(moment()),
      })}
    >
      <div className="TaskReminderViewItem-history">
        <i className="fas fa-history" />
      </div>
      <div className="TaskReminderViewItem-date">
        {scheduleDate.format('MMM DD YYYY, hh:mm A')}
      </div>
      <div>
        <Button
          className="TaskReminderViewItem-delete"
          shape="circle"
          size="small"
          onClick={clickHandler}
        >
          <i className="fas fa-trash-alt" />
        </Button>
      </div>
    </div>
  );
};

export default TaskReminderViewItem;
