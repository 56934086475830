import { Input, Select } from 'antd';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import UploadToolbar, { FileObj } from '../../todo/uploadToolbar/UploadToolbar';
import { DURATIONS, RELATIVE_MEETING_OPTIONS } from './constants';
import './MeetingWorkflowItem.scss';

/** interface to describe mail data */
interface MailData {
  type: string;
  subject: string;
  description: any;
  duration: string;
  relative: string;
  attachments: FileObj[];
}

/** interface to describe MeetingWorkflowItem props */
interface MeetingWorkflowItemProps {
  mailData: MailData;
  onChangeHandler: (requested: MailData) => void;
  onBlurHandler: () => void;
}

const MeetingWorkflowItem: React.FC<MeetingWorkflowItemProps> = (
  props: MeetingWorkflowItemProps
) => {
  const { mailData, onChangeHandler } = props;

  /** subject change handler */
  const subjectChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandler({ ...mailData, subject: event.target.value });
  };

  /** editor handler */
  const editorChangeHandler = (value: any) => {
    onChangeHandler({ ...mailData, description: value });
  };

  /** relative change handler */
  const relativeChangeHandler = (value: string) => {
    onChangeHandler({ ...mailData, relative: value });
  };

  /** duration change handler */
  const durationChangeHandler = (value: string) => {
    onChangeHandler({ ...mailData, duration: value });
  };

  /** attachments change handler */
  const onAttachmentsChangeHandler = (attachments: FileObj[]) => {
    onChangeHandler({ ...mailData, attachments });
  };

  return (
    <div className="MeetingWorkflowItem-container">
      <div className="MeetingWorkflowItem-field-container">
        <div className="MeetingWorkflowItem-field-label">Subject</div>
        <div className="MeetingWorkflowItem-field-body">
          <Input
            value={mailData.subject}
            onChange={subjectChangeHandler}
            placeholder="Write your subject here"
          />
        </div>
      </div>
      <div className="MeetingWorkflowItem-field-container">
        <div className="MeetingWorkflowItem-field-label">Description</div>
        <div className="MeetingWorkflowItem-field-body">
          <Editor
            editorState={mailData.description}
            onEditorStateChange={editorChangeHandler}
            toolbar={{
              options: ['history', 'inline', 'link', 'list'],
              history: { options: ['undo', 'redo'] },
              inline: { options: ['bold', 'italic', 'underline'] },
              link: { options: ['link'] },
              list: {
                options: ['ordered', 'unordered', 'indent', 'outdent'],
              },
            }}
            placeholder="Write text here"
            toolbarCustomButtons={[
              <UploadToolbar
                fileList={mailData.attachments || []}
                setFileList={onAttachmentsChangeHandler}
                key="upload"
              />,
            ]}
          />
        </div>
      </div>
      <div className="MeetingWorkflowItem-shared-field-container">
        <div className="MeetingWorkflowItem-field-relative">
          <Select
            value={mailData.relative}
            options={RELATIVE_MEETING_OPTIONS}
            onChange={relativeChangeHandler}
          />
        </div>
        <div className="MeetingWorkflowItem-field-duration">
          <Select
            value={mailData.duration}
            options={DURATIONS}
            onChange={durationChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetingWorkflowItem;
