import { Divider, TimePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import SettingsDropdown from '../../components/settings/settingsDropdown/SettingsDropdown';
import {
  SERVER_SETTINGS_ENDPOINT,
  SERVER_TIMEZONES_ENDPOINT,
} from '../../configs/endpoints';
import withSettingsLayout from '../../hocs/withSettingsLayout/withSettingsLayout';
import { axioService, GET, POST } from '../../services/axioService';
import {
  getSettings,
  setSettings,
  SettingsObj,
} from '../../store/ducks/settings';
import './GeneralSettings.scss';

interface GeneralSettingsProps {
  settings: SettingsObj;
  setSettingsActionCreator: typeof setSettings;
}

/** the time format to use */
const TIME_FORMAT = 'HH:mm';

const GeneralSettings: React.FC<GeneralSettingsProps> = (
  props: GeneralSettingsProps
) => {
  const { settings, setSettingsActionCreator } = props;
  const [timeZoneOptions, setTimeZoneOptions] = React.useState<any>([]);

  React.useEffect(() => {
    const fetchTimeZoneOptions = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TIMEZONES_ENDPOINT,
          {},
          true
        );
        setTimeZoneOptions(
          response.data.zones.map((iterZone: string) => ({
            label: iterZone,
            value: iterZone,
          }))
        );
      } catch (exception) {
        console.error(exception);
      }
    };

    fetchTimeZoneOptions();
  }, []);

  /**
   * handles the request on change of next week
   * @param {string} value - requested value to set
   */
  const nextWeekHandler = async (value: string) => {
    try {
      await axioService(
        POST,
        SERVER_SETTINGS_ENDPOINT,
        {
          todo: {
            next_week: value,
          },
        },
        true
      );
      setSettingsActionCreator({ nextWeek: value });
    } catch (exception) {
      console.error(exception);
    }
  };

  /**
   * handles the request on change of time format
   * @param {string} value - requested value to set
   */
  const timeFormatHandler = async (value: string) => {
    try {
      await axioService(
        POST,
        SERVER_SETTINGS_ENDPOINT,
        {
          general: {
            time_format: value,
          },
        },
        true
      );
      setSettingsActionCreator({ timeFormat: value });
    } catch (exception) {
      console.error(exception);
    }
  };

  /**
   * handles the request on change of date format
   * @param {string} value - requested value to set
   */
  const dateFormatHandler = async (value: string) => {
    try {
      await axioService(
        POST,
        SERVER_SETTINGS_ENDPOINT,
        {
          general: {
            date_format: value,
          },
        },
        true
      );
      setSettingsActionCreator({ dateFormat: value });
    } catch (exception) {
      console.error(exception);
    }
  };

  const timeZoneHandler = async (value: string) => {
    try {
      await axioService(
        POST,
        SERVER_SETTINGS_ENDPOINT,
        {
          general: {
            timezone: value,
          },
        },
        true
      );
      setSettingsActionCreator({ timezone: value });
    } catch (exception) {
      console.error(exception);
    }
  };

  /**
   * handles the request on change of task time completion
   * @param {any} requestedTime - requested time to set
   */
  const defaultCompletionTimeHandler = async (requestedTime: any) => {
    try {
      await axioService(
        POST,
        SERVER_SETTINGS_ENDPOINT,
        {
          todo: {
            completion_time: requestedTime.format(TIME_FORMAT),
          },
        },
        true
      );
      setSettingsActionCreator({
        defaultCompletionTime: requestedTime.format(TIME_FORMAT),
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <div className="GeneralSettings-container">
      <SettingsDropdown
        fieldName={'Language'}
        selected={settings.language}
        options={[{ label: 'English', value: 'english' }]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChangeHandler={() => {}}
      />
      <Divider className="GeneralSettings-divider" />
      <div className="GeneralSettings-section-title">Date & time </div>
      <SettingsDropdown
        fieldName={'Time zone'}
        selected={settings.timezone}
        options={timeZoneOptions}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChangeHandler={timeZoneHandler}
      />
      <SettingsDropdown
        fieldName={'Date Format'}
        selected={settings.dateFormat}
        options={[
          { label: 'D MMM YYYY', value: 'D MMM YYYY' },
          { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
          { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
        ]}
        onChangeHandler={dateFormatHandler}
      />
      <SettingsDropdown
        fieldName={'Time Format'}
        selected={settings.timeFormat}
        options={[
          { label: '13:30', value: '24' },
          { label: '01:30 pm', value: '12' },
        ]}
        onChangeHandler={timeFormatHandler}
      />
      <Divider className="GeneralSettings-divider" />
      <div className="SettingsDropdown-container">
        <div className="SettingsDropdown-fieldname">Completion Time</div>
        <div>
          <TimePicker
            className="GeneralSettings-timepicker"
            value={moment(settings.defaultCompletionTime, TIME_FORMAT)}
            format={TIME_FORMAT}
            onChange={defaultCompletionTimeHandler}
            placeholder="Time"
            allowClear={false}
            use12Hours={settings.timeFormat === '12'}
          />
        </div>
      </div>
      <SettingsDropdown
        fieldName={'Next week'}
        selected={settings.nextWeek}
        options={[
          { label: 'Sunday', value: 'Sunday' },
          { label: 'Monday', value: 'Monday' },
          { label: 'Tuesday', value: 'Tuesday' },
          { label: 'Wednesday', value: 'Wednesday' },
          { label: 'Thursday', value: 'Thursday' },
          { label: 'Friday', value: 'Friday' },
          { label: 'Saturday', value: 'Saturday' },
        ]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChangeHandler={nextWeekHandler}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  settings: SettingsObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    settings: getSettings(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSettingsActionCreator: setSettings,
};

/** connect GeneralSettings to the redux store */
const ConnectedGeneralSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettings);

export default withSettingsLayout(ConnectedGeneralSettings);
