import React from 'react';
import './withFolderLayout.scss';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';

/** interface to describe the withFolder hoc injecting props */
interface withFolderLayoutProps {
  isModalOpen: boolean;
  modalOpenHandler: () => void;
  modalCloseHandler: () => void;
}

/**
 * withFolderLayout injects the section folder layout with additional modal properties
 * @param {React.FC<ComponentPropsType>} Component - the component that will be wrapped
 * @param {string} label - the section label
 * @param {string} tooltipTitle - string
 * @returns {React.FC<Pick<ComponentPropsType,Exclude<keyof ComponentPropsType, keyof withFolderLayoutProps>>} - a wrapped component with the additional injecting props
 */
const withFolderLayout = <ComponentPropsType extends withFolderLayoutProps>(
  Component: React.FC<ComponentPropsType>,
  label: string,
  tooltipTitle: string
): React.FC<
  Pick<
    ComponentPropsType,
    Exclude<keyof ComponentPropsType, keyof withFolderLayoutProps>
  >
> => {
  return (
    props: Pick<
      ComponentPropsType,
      Exclude<keyof ComponentPropsType, keyof withFolderLayoutProps>
    >
  ) => {
    {
      // React component states
      /** manages the section expand and collapse */
      const [expand, setExpand] = React.useState<boolean>(true);
      /** manages the modal open and close */
      const [open, setOpen] = React.useState<boolean>(false);

      /**
       * modalOpenHandler sets the modal open
       */
      const modalOpenHandler = () => {
        setOpen(true);
      };

      /**
       * modalCloseHandler sets the modal close
       */
      const modalCloseHander = () => {
        setOpen(false);
      };

      /**
       * onExpandHandler toggles the expanded panel
       */
      const onExpandHandler = () => {
        setExpand(!expand);
      };

      /**
       * onAddHandler stops the event propagation to parent component and also opens the modal
       * @param {React.MouseEvent} event - the default React mouse event
       */
      const onAddHandler = (event: React.MouseEvent) => {
        /** request to open the modal */
        modalOpenHandler();
        /** stops further progression */
        event.stopPropagation();
      };

      /**
       * handles the expand keyboard request
       * @param {React.KeyboardEvent} event - react keyboard event
       */
      const handleKeyPressExpand = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          onExpandHandler();
        }
      };

      /**
       * handles the add keyboard request
       * @param {any} event - react keyboard event
       */
      const handleKeyPressOnAdd = (event: any) => {
        if (event.key === 'Enter') {
          onAddHandler(event);
        }
      };

      React.useEffect(() => {
        (document as any)
          .querySelectorAll('.withFolderLayout-body')
          .forEach((iter: any) => {
            iter.classList.add('withFolderLayout-body-animation-off');
            setTimeout(
              () => iter.classList.remove('DaySection-body-animation-off'),
              100
            );
          });
      }, []);

      return (
        <div className="withFolderLayout-container">
          <div
            onClick={onExpandHandler}
            onKeyPress={handleKeyPressExpand}
            className="withFolderLayout-header"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          >
            <div className="withFolderLayout-header-inner">
              <div
                className={clsx({
                  'withFolderLayout-expand-icon': true,
                  'withFolderLayout-expand-icon-active': expand,
                })}
              >
                {expand ? (
                  <i className="fas fa-folder-open"></i>
                ) : (
                  <i className="fas fa-folder"></i>
                )}
              </div>
              <div
                className={clsx({
                  'withFolderLayout-label': true,
                  'withFolderLayout-label-active': expand,
                })}
              >
                {label}
              </div>
              <Tooltip title={tooltipTitle} placement="bottom">
                <div
                  className={clsx({
                    'withFolderLayout-add-icon': true,
                    'withFolderLayout-add-icon-active': expand,
                  })}
                >
                  <Button
                    onKeyPress={handleKeyPressOnAdd}
                    onClick={onAddHandler}
                    tabIndex={0}
                    shape="circle"
                    size="small"
                    icon={<i className="fas fa-plus"></i>}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={clsx({
              'withFolderLayout-body': true,
              'withFolderLayout-body-hidden': !expand,
              'withFolderLayout-body-active': expand,
            })}
          >
            <Component
              {...(props as ComponentPropsType)}
              isModalOpen={open}
              modalOpenHandler={modalOpenHandler}
              modalCloseHandler={modalCloseHander}
            />
          </div>
        </div>
      );
    }
  };
};

/** the default import */
export default withFolderLayout;
