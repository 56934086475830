import React from 'react';
import './TaskItem.scss';
import { Button, Checkbox, Dropdown, message, Tooltip } from 'antd';
import clsx from 'clsx';
import {
  fixTaskOrders,
  getTaskById,
  getTasks,
  setTask,
  TaskObj,
  updateTasks,
} from '../../store/ducks/tasks';
import {
  LOW_PRIORITY_VALUE,
  MIDDLE_PRIORITY_VALUE,
  NO_PRIORITY_VALUE,
  TOP_PRIORITY_VALUE,
} from '../../components/todo/todoPriorityDropdown/constants';
import TaskViewItem from '../taskViewItem/TaskViewItem';
import TodoTaskOptionsPanel from '../../components/todo/todoTaskOptionsPanel/TodoTaskOptionsPanel';
import ConnectedAddTask from '../addTask/AddTask';
import { Store } from 'redux';
import { connect } from 'react-redux';
import {
  completeSubTaskListByParent,
  getChildItemsFromParent,
} from '../../services/utility';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import { MEETING_ACTIVTY_TYPE } from '../../configs/activityTypes';

export interface TreeMeta {
  parent: string;
  order: number;
}

export interface TaskItemProps {
  path: number;
  task: Omit<TaskObj, keyof TreeMeta>;
  taskInfo: TaskObj;
  childrenTasks: TaskObj[];
  preventExpanded?: boolean;
  setTaskActionCreator: typeof setTask;
  updateTasksActionCreator: typeof updateTasks;
  fixTaskOrdersActionCreator: typeof fixTaskOrders;
}

const TaskItem: React.FC<TaskItemProps> = (props: TaskItemProps) => {
  const {
    preventExpanded,
    task,
    path,
    taskInfo,
    childrenTasks,
    setTaskActionCreator,
    fixTaskOrdersActionCreator,
    updateTasksActionCreator,
  } = props;

  const history = useHistory();

  const [complete, setComplete] = React.useState<boolean>(false);

  /** manages the visibility of task more panel */
  const [editVisible, setEditVisible] = React.useState<boolean>(false);

  /** manages the visibility of task more panel */
  const [visible, setVisible] = React.useState<boolean>(false);

  const [opacity, setOpacity] = React.useState<boolean>(true);

  /** resets the edit visibility if order is changed */
  React.useMemo(() => {
    setEditVisible(false);
  }, [taskInfo.id]);

  React.useMemo(() => {
    setComplete(taskInfo.completed);
  }, [taskInfo.id, taskInfo.completed]);

  /** opens the edit input  */
  const editRequestHandler = () => setEditVisible(true);

  /** closes the edit input  */
  const editCloseHandler = () => setEditVisible(false);

  /** requests to complete the task */
  const onComplete = (event: any): void => {
    event.stopPropagation();
    setComplete(event.target.checked);

    setOpacity(false);
    setTimeout(() => {
      setTaskActionCreator({
        ...taskInfo,
        synced: false,
        completed: event.target.checked,
      });
      if (event.target.checked) {
        const completedTasksCount =
          lodash.filter(childrenTasks, { completed: false }).length + 1;
        if (completedTasksCount > 1) {
          message.success(`${completedTasksCount} tasks completed`);
        } else {
          message.success(`1 task completed`);
        }
        completeSubTaskListByParent(taskInfo.id);
      } else {
        message.warning('1 task marked incomplete');
      }
      fixTaskOrdersActionCreator(
        taskInfo.projectId,
        taskInfo.sectionId,
        taskInfo.parent
      );
      setOpacity(true);
    }, 300);
  };

  const onTaskAssigned = (assignedId: string): void => {
    setTaskActionCreator({
      ...taskInfo,
      synced: false,
      assignedTo: assignedId,
    });
  };

  /** opens the task details panel */
  const openTaskSider = () => {
    history.push({
      search:
        taskInfo?.activityType === MEETING_ACTIVTY_TYPE.value
          ? `task=${task.id}&tab=3`
          : `task=${task.id}`,
    });
  };

  /** prevents bubbling of unwanted events */
  const stopEventBubbling = (event: React.MouseEvent) =>
    event.stopPropagation();

  const deleteRequestHandler = () => {
    setTaskActionCreator({ ...taskInfo, synced: false, deleted: true });

    /** warning: syncing sub tasks deletion as well */
    const tmpSubTasks = childrenTasks.map((iterTask: TaskObj) => ({
      ...iterTask,
      synced: false,
      deleted: true,
    }));
    updateTasksActionCreator(tmpSubTasks);

    fixTaskOrdersActionCreator(
      taskInfo.projectId,
      taskInfo.sectionId,
      taskInfo.parent
    );
  };

  const toggleExpand = (event: React.MouseEvent) => {
    event.stopPropagation();
    setTaskActionCreator({
      ...taskInfo,
      synced: false,
      expanded: !taskInfo.expanded,
    });
  };

  /**
   * handles the requests of edit on task
   * @param event {React.MouseEvent} - the mouse event
   */
  const editOpenHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    editRequestHandler();
  };

  return !editVisible ? (
    <React.Fragment>
      <div
        /** dependency: TaskViewItem depends on TaskItem-container classname */
        className={clsx({
          'TaskItem-container': true,
          'TaskItem-container--first': path === 1,
          'TaskItem-container--second': path === 2,
          'TaskItem-container--third': path === 3,
          'TaskItem-container--fourth': path === 4,
          'TaskItem-container--fifth': path === 5,
          'TaskItem-container--completed': task.completed,
        })}
        style={{ opacity: opacity ? 1 : 0 }}
        onClick={openTaskSider}
      >
        <div className="TaskItem-view-container">
          {!preventExpanded && childrenTasks.length > 0 && (
            <div onClick={toggleExpand} className="TaskItem-collapse-container">
              {!taskInfo.expanded ? (
                <i className="fas fa-chevron-right" />
              ) : (
                <i className="fas fa-chevron-down" />
              )}{' '}
              {childrenTasks.length}
            </div>
          )}
          {preventExpanded && childrenTasks.length > 0 && (
            <div className="TaskItem-collapse-container">
              <i className="fas fa-chevron-right TaskItem-prevent-expand" />{' '}
              {childrenTasks.length}
            </div>
          )}
          <Checkbox
            onClick={stopEventBubbling}
            checked={complete}
            onChange={onComplete}
            className={clsx({
              'TaskItem-checkbox': true,
              'top-priority': task.priority === TOP_PRIORITY_VALUE,
              'middle-priority': task.priority === MIDDLE_PRIORITY_VALUE,
              'low-priority': task.priority === LOW_PRIORITY_VALUE,
              'no-priority': task.priority === NO_PRIORITY_VALUE,
            })}
          />
          <div
            className={clsx({
              'TaskItem-label': true,
              'TaskItem-label--first': path === 1,
              'TaskItem-label--second': path === 2,
              'TaskItem-label--third': path === 3,
              'TaskItem-label--fourth': path === 4,
              'TaskItem-label--fifth': path === 5,
            })}
          >
            <TaskViewItem taskId={task.id} onTaskAssigned={onTaskAssigned} />
          </div>
          <Tooltip title="Edit" placement="bottom">
            <div className="TaskItem-edit" onClick={editOpenHandler}>
              <i className="fas fa-pen"></i>
            </div>
          </Tooltip>
          <div
            className={clsx({
              'TaskItem-more-btn-container': true,
              'TaskItem-more-btn-container-active': visible,
            })}
            onClick={stopEventBubbling}
          >
            <Dropdown
              overlay={
                <TodoTaskOptionsPanel
                  taskInfo={taskInfo}
                  panelCloseHandler={() => setVisible(false)}
                  editRequestHandler={editRequestHandler}
                  deleteRequestHandler={deleteRequestHandler}
                />
              }
              placement="bottomRight"
              trigger={['click']}
              visible={visible}
              onVisibleChange={(requestedVisibility: boolean) =>
                setVisible(requestedVisibility)
              }
            >
              <Button className="TaskItem-more-btn" shape="circle" size="small">
                <i className="fas fa-ellipsis-v"></i>
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <div
      className={clsx({
        'TaskItem-edit-container': true,
        'TaskItem-edit-container--first': path === 1,
        'TaskItem-edit-container--second': path === 2,
        'TaskItem-edit-container--third': path === 3,
        'TaskItem-edit-container--fourth': path === 4,
        'TaskItem-edit-container--fifth': path === 5,
      })}
    >
      <ConnectedAddTask
        taskInfo={taskInfo}
        projectId={task.projectId}
        sectionId={task.sectionId}
        submitLabel={'Save'}
        postCloseHandler={editCloseHandler}
        postSuccessHandler={editCloseHandler}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  taskInfo: TaskObj;
  childrenTasks: TaskObj[];
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TaskItemProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const allTasks = lodash.filter(getTasks(state), { deleted: false });
  const result = {
    taskInfo: getTaskById(state, parentProps.task.id),
    childrenTasks: getChildItemsFromParent(allTasks, parentProps.task.id, []),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTaskActionCreator: setTask,
  fixTaskOrdersActionCreator: fixTaskOrders,
  updateTasksActionCreator: updateTasks,
};

/** connect TaskItem to the redux store */
const ConnectedTaskItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskItem);

export default ConnectedTaskItem;
