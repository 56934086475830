export const NAVIGATION_DAILY = 'daily';
export const NAVIGATION_WEEKLY = 'weekly';
export const NAVIGATION_MONTHLY = 'monthly';
export const NAVIGATION_YEARLY = 'yearly';

export const NAVIGATION_INTERVAL_OPTIONS: string[] = [
  NAVIGATION_DAILY,
  NAVIGATION_WEEKLY,
  NAVIGATION_MONTHLY,
  NAVIGATION_YEARLY,
];
