import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './TodoTodayPage.scss';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';
import TodoTodayContent from '../../../containers/todoTodayContent/TodoTodayContent';

const TodoTodayPage: React.FC = () => {
  return <TodoTodayContent />;
};

export default withTaskSider(withTodoLayout(TodoTodayPage));
