import { Button } from 'antd';
import React from 'react';
import './todoProjectFavouriteButton.scss';
import { ProjectObj, setProject } from '../../store/ducks/projects';
import { connect } from 'react-redux';

/** interface to describe TodoProjectFavouriteButton props */
interface TodoProjectFavouriteButtonProps {
  projectInfo: ProjectObj;
  setProjectActionCreator: typeof setProject;
  closeHandler: () => void;
}

/** Placeholders subject to Favourite flag */
const ADD_TO_FAVOURITE_LABEL = 'Add to favourites';
const REMOVE_FROM_FAVOURITE_LABEL = 'Remove from favourites';

const TodoProjectFavouriteButton: React.FC<TodoProjectFavouriteButtonProps> = (
  props: TodoProjectFavouriteButtonProps
) => {
  const { projectInfo, setProjectActionCreator, closeHandler } = props;

  /** manages the favourite toogle request */
  const favouriteHandler = () => {
    setProjectActionCreator({
      ...projectInfo,
      synced: false,
      isFavourite: !projectInfo.isFavourite,
    });
    closeHandler();
  };

  return (
    <Button
      onClick={favouriteHandler}
      icon={
        projectInfo.isFavourite ? (
          <i className="fas fa-heart-broken"></i>
        ) : (
          <i className="fas fa-heart"></i>
        )
      }
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="ProjectOptionsPanel-item"
    >
      {projectInfo.isFavourite
        ? REMOVE_FROM_FAVOURITE_LABEL
        : ADD_TO_FAVOURITE_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setProjectActionCreator: setProject,
};

/** connect TodoProjectFavouriteButton to the redux store */
const ConnectedTodoProjectFavouriteButton = connect(
  null,
  mapDispatchToProps
)(TodoProjectFavouriteButton);

export default ConnectedTodoProjectFavouriteButton;
