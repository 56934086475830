import React from 'react';
import './MomentTimePicker.scss';
import moment from 'moment';
import { TIME_FORMAT } from '../../../configs/constants';
import TimePicker from '../timePicker/TimePicker';

interface MomentTimePickerProps {
  selectedTime: moment.Moment;
  use12HoursFormat: boolean;
  timeChangeHandler: (requestedTime: moment.Moment | null) => void;
}

const MomentTimePicker: React.FC<MomentTimePickerProps> = (
  props: MomentTimePickerProps
) => {
  const { selectedTime, use12HoursFormat, timeChangeHandler } = props;

  const modifyHandler = (value: string) => {
    timeChangeHandler(moment(value, TIME_FORMAT));
  };

  const clearHandler = () => timeChangeHandler(null);

  return (
    <div className="MomentTimePicker-container">
      <TimePicker
        selectedTime={selectedTime.format(TIME_FORMAT)}
        use12HoursFormat={use12HoursFormat}
        timeChangeHandler={modifyHandler}
      />
      <div onClick={clearHandler} className="MomentTimePicker-clear-btn">
        <i className="fas fa-clock MomentTimePicker-clock" />
        <i className="fas fa-times MomentTimePicker-clear" />
      </div>
    </div>
  );
};

export default MomentTimePicker;
