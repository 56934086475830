import React from 'react';
import './TodoLabelContent.scss';
import { getLabelById, LabelObj } from '../../store/ducks/labels';
import { Store } from 'redux';
import { connect } from 'react-redux';
import ConnectedLabelTasksList from '../labelTasksList/LabelTasksList';
import { Dropdown, Select } from 'antd';
import {
  ASC_SORT,
  DEFAULT_SORT,
  DESC_SORT,
  PRIORITY_SORT,
  SORTING_OPTIONS,
  SORT_TYPE,
} from '../../configs/constants';
import clsx from 'clsx';
import TodoLabelOptionsPanel from '../../components/todo/labels/labelOptionsPanel/TodoLabelOptionsPanel';
import {
  disablePointerEventsOnMainLayout,
  disablePointerEventsOnTaskSider,
} from '../../services/utility';
import lodash from 'lodash';

/** types */

/** interface to describe TodoLabelContent props */
interface TodoLabelContentProps {
  labelId: string;
  labelInfo: LabelObj | null;
}

const TodoLabelContent: React.FC<TodoLabelContentProps> = (
  props: TodoLabelContentProps
) => {
  const { labelId, labelInfo } = props;

  const { Option } = Select;

  /** manages the sorting type */
  const [sortType, setSortType] = React.useState<SORT_TYPE>(PRIORITY_SORT);

  /** manages the sorting order */
  const [sortOrder, setSortOrder] = React.useState<ASC_SORT | DESC_SORT>(
    DESC_SORT
  );

  /** manages the more options visibility */
  const [optionsVisible, setOptionsVisible] = React.useState<boolean>(false);

  /**
   * sets the requested sort type
   * @param {SORT_TYPE} requestedSortType - the request the sort type
   */
  const onSortTypeChange = (requestedSortType: SORT_TYPE) => {
    if (requestedSortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    }
    setSortType(requestedSortType);
  };

  /** toggles the sorting order */
  const onSortOrderChange = () => {
    if (sortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    } else {
      setSortOrder(sortOrder === ASC_SORT ? DESC_SORT : ASC_SORT);
    }
  };

  /**
   * sets the requested visibility on options panel
   * @param {boolean} requestedVisibility - the requested viisbility
   */
  const onOptionsVisibilityChange = (requestedVisibility: boolean) => {
    setOptionsVisible(requestedVisibility);
    disablePointerEventsOnMainLayout(requestedVisibility);
    disablePointerEventsOnTaskSider(requestedVisibility);
  };

  /** closes the options panel */
  const optionsPanelCloseHandler = () => {
    setOptionsVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
  };

  return labelInfo ? (
    <div className="TodoLabelContent-container">
      <div className="TodoLabelContent-title-container">
        <h2
          className="TodoLabelContent-label-title"
          style={{ color: labelInfo.color }}
        >
          #{labelInfo.title}
        </h2>
        <div className="TodoLabelContent-sort-container">
          <div
            className={clsx({
              'TodoLabelContent-sort-order': true,
              'TodoLabelContent-sort-order-enabled': sortType !== DEFAULT_SORT,
            })}
            onClick={onSortOrderChange}
          >
            <i
              className={
                sortOrder === ASC_SORT
                  ? 'fas fa-sort-amount-down'
                  : 'fas fa-sort-amount-up'
              }
            ></i>
          </div>
          <div>
            <Select
              dropdownClassName="TodoLabelContent-sort-dropdown"
              value={sortType}
              bordered={false}
              onChange={onSortTypeChange}
              suffixIcon={<i className="fas fa-sort-down" />}
            >
              {lodash
                .without(SORTING_OPTIONS, DEFAULT_SORT)
                .map((iterOption, index) => (
                  <Option key={'sort' + index} value={iterOption}>
                    {iterOption}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <Dropdown
          overlay={
            labelInfo ? (
              <TodoLabelOptionsPanel
                labelInfo={labelInfo}
                panelCloseHandler={optionsPanelCloseHandler}
              />
            ) : (
              <div />
            )
          }
          placement="bottomRight"
          trigger={['click']}
          visible={optionsVisible}
          onVisibleChange={onOptionsVisibilityChange}
        >
          <div className="TodoLabelContent-more-container">
            <i className="fas fa-ellipsis-v" />
          </div>
        </Dropdown>
      </div>
      <ConnectedLabelTasksList
        labelId={labelId}
        sortOrder={sortOrder}
        sortType={sortType}
      />
    </div>
  ) : (
    <div className="TodoLabelContent-container TodoLabelContent-empty">
      <div className="TodoLabelContent-not-found-img">
        <img src="/undraw_empty.svg" width="350" alt="" />
      </div>
      <div className="TodoLabelContent-not-found-title">Oops!</div>
      <div className="TodoLabelContent-not-found-text">No label found.</div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  labelInfo: LabelObj | null;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoLabelContentProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const labelInfo = getLabelById(state, parentProps.labelId);
  const result = {
    labelInfo:
      labelInfo !== null && labelInfo.deleted === false ? labelInfo : null,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoLabelContent to the redux store */
const ConnectedTodoLabelContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoLabelContent);

/** allow import as default component */
export default ConnectedTodoLabelContent;
