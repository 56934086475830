import React from 'react';
import { Input, Button, Dropdown, Modal, Badge } from 'antd';
import './TodoHeader.scss';
import MenuMorePanel from '../menuMorePanel/MenuMorePanel';
import ConnectedAddTask from '../addTask/AddTask';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  LOCAL_INBOX_ENDPOINT,
  LOCAL_PRE_SEARCH_ENDPOINT,
} from '../../configs/endpoints';
import AppsDropdown from '../appsDropdown/AppsDropdown';
import ConnectedNotificationsDropdown from '../notificationsDropdown/NotificationsDropdown';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { getUnreadNotificationsCount } from '../../store/ducks/notifications';
import { getUnsyncedProjects } from '../../store/ducks/projects';
import { getUnsyncedLabels } from '../../store/ducks/labels';
import { getUnsyncedTasks } from '../../store/ducks/tasks';
import { getUnsyncedSections } from '../../store/ducks/sections';
import { getSessionToken } from '../../store/ducks/session';
import clsx from 'clsx';
import { channelInitialization } from '../../services/channel';

/** Interface to describe TodoHeader props */
interface TodoHeaderProps {
  unreadNotificationsCount: number;
  isUnsavedData: boolean;
  token: string;
  isSiderOpen: boolean;
  toggleSiderPanel: () => void;
}

const handleUnload = (e: any) => {
  const message = 'o/';
  (e || window.event).returnValue = message; //Gecko + IE
  return message;
};

const TodoHeader: React.FC<TodoHeaderProps> = (props: TodoHeaderProps) => {
  const {
    isSiderOpen,
    unreadNotificationsCount,
    isUnsavedData,
    toggleSiderPanel,
  } = props;

  const [addTaskModalVisible, setAddTaskModalVisible] = React.useState<boolean>(
    false
  );
  const history = useHistory();
  const location = useLocation();

  const [searchText, setSearchText] = React.useState<string>('');
  const [showCount, setShowCount] = React.useState<boolean>(false);

  React.useMemo(() => {
    if (unreadNotificationsCount > 0) {
      setShowCount(true);
    }
  }, [unreadNotificationsCount]);

  const closeHandler = () => {
    /**
     * Hack: run the line of code a bit later.
     * Result: Allows modal to close properly after task added using enter key; otherwise remains open
     * Reason - Not found yet. Suspects possible collision with antd modal enter listener
     */
    setTimeout(() => setAddTaskModalVisible(false), 100);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const searchHanlder = () => {
    if (searchText === '') return;
    history.push(LOCAL_PRE_SEARCH_ENDPOINT + searchText);
    setSearchText('');
  };

  /** prevents reload if unsaved data */
  React.useEffect(() => {
    try {
      if (isUnsavedData) {
        window.addEventListener('beforeunload', handleUnload);
      } else {
        window.removeEventListener('beforeunload', handleUnload);
      }
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    } catch (exception) {
      console.error(exception);
    }
  }, [isUnsavedData]);

  React.useEffect(() => {
    channelInitialization();
  }, []);

  return (
    <div className="TodoHeader-header-container">
      <div
        className={clsx({
          'TodoHeader-logo-container': true,
          'TodoHeader-logo-container-open': isSiderOpen,
        })}
      >
        <Button
          className="TodoHeader-sider-menu-bars"
          type="text"
          size="large"
          icon={<i className="fas fa-chevron-left"></i>}
          onClick={toggleSiderPanel}
        ></Button>
        <div className="TodoHeader-app-logo">
          <Link to={LOCAL_INBOX_ENDPOINT}>
            <img src="/todoNewLogo.svg" width="120" alt="" />
          </Link>
        </div>
        <Dropdown overlay={<AppsDropdown />} placement="bottomRight">
          <div className="TodoHeader-apps-container">
            <i className="fas fa-th"></i>
          </div>
        </Dropdown>
      </div>
      <div className="TodoHeader-menu-container">
        <div className="TodoHeader-search-input-container">
          <Button
            className="TodoHeader-menu-bars"
            type="text"
            size="large"
            icon={<i className="fas fa-bars"></i>}
            onClick={toggleSiderPanel}
          ></Button>
          <div className="TodoHeader-search-input-inner-container">
            <Input
              className="TodoHeader-search-input"
              placeholder="Search"
              value={searchText}
              prefix={<i className="fas fa-search TodoHeader-search-icon"></i>}
              onPressEnter={searchHanlder}
              onChange={onChangeHandler}
            />
          </div>
        </div>
        <Button
          className="TodoHeader-menu-item TodoHeader-add-task-btn"
          type="primary"
          onClick={() => setAddTaskModalVisible(true)}
        >
          <i className="fas fa-plus"></i>
          <span className="TodoHeader-extended-btn-txt"> Add new task </span>
        </Button>
        <Dropdown
          overlay={<ConnectedNotificationsDropdown />}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={() => {
            setShowCount(false);
          }}
        >
          <Badge
            size="small"
            count={showCount ? unreadNotificationsCount : 0}
            overflowCount={9}
          >
            <Button
              className="TodoHeader-menu-item"
              type="primary"
              shape="circle"
              size="large"
            >
              <i className="fas fa-bell"></i>
            </Button>
          </Badge>
        </Dropdown>
        <Dropdown
          overlay={<MenuMorePanel />}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            className="TodoHeader-menu-item-corner"
            type="primary"
            shape="circle"
          >
            <i className="fas fa-ellipsis-h"></i>
          </Button>
        </Dropdown>
      </div>
      <Modal
        width={650}
        className="TodoHeader-add-task-modal"
        title="Add task"
        footer={null}
        visible={addTaskModalVisible}
        keyboard={true}
        onCancel={() => {
          setAddTaskModalVisible(false);
        }}
      >
        <ConnectedAddTask
          projectId={
            location.pathname.startsWith('/project/')
              ? location.pathname.split('/')[2] || ''
              : ''
          }
          sectionId=""
          postSuccessHandler={closeHandler}
        />
      </Modal>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  unreadNotificationsCount: number;
  isUnsavedData: boolean;
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const unsyncedProjects = getUnsyncedProjects(state);
  const unsyncedLabels = getUnsyncedLabels(state);
  const unsyncedTasks = getUnsyncedTasks(state);
  const unsyncedSections = getUnsyncedSections(state);
  const result = {
    unreadNotificationsCount: getUnreadNotificationsCount(state),
    token: getSessionToken(state),
    isUnsavedData:
      unsyncedProjects.length > 0 ||
      unsyncedLabels.length > 0 ||
      unsyncedTasks.length > 0 ||
      unsyncedSections.length > 0,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoHeader to the redux store */
const ConnectedTodoHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoHeader);

export default ConnectedTodoHeader;
