import { Button, List, message, Spin } from 'antd';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { MEETING_ACTIVTY_TYPE } from '../../../configs/activityTypes';
import {
  SERVER_FILE_UPLOAD_ENDPOINT,
  SERVER_TASK_TYPES_DETAILS_ENDPOINT,
} from '../../../configs/endpoints';
import { axioService, GET, POST } from '../../../services/axioService';
import AttachmentItem from '../attachmentItem/AttachmentItem';
import './Attachment.scss';

/** interface to describe FileListItem props */
interface FileListItem {
  src: string;
  name: string;
  size: string;
}

/** interface to describe Attachment props */
interface AttachmentProps {
  taskId: string;
}

const Attachment = (props: AttachmentProps) => {
  const { taskId } = props;

  /** manages the filelist */
  const [fileList, setFileList] = React.useState<FileListItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  /** fetch event data from server */
  React.useEffect(() => {
    const fetchAgendaData = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASK_TYPES_DETAILS_ENDPOINT,
          { type: MEETING_ACTIVTY_TYPE.value, task_id: taskId },
          true
        );
        if (response.data.attachments) {
          setFileList(response.data.attachments);
        }
      } catch (Exception) {
        message.error('Failed to load existing files');
        console.error(Exception);
      }
    };
    fetchAgendaData();
  }, []);

  /** commit agenda state to server hadler */
  const commitHandler = async (files: FileListItem[], msg: string) => {
    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          attachments: files,
        },
        true
      );
      message.success(msg);
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
  };

  /** propagates the event of any file selected */
  const onDropAccepted = async (files: any) => {
    setLoading(true);
    let modifiedState = [...fileList];
    try {
      await Promise.all(
        files.map(async (file: any) => {
          const request = new FormData();
          request.append('visibility', 'private');
          request.append('attachment', file);
          console.warn(files);

          const response: any = await axioService(
            POST,
            SERVER_FILE_UPLOAD_ENDPOINT,
            request,
            true
          );
          //addFilesHandler(files);
          modifiedState = [
            ...modifiedState,
            {
              src: response.data?.url || '',
              name: response.data?.name || '',
              size: Math.round(file.size / 1000).toString() + ' KB',
            },
          ];
        })
      );
      setFileList(modifiedState);
      commitHandler(modifiedState, 'Added successfully');
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to upload');
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click behavior
    noClick: true,
    onDropAccepted,
  });

  const removeFileItem = (index: number) => {
    const modifiedState = [...fileList];
    modifiedState.splice(index, 1);
    setFileList(modifiedState);
    commitHandler(modifiedState, 'File deleted successfully');
  };

  return (
    <div className="Attachment-container">
      <div className="Attachment-upload-container">
        <Spin tip="Uploading..." spinning={loading}>
          <div
            {...getRootProps({ className: 'Attachment-dropzone-container' })}
          >
            <div className="Attachment-dropzone-inner">
              <input {...getInputProps()} />
              <div className="Attachment-upload-icon">
                <i className="fas fa-cloud-upload-alt"></i>
              </div>
              <div className="Attachment-upload-text">
                Drag and drop files here
              </div>
              <Button
                className="Attachment-upload-btn"
                type="primary"
                onClick={open}
              >
                Browse Computer
              </Button>
            </div>
          </div>
        </Spin>
      </div>
      <div className="Attachment-gallery">
        <div className="Attachment-gallery-title">Files</div>
        {fileList.length === 0 && (
          <List itemLayout="horizontal" dataSource={[]} />
        )}
        <div className="Attachment-gallery-body">
          {fileList.map((iterFileItem, index) => (
            <AttachmentItem
              key={'fileItem-' + index}
              src={iterFileItem.src}
              name={iterFileItem.name}
              size={iterFileItem.size}
              removeHandler={() => removeFileItem(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Attachment;
