import store from '../store';
import { addNotifications } from '../store/ducks/notifications';
import { getSessionUserInfo } from '../store/ducks/session';
import firebase from 'firebase/app';
import 'firebase/database';
import { transformNotificationFromServer } from '../api/notifications/transformer';

const firebaseConfig = {
  apiKey: 'AIzaSyDTQNUqoPmEdV2h7WRT4dfhFXp4OyAbTus',
  authDomain: 'virtunus-todo.firebaseapp.com',
  databaseURL: 'https://virtunus-todo-default-rtdb.firebaseio.com',
  projectId: 'virtunus-todo',
  storageBucket: 'virtunus-todo.appspot.com',
  messagingSenderId: '618046938393',
  appId: '1:618046938393:web:a49c33f0f87dd45b7ef988',
  measurementId: 'G-04QLD2X15B',
};

/** initialize the firebase config */
firebase.initializeApp(firebaseConfig);

export const channelInitialization = () => {
  const userInfo = getSessionUserInfo((store as any).getState());

  /** notifications */

  /** subscribe to the user notification channel */
  const notificationLocation = firebase
    .database()
    .ref('notifications/' + userInfo.id);

  notificationLocation.on('value', (snapshot) => {
    const data = snapshot.val();
    if (data) {
      (store as any).dispatch(
        addNotifications([transformNotificationFromServer(data)])
      );
    }
  });
};
