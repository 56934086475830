import { Button } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import RecurrenceTimePicker from '../recurrenceTimePicker/RecurrenceTimePicker';
import './RecurrenceYearPicker.scss';

export interface RecurrenceYearValue {
  key: string;
  value: moment.Moment[];
}

interface RecurrenceYearPickerProps {
  values: Array<RecurrenceYearValue | null>;
  onChangeHandler: (requested: Array<RecurrenceYearValue | null>) => void;
}

const RecurrenceYearPicker: React.FC<RecurrenceYearPickerProps> = (
  props: RecurrenceYearPickerProps
) => {
  const { values, onChangeHandler } = props;

  const onAddHandler = (index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index + 1, 0, null);
    onChangeHandler(tmpValues);
  };

  const onDeleteHandler = (index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1);
    if (tmpValues.length) {
      onChangeHandler(tmpValues);
    } else {
      onChangeHandler([null]);
    }
  };

  const onSelectHandler = (
    yearKey: string | null | undefined,
    index: number,
    requested: null | moment.Moment[] = null
  ) => {
    if (yearKey) {
      const tmpValues = [...values];
      tmpValues.splice(index, 1, {
        key: yearKey,
        value: requested ? requested : [moment()],
      });
      onChangeHandler(tmpValues);
    }
  };

  const onChangeWrapper = (
    yearKey: string,
    index: number,
    requested: moment.Moment[]
  ) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1, { key: yearKey, value: requested });
    onChangeHandler(tmpValues);
    if (requested.length === 0) {
      onDeleteHandler(index);
    }
  };

  const onAddTimeHandler = (yearKey: string, index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1, {
      key: yearKey,
      value: values[index]
        ? [...(values[index] as any)['value'], moment()]
        : [moment()],
    });
    onChangeHandler(tmpValues);
  };

  const DatePickerInput = ({ value, onClick, placeholder }: any) => (
    <div className="RecurrenceYearPicker-input" onClick={onClick}>
      {value || <span>{placeholder}</span>}{' '}
      <i className="fas fa-sort-down RecurrenceYearPicker-down-arrow" />
    </div>
  );

  return (
    <div className="RecurrenceYearPicker-container">
      {values.map((iterYearItem, index) =>
        iterYearItem ? (
          <div key={`year` + index} className="RecurrenceYearPicker-row">
            <div className="RecurrenceYearPicker-label">
              <DatePicker
                dateFormat="dd MMM"
                selected={new Date(iterYearItem['key'])}
                customInput={<DatePickerInput />}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="RecurrenceYearPicker-header">
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <i className="fas fa-chevron-left" />
                    </button>
                    <div className="RecurrenceYearPicker-header-label">
                      {moment(date.toString()).format('MMMM')}
                    </div>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <i className="fas fa-chevron-right" />
                    </button>
                  </div>
                )}
                onChange={(requestedDate) =>
                  onSelectHandler(
                    requestedDate?.toString(),
                    index,
                    iterYearItem['value']
                  )
                }
                minDate={new Date('3020-01-01')}
                maxDate={new Date('3020-12-31')}
                excludeDates={lodash
                  .without(values, null)
                  .map((iterKey: any) => new Date(iterKey['key']))}
              />
            </div>
            <div className="RecurrenceYearPicker-time-container">
              {iterYearItem['value'].map(
                (iterTimeUnit: moment.Moment, timeIndex: number) => (
                  <RecurrenceTimePicker
                    values={iterYearItem.value}
                    onChangeHandler={(requested) =>
                      onChangeWrapper(iterYearItem.key, index, requested)
                    }
                    index={timeIndex}
                    key={'daily' + timeIndex}
                  />
                )
              )}
              <Button
                className="RecurrenceYearPicker-plus"
                onClick={() => onAddTimeHandler(iterYearItem.key, index)}
                type="text"
                shape="circle"
                icon={<i className="fas fa-plus" />}
                size="small"
              />
            </div>
            <Button
              className="RecurrenceYearPicker-add-div"
              onClick={() => onAddHandler(index)}
              icon={<i className="fas fa-plus" />}
              size="small"
            />
            <Button
              className="RecurrenceYearPicker-minus-div"
              onClick={() => onDeleteHandler(index)}
              icon={<i className="fas fa-minus" />}
              size="small"
            />
          </div>
        ) : (
          <div key={`year` + index} className="RecurrenceYearPicker-row">
            <div className="RecurrenceYearPicker-label">
              <DatePicker
                placeholderText="Select Date"
                onChange={(requestedDate) =>
                  onSelectHandler(requestedDate?.toString(), index)
                }
                customInput={<DatePickerInput />}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="RecurrenceYearPicker-header">
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <i className="fas fa-chevron-left" />
                    </button>
                    <div className="RecurrenceYearPicker-header-label">
                      {moment(date.toString()).format('MMMM')}
                    </div>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <i className="fas fa-chevron-right" />
                    </button>
                  </div>
                )}
                minDate={new Date('3020-01-01')}
                maxDate={new Date('3020-12-31')}
                excludeDates={lodash
                  .without(values, null)
                  .map((iterKey: any) => new Date(iterKey['key']))}
              />
            </div>
            <Button
              className="RecurrenceYearPicker-add-div"
              onClick={() => onAddHandler(index)}
              icon={<i className="fas fa-plus" />}
              size="small"
            />
            <Button
              className="RecurrenceYearPicker-minus-div"
              onClick={() => onDeleteHandler(index)}
              icon={<i className="fas fa-minus" />}
              size="small"
            />
          </div>
        )
      )}
    </div>
  );
};

export default RecurrenceYearPicker;
