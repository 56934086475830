import { Input } from 'antd';
import React from 'react';
import './TodoLabelDropdownPicker.scss';
import { getLabels, LabelObj } from '../../store/ducks/labels';
import { connect } from 'react-redux';
import { Store } from 'redux';
import lodash from 'lodash';
import clsx from 'clsx';

/** types */

/** interface to describe TodoLabelDropdownPicker props */
interface TodoLabelDropdownPickerProps {
  labelList: LabelObj[]; // the existing label list
  selected: string[]; // the selected label
  selectedHandler: (selected: string[]) => void; // the onchange selction handler
}

/** constants */

/** the search text on the search input placeholder */
const SEARCH_PLACEHOLDER = 'Search Label';

const TodoLabelDropdownPicker: React.FC<TodoLabelDropdownPickerProps> = (
  props: TodoLabelDropdownPickerProps
) => {
  /** props */

  const { labelList, selected, selectedHandler } = props;

  /** component states */

  /** manages the search input text */
  const [search, setSearch] = React.useState<string>('');
  /** manages the filtered list based on search input */
  const [filteredList, setFilteredList] = React.useState<LabelObj[]>([]);

  /** cycles */

  /** filters the actual list based on changes of search input */
  React.useEffect(() => {
    setFilteredList(
      lodash.orderBy(
        search === ''
          ? labelList
          : [
              ...lodash.filter(labelList, (label: LabelObj) =>
                label.title.toLowerCase().startsWith(search.toLowerCase())
              ),
              ...lodash.filter(
                labelList,
                (label: LabelObj) =>
                  !label.title.toLowerCase().startsWith(search.toLowerCase()) &&
                  label.title.toLowerCase().includes(search.toLowerCase())
              ),
            ],
        [(label: LabelObj) => label.title.toLowerCase()],
        ['asc']
      )
    );
  }, [search, labelList]);

  /** handlers */

  /**
   * sets search text based on user search input
   * @param {React.ChangeEvent<HTMLInputElement>} event - the html input onchange event
   */
  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  /**
   * selects/ deselects the label
   * @param {React.MouseEvent} event - the onclick mouse event
   */
  const setSelectedId = (event: React.MouseEvent) => {
    if (selected.includes((event.target as any).id)) {
      selectedHandler(
        lodash.filter(
          selected,
          (elm: string) => elm !== (event.target as any).id
        )
      );
    } else {
      selectedHandler([...selected, (event.target as any).id]);
    }
  };

  /** rendered jsx */
  return (
    <div className="TodoLabelDropdownPicker-container">
      <div className="TodoLabelDropdownPicker-search-container">
        <Input
          placeholder={SEARCH_PLACEHOLDER}
          value={search}
          onChange={searchHandler}
          prefix={<i className="fas fa-search"></i>}
        />
      </div>
      <div className="TodoLabelDropdownPicker-body-container">
        {filteredList.map((labelItem, index) => (
          <div
            key={'label-item-' + index}
            className={clsx({
              'TodoLabelDropdownPicker-label-item': true,
              'TodoLabelDropdownPicker-label-item-selected': selected.includes(
                labelItem.id
              ),
            })}
            id={labelItem.id}
            onClick={setSelectedId}
          >
            <i
              className="fas fa-tag TodoLabelDropdownPicker-tag"
              style={{ color: labelItem.color }}
            />
            <div className="TodoLabelDropdownPicker-label-item-label">
              {labelItem.title}
            </div>
            <div>
              {selected.includes(labelItem.id) && (
                <i className="fas fa-check"></i>
              )}
            </div>
          </div>
        ))}
        {filteredList.length === 0 && (
          <div className="TodoLabelDropdownPicker-no-labels">
            No labels to display
          </div>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  labelList: LabelObj[];
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    labelList: lodash.filter(getLabels(state), { deleted: false }),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoLabelDropdownPicker to the redux store */
const ConnectedTodoLabelDropdownPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoLabelDropdownPicker);

/** all import as default component */
export default ConnectedTodoLabelDropdownPicker;
