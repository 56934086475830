import React from 'react';
import './Invitation.scss';
import { Card, Button, Spin, Alert } from 'antd';
import { connect } from 'react-redux';
import { getSessionToken, getSessionUserInfo } from '../../store/ducks/session';
import { Store } from 'antd/lib/form/interface';
import { Link, useParams } from 'react-router-dom';
import {
  LOCAL_INBOX_ENDPOINT,
  SERVER_INVITATIONS_ENDPOINT,
} from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';

interface InvitationProps {
  token: string;
  userInfo: any;
}

const Invitation: React.FC<InvitationProps> = (props: InvitationProps) => {
  const { userInfo } = props;
  const { id } = useParams<{ id: string }>();

  // React states
  /** manages loading while processing account recovery request */
  const [loading, setLoading] = React.useState<boolean>(true);
  /** error message to show if occurs */
  const [errorMessage, setErrorMessage] = React.useState<any>('');
  const [invitationObj, setInvitationObj] = React.useState<any>(null);
  const [notFound, setNotFound] = React.useState<boolean>(false);

  /** onAlertClose is used to close alert message */
  const onAlertClose = () => setErrorMessage('');

  React.useEffect(() => {
    const fetchInvitation = async () => {
      setLoading(true);
      if (id) {
        try {
          const response = await axioService(
            GET,
            `${SERVER_INVITATIONS_ENDPOINT}/${id}`,
            {},
            true
          );
          setInvitationObj(response.data);
          if (
            response.data?.status > 1 ||
            response.data?.subject?.shared_with?.includes(parseInt(userInfo.id))
          ) {
            setNotFound(true);
          }
          setLoading(false);
        } catch (Exception) {
          setNotFound(true);
          setLoading(false);
          console.error(Exception);
        }
      }
    };
    try {
      fetchInvitation();
    } catch (exception) {
      console.error(exception);
      setNotFound(true);
    }
  }, []);

  const successHandler = async () => {
    setLoading(true);
    try {
      const response = await axioService(
        POST,
        `${SERVER_INVITATIONS_ENDPOINT}/${invitationObj.token}/acceptance`,
        { status: 2 },
        true
      );
      if (response?.data?.subject?.id) {
        window.location.href = `/project/${response?.data?.subject?.id}`;
      } else {
        window.location.href = LOCAL_INBOX_ENDPOINT;
      }
      setLoading(false);
    } catch (Exception) {
      console.error(Exception);
      setLoading(false);
    }
  };

  const rejectHandler = async () => {
    setLoading(true);
    try {
      await axioService(
        POST,
        `${SERVER_INVITATIONS_ENDPOINT}/${invitationObj.token}/acceptance`,
        { status: 3 },
        true
      );
      window.location.href = LOCAL_INBOX_ENDPOINT;
      setLoading(false);
    } catch (Exception) {
      console.error(Exception);
      setLoading(false);
    }
  };

  return (
    <Spin wrapperClassName="Invitation-spinner" spinning={loading}>
      {errorMessage !== '' && (
        <Alert
          closable
          onClose={onAlertClose}
          className="Invitation-error"
          message="Failed"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
      <div className="Invitation-body">
        <div className="Invitation-content">
          <div className="Invitation-logo-container">
            <img src="/virtunus-logo-vertical.svg" width="120" alt="" />
          </div>

          <Card className="Invitation-card">
            {notFound ? (
              <React.Fragment>
                <h4 className="Invitation-not-found-text">Not Found!</h4>
                <div>
                  <img src="/undraw_page_not_found.svg" width="250" alt="" />
                </div>
                <h5 className="Invitation-not-found-help-text">
                  Looks like the page does not exist.
                </h5>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h4 className="Invitation-title-text">Project Invitation</h4>
                <div>
                  <img src="/undraw_Opened.svg" width="120" alt="" />
                </div>
                <h5 className="Invitation-help-text">
                  You are requested to join the project{' '}
                  <span>{invitationObj?.subject?.title || ''}.</span>
                </h5>
                <div className="Invitation-buttons-container">
                  <Button
                    className="Invitation-btn Invitation-reject"
                    type="primary"
                    htmlType="submit"
                    onClick={rejectHandler}
                  >
                    Reject
                  </Button>
                  <Button
                    className="Invitation-btn Invitation-accept"
                    type="primary"
                    htmlType="submit"
                    onClick={successHandler}
                  >
                    Accept
                  </Button>
                </div>
              </React.Fragment>
            )}
            <div className="Invitation-sign-in-container">
              Back to
              <Link to={LOCAL_INBOX_ENDPOINT}>
                <h4>Todo</h4>
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
  userInfo: any;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect Invitation to the redux store */
const ConnectedInvitation = connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);

export default ConnectedInvitation;
