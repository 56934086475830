import { Button, Input } from 'antd';
import React from 'react';
import { AgendaStateItem } from '../meetingAgenda/MeetingAgenda';
import './MeetingEventAgendaItem.scss';

/** interface to describe MeetingEventAgendaItem */
interface MeetingEventAgendaItemProps {
  index: number;
  agendaState: AgendaStateItem[];
  onAgendaChange: (requested: AgendaStateItem[]) => void;
  addHandler: () => void;
  removeHandler: (index: number) => void;
}

const MeetingEventAgendaItem: React.FC<MeetingEventAgendaItemProps> = (
  props: MeetingEventAgendaItemProps
) => {
  const {
    index,
    agendaState,
    onAgendaChange,
    removeHandler,
    addHandler,
  } = props;

  /** title change handler */
  const onTitleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modifiedState = [...agendaState];
    modifiedState[index] = {
      title: event.target.value,
      description: modifiedState[index].description,
      attachments: modifiedState[index].attachments,
    };
    onAgendaChange(modifiedState);
  };

  const removeItemHandler = () => removeHandler(index);

  const enterHandler = () => {
    if (
      index === agendaState.length - 1 &&
      agendaState[index]['title'] !== ''
    ) {
      addHandler();
    }
    setTimeout(() => {
      document
        .getElementById('meeting-agenda-' + (index + 1))
        ?.scrollIntoView();
      document.getElementById('meeting-agenda-' + (index + 1))?.focus();
    }, 100);
  };

  return (
    <div className="MeetingEventAgendaItem-container">
      <Input
        id={'meeting-agenda-' + index}
        value={agendaState[index]['title']}
        onChange={onTitleChangeHandler}
        placeholder="Agenda Title"
        onPressEnter={enterHandler}
      />
      <Button
        className="MeetingEventAgendaItem-btn"
        size="small"
        shape="circle"
        onClick={removeItemHandler}
      >
        <i className="fas fa-minus" />
      </Button>
      {index === agendaState.length - 1 && (
        <Button
          className="MeetingEventAgendaItem-btn"
          size="small"
          shape="circle"
          onClick={addHandler}
        >
          <i className="fas fa-plus" />
        </Button>
      )}
    </div>
  );
};

export default MeetingEventAgendaItem;
