import { Avatar, Input } from 'antd';
import React from 'react';
import './TodoAssigneeDropdownPicker.scss';
import { connect } from 'react-redux';
import { Store } from 'redux';
import lodash from 'lodash';
import clsx from 'clsx';
import {
  CollaboratorObj,
  getCollaborators,
} from '../../store/ducks/collaborators';

/** types */

/** interface to describe TodoAssigneeDropdownPicker props */
interface TodoAssigneeDropdownPickerProps {
  collaboratorIds: string[];
  collaborators: CollaboratorObj[];
  selected: string;
  selectedHandler: (selected: string) => void;
}

/** constants */

/** the search text on the search input placeholder */
const SEARCH_PLACEHOLDER = 'Search Assignee';

const TodoAssigneeDropdownPicker: React.FC<TodoAssigneeDropdownPickerProps> = (
  props: TodoAssigneeDropdownPickerProps
) => {
  /** props */

  const { collaborators, selected, selectedHandler } = props;

  /** component states */

  /** manages the search input text */
  const [search, setSearch] = React.useState<string>('');
  /** manages the filtered list based on search input */
  const [filteredList, setFilteredList] = React.useState<CollaboratorObj[]>([]);

  /** cycles */

  /** filters the actual list based on changes of search input */
  React.useEffect(() => {
    setFilteredList(
      lodash.orderBy(
        search === ''
          ? collaborators
          : [
              ...lodash.filter(collaborators, (collaborator: CollaboratorObj) =>
                collaborator.name.toLowerCase().startsWith(search.toLowerCase())
              ),
              ...lodash.filter(
                collaborators,
                (collaborator: CollaboratorObj) =>
                  !collaborator.name
                    .toLowerCase()
                    .startsWith(search.toLowerCase()) &&
                  collaborator.name.toLowerCase().includes(search.toLowerCase())
              ),
            ],
        [(collaborator: CollaboratorObj) => collaborator.name.toLowerCase()],
        ['asc']
      )
    );
  }, [search, collaborators]);

  /** handlers */

  /**
   * sets search text based on user search input
   * @param {React.ChangeEvent<HTMLInputElement>} event - the html input onchange event
   */
  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  /**
   * selects/ deselects the label
   * @param {React.MouseEvent} event - the onclick mouse event
   */
  const setSelectedId = (event: React.MouseEvent) => {
    // eslint-disable-next-line no-console
    console.log('test', (event.target as any).id);
    selectedHandler((event.target as any).id);
  };

  /** rendered jsx */
  return (
    <div className="TodoAssigneeDropdownPicker-container">
      <div className="TodoAssigneeDropdownPicker-search-container">
        <Input
          placeholder={SEARCH_PLACEHOLDER}
          value={search}
          onChange={searchHandler}
          prefix={<i className="fas fa-search"></i>}
        />
      </div>
      <div className="TodoAssigneeDropdownPicker-body-container">
        <div
          className={clsx({
            'TodoAssigneeDropdownPicker-label-item': true,
            'TodoAssigneeDropdownPicker-label-item-selected': selected === '',
          })}
          id=""
          onClick={setSelectedId}
        >
          <Avatar className="TodoAssigneeDropdownPicker-avatar" size="small">
            <i className="fas fa-user-slash"></i>
          </Avatar>
          <div className="TodoAssigneeDropdownPicker-label-item-label">
            Unassigned
          </div>
          <div>{selected === '' && <i className="fas fa-check"></i>}</div>
        </div>
        {filteredList.map((collaborator, index) => (
          <div
            key={'label-item-' + index}
            className={clsx({
              'TodoAssigneeDropdownPicker-label-item': true,
              'TodoAssigneeDropdownPicker-label-item-selected':
                selected === collaborator.id,
            })}
            id={collaborator.id}
            onClick={setSelectedId}
          >
            <Avatar
              className="TodoAssigneeDropdownPicker-avatar"
              size="small"
              src={collaborator?.avatar}
            >
              <div>{collaborator?.name?.slice(0, 2)}</div>
            </Avatar>
            <div className="TodoAssigneeDropdownPicker-label-item-label">
              {collaborator?.name}
            </div>
            <div>
              {selected === collaborator.id && <i className="fas fa-check"></i>}
            </div>
          </div>
        ))}
        {filteredList.length === 0 && (
          <div className="TodoAssigneeDropdownPicker-no-labels">
            No Assignee to display
          </div>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  collaborators: CollaboratorObj[];
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TodoAssigneeDropdownPickerProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const result = {
    collaborators: lodash.filter(
      getCollaborators(state),
      (iterCollaborator: CollaboratorObj) =>
        parentProps.collaboratorIds?.includes(iterCollaborator.id)
    ),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoAssigneeDropdownPicker to the redux store */
const ConnectedTodoAssigneeDropdownPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoAssigneeDropdownPicker);

/** all import as default component */
export default ConnectedTodoAssigneeDropdownPicker;
