import { PriorityObj } from './TodoPriorityDropdown';

// Top priority
export const TOP_PRIORITY_LABEL = 'Top Priority';
export const TOP_PRIORITY_VALUE = '4';
export const TOP_PRIORITY_IMG = '/top-priority.svg';

const TOP_PRIORITY: PriorityObj = {
  label: TOP_PRIORITY_LABEL,
  value: TOP_PRIORITY_VALUE,
  img: TOP_PRIORITY_IMG,
};

// Middle priority
export const MIDDLE_PRIORITY_LABEL = 'Middle Priority';
export const MIDDLE_PRIORITY_VALUE = '3';
export const MIDDLE_PRIORITY_IMG = '/middle-priority.svg';

const MIDDLE_PRIORITY: PriorityObj = {
  label: MIDDLE_PRIORITY_LABEL,
  value: MIDDLE_PRIORITY_VALUE,
  img: MIDDLE_PRIORITY_IMG,
};

// Low priority
export const LOW_PRIORITY_LABEL = 'Low Priority';
export const LOW_PRIORITY_VALUE = '2';
export const LOW_PRIORITY_IMG = '/low-priority.svg';

const LOW_PRIORITY: PriorityObj = {
  label: LOW_PRIORITY_LABEL,
  value: LOW_PRIORITY_VALUE,
  img: LOW_PRIORITY_IMG,
};

// No priority
export const NO_PRIORITY_LABEL = 'No Priority';
export const NO_PRIORITY_VALUE = '1';
export const NO_PRIORITY_IMG = '/no-priority.svg';

const NO_PRIORITY: PriorityObj = {
  label: NO_PRIORITY_LABEL,
  value: NO_PRIORITY_VALUE,
  img: NO_PRIORITY_IMG,
};

export const PRIORITY_LIST: PriorityObj[] = [
  TOP_PRIORITY,
  MIDDLE_PRIORITY,
  LOW_PRIORITY,
  NO_PRIORITY,
];
