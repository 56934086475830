import React from 'react';
import { Modal, Input, Checkbox, Button } from 'antd';
import ColorPicker, {
  DEFAULT_COLORS,
} from '../../components/todo/projects/colorPicker/ColorPicker';
import './AddProject.scss';
import ProjectPicker from '../../components/todo/projects/projectPicker/ProjectPicker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import {
  setProject,
  getProjects,
  ProjectObj,
  getNewProjectOrderbyProjectId,
  fixProjectOrders,
} from '../../store/ducks/projects';
import { Store } from 'redux';
import { getFlatDataBasedOnHeight, getMaxHeight } from '../../services/utility';
import { v4 as uuid } from 'uuid';
import lodash from 'lodash';
import { getSessionUserInfo } from '../../store/ducks/session';

/** interface to describe AddProject props */
interface AddProjectProps {
  visible: boolean;
  userInfo: any;
  getProjectList: (allowedHeight: number) => ProjectObj[];
  getNewProjectOrder: (projectId: string) => number;
  getProjectMaxHeight: (projectId: string) => number;
  setProjectActionCreator: typeof setProject;
  fixProjectOrdersActionCreator: typeof fixProjectOrders;
  closeHandler: () => void;
  initialUserInput?: Partial<ProjectObj>;
  requestedOrder?: number;
}

// constants
/** the modal title */
const MODAL_TITLE = 'Add Project';
/** the default user input object */
const DEFAULT_USER_INPUT: ProjectObj = {
  id: '',
  title: '',
  color: DEFAULT_COLORS[0],
  parent: '',
  expanded: true,
  order: 0,
  isHidden: false,
  isFavourite: false,
  synced: false,
  deleted: false,
  sharedWith: [],
};

/** the add project component */
const AddProject: React.FC<AddProjectProps> = (props: AddProjectProps) => {
  const {
    visible,
    userInfo,
    getProjectList,
    setProjectActionCreator,
    fixProjectOrdersActionCreator,
    closeHandler,
    getNewProjectOrder,
    initialUserInput,
    requestedOrder,
    getProjectMaxHeight,
  } = props;

  /** creates a custom initial user input based on intialUserInput prop; otherwise default is used */
  const modifiedInitialUserInput = initialUserInput
    ? { ...DEFAULT_USER_INPUT, ...initialUserInput }
    : DEFAULT_USER_INPUT;

  const inputRef = React.useRef<any>(null);

  /** calculates the max height that will be allowed */
  const maxHeight =
    /**
     * Resolves conflict
     *
     * background:
     * root id is '' and will usually show a height of 1
     * new project ids are initialized as '' or empty string
     *
     * conflict:
     * new projects should not be restricted by root height
     */
    modifiedInitialUserInput.id === ''
      ? 0
      : getProjectMaxHeight(modifiedInitialUserInput.id);

  /** get the projectList based on allowed max height */
  const projectList =
    maxHeight > 2
      ? [] // if child depth is more than two, restrict folder pick
      : maxHeight === 2
      ? getProjectList(0) // if child dept is two, restrict to only root nodes
      : maxHeight === 1
      ? getProjectList(1) // if child dept is one, allow root and its immediate children
      : getProjectList(2); // otherwise root and its next three children nodes

  // React states
  /** manages the user inputs */
  const [userInput, setUserInput] = React.useState<ProjectObj>(
    modifiedInitialUserInput
  );

  // React cycles
  React.useEffect(() => {
    setUserInput(modifiedInitialUserInput);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [initialUserInput, visible]);

  // confirm handler
  /** add project submit handler */
  const okHandler = async () => {
    if (userInput.title === '') return;
    let requestedProjectData = {
      ...userInput,
      synced: false,
      order:
        requestedOrder !== undefined &&
        initialUserInput &&
        initialUserInput.parent === userInput.parent
          ? requestedOrder
          : initialUserInput &&
            initialUserInput.order !== undefined &&
            initialUserInput.parent === userInput.parent
          ? initialUserInput.order
          : getNewProjectOrder(userInput.parent),
    };
    if (requestedProjectData.id === '') {
      requestedProjectData = {
        ...requestedProjectData,
        id: uuid(),
        sharedWith: [userInfo.id],
      };
    }
    setProjectActionCreator(requestedProjectData);
    if (
      initialUserInput &&
      initialUserInput.parent !== undefined &&
      initialUserInput.parent !== userInput.parent
    ) {
      /**
       * fixes the order of both current and previous lists
       * if the project parent changes while in edit mode
       * */
      fixProjectOrdersActionCreator(userInput.parent);
      fixProjectOrdersActionCreator(initialUserInput.parent);
    } else if (requestedOrder !== undefined) {
      /**
       * fixes the order of the new project is not inserted
       * at a particular index other than available bottom
       * */
      fixProjectOrdersActionCreator(userInput.parent);
    }
    setUserInput(modifiedInitialUserInput);
    setTimeout(() => closeHandler(), 100);
  };

  // on change handlers
  /**
   * titleChangeHandler updates user's title state on change
   * @param {React.ChangeEvent<HTMLInputElement>} event - the on change event
   */
  const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, title: event.target.value });
  };

  /**
   * colorChangeHandler updates user's color choice
   * @param {string} color - the selected color
   */
  const colorChangeHandler = (color: string) => {
    setUserInput({ ...userInput, color });
  };

  /**
   * projectChangeHandler updates project's folder
   * @param {string} project - the selected project
   */
  const projectChangeHandler = (project: string) => {
    setUserInput({ ...userInput, parent: project });
  };

  /**
   * hiddenChangeHandler updates the project's hidden flag
   * @param {CheckboxChangeEvent} event - the checkbox event handler
   */
  const hiddenChangeHandler = (event: CheckboxChangeEvent) => {
    setUserInput({ ...userInput, isHidden: event.target.checked });
  };

  /**
   * favouriteChangeHandler updates the project's favourite flag
   * @param {CheckboxChangeEvent} event - the checkbox event handler
   */
  const favouriteChangeHandler = (event: CheckboxChangeEvent) => {
    setUserInput({ ...userInput, isFavourite: event.target.checked });
  };

  return (
    <Modal
      title={MODAL_TITLE}
      visible={visible}
      onOk={okHandler}
      onCancel={closeHandler}
      width={600}
      footer={[
        <Button key="back" onClick={closeHandler}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={userInput.title === '' || userInput.color === ''}
          onClick={okHandler}
        >
          Done
        </Button>,
      ]}
    >
      <div className="AddProject-title-container">
        <Input
          value={userInput.title}
          size="large"
          placeholder="Project Name"
          onChange={titleChangeHandler}
          onPressEnter={okHandler}
          ref={inputRef}
        />
      </div>
      <div className="AddProject-colorpicker-container">
        <h4>Project Color</h4>
        <div className="AddProject-colorpicker-section">
          <ColorPicker
            selectedColor={userInput.color}
            colorChangeHandler={colorChangeHandler}
          />
        </div>
      </div>
      <div className="AddProject-folder-container">
        <h4>Project Folder</h4>
        <div className="AddProject-folderpicker-section">
          <ProjectPicker
            excludedProjectIds={[modifiedInitialUserInput.id]}
            projectList={lodash.orderBy(
              projectList.filter(
                (iterProj: ProjectObj) =>
                  iterProj.id !== modifiedInitialUserInput.id
              ),
              ['title'],
              ['asc']
            )}
            /** calculates the max allowed height based on node tree depth */
            maxHeight={maxHeight === 2 ? 0 : maxHeight === 1 ? 1 : 2}
            selectedProject={userInput.parent}
            projectChangeHandler={projectChangeHandler}
          />
        </div>
      </div>
      <div className="AddProject-extra-container">
        <Checkbox
          className="hide-display"
          checked={userInput.isHidden}
          onChange={hiddenChangeHandler}
        >
          Keep Hidden
        </Checkbox>
        <Checkbox
          checked={userInput.isFavourite}
          onChange={favouriteChangeHandler}
        >
          Add to Favourites
        </Checkbox>
      </div>
    </Modal>
  );
};

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: any;
  getProjectList: (allowedHeight: number) => ProjectObj[];
  getNewProjectOrder: (projectId: string) => number;
  getProjectMaxHeight: (projectId: string) => number;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const projects = lodash.filter(getProjects(state), { deleted: false });
  const result = {
    userInfo: getSessionUserInfo(state),
    getProjectList: (allowedHeight: number) =>
      getFlatDataBasedOnHeight(projects, allowedHeight),
    getNewProjectOrder: (projectId: string) =>
      getNewProjectOrderbyProjectId(state, projectId),
    getProjectMaxHeight: (projectId: string) =>
      getMaxHeight(projects, projectId),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setProjectActionCreator: setProject,
  fixProjectOrdersActionCreator: fixProjectOrders,
};

/** connect AddProject to the redux store */
const ConnectedAddProject = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProject);

/** the default export */
export default ConnectedAddProject;
