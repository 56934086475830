import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { ServerTaskObj } from '../../api/tasks/data';
import { transformTaskFromServer } from '../../api/tasks/transformer';
import { ASC_SORT, DUE_DATE_SORT } from '../../configs/constants';
import { SERVER_TASKS_ENDPOINT } from '../../configs/endpoints';
import { axioService, GET } from '../../services/axioService';
import {
  removeNonExistingTasksBasedOnDate,
  TaskObj,
  updateTasks,
} from '../../store/ducks/tasks';
import ConnectedAddTask from '../addTask/AddTask';
import ConnectedIteratorBasedTaskList from '../iteratorBasedTaskList/IteratorBasedTaskList';
import './UpcomingDayBody.scss';

/** interface to describe the UpcomingDayBody props */
interface UpcomingDayBodyProps {
  currentDate: string;
  topAddTaskVisibility: boolean;
  bottomAddTaskVisibility: boolean;
  onTopAddTaskVisibilityChange: (requestedVisibility: boolean) => void;
  onBottomAddTaskVisibilityChange: (requestedVisibility: boolean) => void;
  updateTasksActionCreator: typeof updateTasks;
  removeTasksActionCreator: typeof removeNonExistingTasksBasedOnDate;
}

const UpcomingDayBody: React.FC<UpcomingDayBodyProps> = (
  props: UpcomingDayBodyProps
) => {
  const {
    currentDate,
    updateTasksActionCreator,
    removeTasksActionCreator,
    topAddTaskVisibility,
    bottomAddTaskVisibility,
    onTopAddTaskVisibilityChange,
    onBottomAddTaskVisibilityChange,
  } = props;

  const dateIterator = (taskItem: TaskObj) => {
    const taskDate = moment(taskItem.dueDate);
    return taskDate.isSame(moment(currentDate), 'day');
  };

  const fetchTasks = async () => {
    try {
      const response = await axioService(
        GET,
        `${SERVER_TASKS_ENDPOINT}/upcoming`,
        { start_date: currentDate, end_date: currentDate },
        true
      );

      updateTasksActionCreator(
        lodash.filter(
          response.data.data.map(
            (iterTask: ServerTaskObj): TaskObj =>
              transformTaskFromServer(iterTask)
          ),
          { isExists: 0 }
        )
      );
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
    }
  };

  React.useEffect(() => {
    fetchTasks();
    return () => {
      removeTasksActionCreator(currentDate);
    };
  }, []);

  /** handlers */

  /** opens the bottom add Task  */
  const openBottomTaskHander = () => onBottomAddTaskVisibilityChange(true);

  /** cancels the top add Task  */
  const cancelAddTopTask = () => onTopAddTaskVisibilityChange(false);

  /** cancels the bottom add Task */
  const cancelAddBottomTask = () => onBottomAddTaskVisibilityChange(false);

  return (
    <React.Fragment>
      {topAddTaskVisibility && (
        <ConnectedAddTask
          projectId=""
          sectionId=""
          taskInfo={{ dueDate: currentDate }}
          postCloseHandler={cancelAddTopTask}
          postSuccessHandler={cancelAddTopTask}
        />
      )}
      <ConnectedIteratorBasedTaskList
        iterator={dateIterator}
        preventExpanded={true}
        sortType={DUE_DATE_SORT}
        sortOrder={ASC_SORT}
      />
      {bottomAddTaskVisibility ? (
        <ConnectedAddTask
          projectId=""
          sectionId=""
          taskInfo={{ dueDate: currentDate }}
          postCloseHandler={cancelAddBottomTask}
          postSuccessHandler={cancelAddBottomTask}
        />
      ) : (
        <div
          onClick={openBottomTaskHander}
          className="UpcomingDayBody-add-task"
        >
          <i className="fas fa-plus" />
          Add Task
        </div>
      )}
    </React.Fragment>
  );
};

/** map props to actions */
const mapDispatchToProps = {
  updateTasksActionCreator: updateTasks,
  removeTasksActionCreator: removeNonExistingTasksBasedOnDate,
};

/** connect UpcomingDayBody to the redux store */
const ConnectedUpcomingDayBody = connect(
  null,
  mapDispatchToProps
)(UpcomingDayBody);

export default ConnectedUpcomingDayBody;
