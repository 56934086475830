import { Input } from 'antd';
import React from 'react';
import './TodoProjectDropdownPicker.scss';
import { getProjects, ProjectObj } from '../../store/ducks/projects';
import { connect } from 'react-redux';
import { Store } from 'redux';
import lodash from 'lodash';
import clsx from 'clsx';
import { getFlatDataBasedOnHeight } from '../../services/utility';

/** types */

/** interface to describe TodoProjectDropdownPicker props */
interface TodoProjectDropdownPickerProps {
  projectList: ProjectObj[]; // the existing project list
  selected: string; // the selected project
  selectedHandler: (selected: string) => void; // the onchange selction handler
  maxHeight?: number; // project list will be filtered according to maxheight if present
  excludedProjectIds?: string[]; // the list of project ids to be excluded from the list
}

/** constants */

/** the search text on the search input placeholder */
const SEARCH_PLACEHOLDER = 'Search Project';

const TodoProjectDropdownPicker: React.FC<TodoProjectDropdownPickerProps> = (
  props: TodoProjectDropdownPickerProps
) => {
  /** props */

  const { projectList, selected, selectedHandler } = props;

  /** component states */

  /** manages the search input text */
  const [search, setSearch] = React.useState<string>('');
  /** manages the filtered list based on search input */
  const [filteredList, setFilteredList] = React.useState<ProjectObj[]>([]);

  /** cycles */

  /** filters the actual list based on changes of search input */
  React.useEffect(() => {
    setFilteredList(
      lodash.orderBy(
        search === ''
          ? projectList
          : [
              ...lodash.filter(projectList, (project: ProjectObj) =>
                project.title.toLowerCase().startsWith(search.toLowerCase())
              ),
              ...lodash.filter(
                projectList,
                (project: ProjectObj) =>
                  !project.title
                    .toLowerCase()
                    .startsWith(search.toLowerCase()) &&
                  project.title.toLowerCase().includes(search.toLowerCase())
              ),
            ],
        [(project: ProjectObj) => project.title.toLowerCase()],
        ['asc']
      )
    );
  }, [search, projectList]);

  /** handlers */

  /**
   * sets search text based on user search input
   * @param {React.ChangeEvent<HTMLInputElement>} event - the html input onchange event
   */
  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  /**
   * selects/ deselects the project
   * @param {React.MouseEvent} event - the onclick mouse event
   */
  const setSelectedId = (event: React.MouseEvent) => {
    if (selected === (event.target as any).id) {
      selectedHandler('');
    } else {
      selectedHandler((event.target as any).id);
    }
  };

  /** rendered jsx */
  return (
    <div className="TodoProjectDropdownPicker-container">
      <div className="TodoProjectDropdownPicker-search-container">
        <Input
          placeholder={SEARCH_PLACEHOLDER}
          value={search}
          onChange={searchHandler}
          prefix={<i className="fas fa-search"></i>}
        />
      </div>
      <div className="TodoProjectDropdownPicker-body-container">
        {filteredList.map((projectItem, index) => (
          <div
            key={'project-item-' + index}
            className={clsx({
              'TodoProjectDropdownPicker-project-item': true,
              'TodoProjectDropdownPicker-project-item-selected':
                projectItem.id === selected,
            })}
            id={projectItem.id}
            onClick={setSelectedId}
          >
            <div
              className="TodoProjectDropdownPicker-dot"
              style={{ background: projectItem.color }}
            />
            <div className="TodoProjectDropdownPicker-project-item-label">
              {projectItem.title}
            </div>
            <div>
              {selected === projectItem.id && <i className="fas fa-check"></i>}
            </div>
          </div>
        ))}
        {filteredList.length === 0 && (
          <div className="TodoProjectDropdownPicker-no-projects">
            No projects to display
          </div>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  projectList: ProjectObj[];
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoProjectDropdownPickerProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const excludedIds = parentProps.excludedProjectIds || [];
  const result = {
    projectList: lodash.filter(
      parentProps.maxHeight
        ? getFlatDataBasedOnHeight(getProjects(state), parentProps.maxHeight)
        : getProjects(state),
      (iterProj: ProjectObj) =>
        !excludedIds.includes(iterProj.id) && iterProj.deleted === false
    ),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoProjectDropdownPicker to the redux store */
const ConnectedTodoProjectDropdownPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoProjectDropdownPicker);

/** all import as default component */
export default ConnectedTodoProjectDropdownPicker;
