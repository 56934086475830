import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Store } from 'redux';
import { getSessionToken, setSessionToken } from '../../store/ducks/session';
import './SessionAuthorize.scss';
import queryString from 'query-string';

/** interface to describe SessionAuthorize props*/
interface SessionAuthorizeProps {
  token: string;
  setSessionTokenActionCreator: typeof setSessionToken;
}

const SessionAuthorize = (props: SessionAuthorizeProps) => {
  const { token, setSessionTokenActionCreator } = props;
  const location = useLocation();
  const history = useHistory();

  /** destroys the session token */
  React.useEffect(() => {
    setSessionTokenActionCreator(
      (queryString.parse(location.search)['token'] as string) || ''
    );

    history.push(
      (queryString.parse(location.search)['redirect'] as string) || '/'
    );
  }, []);

  return <div>{token !== '' && <Redirect to={'/'} />}</div>;
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionTokenActionCreator: setSessionToken,
};

/** connect SessionAuthorize to the redux store */
const ConnectedSessionAuthorize = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionAuthorize);

export default ConnectedSessionAuthorize;
