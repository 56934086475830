import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import {
  DUE_NOTIFICATION,
  INVITATION_ACCEPTANCE_NOTIFICATION,
  INVITATION_NOTIFICATION,
  NotificationObj,
  OVERDUE_NOTIFICATION,
  REMINDER_NOTIFICATION,
  UPCOMING_NOTIFICATION,
} from '../../../store/ducks/notifications';
import './NotificationItem.scss';

interface NotificationItemProps {
  data: NotificationObj;
  clickHandler?: (requestedId: string) => void;
}

const NotificationItem: React.FC<NotificationItemProps> = (
  props: NotificationItemProps
) => {
  const { data, clickHandler } = props;
  const onClickHandler = () => {
    if (data.url && clickHandler) {
      clickHandler(data.id);
    }
  };
  return (
    <div
      className={clsx({
        'NotificationItem-container': true,
        'NotificationItem-container-read': data.isRead,
      })}
      onClick={onClickHandler}
    >
      <div className="NotificationItem-icon">
        <i
          className={clsx({
            fas: true,
            'fa-bell': data.type === REMINDER_NOTIFICATION,
            'fa-calendar-day': [
              UPCOMING_NOTIFICATION,
              OVERDUE_NOTIFICATION,
              DUE_NOTIFICATION,
            ].includes(data.type),
            'fa-user': [
              INVITATION_NOTIFICATION,
              INVITATION_ACCEPTANCE_NOTIFICATION,
            ].includes(data.type),
            'fa-info': ![
              UPCOMING_NOTIFICATION,
              OVERDUE_NOTIFICATION,
              DUE_NOTIFICATION,
              REMINDER_NOTIFICATION,
              INVITATION_NOTIFICATION,
              INVITATION_ACCEPTANCE_NOTIFICATION,
            ].includes(data.type),
          })}
        ></i>
      </div>
      <div>
        <div className="NotificationItem-content">
          <strong>
            {data.type ? (
              <span className="NotificationItem-margin-right">
                {data.type + ': '}
              </span>
            ) : (
              ''
            )}
          </strong>
          <strong>{data.subject ? data.subject?.name + ' ' || '' : ''}</strong>
          {data.message}
          <strong>{data.object ? ' ' + data.object?.name : ''}</strong>.
        </div>
        <div className="NotificationItem-meta">
          <div>
            {data.createdAt
              ? moment(data.createdAt).fromNow()
              : moment().fromNow()}
          </div>
        </div>
      </div>
      <div className="NotificationItem-read">
        {!data.isRead && <div className="NotificationItem-circle"></div>}
      </div>
    </div>
  );
};

export default NotificationItem;
