import lodash from 'lodash';
import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';

export const UPCOMING_NOTIFICATION = 'Upcoming';
type UPCOMING_NOTIFICATION = typeof UPCOMING_NOTIFICATION;

export const OVERDUE_NOTIFICATION = 'Overdue';
type OVERDUE_NOTIFICATION = typeof OVERDUE_NOTIFICATION;

export const DUE_NOTIFICATION = 'Due';
type DUE_NOTIFICATION = typeof DUE_NOTIFICATION;

export const REMINDER_NOTIFICATION = 'Reminder';
type REMINDER_NOTIFICATION = typeof REMINDER_NOTIFICATION;

export const INVITATION_NOTIFICATION = 'Project Invitation';
type INVITATION_NOTIFICATION = typeof INVITATION_NOTIFICATION;

export const INVITATION_ACCEPTANCE_NOTIFICATION = 'Invitation Accepted';
type INVITATION_ACCEPTANCE_NOTIFICATION = typeof INVITATION_ACCEPTANCE_NOTIFICATION;

export type NOTIFICATION_TYPE =
  | UPCOMING_NOTIFICATION
  | OVERDUE_NOTIFICATION
  | DUE_NOTIFICATION
  | REMINDER_NOTIFICATION
  | INVITATION_NOTIFICATION
  | INVITATION_ACCEPTANCE_NOTIFICATION;

export interface NotificationEntity {
  name: string | null;
  id: string | null;
  url: string | null;
  avatar: string | null;
}

export interface NotificationObj {
  id: string;
  url: string | null;
  visibility: boolean;
  category: string | null;
  target: string | null;
  type: NOTIFICATION_TYPE;
  declineUrl: string | null;
  acceptUrl: string | null;
  subject: NotificationEntity | null;
  object: NotificationEntity | null;
  message: string;
  isRead: boolean;
  createdAt: string | null;
}

/** The reducer name */
export const reducerName = 'notifications';

// actions
/** action types */
export const SET_NOTIFICATIONS =
  'virtunus/reducer/notifications/SET_NOTIFICATIONS';
/** action types */
export const SET_NOTIFICATION =
  'virtunus/reducer/notifications/SET_NOTIFICATION';
/** action types */
export const ADD_NOTIFICATIONS =
  'virtunus/reducer/notifications/ADD_NOTIFICATIONS';
/** action types */
export const MARK_NOTIFICATION_AS_READ =
  'virtunus/reducer/notifications/MARK_NOTIFICATION_AS_READ';
/** action types */
export const MARK_ALL_NOTIFICATIONS_AS_READ =
  'virtunus/reducer/notifications/MARK_ALL_NOTIFICATIONS_AS_READ';
/** action types */
export const DELETE_NOTIFICATIONS =
  'virtunus/reducer/notifications/DELETE_NOTIFICATIONS';

/** interface for SET_NOTIFICATIONS action */
export interface SetNotificationsAction extends AnyAction {
  notifications: NotificationObj[];
  type: typeof SET_NOTIFICATIONS;
}

/** interface for SET_NOTIFICATION action */
export interface SetNotificationAction extends AnyAction {
  notification: NotificationObj;
  type: typeof SET_NOTIFICATION;
}

/** interface for ADD_NOTIFICATIONS action */
export interface AddNotificationsAction extends AnyAction {
  notifications: NotificationObj[];
  type: typeof ADD_NOTIFICATIONS;
}

/** interface for DELETE_NOTIFICATIONS action */
export interface DeleteNotificationsAction extends AnyAction {
  notificationIds: string[];
  type: typeof DELETE_NOTIFICATIONS;
}

/** interface for MARK_NOTIFICATION_AS_READ action */
export interface MarkNotificationAsRead extends AnyAction {
  notificationId: string;
  type: typeof MARK_NOTIFICATION_AS_READ;
}

/** interface for MARK_ALL_NOTIFICATIONS_AS_READ action */
export interface MarkAllNotificationsAsRead extends AnyAction {
  type: typeof MARK_ALL_NOTIFICATIONS_AS_READ;
}

/** Create type for notifications reducer actions */
export type NotificationsActionTypes =
  | SetNotificationsAction
  | SetNotificationAction
  | AddNotificationsAction
  | DeleteNotificationsAction
  | MarkNotificationAsRead
  | MarkAllNotificationsAsRead
  | AnyAction;

// action creators

/** set notifications action creator
 * @param {NotificationObj[]} notifications - the notifications to set
 * @returns {SetNotificationsAction} - an action to set notifications in store
 */
export const setNotifications = (
  notifications: NotificationObj[]
): SetNotificationsAction => ({
  notifications,
  type: SET_NOTIFICATIONS,
});

/** set notifications action creator
 * @param {NotificationObj} notification - the notification to set
 * @returns {SetNotificationAction} - an action to set notifications in store
 */
export const setNotification = (
  notification: NotificationObj
): SetNotificationAction => ({
  notification,
  type: SET_NOTIFICATION,
});

/** add notifications action creator
 * @param {NotificationObj[]} notifications - the notifications to add
 * @returns {AddNotificationsAction} - an action to add notifications in store
 */
export const addNotifications = (
  notifications: NotificationObj[]
): AddNotificationsAction => ({
  notifications,
  type: ADD_NOTIFICATIONS,
});

/** delete notifications action creator
 * @param {string[]} notificationIds - the notification ids to delete
 * @returns {DeleteNotificationsAction} - an action to delete notifications in store
 */
export const deleteNotifications = (
  notificationIds: string[]
): DeleteNotificationsAction => ({
  notificationIds,
  type: DELETE_NOTIFICATIONS,
});

/** mark notifications as read action creator
 * @param {string} notificationId - the notification id to mark
 * @returns {MarkNotificationAsRead} - an action to mark notifications in store
 */
export const markNotificationAsRead = (
  notificationId: string
): MarkNotificationAsRead => ({
  notificationId,
  type: MARK_NOTIFICATION_AS_READ,
});

/** mark all notifications as read action creator
 * @returns {MarkAllNotificationsAsRead} - an action to mark notifications in store
 */
export const markAllNotificationsAsRead = (): MarkAllNotificationsAsRead => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ,
});

// the reducer

/** interface for notifications state in redux store */
type NotificationsState = NotificationObj[];

/** Create an immutable notifications state */
export type ImmutableNotificationsState = SeamlessImmutable.ImmutableArray<
  NotificationsState
>;

/** initial notifications state */
const initialState: ImmutableNotificationsState = SeamlessImmutable([]);

/** the notifications reducer function */
export default function reducer(
  state: ImmutableNotificationsState = initialState,
  action: NotificationsActionTypes
): ImmutableNotificationsState {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return SeamlessImmutable(action.notifications);
    case SET_NOTIFICATION:
      return SeamlessImmutable([
        ...state.asMutable({ deep: true }),
        action.notification,
      ]);
    case ADD_NOTIFICATIONS:
      return SeamlessImmutable([
        ...lodash.filter(
          action.notifications,
          (iterNotification: NotificationObj) =>
            lodash.find(state as any, {
              id: iterNotification.id,
            })
              ? false
              : true
        ),
        ...state.asMutable({ deep: true }),
      ]);
    case DELETE_NOTIFICATIONS:
      return SeamlessImmutable(
        lodash.filter(
          state.asMutable({ deep: true }) as any,
          (iterNotification: NotificationObj) =>
            !action.notificationIds.include(iterNotification.id)
        )
      );
    case MARK_NOTIFICATION_AS_READ:
      return SeamlessImmutable(
        lodash.map(
          state.asMutable({ deep: true }) as any,
          (iterNotification: NotificationObj) => {
            return action.notificationId === iterNotification.id
              ? { ...iterNotification, isRead: true }
              : iterNotification;
          }
        ) as any
      );
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      return SeamlessImmutable(
        lodash.map(
          state.asMutable({ deep: true }) as any,
          (iterNotification: NotificationObj) => {
            return !iterNotification.isRead
              ? { ...iterNotification, isRead: true }
              : iterNotification;
          }
        ) as any
      );
    default:
      return state;
  }
}

// selectors

/** returns the notifications list
 * @param {Partial<Store>} state - the redux store
 * @return { NotificationObj[] } - the existing notifications
 */
export function getNotifications(state: Partial<Store>): NotificationObj[] {
  return (state as any)[reducerName];
}

/** returns the unread notification count
 * @param {Partial<Store>} state - the redux store
 * @return { NotificationObj[] } - the existing unread notifications count
 */
export function getUnreadNotificationsCount(state: Partial<Store>): number {
  return lodash.filter((state as any)[reducerName], { isRead: false }).length;
}

/** returns true if notification id is present; otherwise false
 * @param {Partial<Store>} state - the redux store
 * @param {string} notificationId - the notifcation id
 * @return { boolean } - returns true if notification id is present; otherwise false
 */
export function checkIfNotificationPresent(
  state: Partial<Store>,
  notificationId: string
): boolean {
  return lodash.find((state as any)[reducerName], { id: notificationId })
    ? true
    : false;
}
