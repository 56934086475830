import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Store } from 'redux';
import NotificationItem from '../../components/todo/notificationItem/NotificationItem';
import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  NotificationObj,
} from '../../store/ducks/notifications';
import queryString from 'query-string';
import './NotificationsDropdown.scss';
import clsx from 'clsx';
import {
  requestAllNotificationsAsRead,
  requestNotificationAsRead,
} from '../../api/notifications/request';

interface NotificationsDropdownInterface {
  markNotificationAsReadActionCreator: typeof markNotificationAsRead;
  markAllNotificationsAsReadActionCreator: typeof markAllNotificationsAsRead;
  notifications: NotificationObj[];
}

const NotificationsDropdown: React.FC<NotificationsDropdownInterface> = (
  props: NotificationsDropdownInterface
) => {
  const {
    notifications,
    markAllNotificationsAsReadActionCreator,
    markNotificationAsReadActionCreator,
  } = props;

  const location = useLocation();
  const taskId = (queryString.parse(location.search)['task'] as string) || '';

  const allMarkHandler = async () => {
    try {
      await requestAllNotificationsAsRead();
      markAllNotificationsAsReadActionCreator();
    } catch (Exception) {
      console.error(Exception);
    }
  };

  const notificationClickHandler = async (requestedId: string) => {
    try {
      const notificationInfo = lodash.find(notifications, { id: requestedId });
      if (!notificationInfo?.isRead) {
        requestNotificationAsRead(requestedId);
        markNotificationAsReadActionCreator(requestedId);
      }
      if (notificationInfo?.url) {
        window.location.href = notificationInfo?.url;
      }
    } catch (Exception) {
      console.error(Exception);
    }
  };

  return (
    <div
      className={clsx({
        'NotificationsDropdown-container': true,
        'NotificationsDropdown-container-hidden': taskId !== '',
      })}
    >
      <div className="NotificationsDropdown-header">
        <div className="NotificationsDropdown-title">Notifications</div>
        <div
          onClick={allMarkHandler}
          className="NotificationsDropdown-mark-all"
        >
          Mark all as Read
        </div>
      </div>
      <div className="NotificationsDropdown-body">
        {notifications.map(
          (iterNotification: NotificationObj, index: number) => (
            <NotificationItem
              key={'notification' + index}
              data={iterNotification}
              clickHandler={notificationClickHandler}
            />
          )
        )}
        {notifications.length === 0 && (
          <div className="NotificationsDropdown-body-empty">
            <div>You have no notifications yet.</div>
          </div>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  notifications: NotificationObj[];
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    notifications: getNotifications(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  markNotificationAsReadActionCreator: markNotificationAsRead,
  markAllNotificationsAsReadActionCreator: markAllNotificationsAsRead,
};

/** connect NotificationsDropdown to the redux store */
const ConnectedNotificationsDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDropdown);

export default ConnectedNotificationsDropdown;
