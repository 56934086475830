import lodash from 'lodash';
import {
  ALPHA_SORT,
  ASC_SORT,
  DEFAULT_SORT,
  DUE_DATE_SORT,
  PRIORITY_SORT,
  SORT_ORDER,
  SORT_TYPE,
} from '../configs/constants';

const treeIterator = (container: any, nodeList: any, parent: string) => {
  nodeList.forEach((node: any, index: any) => {
    if ('children' in node) {
      treeIterator(container, node.children, node.id);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, expanded, ...rest } = node;
    container.push({
      ...rest,
      expanded: expanded ? true : false,
      parent,
      order: index,
    });
  });
};

/** get flat structure from tree structure */
export const getFlatDataFromTree = (data: any) => {
  const container: any = [];
  treeIterator(container, data, '');
  return container;
};

/** sorts the list according to the system types and orders */
export const sortList = (
  data: any,
  sortType: SORT_TYPE = DEFAULT_SORT,
  sortOrder: SORT_ORDER = ASC_SORT
) => {
  switch (sortType) {
    case PRIORITY_SORT:
      return lodash.orderBy(data, ['priority'], [sortOrder]);
    case DUE_DATE_SORT:
      return lodash.orderBy(
        data,
        ['dueDate', 'dueTime'],
        [sortOrder, sortOrder]
      );
    case ALPHA_SORT:
      return lodash.orderBy(data, ['title'], [sortOrder]);
    case DEFAULT_SORT:
      return lodash.orderBy(data, ['order'], [sortOrder]);
    default:
      return lodash.orderBy(data, ['order'], ['asc']);
  }
};

const flatIterator = (
  data: any,
  parent: string,
  sortType: SORT_TYPE,
  sortOrder: SORT_ORDER
) => {
  const tmpContainer = lodash.filter(data, { parent });
  const tree: any = [];
  if (tmpContainer.length === 0) {
    return tree;
  }
  tmpContainer.forEach((node: any) => {
    const children = flatIterator(data, node.id, sortType, sortOrder);
    if (children) {
      tree.push({ ...node, children: children });
    } else {
      tree.push(node);
    }
  });
  const modifiedTree = sortList(tree, sortType, sortOrder);
  return modifiedTree.map((node) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, order, ...rest } = node;
    return rest;
  });
};

/** get tree structure from flat list */
export const getTreeDataFromFlat = (
  data: any,
  sortType: SORT_TYPE = DEFAULT_SORT,
  sortOrder: SORT_ORDER = ASC_SORT
) => {
  return flatIterator(data, '', sortType, sortOrder);
};

export const getSubTreeDataFromParents = (data: any, parents: any) => {
  return parents.map((node: any) => {
    const children = flatIterator(data, node.id, DEFAULT_SORT, ASC_SORT);
    if (children) {
      node = { ...node, children: children };
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, order, ...rest } = node;
    return rest;
  });
};
