import { Button, Modal } from 'antd';
import React from 'react';
import './TodoLabelDeleteButton.scss';
import { connect } from 'react-redux';
import { fixLabelOrders, LabelObj, setLabel } from '../../store/ducks/labels';
import { deleteTasksBasedOnLabels } from '../../store/ducks/tasks';

/** interface to describe TodoLabelDeleteButton props */
interface TodoLabelDeleteButtonProps {
  labelInfo: LabelObj;
  setLabelActionCreator: typeof setLabel;
  closeHandler: () => void;
  fixLabelOrdersActionCreator: typeof fixLabelOrders;
  deleteTasksBasedOnLabelsActionCreator: typeof deleteTasksBasedOnLabels;
}

/** Placeholders subject to delete */
const DELETE_LABEL = 'Delete Label';

const TodoLabelDeleteButton: React.FC<TodoLabelDeleteButtonProps> = (
  props: TodoLabelDeleteButtonProps
) => {
  const {
    labelInfo,
    setLabelActionCreator,
    closeHandler,
    fixLabelOrdersActionCreator,
    deleteTasksBasedOnLabelsActionCreator,
  } = props;
  const { confirm } = Modal;

  /** manages the favourite toogle request */
  const okHandler = () => {
    setLabelActionCreator({
      ...labelInfo,
      synced: false,
      deleted: true,
    });

    /** fix the order after label deletion */
    fixLabelOrdersActionCreator(labelInfo.parent);

    /** remove tasks associated with the labels */
    deleteTasksBasedOnLabelsActionCreator([labelInfo.id]);
  };

  const config = {
    className: 'TodoLabelDeleteButton-modal-container',
    title: (
      <div className="TodoLabelDeleteButton-modal-title">Delete Label</div>
    ),
    content: (
      <div>
        Are you sure you want to delete the label{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {labelInfo.title}
        </span>{' '}
        ?<br />
      </div>
    ),
    width: 500,
    onOk() {
      okHandler();
    },
  };

  const requestHandler = () => {
    closeHandler();
    confirm(config);
  };

  return (
    <Button
      onClick={requestHandler}
      icon={<i className="fas fa-trash"></i>}
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="TodoLabelOptionsPanel-item"
    >
      {DELETE_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setLabelActionCreator: setLabel,
  fixLabelOrdersActionCreator: fixLabelOrders,
  deleteTasksBasedOnLabelsActionCreator: deleteTasksBasedOnLabels,
};

/** connect TodoLabelDeleteButton to the redux store */
const ConnectedTodoLabelDeleteButton = connect(
  null,
  mapDispatchToProps
)(TodoLabelDeleteButton);

export default ConnectedTodoLabelDeleteButton;
