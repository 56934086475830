import React from 'react';
import './ProjectTasksList.scss';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import TaskItem from '../taskItem/TaskItem';
import { connect } from 'react-redux';
import {
  getProjectAndSectionBasedTasks,
  TaskObj,
  updateTasks,
} from '../../store/ducks/tasks';
import { Store } from 'redux';
import {
  getFlatDataFromTree,
  getTreeDataFromFlat,
} from '../../services/treeConverter';
import lodash from 'lodash';
import { getProjectById } from '../../store/ducks/projects';
import { syncService } from '../../services/syncService';
import FetchCompletedTasks from '../fetchCompletedTasks/FetchCompletedTasks';
import { SORT_ORDER, SORT_TYPE } from '../../configs/constants';

interface ProjectTasksListProps {
  sectionId: string;
  projectId: string;
  isProjectShared: boolean;
  treeData: any;
  completedTreeData: any;
  sortType: SORT_TYPE;
  sortOrder: SORT_ORDER;
  hideCompletedTasks: boolean;
  setTreeTasksActionCreator: typeof updateTasks;
}

const ProjectTasksList: React.FC<ProjectTasksListProps> = (
  props: ProjectTasksListProps
) => {
  const {
    treeData,
    setTreeTasksActionCreator,
    projectId,
    sectionId,
    completedTreeData,
    isProjectShared,
    hideCompletedTasks,
  } = props;

  /** manages the mount/unmount of react sortable tree */
  const [loaded, setLoaded] = React.useState<boolean>(true);

  const treeChangeHandler = (modifiedTreeData: any) => {
    setTreeTasksActionCreator(
      getFlatDataFromTree(modifiedTreeData as any).map(
        (iterTaskObj: TaskObj) => ({
          ...iterTaskObj,
          synced: false,
          projectId: projectId,
          sectionId: sectionId,
        })
      ) as any
    );

    /** manages the sync service */
    syncService();
  };

  /** hack: unmounts the sortable tree */
  React.useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }, [isProjectShared]);

  return (
    <div className="ProjectTasksList-container">
      {loaded && (
        <SortableTree
          dndType={isProjectShared ? 'shared-task' : 'task'}
          isVirtualized={false}
          treeData={treeData}
          generateNodeProps={({ node, path }) => ({
            title: <TaskItem path={path.length} task={node as any} />,
          })}
          maxDepth={5}
          onChange={treeChangeHandler}
        />
      )}
      {!hideCompletedTasks && completedTreeData.length > 0 && (
        <React.Fragment>
          <div>
            <div className="ProjectTasksList-completed-title">Completed</div>
          </div>
          <div className="ProjectTasksList-completed-section">
            <SortableTree
              dndType="completedTask"
              isVirtualized={false}
              treeData={completedTreeData}
              generateNodeProps={({ node, path }) => ({
                title: <TaskItem path={path.length} task={node as any} />,
              })}
              onChange={treeChangeHandler}
              maxDepth={5}
            />
          </div>
        </React.Fragment>
      )}
      {!hideCompletedTasks && (
        <div>
          <FetchCompletedTasks
            isPresent={completedTreeData.length > 0}
            projectId={projectId}
            sectionId={sectionId}
          />
        </div>
      )}
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  treeData: any;
  completedTreeData: any;
  isProjectShared: boolean;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: any
): DispatchedStateProps => {
  const tmpFlatData = getProjectAndSectionBasedTasks(
    state,
    parentProps.projectId,
    parentProps.sectionId
  );
  const result = {
    treeData: getTreeDataFromFlat(
      lodash.filter(
        tmpFlatData,
        (iterTask: TaskObj) =>
          !(iterTask.parent === '' && iterTask.completed) &&
          iterTask.deleted === false
      ),
      parentProps.sortType,
      parentProps.sortOrder
    ),
    completedTreeData: getTreeDataFromFlat(
      lodash.filter(
        tmpFlatData,
        (iterTask: TaskObj) =>
          (iterTask.parent === '' &&
            iterTask.completed &&
            iterTask.deleted === false) ||
          iterTask.parent !== ''
      ),
      parentProps.sortType,
      parentProps.sortOrder
    ),
    isProjectShared:
      getProjectById(state, parentProps.projectId)?.sharedWith.length > 1
        ? true
        : false,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTreeTasksActionCreator: updateTasks,
};

/** connect ProjectTasksList to the redux store */
const ConnectedProjectTasksList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectTasksList);

export default ConnectedProjectTasksList;
