import React from 'react';
import './TodoLabels.scss';
import withFolderLayout from '../../hocs/withFolderLayout/withFolderLayout';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import LabelItem from '../todoLabelItem/TodoLabelItem';
import {
  getFlatDataFromTree,
  getTreeDataFromFlat,
} from '../../services/treeConverter';
import { Store } from 'antd/lib/form/interface';
import { connect } from 'react-redux';
import { getLabels, LabelObj, updateLabels } from '../../store/ducks/labels';
import ConnectedAddTodoLabel from '../addTodoLabel/AddTodoLabel';
import lodash from 'lodash';
import { customTreeNodeComparator } from '../../services/utility';

/** interface to describe the TodoLabels props */
interface TodoLabelsProps {
  isModalOpen: boolean;
  flatTreeData: LabelObj[];
  updateLabelsActionCreator: typeof updateLabels;
  modalOpenHandler: () => void;
  modalCloseHandler: () => void;
}

const TodoLabels: React.FC<TodoLabelsProps> = (props: TodoLabelsProps) => {
  const {
    flatTreeData,
    updateLabelsActionCreator,
    isModalOpen,
    modalCloseHandler,
  } = props;

  /** Get the tree data from flat data */
  const treeData = getTreeDataFromFlat(flatTreeData);

  /**
   * handles the changed request on tree data
   * @param {any} requestTreeData - the changed tree data
   */
  const treeChangeHandler = (requestTreeData: any) => {
    let modifiedRows: any = lodash.differenceWith(
      getFlatDataFromTree(requestTreeData),
      flatTreeData,
      customTreeNodeComparator
    );
    modifiedRows = modifiedRows.map((iterLabel: LabelObj) => ({
      ...iterLabel,
      synced: false,
    }));
    updateLabelsActionCreator(modifiedRows);
  };

  return (
    <div className="TodoLabels-container">
      {treeData.length !== 0 ? (
        <SortableTree
          isVirtualized={false}
          treeData={treeData}
          scaffoldBlockPxWidth={7}
          generateNodeProps={({ node, path }) => ({
            title: (
              <LabelItem
                labelId={node.id}
                path={path.length}
                label={node.title as string}
                color={node.color}
              />
            ),
          })}
          maxDepth={1}
          onChange={treeChangeHandler}
        />
      ) : (
        <div className="TodoLabels-empty-container">You have no labels</div>
      )}
      <ConnectedAddTodoLabel
        visible={isModalOpen}
        closeHandler={modalCloseHandler}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  flatTreeData: LabelObj[];
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    flatTreeData: lodash.filter(getLabels(state), { deleted: false }),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  updateLabelsActionCreator: updateLabels,
};

/** connect TodoLabels to the redux store */
const ConnectedTodoLabels = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoLabels);

export default withFolderLayout(ConnectedTodoLabels, 'Labels', 'Add new label');
