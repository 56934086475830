import DatePicker from 'react-datepicker';
import React from 'react';
import './CustomDatePicker.scss';
import moment from 'moment';

interface CustomDatePickerProps {
  selectedDate: Date;
  dateChangeHandler: (requestedDate: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = (
  props: CustomDatePickerProps
) => {
  const { selectedDate, dateChangeHandler, minDate, maxDate } = props;

  const onChangeHandler = (date: Date | null) => {
    if (date) {
      dateChangeHandler(date);
    } else {
      dateChangeHandler(new Date());
    }
  };

  return (
    <div className="CustomDatePicker-datepicker">
      <div className="CustomDatePicker-calendar-icon">
        <i className="fas fa-calendar-alt" />{' '}
      </div>
      <DatePicker
        className="CustomDatePicker-dateinput"
        selected={selectedDate}
        onChange={onChangeHandler}
        dateFormat="d MMM, yyyy"
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="CustomDatePicker-datepicker-header">
            <div className="CustomDatePicker-datepicker-label">
              {moment(date.toString()).format('MMMM YYYY')}
            </div>
            <button
              className="CustomDatePicker-datepicker-left"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <i className="fas fa-chevron-left" />
            </button>
            <button
              className="CustomDatePicker-datepicker-right"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        )}
      />
    </div>
  );
};

export default CustomDatePicker;
