import React from 'react';
import UpcomingBadge from '../../upcomingBadge/UpcomingBadge';
import './SettingsSider.scss';

const SettingsSider: React.FC = () => {
  return (
    <div className="SettingsSider-container">
      <div className="SettingsSider-nav-item SettingsSider-nav-item-focused">
        General
      </div>
      <div className="SettingsSider-nav-item">
        Subscriptions <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Theme <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Performance <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Reminders <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Notifications <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Backups <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Integrations <UpcomingBadge />
      </div>
      <div className="SettingsSider-nav-item">
        Import <UpcomingBadge />
      </div>
    </div>
  );
};

export default SettingsSider;
