import React from 'react';
import './TodoLabelItem.scss';
import { Button, Dropdown } from 'antd';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { getLabelById, LabelObj } from '../../store/ducks/labels';
import TodoLabelOptionsPanel from '../../components/todo/labels/labelOptionsPanel/TodoLabelOptionsPanel';
import { getPendingTasksCountByLabelId } from '../../store/ducks/tasks';
import { LOCAL_PRE_LABEL_ENDPOINT } from '../../configs/endpoints';
import ConnectedAddTodoLabel from '../addTodoLabel/AddTodoLabel';
import { disablePointerEventsOnMainLayout } from '../../services/utility';

export interface TodoLabelItemProps {
  labelId: string;
  labelInfo: LabelObj;
  path: number;
  color: string;
  label: string;
  count: number;
}

const TodoLabelItem: React.FC<TodoLabelItemProps> = (
  props: TodoLabelItemProps
) => {
  const { labelId, labelInfo, color, label, count, path } = props;
  const history = useHistory();
  const location = useLocation();

  /** manages the visibility of project more panel */
  const [visible, setVisible] = React.useState<boolean>(false);

  /** manages the visiblity of modal */
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  /** handles the request of closing modal */
  const modalCloseHandler = () => setIsModalOpen(false);

  /** handles the request of opening modal */
  const modalOpenHandler = () => {
    setIsModalOpen(true);
  };

  /** handles the navigation */
  const navigationHandler = () =>
    history.push(LOCAL_PRE_LABEL_ENDPOINT + labelId);

  /** handles the keyboard enter request */
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigationHandler();
    }
  };

  return (
    <div
      className={clsx({
        'TodoLabelItem-container': true,
        'TodoLabelItem-container--active':
          location.pathname === LOCAL_PRE_LABEL_ENDPOINT + labelId,
        'TodoLabelItem-container--first': path === 1,
        'TodoLabelItem-container--second': path === 2,
        'TodoLabelItem-container--third': path === 3,
        'TodoLabelItem-container--fourth': path === 4,
      })}
      onClick={navigationHandler}
      onContextMenu={(event: any) => {
        event.preventDefault();
        setVisible(true);
        disablePointerEventsOnMainLayout(true);
      }}
      onDoubleClick={modalOpenHandler}
      onKeyPress={handleKeyPress}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <i className="fas fa-tag TodoLabelItem-tag" style={{ color: color }}></i>
      <div
        className={clsx({
          'TodoLabelItem-label': true,
          'TodoLabelItem-label--first': path === 1,
          'TodoLabelItem-label--second': path === 2,
          'TodoLabelItem-label--third': path === 3,
          'TodoLabelItem-label--fourth': path === 4,
        })}
      >
        {label}
      </div>
      <div className="TodoLabelItem-count">{count}</div>
      <div
        className={clsx({
          'TodoLabelItem-more-btn-container': true,
          'TodoLabelItem-more-btn-container--active': visible,
        })}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown
            overlay={
              <TodoLabelOptionsPanel
                labelInfo={labelInfo}
                panelCloseHandler={() => {
                  setVisible(false);
                  disablePointerEventsOnMainLayout(false);
                }}
              />
            }
            placement="bottomRight"
            trigger={['click']}
            visible={visible}
            onVisibleChange={(requestedVisibility: boolean) => {
              setVisible(requestedVisibility);
              disablePointerEventsOnMainLayout(requestedVisibility);
            }}
          >
            <Button
              className="TodoLabelItem-more-btn"
              shape="circle"
              size="small"
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
          </Dropdown>
        </div>
        <ConnectedAddTodoLabel
          initialUserInput={labelInfo}
          visible={isModalOpen}
          closeHandler={modalCloseHandler}
        />
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  count: number;
  labelInfo: LabelObj;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoLabelItemProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const result = {
    labelInfo: getLabelById(state, parentProps.labelId),
    count: getPendingTasksCountByLabelId(state, parentProps.labelId),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoLabelItem to the redux store */
const ConnectedTodoLabelItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoLabelItem);

export default ConnectedTodoLabelItem;
