import { SERVER_NOTIFICATIONS_ENDPOINT } from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { NotificationObj } from '../../store/ducks/notifications';
import { ServerNotificationObj } from './data';
import { transformNotificationFromServer } from './transformer';

export const fetchNotificationFromServer = async (): Promise<
  NotificationObj[]
> => {
  const response = await axioService(
    GET,
    SERVER_NOTIFICATIONS_ENDPOINT,
    {},
    true
  );
  return response.data.data.map(
    (iterNotification: ServerNotificationObj): NotificationObj =>
      transformNotificationFromServer(iterNotification)
  );
};

export const requestAllNotificationsAsRead = async () => {
  await axioService(
    POST,
    SERVER_NOTIFICATIONS_ENDPOINT + '/mark-all-as-read',
    {},
    true
  );
};

export const requestNotificationAsRead = async (notificationId: string) => {
  await axioService(
    POST,
    SERVER_NOTIFICATIONS_ENDPOINT + '/' + notificationId + '/mark-as-read',
    {},
    true
  );
};
