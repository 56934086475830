import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'redux';
import {
  LOCAL_INBOX_ENDPOINT,
  LOCAL_PRE_PROJECT_ENDPOINT,
} from '../../configs/endpoints';
import { getProjectById } from '../../store/ducks/projects';
import './TodoTaskProjectTag.scss';

/** interface to describe TodoTaskProjectTag props */
interface TodoTaskProjectTagProps {
  id: string;
  title: string;
  location: string;
  color: string | null;
}

const TodoTaskProjectTag = (props: TodoTaskProjectTagProps) => {
  const { title, location, color } = props;
  const history = useHistory();
  return (
    <div
      className="TodoTaskProjectTag-project"
      style={color ? { color } : {}}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        history.push(location);
      }}
    >
      {title}
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  title: string;
  location: string;
  color: string | null;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoTaskProjectTagProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  if (parentProps.id === '') {
    return {
      title: 'Inbox',
      location: LOCAL_INBOX_ENDPOINT,
      color: null,
    };
  }
  const projectInfo = getProjectById(state, parentProps.id) || {
    title: '',
    color: '',
  };
  const result = {
    title: projectInfo.title,
    location: LOCAL_PRE_PROJECT_ENDPOINT + projectInfo.id,
    color: projectInfo.color,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoTaskProjectTag to the redux store */
const ConnectedTodoTaskProjectTag = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoTaskProjectTag);

export default ConnectedTodoTaskProjectTag;
