import { Tooltip } from 'antd';
import lodash from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ActivityType, ACTIVITY_TYPES } from '../../../configs/activityTypes';
import { LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT } from '../../../configs/endpoints';
import './TodoTaskActivityTag.scss';

/** interface to describe TodoTaskActivityTag props */
interface TodoTaskActivityTagProps {
  title: string;
}

const TodoTaskActivityTag = (props: TodoTaskActivityTagProps) => {
  const { title } = props;
  const history = useHistory();
  const intended = lodash.find(ACTIVITY_TYPES, { value: title });
  return (
    <Tooltip title={intended?.label || ''} placement="bottom">
      <div
        className="TodoTaskActivityTag-container"
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          history.push(LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT + title);
        }}
      >
        <i className={(intended as ActivityType)?.icon || ''} />
      </div>
    </Tooltip>
  );
};

export default TodoTaskActivityTag;
