import React from 'react';
import './TodoProjectContent.scss';
import AddTask from '../addTask/AddTask';
import TasksList from '../projectTasksList/ProjectTasksList';
import TasksSection from '../tasksSection/TasksSection';
import AddTaskSection from '../addTaskSection/AddTaskSection';
import { getProjectById, ProjectObj } from '../../store/ducks/projects';
import { Store } from 'redux';
import { connect } from 'react-redux';
import {
  getSectionsByProjectId,
  SectionObj,
  updateSections,
} from '../../store/ducks/sections';
import {
  getFlatDataFromTree,
  getTreeDataFromFlat,
} from '../../services/treeConverter';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import lodash from 'lodash';
import { Avatar, Dropdown, Select } from 'antd';
import {
  CollaboratorObj,
  getCollaborators,
} from '../../store/ducks/collaborators';
import { getSessionUserInfo } from '../../store/ducks/session';
import ConnectedShareProject from '../shareProject/ShareProject';
import {
  ASC_SORT,
  DEFAULT_SORT,
  DESC_SORT,
  SORTING_OPTIONS,
  SORT_TYPE,
} from '../../configs/constants';
import clsx from 'clsx';
import { removeSyncedCompletedTasks } from '../../store/ducks/tasks';
import ProjectOptionsPanel from '../../components/todo/projects/projectOptionsPanel/ProjectOptionsPanel';
import {
  disablePointerEventsOnMainLayout,
  disablePointerEventsOnTaskSider,
} from '../../services/utility';

/** types */

/** interface to describe TodoProjectContent props */
interface TodoProjectContentProps {
  projectId: string;
  projectInfo: ProjectObj | null;
  treeSections: any;
  collaboratorList: CollaboratorObj[];
  userInfo: any;
  setTreeSectionsActionCreator: typeof updateSections;
  removeSyncedCompletedTasksActionCreator: typeof removeSyncedCompletedTasks;
}

const TodoProjectContent: React.FC<TodoProjectContentProps> = (
  props: TodoProjectContentProps
) => {
  const {
    projectId,
    projectInfo,
    treeSections,
    userInfo,
    collaboratorList,
    removeSyncedCompletedTasksActionCreator,
    setTreeSectionsActionCreator,
  } = props;

  const { Option } = Select;

  /** manages the visiblity of share modal */
  const [isShareModalOpen, setIsShareModalOpen] = React.useState<boolean>(
    false
  );

  /** manages the sorting type */
  const [sortType, setSortType] = React.useState<SORT_TYPE>(DEFAULT_SORT);

  /** manages the sorting order */
  const [sortOrder, setSortOrder] = React.useState<ASC_SORT | DESC_SORT>(
    ASC_SORT
  );

  /** manages the more options visibility */
  const [optionsVisible, setOptionsVisible] = React.useState<boolean>(false);

  /** manages the visibility of hidden tasks */
  const [isCompletedTasksHidden, setIsCompletedTasksHidden] = React.useState<
    boolean
  >(true);

  /** cycles */

  React.useEffect(() => {
    /** remove completed tasks if project id changes */
    removeSyncedCompletedTasksActionCreator();
    setIsCompletedTasksHidden(true);
    return () => {
      /** remove completed tasks if user navigates to other pages */
      removeSyncedCompletedTasksActionCreator();
    };
  }, [projectId]);

  /** removes synced tasks on completed tasks visibility */
  React.useEffect(() => {
    isCompletedTasksHidden && removeSyncedCompletedTasksActionCreator();
  }, [isCompletedTasksHidden]);

  /** handlers */

  const treeSectionsChangeHandler = (modifiedTreeData: any) => {
    setTreeSectionsActionCreator(
      getFlatDataFromTree(modifiedTreeData as any).map(
        (iterSectionObj: SectionObj) => ({
          ...iterSectionObj,
          synced: false,
          projectId: projectId,
        })
      ) as any
    );
  };

  /** handles the request of closing share modal */
  const shareModalCloseHandler = () => setIsShareModalOpen(false);

  /** handles the request of opening share modal */
  const shareModalOpenHandler = () => setIsShareModalOpen(true);

  /**
   * sets the requested sort type
   * @param {SORT_TYPE} requestedSortType - the request the sort type
   */
  const onSortTypeChange = (requestedSortType: SORT_TYPE) => {
    if (requestedSortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    }
    setSortType(requestedSortType);
  };

  /** toggles the sorting order */
  const onSortOrderChange = () => {
    if (sortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    } else {
      setSortOrder(sortOrder === ASC_SORT ? DESC_SORT : ASC_SORT);
    }
  };

  /**
   * sets the requested visibility on options panel
   * @param {boolean} requestedVisibility - the requested viisbility
   */
  const onOptionsVisibilityChange = (requestedVisibility: boolean) => {
    setOptionsVisible(requestedVisibility);
    disablePointerEventsOnMainLayout(requestedVisibility);
    disablePointerEventsOnTaskSider(requestedVisibility);
  };

  /** closes the options panel */
  const optionsPanelCloseHandler = () => {
    setOptionsVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
  };

  /** toggles the visibility of completed tasks */
  const toggleCompletedTasksVisibility = () => {
    setIsCompletedTasksHidden(!isCompletedTasksHidden);
  };

  return projectInfo || projectId === '' ? (
    <div
      className={clsx({
        'TodoProjectContent-container': true,
        'TodoProjectContent-container-sorted': sortType !== DEFAULT_SORT,
      })}
    >
      <div className="TodoProjectContent-add-task-container">
        {projectInfo ? (
          <div className="TodoProjectContent-title-container">
            <h2
              className="TodoProjectContent-project-title"
              style={{ color: projectInfo.color }}
            >
              {' '}
              {projectInfo.title}
            </h2>
            <div className="TodoProjectContent-sort-container">
              <div
                className={clsx({
                  'TodoProjectContent-sort-order': true,
                  'TodoProjectContent-sort-order-enabled':
                    sortType !== DEFAULT_SORT,
                })}
                onClick={onSortOrderChange}
              >
                <i
                  className={
                    sortOrder === ASC_SORT
                      ? 'fas fa-sort-amount-down'
                      : 'fas fa-sort-amount-up'
                  }
                ></i>
              </div>
              <div>
                <Select
                  value={sortType}
                  bordered={false}
                  suffixIcon={<i className="fas fa-sort-down" />}
                  onChange={onSortTypeChange}
                  dropdownClassName="TodoProjectContent-sort-dropdown"
                >
                  {SORTING_OPTIONS.map((iterOption, index) => (
                    <Option key={'sort' + index} value={iterOption}>
                      {iterOption}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="TodoProjectContent-share-container">
              {projectInfo.sharedWith.length > 1 && (
                <div
                  className="TodoProjectContent-shared-avatars"
                  onClick={shareModalOpenHandler}
                >
                  <Avatar.Group maxCount={3}>
                    {lodash
                      .without(projectInfo.sharedWith, userInfo.id)
                      .map(
                        (
                          iterCollaboratorId: string,
                          iterCollaboratorIndex: number
                        ) => {
                          const collaborator = lodash.find(collaboratorList, {
                            id: iterCollaboratorId,
                          });
                          return (
                            <Avatar
                              key={`collaborator-${iterCollaboratorIndex}`}
                              src={collaborator?.avatar}
                            >
                              <div>{collaborator?.name?.slice(0, 2)}</div>
                            </Avatar>
                          );
                        }
                      )}
                    <Avatar src={userInfo.avatar}>
                      <div>{userInfo?.name?.slice(0, 2)}</div>
                    </Avatar>
                  </Avatar.Group>
                </div>
              )}
              <ConnectedShareProject
                projectInfo={projectInfo}
                visible={isShareModalOpen}
                closeHandler={shareModalCloseHandler}
              />
            </div>

            <Dropdown
              overlay={
                projectInfo ? (
                  <ProjectOptionsPanel
                    projectInfo={projectInfo}
                    panelCloseHandler={optionsPanelCloseHandler}
                    isTasksHidden={isCompletedTasksHidden}
                    toggleTaskVisibility={toggleCompletedTasksVisibility}
                  />
                ) : (
                  <div />
                )
              }
              placement="bottomRight"
              trigger={['click']}
              visible={optionsVisible}
              onVisibleChange={onOptionsVisibilityChange}
            >
              <div className="TodoProjectContent-more-container">
                <i className="fas fa-ellipsis-v" />
              </div>
            </Dropdown>
          </div>
        ) : (
          <div className="TodoProjectContent-title-container">
            <h2 className="TodoProjectContent-project-title">Inbox</h2>
            <div className="TodoProjectContent-sort-container">
              <div
                className={clsx({
                  'TodoProjectContent-sort-order': true,
                  'TodoProjectContent-sort-order-enabled':
                    sortType !== DEFAULT_SORT,
                })}
                onClick={onSortOrderChange}
              >
                <i
                  className={
                    sortOrder === ASC_SORT
                      ? 'fas fa-sort-amount-down'
                      : 'fas fa-sort-amount-up'
                  }
                ></i>
              </div>
              <div>
                <Select
                  dropdownClassName="TodoProjectContent-sort-dropdown"
                  value={sortType}
                  bordered={false}
                  onChange={onSortTypeChange}
                  suffixIcon={<i className="fas fa-sort-down" />}
                >
                  {SORTING_OPTIONS.map((iterOption, index) => (
                    <Option key={'sort' + index} value={iterOption}>
                      {iterOption}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <Dropdown
              overlay={
                <ProjectOptionsPanel
                  panelCloseHandler={optionsPanelCloseHandler}
                  isTasksHidden={isCompletedTasksHidden}
                  toggleTaskVisibility={toggleCompletedTasksVisibility}
                />
              }
              placement="bottomRight"
              trigger={['click']}
              visible={optionsVisible}
              onVisibleChange={onOptionsVisibilityChange}
            >
              <div className="TodoProjectContent-more-container">
                <i className="fas fa-ellipsis-v" />
              </div>
            </Dropdown>
          </div>
        )}
        <div className="TodoProjectContent-add-task">
          <AddTask projectId={projectId} sectionId="" />
        </div>
      </div>
      <div className="TodoProjectContent-main-section">
        <TasksList
          sectionId=""
          projectId={projectId}
          sortOrder={sortOrder}
          sortType={sortType}
          hideCompletedTasks={isCompletedTasksHidden}
        />
      </div>
      <div className="TodoProjectContent-add-section-container">
        <AddTaskSection requestedOrder={-1} projectId={projectId} />
      </div>
      <div id="todo-project-sections" className="TodoProjectContent-sections">
        <SortableTree
          dndType="section"
          isVirtualized={false}
          treeData={treeSections}
          generateNodeProps={({ node }: any) => ({
            title: (
              <TasksSection
                section={node as any}
                projectId={projectId}
                sortOrder={sortOrder}
                sortType={sortType}
                hideCompletedTasks={isCompletedTasksHidden}
              />
            ),
          })}
          maxDepth={1}
          onChange={treeSectionsChangeHandler}
        />
      </div>
    </div>
  ) : (
    <div className="TodoProjectContent-container TodoProjectContent-empty">
      <div className="TodoProjectContent-not-found-img">
        <img src="/undraw_empty.svg" width="350" alt="" />
      </div>
      <div className="TodoProjectContent-not-found-title">Oops!</div>
      <div className="TodoProjectContent-not-found-text">No project found.</div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  projectInfo: ProjectObj | null;
  treeSections: any;
  collaboratorList: CollaboratorObj[];
  userInfo: any;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TodoProjectContentProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const projectInfo = getProjectById(state, parentProps.projectId);
  const result = {
    projectInfo:
      projectInfo !== null && projectInfo.deleted === false
        ? projectInfo
        : null,
    treeSections: getTreeDataFromFlat(
      lodash.filter(getSectionsByProjectId(state, parentProps.projectId), {
        deleted: false,
      }),
      DEFAULT_SORT,
      ASC_SORT
    ),
    collaboratorList: getCollaborators(state),
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTreeSectionsActionCreator: updateSections,
  removeSyncedCompletedTasksActionCreator: removeSyncedCompletedTasks,
};

/** connect TodoProjectContent to the redux store */
const ConnectedTodoProjectContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoProjectContent);

/** allow import as default component */
export default ConnectedTodoProjectContent;
