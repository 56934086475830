import React from 'react';
import './MeetingEventTitle.scss';

interface MeetingEventTitleProps {
  color: string;
  icon: string;
  title: string;
  subtitle: string;
}

const MeetingEventTitle: React.FC<MeetingEventTitleProps> = (
  props: MeetingEventTitleProps
) => {
  const { icon, title, subtitle, color } = props;
  return (
    <div className="MeetingEventTitle-container">
      <div className="MeetingEventTitle-icon">
        <i style={{ color }} className={icon} />
      </div>
      <div className="MeetingEventTitle-title-container">
        <div className="MeetingEventTitle-title">{title}</div>
        <div className="MeetingEventTitle-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

export default MeetingEventTitle;
