/** local */

/** local logout endpoint */
export const LOCAL_LOGOUT_ENDPOINT = `/logout`;

/** local logout endpoint */
export const LOCAL_AUTHORIZE_ENDPOINT = `/authorize`;

/** local todo inbox endpoint */
export const LOCAL_INBOX_ENDPOINT = `/inbox`;

/** local todo today endpoint */
export const LOCAL_TODAY_ENDPOINT = `/today`;

/** local todo upcoming endpoint */
export const LOCAL_UPCOMING_ENDPOINT = `/upcoming`;

/** local todo pre project endpoint */
export const LOCAL_PRE_PROJECT_ENDPOINT = '/project/';

/** local todo project endpoint */
export const LOCAL_PROJECT_ENDPOINT = '/project/:id';

/** local todo pre label endpoint */
export const LOCAL_PRE_LABEL_ENDPOINT = '/label/';

/** local todo label endpoint */
export const LOCAL_LABEL_ENDPOINT = '/label/:id';

/** local todo pre filter endpoint */
export const LOCAL_PRE_FILTER_ENDPOINT = '/filter/';

/** local todo filter endpoint */
export const LOCAL_FILTER_ENDPOINT = '/filter/:id';

/** local todo pre search endpoint */
export const LOCAL_PRE_SEARCH_ENDPOINT = '/search/';

/** local todo search endpoint */
export const LOCAL_SEARCH_ENDPOINT = '/search/:keyword';

/** local settings general endpoint */
export const LOCAL_GENERAL_SETTINGS_ENDPOINT = '/settings/general';

/** local todo invitation endpoint */
export const LOCAL_INVITATION_ENDPOINT = '/invitation/:id';

/** local todo pre activity endpoint */
export const LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT = '/activity/';

/** server */

/** server todo base endpoint */
export const SERVER_TODO_BASE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/todo`;

/** server profile endpoint */
export const SERVER_USER_PROFILE_ENDPOINT =
  process.env.REACT_APP_USER_PROFILE_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/basic`;

/** server collaborators endpoint */
export const SERVER_COLLABORATORS_ENDPOINT =
  process.env.REACT_APP_COLLABORATORS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/search`;

/** server sync endpoint */
export const SERVER_SYNC_ENDPOINT =
  process.env.REACT_APP_SERVER_SYNC_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/batch/sync`;

/** server projects endpoint */
export const SERVER_PROJECTS_ENDPOINT =
  process.env.REACT_APP_SERVER_PROJECTS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/projects`;

/** server projects revoke endpoint */
export const SERVER_PROJECTS_REVOKE_ENDPOINT =
  process.env.REACT_APP_SERVER_PROJECTS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/projects/revoke`;

/** server labels endpoint */
export const SERVER_LABELS_ENDPOINT =
  process.env.REACT_APP_SERVER_LABELS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/labels`;

/** server sections endpoint */
export const SERVER_SECTIONS_ENDPOINT =
  process.env.REACT_APP_SERVER_SECTIONS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/sections`;

/** server tasks endpoint */
export const SERVER_TASKS_ENDPOINT =
  process.env.REACT_APP_SERVER_TASKS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/tasks`;

/** server comments endpoint */
export const SERVER_COMMENTS_ENDPOINT =
  process.env.REACT_APP_SERVER_COMMENTS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/comments`;

/** server invitations endpoint */
export const SERVER_INVITATIONS_ENDPOINT =
  process.env.REACT_APP_SERVER_INVITATIONS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/todo/invitations`;

/** server notifications endpoint */
export const SERVER_NOTIFICATIONS_ENDPOINT =
  process.env.REACT_APP_SERVER_NOTIFICATIONS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/notifications`;

/** server settings endpoint */
export const SERVER_SETTINGS_ENDPOINT =
  process.env.REACT_APP_SERVER_SETTINGS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/settings`;

/** server timezones endpoint */
export const SERVER_TIMEZONES_ENDPOINT =
  process.env.REACT_APP_SERVER_TIMEZONES_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/timezones`;

/** server file upload endpoint */
export const SERVER_FILE_UPLOAD_ENDPOINT =
  process.env.REACT_APP_SERVER_FILE_UPLOAD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/file/upload`;

/** server task type details endpoint */
export const SERVER_TASK_TYPES_DETAILS_ENDPOINT =
  process.env.REACT_APP_SERVER_TASK_TYPES_DETAILS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/details`;

/** server task type integrations endpoint */
export const SERVER_TASK_TYPES_INTEGRATIONS_ENDPOINT =
  process.env.REACT_APP_SERVER_TASK_TYPES_INTEGRATIONS_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/integrations`;

/** server meeting task type dashboard counts endpoint */
export const SERVER_MEETING_DASHBOARD_COUNT_ENDPOINT =
  process.env.REACT_APP_SERVER_MEETING_DASHBOARD_COUNT_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/dashboard/meeting`;

/** server meeting task type meeting list endpoint */
export const SERVER_MEETING_LIST_ENDPOINT =
  process.env.REACT_APP_SERVER_MEETING_LIST_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/dashboard/meeting/upcoming`;

/** server meeting create endpoint */
export const SERVER_MEETING_CREATE_ENDPOINT =
  process.env.REACT_APP_SERVER_MEETING_CREATE_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/conferences/create`;

/** server meeting send email endpoint */
export const SERVER_MEETING_SEND_EMAIL_ENDPOINT =
  process.env.REACT_APP_SERVER_MEETING_SEND_EMAIL_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/task-types/send-email`;

/** apps */

/** Account app endpoint */
export const ACCOUNT_APP_ENDPOINT =
  process.env.REACT_APP_ACCOUNT_ENDPOINT || '';

export const ACCOUNT_APP_LOGIN_ENDPOINT =
  process.env.REACT_APP_ACCOUNT_LOGIN_ENDPOINT || '';

export const ACCOUNT_APP_LOGOUT_ENDPOINT =
  process.env.REACT_APP_ACCOUNT_LOGOUT_ENDPOINT || '';
