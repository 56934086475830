import { Avatar, Divider, Tooltip } from 'antd';
import React from 'react';
import './TodoShareUserItem.scss';

/** interface to describe the TodoShareUserItem props */
interface TodoShareUserItemProps {
  id: string;
  name: string;
  email: string;
  avatar: string;
  pending: boolean;
  deleteHandler: (id: string) => void;
}

const TodoShareUserItem: React.FC<TodoShareUserItemProps> = (
  props: TodoShareUserItemProps
) => {
  const { id, name, email, avatar, pending, deleteHandler } = props;

  /** handles the delete collaborator request */
  const deleteRequest = () => {
    deleteHandler(id);
  };

  return (
    <React.Fragment>
      <div className="TodoShareUserItem-user-item">
        <Avatar size="large" src={avatar}>
          <div>{name.slice(0, 2)}</div>
        </Avatar>
        <div className="TodoShareUserItem-user-description">
          <div className="TodoShareUserItem-user-name">{name}</div>
          <div className="TodoShareUserItem-user-email">{email}</div>
        </div>
        {pending && (
          <div className="TodoShareUserItem-user-pending">Pending</div>
        )}
        <Tooltip placement="right" title="Remove">
          <div
            onClick={deleteRequest}
            className="TodoShareUserItem-user-delete"
          >
            <i className="fas fa-trash"></i>
          </div>
        </Tooltip>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default TodoShareUserItem;
