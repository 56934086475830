import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'redux';
import { LOCAL_PRE_LABEL_ENDPOINT } from '../../configs/endpoints';
import { getLabelById } from '../../store/ducks/labels';
import './TodoTaskLabelTag.scss';

/** interface to describe TodoTaskLabelTag props */
interface TodoTaskLabelTagProps {
  id: string;
  title: string;
  location: string;
  color: string | null;
}

const TodoTaskLabelTag = (props: TodoTaskLabelTagProps) => {
  const { title, location, color } = props;
  const history = useHistory();
  return (
    <div
      className="TodoTaskLabelTag-label"
      style={color ? { color: color } : {}}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        history.push(location);
      }}
    >
      {title}
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  title: string;
  location: string;
  color: string | null;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoTaskLabelTagProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const labelInfo = getLabelById(state, parentProps.id) || {
    title: '',
    color: '',
  };
  const result = {
    title: labelInfo.title,
    location: LOCAL_PRE_LABEL_ENDPOINT + labelInfo.id,
    color: labelInfo.color,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoTaskLabelTag to the redux store */
const ConnectedTodoTaskLabelTag = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoTaskLabelTag);

/** allow default import */
export default ConnectedTodoTaskLabelTag;
