import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';

/** interface for Settings object */
export interface SettingsObj {
  language: string;
  dateFormat: string;
  timeFormat: string;
  timezone: string;
  nextWeek: string;
  defaultCompletionTime: string;
}

/** The reducer name */
export const reducerName = 'settings';

// actions
/** action types */
export const SET_SETTINGS = 'virtunus/reducer/settings/SET_SETTINGS';
export const RESET_SETTINGS = 'virtunus/reducer/settings/RESET_SETTINGS';

/** interface for SET_SETTINGS action */
export interface SetSettingsAction extends AnyAction {
  settings: Partial<SettingsObj>;
  type: typeof SET_SETTINGS;
}

/** interface for RESET_SETTINGS action */
export interface ResetSettingsAction extends AnyAction {
  type: typeof RESET_SETTINGS;
}

/** Create type for settings reducer actions */
export type SettingsActionTypes =
  | SetSettingsAction
  | ResetSettingsAction
  | AnyAction;

// action creators

/** set settings action creator
 * @param {Partial<SettingsObj>} settings - info about settings to be set
 * @returns {SetSettingsAction} - an action to set settings in store
 */
export const setSettings = (
  settings: Partial<SettingsObj>
): SetSettingsAction => ({
  type: SET_SETTINGS,
  settings,
});

/** reset settings action creator
 * @returns {ResetSettingsAction} - an action to reset settings in store
 */
export const resetSettings = (): ResetSettingsAction => ({
  type: RESET_SETTINGS,
});

// the reducer

/** Create an immutable settings state */
export type ImmutableSettingsState = SeamlessImmutable.ImmutableObject<
  SettingsObj
>;

/** initial settings state */
const initialState: ImmutableSettingsState = SeamlessImmutable({
  language: 'english',
  dateFormat: 'DD-MM-YYYY',
  timeFormat: '24',
  timezone: 'UTC',
  nextWeek: 'Sunday',
  defaultCompletionTime: '12:00',
});

/** the settings reducer function */
export default function reducer(
  state: ImmutableSettingsState = initialState,
  action: SettingsActionTypes
): ImmutableSettingsState {
  switch (action.type) {
    case SET_SETTINGS:
      return SeamlessImmutable({
        ...state.asMutable({ deep: true }),
        ...action.settings,
      });
    case RESET_SETTINGS:
      return initialState;
    default:
      return state;
  }
}

// selectors

/** returns the existing settings
 * @param {Partial<Store>} state - the redux store
 * @return { SettingsState } - the existing settings
 */
export function getSettings(state: Partial<Store>): SettingsObj {
  return (state as any)[reducerName];
}

/** returns the default completion time
 * @param {Partial<Store>} state - the redux store
 * @return { string } - the default completion time
 */
export function getDefaultCompletionTime(state: Partial<Store>): string {
  return (state as any)[reducerName].defaultCompletionTime;
}

/** returns true if 12 hour format is used and vice versa.
 * @param {Partial<Store>} state - the redux store
 * @return { string } - true if 12 hour format; otherwise false
 */
export function is12HourFormat(state: Partial<Store>): boolean {
  return (state as any)[reducerName].timeFormat === '12' ? true : false;
}

/** returns the date format.
 * @param {Partial<Store>} state - the redux store
 * @return { string } - the selected date format
 */
export function getDateFormat(state: Partial<Store>): string {
  return (state as any)[reducerName].dateFormat;
}

/** returns the default next week.
 * @param {Partial<Store>} state - the redux store
 * @return { string } - the default next week
 */
export function getDefaultNextWeekDay(state: Partial<Store>): string {
  return (state as any)[reducerName].nextWeek;
}
