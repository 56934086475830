import { Button, Dropdown } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { LOCAL_INBOX_ENDPOINT } from '../../../configs/endpoints';
import AppsDropdown from '../../../containers/appsDropdown/AppsDropdown';
import './SettingsHeader.scss';

const SettingsHeader: React.FC = () => {
  return (
    <div className="SettingsHeader-container">
      <div className="SettingsHeader-content">
        <div className="SettingsHeader-logo-container">
          <div className="SettingsHeader-logo">
            <Link to={LOCAL_INBOX_ENDPOINT}>
              <img src="/todoNewLogo.svg" width="120" alt="" />
            </Link>
            {/* <div className="TodoHeader-app-font">Todo</div> */}
          </div>
          <Dropdown overlay={<AppsDropdown />} placement="bottomRight">
            <div className="SettingsHeader-apps">
              <i className="fas fa-th"></i>
            </div>
          </Dropdown>
        </div>
        <div className="SettingsHeader-main-content">
          <div className="SettingsHeader-content-title">Settings</div>
          <Link to={LOCAL_INBOX_ENDPOINT}>
            <Button className="SettingsHeader-cancel-btn">
              <div className="SettingsHeader-extended-txt">Close</div>{' '}
              <i className="fas fa-times-circle"></i>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SettingsHeader;
