import React from 'react';
import './LabelTasksList.scss';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import TaskItem from '../taskItem/TaskItem';
import { connect } from 'react-redux';
import { getTasksByLabelId } from '../../store/ducks/tasks';
import { Store } from 'redux';
import lodash from 'lodash';
import { SORT_ORDER, SORT_TYPE } from '../../configs/constants';
import { sortList } from '../../services/treeConverter';

interface LabelTasksListProps {
  labelId: string;
  treeData: any;
  sortType: SORT_TYPE;
  sortOrder: SORT_ORDER;
}

const LabelTasksList: React.FC<LabelTasksListProps> = (
  props: LabelTasksListProps
) => {
  const { treeData } = props;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  const treeChangeHandler = () => {};

  return (
    <div className="LabelTasksList-container">
      <SortableTree
        dndType="task"
        isVirtualized={false}
        treeData={treeData}
        generateNodeProps={({ node, path }) => ({
          title: (
            <TaskItem
              preventExpanded={true}
              path={path.length}
              task={node as any}
            />
          ),
        })}
        maxDepth={5}
        onChange={treeChangeHandler}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  treeData: any;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: any
): DispatchedStateProps => {
  const result = {
    treeData: sortList(
      lodash.filter(getTasksByLabelId(state, parentProps.labelId), {
        deleted: false,
        completed: false,
        isExists: 1,
      }),
      parentProps.sortType,
      parentProps.sortOrder
    ),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect LabelTasksList to the redux store */
const ConnectedLabelTasksList = connect(
  mapStateToProps,
  mapDispatchToProps
)(LabelTasksList);

export default ConnectedLabelTasksList;
