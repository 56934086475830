import React from 'react';
import {
  TimeReminderObj,
  TIME_REMINDER_TYPE,
} from '../../../../store/ducks/tasks';
import { REMINDER_OPTIONS } from './constants';
import TaskReminderSelectItem from './taskReminderSelectItem/TaskReminderSelectItem';
import TaskReminderCustomSelect from './taskReminderCustomSelect/TaskReminderCustomSelect';
import lodash from 'lodash';
import { message } from 'antd';
import { generateNewUUID } from '../../../../services/utility';

export interface TaskTimeReminderOption {
  label: string;
  value: number;
}

interface TaskReminderSelectProps {
  values: TimeReminderObj[];
  onChangeHandler: (requested: TimeReminderObj[]) => void;
}

const MAX_LIMIT = 40320;

const TaskReminderSelect: React.FC<TaskReminderSelectProps> = (
  props: TaskReminderSelectProps
) => {
  const { values, onChangeHandler } = props;
  const [isCustomInputVisible, setIsCustomInputVisible] = React.useState<
    boolean
  >(false);

  const existingValues = lodash
    .filter(values, { deleted: false })
    .map((reminder) => reminder.before);

  const clickHandler = (requested: number) => {
    if (requested > MAX_LIMIT) {
      message.warn('A reminder can not be set more than 4 weeks prior');
      return;
    }
    if (existingValues.includes(requested)) {
      message.info('A reminder exists at that time');
      return;
    }
    onChangeHandler([
      ...values,
      {
        type: TIME_REMINDER_TYPE,
        id: generateNewUUID(),
        before: requested,
        deleted: false,
      },
    ]);
    if (isCustomInputVisible) {
      setIsCustomInputVisible(false);
    }
  };

  const openCustomInput = () => setIsCustomInputVisible(true);
  const cancelHandler = () => setIsCustomInputVisible(false);

  return (
    <div className="TaskReminderSelect-container">
      {REMINDER_OPTIONS.filter(
        (option) => !existingValues.includes(option.value)
      ).map((option) => (
        <TaskReminderSelectItem
          key="reminder-option-1"
          label={option.label}
          value={option.value}
          selectHandler={clickHandler}
        />
      ))}
      <TaskReminderSelectItem
        label={'Custom'}
        value={0}
        selectHandler={openCustomInput}
      />
      {isCustomInputVisible && (
        <TaskReminderCustomSelect
          confirmHandler={clickHandler}
          cancelHandler={cancelHandler}
        />
      )}
    </div>
  );
};

export default TaskReminderSelect;
