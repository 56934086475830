import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';

/** interface for section object */
export interface SectionObj {
  id: string;
  title: string;
  projectId: string;
  order: number;
  parent: string;
  expanded: boolean;
  bodyExpanded: boolean;
  synced: boolean;
  deleted: boolean;
}

/** The reducer name */
export const reducerName = 'sections';

// actions
/** action types */
export const SET_SECTIONS = 'virtunus/reducer/sections/SET_SECTIONS';
export const SET_SECTION = 'virtunus/reducer/sections/SET_SECTION';
export const UPDATE_SECTIONS = 'virtunus/reducer/sections/UPDATE_SECTIONS';
export const FIX_SECTION_ORDERS =
  'virtunus/reducer/sections/FIX_SECTION_ORDERS';
export const DELETE_SECTIONS_BASED_ON_PROJECTS =
  'virtunus/reducer/sections/DELETE_SECTIONS_BASED_ON_PROJECTS';
export const SET_SECTIONS_SYNCED_STATUS =
  'virtunus/reducer/sections/SET_SECTIONS_SYNCED_STATUS';

/** interface for SET_SECTIONS action */
export interface SetSectionsAction extends AnyAction {
  sections: SectionObj[];
  type: typeof SET_SECTIONS;
}

/** interface for SET_SECTION action */
export interface SetSectionAction extends AnyAction {
  section: SectionObj;
  type: typeof SET_SECTION;
}

/** interface for UPDATE_SECTIONS action */
export interface UpdateSectionsAction extends AnyAction {
  sections: SectionObj[];
  type: typeof UPDATE_SECTIONS;
}

/** interface for FIX_SECTION_ORDERS action */
export interface FixSectionOrdersAction extends AnyAction {
  projectId: string;
  type: typeof FIX_SECTION_ORDERS;
}

/** interface for DELETE_SECTIONS_BASED_ON_PROJECTS action */
export interface DeleteSectionsBasedOnProjectsAction extends AnyAction {
  projectIds: string[];
  type: typeof DELETE_SECTIONS_BASED_ON_PROJECTS;
  status: boolean;
}

/** interface for SET_SECTIONS_SYNCED_STATUS action */
export interface SetSectionsSyncedStatusAction extends AnyAction {
  status: boolean;
  type: typeof SET_SECTIONS_SYNCED_STATUS;
  ids: string[];
}

/** Create type for section reducer actions */
export type SectionsActionTypes =
  | SetSectionsAction
  | SetSectionAction
  | UpdateSectionsAction
  | FixSectionOrdersAction
  | DeleteSectionsBasedOnProjectsAction
  | SetSectionsSyncedStatusAction
  | AnyAction;

// action creators

/** set sections action creator
 * @param {SectionObj} sections - an array of section items
 * @returns {SetSectionsAction} - an action to set sections in store
 */
export const setSections = (sections: SectionObj[]): SetSectionsAction => {
  return {
    sections,
    type: SET_SECTIONS,
  };
};

/** set sections action creator
 * @param {SectionObj} section - a section object
 * @returns {SetSectionAction} - an action to set section in store
 */
export const setSection = (section: SectionObj): SetSectionAction => ({
  section,
  type: SET_SECTION,
});

/** update sections action creator
 * @param {SectionObj[]} sections - an array of section items
 * @returns {SetSectionsAction} - an action to update sections in store
 */
export const updateSections = (
  sections: SectionObj[]
): UpdateSectionsAction => {
  const ids = sections.map((section: SectionObj) => section.id);
  return {
    sections,
    ids,
    type: UPDATE_SECTIONS,
  };
};

/**
 * set the section orders correctly based on project id
 * @param {string} projectId - requested project id
 * @returns {FixSectionOrdersAction} - an action to fix the sections order given the parent project id
 */
export const fixSectionOrders = (
  projectId: string
): FixSectionOrdersAction => ({
  projectId,
  type: FIX_SECTION_ORDERS,
});

/**
 * delete the sections based on the project ids
 * @param {string[]} projectIds - the project ids
 * @returns {DeleteSectionsBasedOnProjectsAction} - an action to delete sections based on project ids
 */
export const deleteSectionsBasedOnProjects = (
  projectIds: string[],
  status?: boolean
): DeleteSectionsBasedOnProjectsAction => ({
  projectIds,
  type: DELETE_SECTIONS_BASED_ON_PROJECTS,
  status: status || false,
});

/** set sections synced status action creator
 * @param {string[]} ids - an array of section ids
 * @param {boolean} status - the synced status to set
 * @returns {SetSectionsSyncedStatusAction} - an action to set sections synced status in store
 */
export const setSectionsSyncedStatus = (
  ids: string[],
  status: boolean
): SetSectionsSyncedStatusAction => ({
  status,
  type: SET_SECTIONS_SYNCED_STATUS,
  ids,
});

// the reducer

/** interface for sections state in redux store */
type SectionsState = SectionObj[];

/** Create an immutable sections state */
export type ImmutableSectionsState = SeamlessImmutable.ImmutableArray<
  SectionsState
>;

/** initial section state */
const initialState: ImmutableSectionsState = SeamlessImmutable([]);

/** the sections reducer function */
export default function reducer(
  state: ImmutableSectionsState = initialState,
  action: SectionsActionTypes
): ImmutableSectionsState {
  switch (action.type) {
    case SET_SECTIONS:
      return SeamlessImmutable(action.sections);
    case SET_SECTION:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateSection: SectionObj) =>
            iterateSection.id !== action.section.id
        ),
        action.section,
      ]);
    case UPDATE_SECTIONS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateSection: SectionObj) =>
            !action.ids.includes(iterateSection.id)
        ),
        ...action.sections,
      ]);
    case FIX_SECTION_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state,
          (iter: SectionObj) =>
            iter.projectId !== action.projectId || iter.deleted === true
        ),
        ...lodash.map(
          lodash.orderBy(
            lodash.filter(
              state,
              (iter: SectionObj) =>
                iter.projectId === action.projectId && iter.deleted === false
            ),
            ['order'],
            ['asc']
          ),
          (iterateSection: SectionObj, index: number) => ({
            ...iterateSection,
            synced: false,
            order: index,
          })
        ),
      ] as any);
    case DELETE_SECTIONS_BASED_ON_PROJECTS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }) as any,
          (iterateSection: SectionObj) =>
            !action.projectIds.includes(iterateSection.projectId) ||
            iterateSection.deleted === true
        ),
        ...lodash.map(
          lodash.filter(
            state.asMutable({ deep: true }),
            (iterateSection: SectionObj) =>
              action.projectIds.includes(iterateSection.projectId) &&
              iterateSection.deleted === false
          ),
          (iterateSection: SectionObj) => ({
            ...iterateSection,
            deleted: true,
            synced: action.status,
          })
        ),
      ]);
    case SET_SECTIONS_SYNCED_STATUS:
      return SeamlessImmutable(
        lodash.map(state, (iterateSection: SectionObj) => {
          return action.ids.includes(iterateSection.id)
            ? { ...iterateSection, synced: action.status }
            : iterateSection;
        }) as any
      );
    default:
      return state;
  }
}

// selectors

/** returns the sections list
 * @param {Partial<Store>} state - the redux store
 * @return { SectionObj[] } - the existing sections
 */
export function getSections(state: Partial<Store>): SectionObj[] {
  return (state as any)[reducerName];
}

/** returns the sections list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing sections length
 */
export function getSectionsLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the sections list of given project id
 * @param {Partial<Store>} state - the redux store
 * @param {string} projectId - the project id
 * @return { SectionObj[] } - the existing sections list with the given project id
 */
export function getSectionsByProjectId(
  state: Partial<Store>,
  projectId: string
): SectionObj[] {
  return lodash.filter((state as any)[reducerName], { projectId });
}

/** returns the next available order of new section
 * @param {Partial<Store>} state - the redux store
 * @param {projectId} string - project id
 * @return { number } - the new available order index
 */
export function getNewSectionOrderIndex(
  state: Partial<Store>,
  projectId: string
): number {
  return lodash.filter((state as any)[reducerName], { projectId }).length;
}

/**
 * returns the section info based on the given section id
 * @param {Partial<Store>} state - the redux state
 * @param {string} sectionId - the requested section id
 * @returns {SectionObj | null} - the section info obj if present; otherwise null
 */
export function getSectionById(
  state: Partial<Store>,
  sectionId: string
): SectionObj | null {
  return lodash.find((state as any)[reducerName], { id: sectionId }) || null;
}

/**
 * returns the list of unsynced sections
 * @param {Partial<Store>} state - the redux store
 * @returns {SectionObj[]} - the list of unsynced sections
 */
export function getUnsyncedSections(state: Partial<Store>): SectionObj[] {
  return lodash.filter((state as any)[reducerName], { synced: false });
}
