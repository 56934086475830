import { Button, Modal } from 'antd';
import React from 'react';
import './TodoProjectDeleteButton.scss';
import {
  fixProjectOrders,
  getProjects,
  ProjectObj,
  setProject,
  updateProjects,
} from '../../store/ducks/projects';
import { connect } from 'react-redux';
import { getChildItemsFromParent } from '../../services/utility';
import { Store } from 'redux';
import lodash from 'lodash';
import { deleteSectionsBasedOnProjects } from '../../store/ducks/sections';
import { deleteTasksBasedOnProjects } from '../../store/ducks/tasks';
import { axioService, POST } from '../../services/axioService';
import { SERVER_PROJECTS_REVOKE_ENDPOINT } from '../../configs/endpoints';
import { getSessionUserInfo } from '../../store/ducks/session';
import { syncService } from '../../services/syncService';

/** interface to describe TodoProjectDeleteButton props */
interface TodoProjectDeleteButtonProps {
  projectInfo: ProjectObj;
  userInfo: any;
  setProjectActionCreator: typeof setProject;
  closeHandler: () => void;
  childrenProjects: ProjectObj[];
  immediateChildrenProjects: ProjectObj[];
  updateProjectsActionCreator: typeof updateProjects;
  fixProjectOrdersActionCreator: typeof fixProjectOrders;
  deleteSectionsBasedOnProjectsActionCreator: typeof deleteSectionsBasedOnProjects;
  deleteTasksBasedOnProjectsActionCreator: typeof deleteTasksBasedOnProjects;
}

/** Placeholders subject to delete */
const DELETE_PROJECT_LABEL = 'Delete Project';
const LEAVE_PROJECT_LABEL = 'Leave Project';

const TodoProjectDeleteButton: React.FC<TodoProjectDeleteButtonProps> = (
  props: TodoProjectDeleteButtonProps
) => {
  const {
    projectInfo,
    userInfo,
    setProjectActionCreator,
    closeHandler,
    childrenProjects,
    immediateChildrenProjects,
    fixProjectOrdersActionCreator,
    updateProjectsActionCreator,
    deleteSectionsBasedOnProjectsActionCreator,
    deleteTasksBasedOnProjectsActionCreator,
  } = props;
  const { confirm, info } = Modal;

  /** manages the favourite toogle request */
  const okHandler = () => {
    setProjectActionCreator({
      ...projectInfo,
      synced: false,
      deleted: true,
    });

    /** warning: syncing sub projects deletion as well */
    const tmpSubProjects = childrenProjects.map((iterProj: ProjectObj) => ({
      ...iterProj,
      synced: false,
      deleted: true,
    }));
    updateProjectsActionCreator(tmpSubProjects);

    /** fix the order after project deletion */
    fixProjectOrdersActionCreator(projectInfo.parent);

    const interestedProjIds = [
      projectInfo.id,
      ...childrenProjects.map((iterProj: ProjectObj) => iterProj.id),
    ];

    /** remove sections associated with the projects */
    deleteSectionsBasedOnProjectsActionCreator(interestedProjIds);

    /** remove tasks associated with the projects */
    deleteTasksBasedOnProjectsActionCreator(interestedProjIds);
  };

  const config = {
    className: 'TodoProjectDeleteButton-modal-container',
    title: (
      <div className="TodoProjectDeleteButton-modal-title">Delete Project</div>
    ),
    content: (
      <div>
        Are you sure you want to delete the project{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {projectInfo.title}
        </span>{' '}
        ?<br />
        {childrenProjects.length > 0 && (
          <div>
            Following projects will also be deleted:{' '}
            {childrenProjects.map((projItem: ProjectObj, index: number) => (
              <React.Fragment key={'delete-proj-' + index}>
                <span style={{ color: 'var(--theme-primary)' }}>
                  {projItem.title}
                </span>
                {index !== childrenProjects.length - 1 && ', '}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    ),
    width: 500,
    onOk() {
      okHandler();
    },
  };

  const restrictInfo = {
    className: 'TodoProjectDeleteButton-infoModal-container',
    title: (
      <div className="TodoProjectDeleteButton-infoModal-title">
        Delete unsuccessful !!!
      </div>
    ),
    content: (
      <div>
        <p>There are other shared projects in this group.</p>
      </div>
    ),
  };

  const requestHandler = () => {
    closeHandler();
    const sharedProjects = lodash.filter(
      childrenProjects,
      (iterProj: ProjectObj) => iterProj.sharedWith.length > 1
    );
    if (sharedProjects.length < 1) {
      confirm(config);
    } else {
      info(restrictInfo);
    }
  };

  /** Project Leave project */
  const projectLeaveHandler = async () => {
    try {
      /** sync any unsaved changes */
      syncService();

      /** make the leave request to server  */
      await axioService(
        POST,
        SERVER_PROJECTS_REVOKE_ENDPOINT,
        {
          project_id: projectInfo.id,
          user_id: userInfo.id,
        },
        true
      );

      setProjectActionCreator({
        ...projectInfo,
        /** unsynced status not changed since api call will be made */
        deleted: true,
      });

      /** warning: syncing sub projects deletion as well */
      const tmpSubProjects = immediateChildrenProjects.map(
        (iterProj: ProjectObj) => ({
          ...iterProj,
          synced: false,
          parent: projectInfo.parent,
          order: parseFloat(iterProj.order + '.' + childrenProjects.length),
        })
      );
      updateProjectsActionCreator(tmpSubProjects);

      /** fix the order after project deletion */
      fixProjectOrdersActionCreator(projectInfo.parent);

      const interestedProjIds = [projectInfo.id];

      /** remove sections associated with the projects */
      deleteSectionsBasedOnProjectsActionCreator(interestedProjIds, true);

      /** remove tasks associated with the projects */
      deleteTasksBasedOnProjectsActionCreator(interestedProjIds, true);
    } catch (exception) {
      console.error(exception);
    }
  };

  const leaveConfig = {
    className: 'TodoProjectDeleteButton-modal-container',
    title: (
      <div className="TodoProjectDeleteButton-modal-title">Leave Project</div>
    ),
    content: (
      <div>
        Are you sure you want to leave the project{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {projectInfo.title}
        </span>{' '}
        ?
      </div>
    ),
    width: 500,
    onOk() {
      projectLeaveHandler();
    },
  };

  const leaveRequestHandler = () => {
    closeHandler();
    confirm(leaveConfig);
  };

  return projectInfo.sharedWith.length > 1 ? (
    <Button
      onClick={leaveRequestHandler}
      icon={<i className="fas fa-sign-out-alt"></i>}
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="ProjectOptionsPanel-item ProjectOptionsPanel-item-delete"
    >
      {LEAVE_PROJECT_LABEL}
    </Button>
  ) : (
    <Button
      onClick={requestHandler}
      icon={<i className="fas fa-trash"></i>}
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="ProjectOptionsPanel-item ProjectOptionsPanel-item-delete"
    >
      {DELETE_PROJECT_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  immediateChildrenProjects: ProjectObj[];
  childrenProjects: ProjectObj[];
  userInfo: any;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TodoProjectDeleteButtonProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const projects = lodash.filter(getProjects(state), { deleted: false });
  const result = {
    childrenProjects: getChildItemsFromParent(
      projects,
      parentProps.projectInfo.id,
      []
    ),
    immediateChildrenProjects: lodash.filter(projects, {
      parent: parentProps.projectInfo.id,
    }),
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setProjectActionCreator: setProject,
  updateProjectsActionCreator: updateProjects,
  fixProjectOrdersActionCreator: fixProjectOrders,
  deleteSectionsBasedOnProjectsActionCreator: deleteSectionsBasedOnProjects,
  deleteTasksBasedOnProjectsActionCreator: deleteTasksBasedOnProjects,
};

/** connect TodoProjectDeleteButton to the redux store */
const ConnectedTodoProjectDeleteButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoProjectDeleteButton);

export default ConnectedTodoProjectDeleteButton;
