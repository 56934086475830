import React from 'react';
import './TodoActivityTypes.scss';
import withFolderLayout from '../../../hocs/withFolderLayout/withFolderLayout';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import { ActivityType, ACTIVITY_TYPES } from '../../../configs/activityTypes';
import TodoActivityTypeItem from '../../../containers/todoActivityTypeItem/TodoActivityTypeItem';

const TodoActivityTypes: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const treeChangeHandler = () => {};

  return (
    <div className="TodoActivityTypes-container">
      <SortableTree
        isVirtualized={false}
        treeData={ACTIVITY_TYPES}
        scaffoldBlockPxWidth={7}
        generateNodeProps={({ node, path }) => ({
          title: (
            <TodoActivityTypeItem
              activityType={node as ActivityType}
              path={path.length}
            />
          ),
        })}
        maxDepth={1}
        onChange={treeChangeHandler}
      />
    </div>
  );
};

export default withFolderLayout(
  TodoActivityTypes,
  'Task Types',
  'Add new task type'
);
