import { DatePicker, Input, List, Radio } from 'antd';
import React from 'react';
import withTaskSider from '../../hocs/withTaskSider/withTaskSider';
import withTodoLayout from '../../hocs/withTodoLayout/withTodoLayout';
import MeetingCard from '../../components/activities/meetingCard/MeetingCard';
import './Meeting.scss';
import { getMeetingCountObj, setMeetings } from '../../store/ducks/meeting';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { axioService, GET } from '../../services/axioService';
import { SERVER_MEETING_LIST_ENDPOINT } from '../../configs/endpoints';
import moment from 'moment';
import { RadioChangeEvent } from 'antd/lib/radio';
import MeetingDashboardItem from '../meetingDashboardItem/MeetingDashboardItem';

interface MeetingProps {
  total: number;
  upcoming: number;
  cancel: number;
  meetings: any[];
  userInfo: any;
  setMeetingsActionCreator: typeof setMeetings;
}

const Meeting: React.FC<MeetingProps> = (props: MeetingProps) => {
  const { total, upcoming, cancel, meetings, setMeetingsActionCreator } = props;
  const { RangePicker } = DatePicker;

  const [startDate, setStartDate] = React.useState<moment.Moment | null>(
    moment()
  );
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(moment());

  const [value, setValue] = React.useState<string>('today');

  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    const fetchMeetings = async () => {
      const response = await axioService(
        GET,
        SERVER_MEETING_LIST_ENDPOINT,
        {
          start_date: startDate?.format('YYYY-MM-DD'),
          end_date: endDate?.format('YYYY-MM-DD'),
          search: search === '' ? null : search,
        },
        true
      );
      setMeetingsActionCreator(response.data.data || []);
    };
    fetchMeetings();
  }, [startDate, endDate, search]);

  const onChangeHandler = (event: RadioChangeEvent) => {
    const requested = event.target.value;
    if (requested === 'today') {
      setStartDate(moment());
      setEndDate(moment());
    } else if (requested === 'upcoming') {
      setStartDate(moment());
      setEndDate(null);
    } else if (requested === 'previous') {
      setStartDate(null);
      setEndDate(moment().subtract(1, 'day'));
    }
    setValue(requested);
  };

  const onRangeChange = (requestedDates: [moment.Moment, moment.Moment]) => {
    setStartDate(requestedDates[0] || null);
    setEndDate(requestedDates[1] || null);
    setValue('');
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const meetingChange = (requestedMeeting: any, index: number) => {
    const tmpMeetings = [...meetings];
    tmpMeetings[index] = requestedMeeting;
    setMeetingsActionCreator(tmpMeetings);
  };

  return (
    <div className="Meeting-container">
      <div className="Meeting-header">
        <div className="Meeting-inner">
          <div className="Meeting-title-container">
            <div className="Meeting-title">
              <h2>Meeting Dashboard</h2>
            </div>
            <div className="Meeting-create-btn">Create Meeting</div>
          </div>
          <div className="Meeting-card-container">
            <MeetingCard
              icon="fas fa-users"
              title="My Meetings"
              value={total}
              color="#427cf6"
            />
            <MeetingCard
              icon="fas fa-user-check"
              title="Invited Meetings"
              value={upcoming}
              color="#4fb045"
            />
            <MeetingCard
              icon="fas fa-user-times"
              title="Cancelled Meetings"
              value={cancel}
              color="#ef5958"
            />
          </div>
        </div>
      </div>
      <div className="Meeting-body">
        <div className="Meeting-inner">
          <div className="Meeting-body-header">
            <Radio.Group
              className="Meeting-radio-group"
              value={value}
              onChange={onChangeHandler}
            >
              <Radio.Button className="Meeting-radio-first" value="today">
                Today
              </Radio.Button>
              <Radio.Button value="upcoming">Upcoming</Radio.Button>
              <Radio.Button className="Meeting-radio-last" value="previous">
                Previous
              </Radio.Button>
            </Radio.Group>
            <RangePicker
              className="Meeting-range-picker"
              value={[startDate, endDate]}
              onChange={onRangeChange as any}
              format="DD MMM YYYY"
              allowClear={false}
            />
            <div className="Meeting-space" />
            <Input
              onBlur={onSearchChange}
              placeholder="Search Meeting"
              onPressEnter={onSearchChange as any}
              prefix={<i className="fas fa-search" />}
            />
          </div>
          <div className="Meeting-body-body">
            {meetings?.length === 0 && (
              <List itemLayout="horizontal" dataSource={[]} />
            )}
            {meetings.map((meeting, index: number) => (
              <MeetingDashboardItem
                key={'meeting_' + index}
                meeting={meeting}
                index={index}
                meetingChangeHandler={meetingChange}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

/** connect the component with the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  total: number;
  upcoming: number;
  cancel: number;
  meetings: any[];
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const meetingInfo = getMeetingCountObj(state);
  const result = {
    total: meetingInfo.total,
    upcoming: meetingInfo.upcoming,
    cancel: meetingInfo.cancel,
    meetings: meetingInfo.meetings,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setMeetingsActionCreator: setMeetings,
};

/** connect Meeting to the redux store */
const ConnectedMeeting = connect(mapStateToProps, mapDispatchToProps)(Meeting);

export default withTaskSider(withTodoLayout(ConnectedMeeting));
