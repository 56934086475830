import { Button } from 'antd';
import React from 'react';
import './TaskReminderPicker.scss';
import { TimeReminderObj } from '../../../store/ducks/tasks';
import TaskReminderHeader from './taskReminderHeader/TaskReminderHeader';
import TaskReminderView from './taskReminderView/TaskReminderView';
import TaskReminderSelect from './taskReminderSelect/TaskReminderSelect';

/** interface to describe TaskReminderPicker component props */
interface TaskReminderPickerProps {
  visible: boolean;
  scheduledDate: moment.Moment | null;
  reminders: Array<TimeReminderObj>;
  remindersChangeHandler: (requested: Array<TimeReminderObj>) => void;
  cancelHandler: () => void;
}

const TaskReminderPicker: React.FC<TaskReminderPickerProps> = (
  props: TaskReminderPickerProps
) => {
  const { scheduledDate, reminders, visible, remindersChangeHandler } = props;

  const [tmpReminders, setTmpReminders] = React.useState<TimeReminderObj[]>([]);

  React.useEffect(() => setTmpReminders(reminders), [visible]);

  const confirmHandler = () => remindersChangeHandler(tmpReminders);

  const onChangeHandler = (requested: TimeReminderObj[]) =>
    setTmpReminders(requested);

  return (
    <div className="TaskReminderPicker-container">
      <div>
        <TaskReminderHeader />
      </div>
      <div>
        {scheduledDate && (
          <TaskReminderView
            scheduleDate={scheduledDate as moment.Moment}
            values={tmpReminders}
            changeHandler={onChangeHandler}
          />
        )}
        <TaskReminderSelect
          values={tmpReminders}
          onChangeHandler={setTmpReminders}
        />
        <div className="TaskReminderPicker-confirm-container">
          <Button
            className="TaskReminderPicker-confirm-btn"
            type="primary"
            onClick={confirmHandler}
          >
            Set Reminders
          </Button>
        </div>
      </div>
    </div>
  );
};

/** allow import as default component */
export default TaskReminderPicker;
