import { Button, Divider, Modal } from 'antd';
import React from 'react';
import { SectionObj } from '../../../../store/ducks/sections';
import './TodoSectionOptionsPanel.scss';

/** interface to describe TodoSectionOptionsPanel props */
interface TodoSectionOptionsPanelProps {
  sectionInfo: SectionObj;
  editRequestHandler: () => void;
  deleteRequestHandler: () => void;
  closeHandler: () => void;
}

const TodoSectionOptionsPanel: React.FC<TodoSectionOptionsPanelProps> = (
  props: TodoSectionOptionsPanelProps
) => {
  const {
    editRequestHandler,
    deleteRequestHandler,
    sectionInfo,
    closeHandler,
  } = props;
  const { confirm } = Modal;

  const requestHandler = () => {
    closeHandler();
    confirm(config);
  };

  const config = {
    className: 'TodoSectionOptionsPanel-modal-container',
    title: (
      <div className="TodoSectionOptionsPanel-modal-title">Delete Section</div>
    ),
    content: (
      <div>
        Are you sure you want to delete the section{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {sectionInfo.title}
        </span>{' '}
        ?<br />
      </div>
    ),
    width: 500,
    onOk() {
      deleteRequestHandler();
    },
  };

  return (
    <div className="TodoSectionOptionsPanel-container">
      <Button
        icon={<i className="fas fa-user-edit"></i>}
        className="TodoSectionOptionsPanel-item"
        onClick={editRequestHandler}
      >
        Edit Section
      </Button>
      <Divider type="horizontal" />
      <Button
        icon={<i className="fas fa-trash"></i>}
        className="TodoSectionOptionsPanel-item"
        onClick={requestHandler}
      >
        Delete Section
      </Button>
    </div>
  );
};

/** allow default import */
export default TodoSectionOptionsPanel;
