import { AttendMeetingObj } from './MeetingDashboardItem';

export const ATTEND_MEETING_NO: AttendMeetingObj = {
  name: 'No',
  value: 0,
  color: '#ef5958',
};

export const ATTEND_MEETING_YES = { name: 'Yes', value: 1, color: '#4fb045' };

export const ATTEND_MEETING_MAYBE = {
  name: 'Maybe',
  value: 2,
  color: '#9b98ab',
};

export const ATTEND_MEETING_PENDING = {
  name: 'Pending',
  value: null,
  color: '#f2b120',
};

export const ATTEND_MEETING = [
  ATTEND_MEETING_YES,
  ATTEND_MEETING_NO,
  ATTEND_MEETING_MAYBE,
  ATTEND_MEETING_PENDING,
];
