import { Button, Input } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { fixSectionOrders, setSection } from '../../store/ducks/sections';
import { v4 as uuid } from 'uuid';
import './AddTaskSection.scss';

/** interface to describe AddTaskSection props */
interface AddTaskSectionProps {
  projectId: string;
  requestedOrder: number;
  setSectionActionCreator: typeof setSection;
  fixSectionOrdersActionCreator: typeof fixSectionOrders;
}

/** the add task section component */
const AddTaskSection: React.FC<AddTaskSectionProps> = (
  props: AddTaskSectionProps
) => {
  const {
    setSectionActionCreator,
    projectId,
    fixSectionOrdersActionCreator,
    requestedOrder,
  } = props;
  // component states
  /** manages whether to render input or not */
  const [isInputActive, setIsInputActive] = React.useState<boolean>(false);
  /** manages the user input of the text field */
  const [text, setText] = React.useState<string>('');

  /**
   * toggleHandler toggles the state of render between hover state and input state
   */
  const toggleHandler = () => {
    setText('');
    setIsInputActive(!isInputActive);
  };

  /**
   * create section handler
   */
  const okHandler = () => {
    if (text === '') return;
    setSectionActionCreator({
      id: uuid(),
      title: text,
      projectId,
      expanded: true,
      parent: '',
      bodyExpanded: true,
      order: requestedOrder,
      synced: false,
      deleted: false,
    });
    fixSectionOrdersActionCreator(projectId);
    toggleHandler();
  };

  /**
   * the on change handler respect to the section title input field
   * @param {React.ChangeEvent<HTMLInputElement>} event - the React Change event
   */
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };

  return isInputActive ? (
    <div className="AddTaskSection-input-container">
      <Input value={text} onChange={onChangeHandler} onPressEnter={okHandler} />
      <Button
        type="primary"
        disabled={text === ''}
        className="AddTaskSection-add-btn"
        onClick={okHandler}
      >
        ADD SECTION
      </Button>
      <Button
        type="text"
        className="AddTaskSection-cancel-btn"
        onClick={toggleHandler}
      >
        Cancel
      </Button>
    </div>
  ) : (
    <div className="AddTaskSection-container">
      <div onClick={toggleHandler} className="AddTaskSection-hover-container">
        <div className="AddTaskSection-divider" />
        <div className="AddTaskSection-divider-label">ADD SECTION</div>
        <div className="AddTaskSection-divider" />
      </div>
    </div>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setSectionActionCreator: setSection,
  fixSectionOrdersActionCreator: fixSectionOrders,
};

/** connect AddTaskSection to the redux store */
const ConnectedAddTaskSection = connect(
  null,
  mapDispatchToProps
)(AddTaskSection);

/** export default component */
export default ConnectedAddTaskSection;
