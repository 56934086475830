import React from 'react';
import './TaskReminderSelectItem.scss';

interface TaskReminderSelectItemProps {
  label: string;
  value: number;
  selectHandler: (requested: number) => void;
}

const TaskReminderSelectItem: React.FC<TaskReminderSelectItemProps> = (
  props: TaskReminderSelectItemProps
) => {
  const { label, value, selectHandler } = props;

  const clickHandler = () => selectHandler(value);

  return (
    <div className="TaskReminderSelectItem-container" onClick={clickHandler}>
      {label}
    </div>
  );
};

export default TaskReminderSelectItem;
