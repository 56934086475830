import { Button, Dropdown, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { generateDays } from '../../../services/utility';
import DaySection from '../daySection/DaySection';
import {
  NAVIGATION_DAILY,
  NAVIGATION_INTERVAL_OPTIONS,
  NAVIGATION_MONTHLY,
  NAVIGATION_WEEKLY,
  NAVIGATION_YEARLY,
} from '../todoUpcomingPage/constants';
import './MonthSection.scss';

/** interface to describe the month section props */
interface MonthSectionProps {
  startDate: string;
  endDate: string;
  currentDate: string;
  navigationInterval: string;
  onNavigationIntervalChange: (requested: string) => void;
}

const MonthSection: React.FC<MonthSectionProps> = (
  props: MonthSectionProps
) => {
  const {
    startDate,
    endDate,
    currentDate,
    navigationInterval,
    onNavigationIntervalChange,
  } = props;
  const { Option } = Select;

  const firstDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
  const lastDate = moment(currentDate).endOf('month').format('YYYY-MM-DD');

  const startIterateDate = moment.max(moment(firstDate), moment(startDate));
  const endIterateDate = moment.min([moment(endDate), moment(lastDate)]);

  const [visible, setVisible] = React.useState<boolean>(false);
  const [calendarVisible, setCalendarVisible] = React.useState<boolean>(false);

  const observer = new IntersectionObserver(
    function (entries) {
      /** if top section header gets out of view point */
      if (entries[0].intersectionRatio === 0) {
        setCalendarVisible(false);
        setVisible(false);
      } else if (entries[0].intersectionRatio >= 0.3) {
        setVisible(true);
      }
    },
    {
      threshold: [0, 0.3],
    }
  );

  React.useEffect(() => {
    observer.observe((document as any).querySelector('#month_' + currentDate));

    return () => {
      observer.disconnect();
    };
  }, []);

  const onCalendarVisibleChange = (visible: boolean) => {
    setCalendarVisible(visible);
  };

  const scrollToDate = (requestedNavigationDate: moment.Moment) => {
    document
      .getElementById(
        'month_' +
          moment()
            .set('month', requestedNavigationDate.month())
            .set('year', requestedNavigationDate.year())
            .format('YYYY-MM-DD')
      )
      ?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      document
        .getElementById('day_' + requestedNavigationDate.format('YYYY-MM-DD'))
        ?.scrollIntoView({ behavior: 'smooth' });
    }, 1200);
  };

  const scrollToToday = () => {
    scrollToDate(moment());
  };

  /** scrolls to the previous date based on the jump interval and current date */
  const prevHandler = () => {
    /** extract the current sticky date */
    const stickyDate = document
      .getElementsByClassName('DaySection-sticky')[0]
      ?.id.split('_')[1];

    /** start with the navigation sticky date */
    let navigationDate = moment(stickyDate);

    /** calculate actual navigation or jump date */
    if (navigationInterval === NAVIGATION_DAILY) {
      navigationDate = moment(stickyDate).subtract(1, 'day');
    } else if (navigationInterval === NAVIGATION_WEEKLY) {
      navigationDate = moment(stickyDate).subtract(1, 'week');
    } else if (navigationInterval === NAVIGATION_MONTHLY) {
      navigationDate = moment(stickyDate).subtract(1, 'month');
    } else if (navigationInterval === NAVIGATION_YEARLY) {
      navigationDate = moment(stickyDate).subtract(1, 'year');
    }

    /** check if jump date within 2 years range */
    if (navigationDate.isBefore(moment(startDate, 'YYYY-MM-DD'))) {
      scrollToDate(moment(startDate, 'YYYY-MM-DD'));
    } else if (navigationDate.isAfter(moment(endDate, 'YYYY-MM-DD'))) {
      scrollToDate(moment(endDate, 'YYYY-MM-DD'));
    } else {
      scrollToDate(navigationDate);
    }
  };

  /** scrolls to the next date based on the jump interval and current date */
  const nextHandler = () => {
    /** extract the current sticky date */
    const stickyDate = document
      .getElementsByClassName('DaySection-sticky')[0]
      ?.id.split('_')[1];

    /** start with the navigation sticky date */
    let navigationDate = moment(stickyDate);

    /** calculate actual navigation or jump date */
    if (navigationInterval === NAVIGATION_DAILY) {
      navigationDate = moment(stickyDate).add(1, 'day');
    } else if (navigationInterval === NAVIGATION_WEEKLY) {
      navigationDate = moment(stickyDate).add(1, 'week');
    } else if (navigationInterval === NAVIGATION_MONTHLY) {
      navigationDate = moment(stickyDate).add(1, 'month');
    } else if (navigationInterval === NAVIGATION_YEARLY) {
      navigationDate = moment(stickyDate).add(1, 'year');
    }

    /** check if jump date within 2 years range */
    if (navigationDate.isBefore(moment(startDate, 'YYYY-MM-DD'))) {
      scrollToDate(moment(startDate, 'YYYY-MM-DD'));
    } else if (navigationDate.isAfter(moment(endDate, 'YYYY-MM-DD'))) {
      scrollToDate(moment(endDate, 'YYYY-MM-DD'));
    } else {
      scrollToDate(navigationDate);
    }
  };

  const onCalendarChange = (date: any) => {
    setCalendarVisible(false);
    scrollToDate(moment(date));
  };

  return (
    <div id={'month_' + currentDate} className="MonthSection-container">
      <div id={'MonthSection-' + currentDate} className="MonthSection-header">
        <div className="MonthSection-header-inner-container">
          <div className="MonthSection-title">
            <Dropdown
              overlayClassName="MonthSection-datepicker"
              visible={calendarVisible}
              onVisibleChange={onCalendarVisibleChange}
              overlay={
                <div>
                  <DatePicker
                    inline
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="MonthSection-datepicker-header">
                        <div className="MonthSection-datepicker-label">
                          {moment(date.toString()).format('MMMM YYYY')}
                        </div>
                        <button
                          className="MonthSection-datepicker-left"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <i className="fas fa-chevron-left" />
                        </button>
                        <button
                          className="MonthSection-datepicker-right"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <i className="fas fa-chevron-right" />
                        </button>
                      </div>
                    )}
                    onChange={onCalendarChange}
                    minDate={new Date(startDate.toString())}
                    maxDate={new Date(endDate.toString())}
                  />
                </div>
              }
              trigger={['click']}
            >
              <div className="MonthSection-title-text">
                {moment(currentDate).format('MMMM YYYY')}
              </div>
            </Dropdown>
          </div>
          <div className="MonthSection-navigation">
            <Button
              onClick={prevHandler}
              type="text"
              shape="circle"
              size="small"
              className="MonthSection-btn-right"
              icon={<i className="fas fa-chevron-left" />}
            />
            <Button
              onClick={nextHandler}
              type="text"
              shape="circle"
              size="small"
              className="MonthSection-btn-right"
              icon={<i className="fas fa-chevron-right" />}
            />

            <Button className="MonthSection-btn-right" onClick={scrollToToday}>
              Today
            </Button>
            <Select
              className="MonthSection-interval-select"
              dropdownClassName="MonthSection-interval-dropdown"
              value={navigationInterval}
              onChange={onNavigationIntervalChange}
            >
              {NAVIGATION_INTERVAL_OPTIONS.map((navigationOpt) => (
                <Option key={navigationOpt} value={navigationOpt}>
                  {navigationOpt}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="MonthSection-body">
        {visible && (
          <div
            id={'MonthSection-body-' + currentDate}
            className="MonthSection-body-inner-container"
          >
            {generateDays(startIterateDate, endIterateDate).map(
              (iterDate: string) => (
                <DaySection
                  key={'day-' + iterDate}
                  currentDate={iterDate}
                  monthDate={currentDate}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthSection;
