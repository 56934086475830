export const BEFORE_MEETING = {
  label: 'Before Meeting',
  value: 'before_meeting',
};
export type BEFORE_MEETING = typeof BEFORE_MEETING;

export const AFTER_MEETING = {
  label: 'After Meeting',
  value: 'after_meeting',
};
export type AFTER_MEETING = typeof BEFORE_MEETING;

export const RELATIVE_MEETING_OPTIONS = [BEFORE_MEETING, AFTER_MEETING];

export const MINUTE_15 = {
  label: '15 Min',
  value: '15',
};

export const MINUTE_30 = {
  label: '30 Min',
  value: '30',
};

export const MINUTE_60 = {
  label: '1 hr',
  value: '60',
};

export const MINUTE_120 = {
  label: '2 hr',
  value: '120',
};

export const MINUTE_300 = {
  label: '5 hr',
  value: '300',
};

export const DURATIONS = [
  MINUTE_15,
  MINUTE_30,
  MINUTE_60,
  MINUTE_120,
  MINUTE_300,
];
