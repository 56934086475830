import { Tooltip } from 'antd';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Store } from 'redux';
import {
  LOCAL_INBOX_ENDPOINT,
  LOCAL_PRE_PROJECT_ENDPOINT,
} from '../../configs/endpoints';
import { getParentsFromChild } from '../../services/utility';
import { getProjectById, ProjectObj } from '../../store/ducks/projects';
import { getSectionById, SectionObj } from '../../store/ducks/sections';
import { getTaskById, getTasks, TaskObj } from '../../store/ducks/tasks';
import './TodoDetailedTaskBreadcrumb.scss';

interface TodoDetailedTaskBreadcrumbProps {
  id: string;
  projectInfo: ProjectObj | null;
  sectionInfo: SectionObj | null;
  parentTasksInfo: TaskObj[];
  taskInfo: TaskObj | null;
}

const TodoDetailedTaskBreadcrumb: React.FC<TodoDetailedTaskBreadcrumbProps> = (
  props: TodoDetailedTaskBreadcrumbProps
) => {
  const { taskInfo, projectInfo, sectionInfo, parentTasksInfo } = props;
  const location = useLocation();
  return taskInfo ? (
    <div className="TodoDetailedTaskBreadcrumb-container">
      <div className="TodoDetailedTaskBreadcrumb-nav-item">
        {taskInfo.projectId === '' ? (
          <Link to={LOCAL_INBOX_ENDPOINT}>
            <Tooltip placement="bottom" title="Inbox">
              <div>
                <i className="fas fa-inbox"></i> Inbox
              </div>
            </Tooltip>
          </Link>
        ) : (
          <Link to={LOCAL_PRE_PROJECT_ENDPOINT + taskInfo.projectId}>
            <Tooltip placement="bottom" title={projectInfo?.title}>
              <div className="TodoDetailedTaskBreadcrumb-project">
                <div
                  className="TodoDetailedTaskBreadcrumb-dot"
                  style={{ background: projectInfo?.color }}
                />
                <div className="TodoDetailedTaskBreadcrumb-project-title">
                  {projectInfo?.title}
                </div>
              </div>
            </Tooltip>
          </Link>
        )}
      </div>
      {taskInfo.sectionId !== '' && (
        <React.Fragment>
          <div className="TodoDetailedTaskBreadcrumb-nav-arrow">
            <i className="fas fa-chevron-right"></i>
          </div>
          <Link to={LOCAL_PRE_PROJECT_ENDPOINT + taskInfo.projectId}>
            <Tooltip placement="bottom" title={sectionInfo?.title}>
              <div className="TodoDetailedTaskBreadcrumb-nav-item">
                {sectionInfo?.title}
              </div>
            </Tooltip>
          </Link>
        </React.Fragment>
      )}
      {(lodash.without(parentTasksInfo, undefined) as any).map(
        (iterTask: TaskObj, index: number) => (
          <React.Fragment key={`bdTask-${index}`}>
            <div className="TodoDetailedTaskBreadcrumb-nav-arrow">
              <i className="fas fa-chevron-right"></i>
            </div>
            <Link to={location.pathname + `?task=` + iterTask.id}>
              <Tooltip placement="bottom" title={iterTask.title}>
                <div className="TodoDetailedTaskBreadcrumb-nav-item">
                  {iterTask.title}
                </div>
              </Tooltip>
            </Link>
          </React.Fragment>
        )
      )}
    </div>
  ) : (
    <div />
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  projectInfo: ProjectObj | null;
  sectionInfo: SectionObj | null;
  parentTasksInfo: TaskObj[];
  taskInfo: TaskObj | null;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TodoDetailedTaskBreadcrumbProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const taskInfo = getTaskById(state, parentProps.id);
  const result = {
    taskInfo,
    projectInfo: taskInfo ? getProjectById(state, taskInfo.projectId) : null,
    sectionInfo: taskInfo ? getSectionById(state, taskInfo.sectionId) : null,
    parentTasksInfo: taskInfo
      ? getParentsFromChild(getTasks(state), taskInfo.parent, [])
      : [],
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoDetailedTaskBreadcrumb to the redux store */
const ConnectedTodoDetailedTaskBreadcrumb = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoDetailedTaskBreadcrumb);

export default ConnectedTodoDetailedTaskBreadcrumb;
