import {
  getUnsyncedProjects,
  ProjectObj,
  setProjectsSyncedStatus,
} from '../store/ducks/projects';
import store from '../store';
import { getSessionToken } from '../store/ducks/session';
import { axioService, POST } from './axioService';
import {
  getUnsyncedLabels,
  LabelObj,
  setLabelsSyncedStatus,
} from '../store/ducks/labels';
import { SERVER_SYNC_ENDPOINT } from '../configs/endpoints';
import {
  getUnsyncedSections,
  SectionObj,
  setSectionsSyncedStatus,
} from '../store/ducks/sections';
import {
  getUnsyncedTasks,
  setTasksSyncedStatus,
  TaskObj,
} from '../store/ducks/tasks';
import { ServerTaskObj } from '../api/tasks/data';
import { transformTaskToServer } from '../api/tasks/transformer';

export interface ServerProjectObj {
  id: string;
  title: string;
  color: string;
  parent_id: string | null;
  order: number;
  is_expanded: 0 | 1;
  is_favorite: 0 | 1;
  delete: 0 | 1;
  shared_with?: string[];
}

export interface ServerLabelObj {
  id: string;
  title: string;
  color: string;
  order: number;
  is_favorite: 0 | 1;
  delete: 0 | 1;
}

export interface ServerSectionObj {
  id: string;
  title: string;
  order: number;
  project_id: string | null;
  is_expanded: 0 | 1;
  delete: 0 | 1;
}

/** fetches the unsynced projects in server project format */
const fetchServerUnsyncedProjects = (): ServerProjectObj[] => {
  const unsyncedProjects = getUnsyncedProjects((store as any).getState());
  const unsyncedProjectIds = unsyncedProjects.map(
    (iterProj: ProjectObj) => iterProj.id
  );
  const serverUnsyncedProjects = unsyncedProjects.map(
    (iterProj: ProjectObj) => ({
      id: iterProj.id,
      title: iterProj.title,
      parent_id: iterProj.parent === '' ? null : iterProj.parent,
      color: iterProj.color,
      order: iterProj.order,
      is_favorite: (iterProj.isFavourite ? 1 : 0) as any,
      is_expanded: (iterProj.expanded ? 1 : 0) as any,
      delete: (iterProj.deleted ? 1 : 0) as any,
    })
  );
  if (unsyncedProjects.length > 0) {
    (store as any).dispatch(setProjectsSyncedStatus(unsyncedProjectIds, true));
  }
  return serverUnsyncedProjects;
};

/** fetches the unsynced labels in server label format */
const fetchServerUnsyncedLabels = (): ServerLabelObj[] => {
  const unsyncedLabels = getUnsyncedLabels((store as any).getState());
  const unsyncedLabelIds = unsyncedLabels.map(
    (iterLabel: LabelObj) => iterLabel.id
  );
  const serverUnsyncedLabels = unsyncedLabels.map((iterLabel: LabelObj) => ({
    id: iterLabel.id,
    title: iterLabel.title,
    color: iterLabel.color,
    order: iterLabel.order,
    is_favorite: (iterLabel.isFavourite ? 1 : 0) as any,
    delete: (iterLabel.deleted ? 1 : 0) as any,
  }));
  if (unsyncedLabels.length > 0) {
    (store as any).dispatch(setLabelsSyncedStatus(unsyncedLabelIds, true));
  }
  return serverUnsyncedLabels;
};

/** fetches the unsynced sections in server label format */
const fetchServerUnsyncedSections = (): ServerSectionObj[] => {
  const unsyncedSections = getUnsyncedSections((store as any).getState());
  const unsyncedSectionIds = unsyncedSections.map(
    (iterSection: SectionObj) => iterSection.id
  );
  const serverUnsyncedSections = unsyncedSections.map(
    (iterSection: SectionObj) => ({
      id: iterSection.id,
      title: iterSection.title,
      project_id: iterSection.projectId === '' ? null : iterSection.projectId,
      order: iterSection.order,
      is_expanded: (iterSection.expanded ? 1 : 0) as any,
      delete: (iterSection.deleted ? 1 : 0) as any,
    })
  );
  if (unsyncedSections.length > 0) {
    (store as any).dispatch(setSectionsSyncedStatus(unsyncedSectionIds, true));
  }
  return serverUnsyncedSections;
};

/** fetches the unsynced tasks in server label format */
const fetchServerUnsyncedTasks = (): ServerTaskObj[] => {
  const unsyncedTasks = getUnsyncedTasks((store as any).getState());
  const unsyncedTasksIds = unsyncedTasks.map(
    (iterTask: TaskObj) => iterTask.id
  );
  const serverUnsyncedTasks = unsyncedTasks.map((iterTask: TaskObj) =>
    transformTaskToServer(iterTask)
  );
  if (unsyncedTasks.length > 0) {
    (store as any).dispatch(setTasksSyncedStatus(unsyncedTasksIds, true));
  }
  return serverUnsyncedTasks;
};

export const syncService = async () => {
  const token = getSessionToken((store as any).getState());

  /** if the token is empty, no need to run the sync */
  if (token === '') {
    return;
  }
  const unsyncedProjects = fetchServerUnsyncedProjects();
  const unsyncedLabels = fetchServerUnsyncedLabels();
  const unsyncedSections = fetchServerUnsyncedSections();
  const unsyncedTasks = fetchServerUnsyncedTasks();

  if (
    unsyncedTasks.length > 0 ||
    unsyncedLabels.length > 0 ||
    unsyncedSections.length > 0 ||
    unsyncedProjects.length > 0
  ) {
    /** fetched the unsynced projects data */
    try {
      await axioService(
        POST,
        SERVER_SYNC_ENDPOINT,
        {
          projects: unsyncedProjects,
          labels: unsyncedLabels,
          sections: unsyncedSections,
          tasks: unsyncedTasks,
        },
        true
      );
    } catch (error) {
      const unsyncedProjectIds = unsyncedProjects.map(
        (iterProj: ServerProjectObj) => iterProj.id
      );
      const unsyncedLabelIds = unsyncedLabels.map(
        (iterLabel: ServerLabelObj) => iterLabel.id
      );
      const unsyncedSectionIds = unsyncedSections.map(
        (iterSection: ServerSectionObj) => iterSection.id
      );
      const unsyncedTaskIds = unsyncedTasks.map(
        (iterTask: ServerTaskObj) => iterTask.id
      );
      /** dispatch the sync status to false for later sync */
      (store as any).dispatch(
        setProjectsSyncedStatus(unsyncedProjectIds, false)
      );
      (store as any).dispatch(setLabelsSyncedStatus(unsyncedLabelIds, false));
      (store as any).dispatch(
        setSectionsSyncedStatus(unsyncedSectionIds, false)
      );
      (store as any).dispatch(setTasksSyncedStatus(unsyncedTaskIds, false));

      console.error(error);
    }
  }
};
