import { Divider, Modal } from 'antd';
import React from 'react';
import './TodoCalendarPicker.scss';
import Calendar from 'react-calendar';
import moment from 'moment';
import RecurrencePicker from '../../../containers/recurrencePicker/RecurrencePicker';
import TimePicker from '../../general/timePicker/TimePicker';
import { MEETING_ACTIVTY_TYPE } from '../../../configs/activityTypes';
import { INTERNAL_DATE_TIME_FORMAT } from '../../../configs/constants';

/** interface to describe TodoCalendarPicker props */
interface TodoCalendarPickerProps {
  taskType: string;
  taskId: string;
  opened: boolean;
  selectedDate: moment.Moment; // current selected date
  selectedTime: string;
  selectedEndDate: moment.Moment;
  selectedEndTime: string;
  use12HourFormat: boolean;
  defaultNextWeekDay: string;
  resetCalendarDate: () => void;
  openReminderHandler: () => void;
  timeChangeHandler: (requestedTime: string) => void;
  endTimeChangeHandler: (requestedTime: string) => void;
  selectedDateChangeHandler: (date: moment.Moment) => void; // handler to change selected date
  selectedEndDateChangeHandler: (date: moment.Moment) => void;
  confirmHandler: (
    date: moment.Moment,
    time: string,
    endDate: moment.Moment,
    endTime: string
  ) => void; // confirms selected date
  cancelHandler: () => void; // cancels selected date
  onRecurringChange: (requested: 0 | 1) => void;
}

const getNextWeekDayInstance = (dayInNeed: number) => {
  return moment().add(1, 'weeks').isoWeekday(dayInNeed);
};

/** string format of the date */
const DATE_FORMAT = 'YYYY-MM-DD';

/** the Todo calendar picker */
const TodoCalendarPicker: React.FC<TodoCalendarPickerProps> = (
  props: TodoCalendarPickerProps
) => {
  const {
    taskId,
    opened,
    selectedTime,
    selectedEndDate,
    selectedEndTime,
    selectedEndDateChangeHandler,
    endTimeChangeHandler,
    cancelHandler,
    confirmHandler,
    selectedDate,
    use12HourFormat,
    openReminderHandler,
    selectedDateChangeHandler,
    timeChangeHandler,
    resetCalendarDate,
    defaultNextWeekDay,
    onRecurringChange,
    taskType,
  } = props;

  let nextWeek = moment();

  const [isRecurrenceActive, setRecurrenceActive] = React.useState<boolean>(
    false
  );

  const dateChangeHandler = (
    startDate: moment.Moment,
    endDate: moment.Moment,
    startTime: string,
    endTime: string
  ) => {
    const tmpStartDate = moment(
      startDate.format(DATE_FORMAT) + ' ' + startTime,
      INTERNAL_DATE_TIME_FORMAT
    );
    const tmpEndDate = moment(
      endDate.format(DATE_FORMAT) + ' ' + endTime,
      INTERNAL_DATE_TIME_FORMAT
    );
    if (tmpStartDate.isBefore(tmpEndDate)) {
      selectedDateChangeHandler(startDate);
      selectedEndDateChangeHandler(endDate);
      timeChangeHandler(startTime);
      endTimeChangeHandler(endTime);
    } else {
      tmpStartDate.add(1, 'hour');
      selectedDateChangeHandler(startDate);
      selectedEndDateChangeHandler(tmpStartDate);
      timeChangeHandler(startTime);
      endTimeChangeHandler(tmpStartDate.format('HH:mm'));
    }
  };

  if (defaultNextWeekDay === 'Sunday') {
    nextWeek = getNextWeekDayInstance(7);
  } else if (defaultNextWeekDay === 'Monday') {
    nextWeek = getNextWeekDayInstance(1);
  } else if (defaultNextWeekDay === 'Tuesday') {
    nextWeek = getNextWeekDayInstance(2);
  } else if (defaultNextWeekDay === 'Wednesday') {
    nextWeek = getNextWeekDayInstance(3);
  } else if (defaultNextWeekDay === 'Thursday') {
    nextWeek = getNextWeekDayInstance(4);
  } else if (defaultNextWeekDay === 'Friday') {
    nextWeek = getNextWeekDayInstance(5);
  } else if (defaultNextWeekDay === 'Saturday') {
    nextWeek = getNextWeekDayInstance(6);
  }

  /** cycles */
  React.useEffect(() => {
    setTimeout(() => setRecurrenceActive(false), 1000);
  }, [opened]);

  /** handlers */

  /** sets the selected date to today */
  const setToday = () => {
    dateChangeHandler(moment(), moment(), selectedTime, selectedEndTime);
  };

  /** sets the selected date to tomorrow */
  const setTomorrow = () => {
    dateChangeHandler(
      moment().add(1, 'days'),
      moment().add(1, 'days'),
      selectedTime,
      selectedEndTime
    );
  };

  /** sets the selected date to next week */
  const setNextWeek = () => {
    dateChangeHandler(nextWeek, nextWeek, selectedTime, selectedEndTime);
  };

  /**
   * sets the changed date on calendar as selected date
   * @param {any} date - the changed date
   */
  const onCalendarChange = (date: any) =>
    dateChangeHandler(
      moment(date),
      moment(date),
      selectedTime,
      selectedEndTime
    );

  /** confirms the selected date */
  const doneHandler = () => {
    confirmHandler(
      selectedDate,
      selectedTime,
      selectedEndDate,
      selectedEndTime
    );
  };

  const setReminderHandler = () => {
    confirmHandler(
      selectedDate,
      selectedTime,
      selectedEndDate,
      selectedEndTime
    );
    openReminderHandler();
  };

  const openRecurrenceHandler = () => {
    if (taskId !== '') {
      setRecurrenceActive(true);
    } else {
      Modal.warning({
        className: 'TodoCalendarPicker-confirm-modal',
        title: 'Warning. Task does not exist.',
        content: (
          <div>
            <p>To enable recurring feature, please create the task first.</p>
          </div>
        ),
      });
    }
  };

  const closeRecurrenceHandler = () => {
    setRecurrenceActive(false);
  };

  /** rendered jsx */
  return !isRecurrenceActive ? (
    <div className="TodoCalendarPicker-container">
      <div onClick={setToday} className="TodoCalendarPicker-quick-container">
        <div className="TodoCalendarPicker-today-icon">
          <i className="far fa-check-circle"></i>
        </div>
        <div className="TodoCalendarPicker-quick-label">Today</div>
        <div className="TodoCalendarPicker-quick-day">
          {moment().format('ddd')}
        </div>
      </div>
      <div onClick={setTomorrow} className="TodoCalendarPicker-quick-container">
        <div className="TodoCalendarPicker-tomorrow-icon">
          <i className="fas fa-forward"></i>
        </div>
        <div className="TodoCalendarPicker-quick-label">Tomorrow</div>
        <div className="TodoCalendarPicker-quick-day">
          {moment().add(1, 'days').format('ddd')}
        </div>
      </div>
      <div onClick={setNextWeek} className="TodoCalendarPicker-quick-container">
        <div className="TodoCalendarPicker-next-week-icon">
          <i className="fas fa-fast-forward"></i>
        </div>
        <div className="TodoCalendarPicker-quick-label">Next week</div>
        <div className="TodoCalendarPicker-quick-day">
          {nextWeek.format('ddd')}
        </div>
      </div>
      <div
        onClick={resetCalendarDate}
        className="TodoCalendarPicker-quick-container"
      >
        <div className="TodoCalendarPicker-no-date-icon">
          <i className="fas fa-ban"></i>
        </div>
        <div className="TodoCalendarPicker-quick-label">No Date</div>
        <div className="TodoCalendarPicker-quick-day"></div>
      </div>
      <Divider />
      <div className="TodoCalendarPicker-calendar">
        <Calendar
          tileClassName={({ date, view }) => {
            if (view === 'month') {
              switch (date.getDay()) {
                case 1:
                  return 'TodoCalendarPicker-monday';
                case 2:
                  return 'TodoCalendarPicker-tuesday';
                case 3:
                  return 'TodoCalendarPicker-wednesday';
                case 4:
                  return 'TodoCalendarPicker-thursday';
                case 5:
                  return 'TodoCalendarPicker-friday';
                case 6:
                  return 'TodoCalendarPicker-saturday';
                case 0:
                  return 'TodoCalendarPicker-sunday';
              }
            }
            return null;
          }}
          value={selectedDate.toDate()}
          onChange={onCalendarChange}
          minDate={moment().toDate()}
        />
      </div>

      <div className="TodoCalendarPicker-set-btn TodoCalendarPicker-time-set-btn">
        <TimePicker
          selectedTime={selectedTime}
          timeChangeHandler={(requested) =>
            dateChangeHandler(
              selectedDate,
              selectedEndDate,
              requested,
              selectedEndTime
            )
          }
          use12HoursFormat={use12HourFormat}
        />
        {taskType === MEETING_ACTIVTY_TYPE.value && (
          <React.Fragment>
            To
            <TimePicker
              selectedTime={selectedEndTime}
              timeChangeHandler={(requested) =>
                dateChangeHandler(
                  selectedDate,
                  selectedEndDate,
                  selectedTime,
                  requested
                )
              }
              use12HoursFormat={use12HourFormat}
            />
          </React.Fragment>
        )}
      </div>

      <div className="TodoCalendarPicker-set-btn" onClick={setReminderHandler}>
        {' '}
        Set Reminder{' '}
      </div>
      <div
        onClick={openRecurrenceHandler}
        className="TodoCalendarPicker-set-btn"
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '15px',
        }}
      >
        {' '}
        Set Repeat{' '}
      </div>
      <div className="TodoCalendarPicker-actions-container">
        <div
          onClick={cancelHandler}
          className="TodoCalendarPicker-cancel-btn"
          key="0"
        >
          Cancel
        </div>
        <div
          onClick={doneHandler}
          className="TodoCalendarPicker-done-btn"
          key="1"
        >
          Done
        </div>
      </div>
    </div>
  ) : (
    <RecurrencePicker
      taskId={taskId}
      closeHandler={cancelHandler}
      backHandler={closeRecurrenceHandler}
      onRecurringChange={onRecurringChange}
    />
  );
};

/** allow import as default component */
export default TodoCalendarPicker;
