import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_TASKS_ENDPOINT } from '../../configs/endpoints';
import { axioService, GET } from '../../services/axioService';
import { TaskObj, updateTasks } from '../../store/ducks/tasks';
import './FetchCompletedTasks.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { transformTaskFromServer } from '../../api/tasks/transformer';
import { ServerTaskObj } from '../../api/tasks/data';

interface FetchCompletedTasksProps {
  isPresent: boolean;
  projectId: string;
  sectionId: string;
  parentTaskId?: string;
  updateTasksActionCreator: typeof updateTasks;
}

const FetchCompletedTasks: React.FC<FetchCompletedTasksProps> = (
  props: FetchCompletedTasksProps
) => {
  const {
    isPresent,
    updateTasksActionCreator,
    projectId,
    sectionId,
    parentTaskId,
  } = props;

  const [offset, setOffSet] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEnd, setIsEnd] = React.useState<boolean>(false);

  React.useEffect(() => {
    setOffSet(1);
    setIsEnd(false);
  }, [projectId, sectionId, parentTaskId]);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await axioService(
        GET,
        `${SERVER_TASKS_ENDPOINT}`,
        parentTaskId
          ? {
              per_page: 10,
              page: offset,
              project_id: projectId,
              section_id: sectionId,
              is_completed: 1,
              parent_id: parentTaskId,
            }
          : {
              per_page: 10,
              page: offset,
              project_id: projectId,
              section_id: sectionId,
              is_completed: 1,
            },
        true
      );

      updateTasksActionCreator(
        response.data.data.map(
          (iterTask: ServerTaskObj): TaskObj =>
            transformTaskFromServer(iterTask)
        )
      );
      if (response.data.meta?.last_page <= offset) {
        setIsEnd(true);
      }
      setOffSet(offset + 1);
    } catch (exception) {
      /** console error the exception */
      console.error(exception);
    }
    setLoading(false);
  };

  return !isEnd ? (
    loading ? (
      <div className="FetchCompletedTasks-container FetchCompletedTasks-loading">
        Fetching tasks <LoadingOutlined />
      </div>
    ) : (
      <div className="FetchCompletedTasks-container">
        {isPresent ? (
          <Button
            className="FetchCompletedTasks-btn"
            type="text"
            onClick={fetchTasks}
          >
            <i className="fas fa-envelope-open-text"></i> Show more
          </Button>
        ) : (
          <Button
            className="FetchCompletedTasks-btn"
            type="text"
            onClick={fetchTasks}
          >
            <i className="far fa-envelope"></i> Show completed tasks
          </Button>
        )}
      </div>
    )
  ) : !isPresent ? (
    <div className="FetchCompletedTasks-container FetchCompletedTasks-empty">
      <i className="far fa-envelope-open"></i> No completed Tasks
    </div>
  ) : null;
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  updateTasksActionCreator: updateTasks,
};

/** connect FetchCompletedTasks to the redux store */
const ConnectedFetchCompletedTasks = connect(
  null,
  mapDispatchToProps
)(FetchCompletedTasks);

export default ConnectedFetchCompletedTasks;
