export const RECURRENCE_MONTH_OPTIONS = {
  1: 'day 1',
  2: 'day 2',
  3: 'day 3',
  4: 'day 4',
  5: 'day 5',
  6: 'day 6',
  7: 'day 7',
  8: 'day 8',
  9: 'day 9',
  10: 'day 10',
  11: 'day 11',
  12: 'day 12',
  13: 'day 13',
  14: 'day 14',
  15: 'day 15',
  16: 'day 16',
  17: 'day 17',
  18: 'day 18',
  19: 'day 19',
  20: 'day 20',
  21: 'day 21',
  22: 'day 22',
  23: 'day 23',
  24: 'day 24',
  25: 'day 25',
  26: 'day 26',
  27: 'day 27',
  28: 'day 28',
  29: 'day 29',
  30: 'day 30',
  31: 'day 31',
  32: 'last day',
};
