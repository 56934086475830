import React from 'react';
import './TaskReminderHeader.scss';

const TaskReminderHeader: React.FC = () => {
  return (
    <div className="TaskReminderHeader-container">
      <div className="TaskReminderHeader-title">Set Reminders</div>
      <div className="TaskReminderHeader-subtitle">Settings</div>
    </div>
  );
};

export default TaskReminderHeader;
