import { Button } from 'antd';
import React from 'react';
import './TodoFilterFavouriteButton.scss';
import { connect } from 'react-redux';
import { TodoFilterObj, setTodoFilter } from '../../store/ducks/todoFilters';

/** interface to describe TodoFilterFavouriteButton props */
interface TodoFilterFavouriteButtonProps {
  filterInfo: TodoFilterObj;
  setTodoFilterActionCreator: typeof setTodoFilter;
  closeHandler: () => void;
}

/** Placeholders subject to Favourite flag */
const ADD_TO_FAVOURITE_LABEL = 'Add to favourites';
const REMOVE_FROM_FAVOURITE_LABEL = 'Remove from favourites';

const TodoFilterFavouriteButton: React.FC<TodoFilterFavouriteButtonProps> = (
  props: TodoFilterFavouriteButtonProps
) => {
  const { filterInfo, setTodoFilterActionCreator, closeHandler } = props;

  /** manages the favourite toogle request */
  const favouriteHandler = () => {
    setTodoFilterActionCreator({
      ...filterInfo,
      synced: false,
      isFavourite: !filterInfo.isFavourite,
    });
    closeHandler();
  };

  return (
    <Button
      onClick={favouriteHandler}
      icon={
        filterInfo.isFavourite ? (
          <i className="fas fa-heart-broken"></i>
        ) : (
          <i className="fas fa-heart"></i>
        )
      }
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="TodoFilterOptionsPanel-item"
    >
      {filterInfo.isFavourite
        ? REMOVE_FROM_FAVOURITE_LABEL
        : ADD_TO_FAVOURITE_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setTodoFilterActionCreator: setTodoFilter,
};

/** connect TodoFilterFavouriteButton to the redux store */
const ConnectedTodoFilterFavouriteButton = connect(
  null,
  mapDispatchToProps
)(TodoFilterFavouriteButton);

export default ConnectedTodoFilterFavouriteButton;
