import { Input } from 'antd';
import React from 'react';
import { AgendaStateItem } from '../meetingAgenda/MeetingAgenda';
import { Editor } from 'react-draft-wysiwyg';
import './MeetingAgendaItem.scss';
import UploadToolbar, { FileObj } from '../../todo/uploadToolbar/UploadToolbar';

/** interface to describe MeetingAgendaItem */
interface MeetingAgendaItemProps {
  index: number;
  agendaState: AgendaStateItem[];
  onAgendaChange: (requested: AgendaStateItem[]) => void;
  onBlurHandler: () => void;
}

const MeetingAgendaItem: React.FC<MeetingAgendaItemProps> = (
  props: MeetingAgendaItemProps
) => {
  const { index, agendaState, onAgendaChange, onBlurHandler } = props;
  const richTextRef = React.useRef<any>(null);

  /** title change handler */
  const onTitleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modifiedState = [...agendaState];
    modifiedState[index] = {
      title: event.target.value,
      description: modifiedState[index].description,
      attachments: modifiedState[index].attachments,
    };
    onAgendaChange(modifiedState);
  };

  /** description change handler */
  const onDescriptionChangeHandler = (value: any) => {
    const modifiedState = [...agendaState];
    modifiedState[index] = {
      title: modifiedState[index].title,
      description: value,
      attachments: modifiedState[index].attachments,
    };
    onAgendaChange(modifiedState);
  };

  /** attachments change handler */
  const onAttachmentsChangeHandler = (attachments: FileObj[]) => {
    const modifiedState = [...agendaState];
    modifiedState[index] = {
      title: modifiedState[index].title,
      description: modifiedState[index].description,
      attachments,
    };
    onAgendaChange(modifiedState);
  };

  const onEnter = () => {
    setTimeout(() => {
      if (richTextRef.current) {
        richTextRef.current.focus();
      }
    }, 0);
  };

  const setEditorReference = (ref: any) => {
    richTextRef.current = ref;
  };

  return (
    <div className="MeetingAgendaItem-container">
      <Input
        value={agendaState[index]['title']}
        onChange={onTitleChangeHandler}
        placeholder="Agenda Title"
        onBlur={onBlurHandler}
        onPressEnter={onEnter}
      />
      <Editor
        editorRef={setEditorReference}
        defaultEditorState={agendaState[index]['description']}
        onEditorStateChange={onDescriptionChangeHandler}
        toolbar={{
          options: ['history', 'inline', 'link', 'list'],
          history: { options: ['undo', 'redo'] },
          inline: { options: ['bold', 'italic', 'underline'] },
          link: { options: ['link'] },
          list: {
            options: ['ordered', 'unordered', 'indent', 'outdent'],
          },
        }}
        placeholder="Agenda Details"
        onBlur={onBlurHandler}
        toolbarCustomButtons={[
          <UploadToolbar
            fileList={agendaState[index]['attachments'] || []}
            setFileList={onAttachmentsChangeHandler}
            key="upload"
          />,
        ]}
      />
    </div>
  );
};

export default MeetingAgendaItem;
