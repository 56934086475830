import React from 'react';
import SettingsHeader from '../../components/settings/settingsHeader/SettingsHeader';
import SettingsSider from '../../components/settings/settingsSider/SettingsSider';
import './withSettingsLayout.scss';

/**
 * withSettingsLayout hoc method wraps the passing component with todo header and sider
 * @param {React.ComponentType<ComponentPropsType>} Component - a component that needs the todo layout
 * @returns a component containing all the todo layout components as well as the passed component
 */
function withSettingsLayout<ComponentPropsType>(
  Component: React.ComponentType<ComponentPropsType>
) {
  return (props: ComponentPropsType) => {
    {
      return (
        <div className="withSettingsLayout-container">
          <div className="withSettingsLayout-header">
            <SettingsHeader />
          </div>
          <div className="withSettingsLayout-main-container">
            <div className="withSettingsLayout-body">
              <div className="withSettingsLayout-sider">
                <SettingsSider />
              </div>
              <div className="withSettingsLayout-content">
                <Component {...props} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default withSettingsLayout;
