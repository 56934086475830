import { Button, DatePicker, InputNumber, Select, Spin } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import RecurrenceDayPicker from '../../components/todo/recurrenceDayPicker/RecurrenceDayPicker';
import RecurrenceMonthPicker, {
  RecurrenceMonthValue,
} from '../../components/todo/recurrenceMonthPicker/RecurrenceMonthPicker';
import RecurrenceWeekPicker, {
  RecurrenceWeekValue,
} from '../../components/todo/recurrenceWeekPicker/RecurrenceWeekPicker';
import RecurrenceYearPicker, {
  RecurrenceYearValue,
} from '../../components/todo/recurrenceYearPicker/RecurrenceYearPicker';
import { SERVER_TODO_BASE_ENDPOINT } from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import {
  DAY_UNIT,
  MONTH_UNIT,
  RECURRENCE_UNIT_OPTIONS,
  WEEK_UNIT,
  YEAR_UNIT,
} from './constants';
import './RecurrencePicker.scss';
import { v4 as uuid } from 'uuid';
import { setRecurring } from '../../store/ducks/tasks';
import { connect } from 'react-redux';

interface RecurrencePickerProps {
  taskId: string;
  closeHandler: () => void;
  backHandler: () => void;
  setRecurringActionCreator: typeof setRecurring;
  onRecurringChange: (requested: 0 | 1) => void;
}

const RecurrencePicker: React.FC<RecurrencePickerProps> = (
  props: RecurrencePickerProps
) => {
  const { Option } = Select;
  const {
    closeHandler,
    backHandler,
    taskId,
    setRecurringActionCreator,
    onRecurringChange,
  } = props;

  /** states */

  /** manages the start date */
  const [startDate, setStartDate] = React.useState<moment.Moment>(moment());
  /** manages the end date */
  const [endDate, setEndDate] = React.useState<moment.Moment>(moment());
  /** manages the interval of the recurrence */
  const [interval, setInterval] = React.useState<number>(1);
  /** manages the type of recurrence unit */
  const [unit, setUnit] = React.useState<
    DAY_UNIT | WEEK_UNIT | MONTH_UNIT | YEAR_UNIT
  >(DAY_UNIT);
  /** holds the daily recurrence values */
  const [dailyValues, setDailyValues] = React.useState<moment.Moment[]>([]);
  /** holds the monthly recurrence values */
  const [weeklyValues, setWeeklyValues] = React.useState<RecurrenceWeekValue>(
    {}
  );
  /** holds the monthly recurrence values */
  const [monthlyValues, setMonthlyValues] = React.useState<
    Array<RecurrenceMonthValue | null>
  >([null]);
  /** holds the yearly recurrence values */
  const [yearlyValues, setYearlyValues] = React.useState<
    Array<RecurrenceYearValue | null>
  >([null]);
  /** manages the loading of recurrence values from server */
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchRecurrence = async () => {
      try {
        const response = await axioService(
          GET,
          `${SERVER_TODO_BASE_ENDPOINT}/task/${taskId}/recurrences`,
          {},
          true
        );
        if (response.data.data[0]['unit'] === DAY_UNIT) {
          setUnit(DAY_UNIT);
          setStartDate(
            moment(response.data.data[0]['started_at'], 'YYYY-MM-DD')
          );
          setEndDate(moment(response.data.data[0]['ended_at'], 'YYYY-MM-DD'));
          setInterval(response.data.data[0]['interval']);
          setDailyValues(
            response.data.data.map((iterDate: any) => {
              return moment(iterDate.time, 'HH:mm');
            })
          );
        } else if (response.data.data[0]['unit'] === WEEK_UNIT) {
          setUnit(WEEK_UNIT);
          setStartDate(
            moment(response.data.data[0]['started_at'], 'YYYY-MM-DD')
          );
          setEndDate(moment(response.data.data[0]['ended_at'], 'YYYY-MM-DD'));
          setInterval(response.data.data[0]['interval']);
          const tmpValues: any = {};
          response.data.data.forEach((iterWeek: any) => {
            const iterValue = iterWeek.value.toString();
            if (Object.keys(tmpValues).includes(iterValue)) {
              tmpValues[iterValue].push(moment(iterWeek.time, 'HH:mm'));
            } else {
              tmpValues[iterValue] = [moment(iterWeek.time, 'HH:mm')];
            }
          });
          setWeeklyValues(tmpValues);
        } else if (response.data.data[0]['unit'] === MONTH_UNIT) {
          setUnit(MONTH_UNIT);
          setStartDate(
            moment(response.data.data[0]['started_at'], 'YYYY-MM-DD')
          );
          setEndDate(moment(response.data.data[0]['ended_at'], 'YYYY-MM-DD'));
          setInterval(response.data.data[0]['interval']);
          const tmpValues: any = [];
          response.data.data.forEach((iterMonth: any) => {
            const iterValue = iterMonth.value.toString();

            if (lodash.find(tmpValues, { key: iterValue })) {
              const tmpMonthItem = lodash.find(tmpValues, {
                key: iterValue,
              });
              tmpMonthItem['value'].push(moment(iterMonth.time, 'HH:mm'));
            } else {
              tmpValues.push({
                key: iterMonth.value.toString(),
                value: [moment(iterMonth.time, 'HH:mm')],
              });
            }
          });

          setMonthlyValues(tmpValues);
        } else if (response.data.data[0]['unit'] === YEAR_UNIT) {
          setUnit(YEAR_UNIT);
          setStartDate(
            moment(response.data.data[0]['started_at'], 'YYYY-MM-DD')
          );
          setEndDate(moment(response.data.data[0]['ended_at'], 'YYYY-MM-DD'));
          setInterval(response.data.data[0]['interval']);
          let tmpValues: any = [];
          response.data.data.forEach((iterYear: any) => {
            const iterValue = iterYear.value.toString();
            if (lodash.find(tmpValues, { key: iterValue })) {
              const tmpMonthItem = lodash.find(tmpValues, {
                key: iterValue,
              });
              tmpMonthItem['value'].push(moment(iterYear.time, 'HH:mm'));
            } else {
              tmpValues.push({
                key: iterValue,
                value: [moment(iterYear.time, 'HH:mm')],
              });
            }
          });
          tmpValues = tmpValues.map((iterYearItem: any) => {
            return {
              key: moment(iterYearItem.key, 'MM-DD').year(3020).toString(),
              value: iterYearItem.value,
            };
          });
          setYearlyValues(tmpValues);
        }
        setLoading(false);
      } catch (exception) {
        console.error(exception);
        setLoading(false);
      }
    };

    fetchRecurrence();
  }, []);

  /** handlers */

  const onStartDateChange = (requested: moment.Moment | null) => {
    if (requested) {
      setStartDate(requested);
      if (endDate.isBefore(requested)) {
        setEndDate(requested);
      }
    }
  };

  const onEndDateChange = (requested: moment.Moment | null) => {
    if (requested) {
      setEndDate(requested);
    }
  };

  const dailyChangeHandler = (requested: moment.Moment[]) =>
    setDailyValues(requested);

  const weeklyChangeHandler = (requested: RecurrenceWeekValue) =>
    setWeeklyValues(requested);

  const monthlyChangeHandler = (
    requested: Array<RecurrenceMonthValue | null>
  ) => setMonthlyValues(requested);

  const yearlyChangeHandler = (requested: Array<RecurrenceYearValue | null>) =>
    setYearlyValues(requested);

  const onIntervalChange = (requested: number | string | undefined) => {
    if (requested) {
      setInterval(
        typeof requested === 'string' ? parseInt(requested) : requested
      );
    }
  };

  const onUnitChange = (
    requested: DAY_UNIT | WEEK_UNIT | MONTH_UNIT | YEAR_UNIT
  ) => {
    if (requested === DAY_UNIT) {
      setEndDate(moment(startDate).add(1, 'days'));
    } else if (requested === WEEK_UNIT) {
      setEndDate(moment(startDate).add(1, 'weeks'));
    } else if (requested === MONTH_UNIT) {
      setEndDate(moment(startDate).add(1, 'months'));
    } else if (requested === YEAR_UNIT) {
      setEndDate(moment(startDate).add(1, 'years'));
    }
    setUnit(requested);
    setDailyValues([]);
    setWeeklyValues({});
    setMonthlyValues([null]);
    setYearlyValues([null]);
  };

  const doneHandler = async () => {
    let requestBody: any = [];
    const metaBody = {
      subject_type: 'task',
      subject_id: taskId,
      unit: unit,
      interval: interval,
      started_at: startDate.format('YYYY-MM-DD'),
      ended_at: endDate.format('YYYY-MM-DD'),
    };

    if (unit === DAY_UNIT) {
      requestBody = dailyValues.map((iterVal) => ({
        ...metaBody,
        id: uuid(),
        time: iterVal.format('HH:mm'),
      }));
    } else if (unit === WEEK_UNIT) {
      Object.keys(weeklyValues).forEach((iterWeek) => {
        weeklyValues[iterWeek].forEach((iterVal) => {
          requestBody.push({
            ...metaBody,
            id: uuid(),
            value: iterWeek,
            time: iterVal.format('HH:mm'),
          });
        });
      });
    } else if (unit === MONTH_UNIT) {
      lodash.without(monthlyValues, null).forEach((iterMonth: any) => {
        iterMonth.value.forEach((iterVal: any) => {
          requestBody.push({
            ...metaBody,
            id: uuid(),
            value: iterMonth.key,
            time: iterVal.format('HH:mm'),
          });
        });
      });
    } else if (unit === YEAR_UNIT) {
      lodash.without(yearlyValues, null).forEach((iterYear: any) => {
        iterYear.value.forEach((iterVal: any) => {
          requestBody.push({
            ...metaBody,
            id: uuid(),
            value: moment(iterYear.key).format('MM-DD'),
            time: iterVal.format('HH:mm'),
          });
        });
      });
    }

    try {
      await axioService(
        POST,
        `${SERVER_TODO_BASE_ENDPOINT}/task/${taskId}/recurrences`,
        { recurrences: requestBody },
        true
      );
      if (requestBody.length > 0) {
        setRecurringActionCreator([taskId], 1);
        onRecurringChange(1);
      } else {
        setRecurringActionCreator([taskId], 0);
        onRecurringChange(0);
      }
      closeHandler();
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Spin
      wrapperClassName="RecurrencePicker-spinning-container"
      spinning={loading}
    >
      <div className="RecurrencePicker-container">
        <div className="RecurrencePicker-head">
          <i
            onClick={backHandler}
            className="fas fa-arrow-left RecurrencePicker-back"
          />{' '}
          Set Custom Repeat
        </div>
        <div className="RecurrencePicker-body">
          <div className="RecurrencePicker-row">
            <div className="RecurrencePicker-label">Every</div>
            <div>
              <InputNumber
                className="RecurrencePicker-interval"
                min={1}
                value={interval}
                onChange={onIntervalChange}
              />
            </div>
            <div className="RecurrencePicker-unit-container">
              <Select
                value={unit}
                onChange={onUnitChange}
                className="RecurrencePicker-unit"
                dropdownClassName="RecurrencePicker-unit-dropdown"
                suffixIcon={<i className="fas fa-chevron-down" />}
              >
                {RECURRENCE_UNIT_OPTIONS.map((iterUnitOption) => (
                  <Option
                    className="RecurrencePicker-unit-option"
                    key={'recurrence-' + iterUnitOption}
                    value={iterUnitOption}
                  >
                    {iterUnitOption}
                    {unit === iterUnitOption && <i className="fas fa-check" />}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="RecurrencePicker-row">
            <div className="RecurrencePicker-label">Start Date</div>
            <div>
              <DatePicker
                className="RecurrencePicker-datepicker"
                dropdownClassName="RecurrencePicker-datepicker-dropdown"
                allowClear={false}
                value={startDate}
                onChange={onStartDateChange}
                format="Do MMM, YYYY"
                disabledDate={(currentDate) =>
                  currentDate.isBefore(moment().subtract(1, 'day'))
                }
                suffixIcon={<i className="fas fa-calendar-alt" />}
              />
            </div>
          </div>
          {unit === DAY_UNIT && (
            <RecurrenceDayPicker
              values={dailyValues}
              onChangeHandler={dailyChangeHandler}
            />
          )}
          {unit === WEEK_UNIT && (
            <RecurrenceWeekPicker
              values={weeklyValues}
              onChangeHandler={weeklyChangeHandler}
            />
          )}
          {unit === MONTH_UNIT && (
            <RecurrenceMonthPicker
              values={monthlyValues}
              onChangeHandler={monthlyChangeHandler}
            />
          )}
          {unit === YEAR_UNIT && (
            <RecurrenceYearPicker
              values={yearlyValues}
              onChangeHandler={yearlyChangeHandler}
            />
          )}
          <div className="RecurrencePicker-row">
            <div className="RecurrencePicker-label">End Date</div>
            <div>
              <DatePicker
                allowClear={false}
                className="RecurrencePicker-datepicker"
                dropdownClassName="RecurrencePicker-datepicker-dropdown"
                value={endDate}
                format="Do MMM, YYYY"
                onChange={onEndDateChange}
                disabledDate={(currentDate) => currentDate.isBefore(startDate)}
                suffixIcon={<i className="fas fa-calendar-alt" />}
              />
            </div>
          </div>
          <div className="RecurrencePicker-btn-container">
            <div
              onClick={closeHandler}
              className="RecurrencePicker-cancel-btn"
              key="0"
            >
              Cancel
            </div>
            <Button
              onClick={doneHandler}
              className="RecurrencePicker-done-btn"
              key="1"
              disabled={taskId === ''}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setRecurringActionCreator: setRecurring,
};

/** connect RecurrencePicker to the redux store */
const ConnectedRecurrencePicker = connect(
  null,
  mapDispatchToProps
)(RecurrencePicker);

/** allow import as default component*/
export default ConnectedRecurrencePicker;
