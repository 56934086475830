import { Collapse, message } from 'antd';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import lodash from 'lodash';
import React from 'react';
import { MEETING_ACTIVTY_TYPE } from '../../../configs/activityTypes';
import { SERVER_TASK_TYPES_DETAILS_ENDPOINT } from '../../../configs/endpoints';
import { axioService, GET, POST } from '../../../services/axioService';
import { FileObj } from '../../todo/uploadToolbar/UploadToolbar';
import MeetingEventTitle from '../meetingEventTitle/MeetingEventTitle';
import MeetingWorkflowItem from '../meetingWorkflowItem/MeetingWorkflowItem';
import './MeetingWorkflow.scss';

/** interface to describe MailDataItem */
interface MailDataItem {
  type: string;
  subject: string;
  description: any;
  duration: string;
  relative: string;
  attachments: FileObj[];
}

/** interface to describe MeetingWorkflow props */
interface MeetingWorkflowProps {
  taskId: string;
}

/** mail type constants */
const HOST = 'host';
const INVITEE = 'invitee';
const GRATITUDE = 'gratitude';

/** initial mail data value */
const INITIAL_WORKFLOW_DATA: MailDataItem[] = [
  {
    type: HOST,
    subject: '',
    duration: '30',
    relative: 'before_meeting',
    description: EditorState.createEmpty(),
    attachments: [],
  },
  {
    type: INVITEE,
    subject: '',
    duration: '30',
    relative: 'before_meeting',
    description: EditorState.createEmpty(),
    attachments: [],
  },
  {
    type: GRATITUDE,
    subject: '',
    duration: '30',
    relative: 'after_meeting',
    description: EditorState.createEmpty(),
    attachments: [],
  },
];

const MeetingWorkflow: React.FC<MeetingWorkflowProps> = (
  props: MeetingWorkflowProps
) => {
  const { taskId } = props;
  const { Panel } = Collapse;

  /** manages the workflow state data */
  const [workflowData, setWorkFlowData] = React.useState<MailDataItem[]>(
    INITIAL_WORKFLOW_DATA
  );

  const commitRef = React.useRef<any>(null);

  /** cycles */

  /** fetch workflow data from server */
  React.useEffect(() => {
    const fetchWorkflowData = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASK_TYPES_DETAILS_ENDPOINT,
          { type: MEETING_ACTIVTY_TYPE.value, task_id: taskId },
          true
        );
        if (response.data.workflow) {
          setWorkFlowData(
            response.data.workflow.map((iter: any) => ({
              ...iter,
              description:
                (response.data.event?.description &&
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(iter.description as any) as any
                    )
                  )) ||
                EditorState.createEmpty(),
            }))
          );
        }
      } catch (Exception) {
        console.error(Exception);
      }
    };
    fetchWorkflowData();
    return () => {
      if (commitRef.current) {
        commitRef.current();
      }
    };
  }, []);

  /** onChange handler related to mail data item */
  const onChangeHandler = (key: string, requestedData: MailDataItem) => {
    const modifiedState = lodash.filter(
      workflowData,
      (iter: MailDataItem) => iter.type !== key
    );
    setWorkFlowData([...modifiedState, requestedData]);
  };

  /** onBlur handler */
  const onBlurHandler = async () => {
    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          workflow: workflowData.map((iter) => ({
            ...iter,
            description: stateToHTML(
              iter.description.getCurrentContent()
            ).toString(),
          })),
        },
        true
      );
      message.success('Saved');
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
  };

  commitRef.current = onBlurHandler;

  return (
    <div className="MeetingWorkflow-container">
      <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="right">
        <Panel
          header={
            <MeetingEventTitle
              color="#5389f4"
              icon="fas fa-bell"
              title="Email Reminder to Host"
              subtitle="Write your email or keep it as it is to send default mail"
            />
          }
          key="1"
        >
          <MeetingWorkflowItem
            mailData={lodash.find(workflowData, { type: HOST }) as MailDataItem}
            onChangeHandler={(requested: MailDataItem) =>
              onChangeHandler(HOST, requested)
            }
            onBlurHandler={onBlurHandler}
          />
        </Panel>
        <Panel
          header={
            <MeetingEventTitle
              color="#b15adc"
              icon="fas fa-volume-up"
              title="Email Reminder to Invitee"
              subtitle="Write your email or keep it as it is to send default mail"
            />
          }
          key="2"
        >
          <MeetingWorkflowItem
            mailData={
              lodash.find(workflowData, { type: INVITEE }) as MailDataItem
            }
            onChangeHandler={(requested: MailDataItem) =>
              onChangeHandler(INVITEE, requested)
            }
            onBlurHandler={onBlurHandler}
          />
        </Panel>
        <Panel
          header={
            <MeetingEventTitle
              color="#63b955"
              icon="far fa-thumbs-up"
              title="Send Thanks mail to Invitee"
              subtitle="Write your email or keep it as it is to send default mail"
            />
          }
          key="3"
        >
          <MeetingWorkflowItem
            mailData={
              lodash.find(workflowData, { type: GRATITUDE }) as MailDataItem
            }
            onChangeHandler={(requested: MailDataItem) =>
              onChangeHandler(GRATITUDE, requested)
            }
            onBlurHandler={onBlurHandler}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default MeetingWorkflow;
