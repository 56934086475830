import React from 'react';
import FavouritesItem, {
  TodoFavourtiesItemProps,
} from '../../components/todo/todoFavouritesItem/TodoFavouritesItem';
import './TodoFavourites.scss';
import { connect } from 'react-redux';
import { getProjects, ProjectObj } from '../../store/ducks/projects';
import { Store } from 'redux';
import { getFavourites } from '../../services/utility';
import {
  getPendingTasksCountByLabelId,
  getPendingTasksCountByProjectId,
  getPendingTasksCountofToday,
  getPendingTasksCountofTomorrow,
} from '../../store/ducks/tasks';
import { getLabels, LabelObj } from '../../store/ducks/labels';
import { getTodoFilters, TodoFilterObj } from '../../store/ducks/todoFilters';
import {
  LOCAL_INBOX_ENDPOINT,
  LOCAL_PRE_FILTER_ENDPOINT,
  LOCAL_PRE_LABEL_ENDPOINT,
  LOCAL_PRE_PROJECT_ENDPOINT,
  LOCAL_TODAY_ENDPOINT,
  LOCAL_UPCOMING_ENDPOINT,
} from '../../configs/endpoints';
import lodash from 'lodash';

interface TodoFavouritesProps {
  favouriteProjects: ProjectObj[];
  favouriteLabels: LabelObj[];
  favouriteFilters: TodoFilterObj[];
  todayCount: number;
  tomorrowCount: number;
  getProjectTasksCount: (projectId: string) => number;
  getLabelTasksCount: (labelId: string) => number;
}

const DEFAULT_OPTIONS: Array<TodoFavourtiesItemProps> = [
  {
    label: 'Inbox',
    count: 0,
    icon: <i className="fas fa-inbox"></i>,
    link: LOCAL_INBOX_ENDPOINT,
  },
  {
    label: 'Today',
    count: 0,
    icon: <i className="fas fa-clock"></i>,
    link: LOCAL_TODAY_ENDPOINT,
  },
  {
    label: 'Upcoming',
    count: 0,
    icon: <i className="fas fa-calendar-alt"></i>,
    link: LOCAL_UPCOMING_ENDPOINT,
  },
];

const TodoFavourites: React.FC<TodoFavouritesProps> = (
  props: TodoFavouritesProps
) => {
  const {
    favouriteProjects,
    favouriteLabels,
    favouriteFilters,
    todayCount,
    tomorrowCount,
    getProjectTasksCount,
    getLabelTasksCount,
  } = props;
  return (
    <div className="TodoFavourites-container">
      {DEFAULT_OPTIONS.map(
        (favouriteItem: TodoFavourtiesItemProps, index: number) => (
          <FavouritesItem
            key={'todo-favourite-item-' + index}
            label={favouriteItem.label}
            count={
              index == 0
                ? getProjectTasksCount('')
                : index == 1
                ? todayCount
                : tomorrowCount
            }
            icon={favouriteItem.icon}
            link={favouriteItem.link}
          />
        )
      )}
      {favouriteProjects.map((favouriteItem: ProjectObj, index: number) => (
        <FavouritesItem
          key={'todo-favourite-project-' + index}
          isShared={favouriteItem.sharedWith.length > 1}
          label={favouriteItem.title}
          count={getProjectTasksCount(favouriteItem.id)}
          icon={
            <div
              className="TodoFavourites-project-dot"
              style={{ background: favouriteItem.color }}
            />
          }
          link={LOCAL_PRE_PROJECT_ENDPOINT + favouriteItem.id}
          projectInfo={favouriteItem}
        />
      ))}
      {favouriteLabels.map((favouriteItem: LabelObj, index: number) => (
        <FavouritesItem
          key={'todo-favourite-label-' + index}
          label={favouriteItem.title}
          count={getLabelTasksCount(favouriteItem.id)}
          icon={
            <i
              className="fas fa-tag"
              style={{ color: favouriteItem.color }}
            ></i>
          }
          link={LOCAL_PRE_LABEL_ENDPOINT + favouriteItem.id}
          labelInfo={favouriteItem}
        />
      ))}
      {favouriteFilters.map((favouriteItem: TodoFilterObj, index: number) => (
        <FavouritesItem
          key={'todo-favourite-label-' + index}
          label={favouriteItem.title}
          /** TODO: Need to fix the filter count when it is implemented */
          count={0}
          icon={
            <i
              className="fas fa-tint TodoFavourites-drop"
              style={{ color: favouriteItem.color }}
            ></i>
          }
          link={LOCAL_PRE_FILTER_ENDPOINT + favouriteItem.id}
          filterInfo={favouriteItem}
        />
      ))}
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  favouriteProjects: ProjectObj[];
  favouriteLabels: LabelObj[];
  favouriteFilters: TodoFilterObj[];
  todayCount: number;
  tomorrowCount: number;
  getProjectTasksCount: (projectId: string) => number;
  getLabelTasksCount: (labelId: string) => number;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    favouriteProjects: getFavourites(
      lodash.filter(getProjects(state), { deleted: false })
    ),
    favouriteLabels: getFavourites(
      lodash.filter(getLabels(state), { deleted: false })
    ),
    favouriteFilters: getFavourites(
      lodash.filter(getTodoFilters(state), { deleted: false })
    ),
    todayCount: getPendingTasksCountofToday(state),
    tomorrowCount: getPendingTasksCountofTomorrow(state),
    getProjectTasksCount: (projectId: string) =>
      getPendingTasksCountByProjectId(state, projectId),
    getLabelTasksCount: (labelId: string) =>
      getPendingTasksCountByLabelId(state, labelId),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoFavourites to the redux store */
const ConnectedTodoFavourites = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoFavourites);

export default ConnectedTodoFavourites;
