import { Button, Dropdown } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ProjectOptionsPanel from '../projects/projectOptionsPanel/ProjectOptionsPanel';
import './TodoFavouritesItem.scss';
import { ProjectObj } from '../../../store/ducks/projects';
import { LabelObj } from '../../../store/ducks/labels';
import TodoLabelOptionsPanel from '../labels/labelOptionsPanel/TodoLabelOptionsPanel';
import { TodoFilterObj } from '../../../store/ducks/todoFilters';
import TodoFilterOptionsPanel from '../filters/filterOptionsPanel/TodoFilterOptionsPanel';
import { disablePointerEventsOnMainLayout } from '../../../services/utility';

/** interface to describe TodoFavourtiesItemProps */
export interface TodoFavourtiesItemProps {
  icon: any;
  label: string;
  count: number;
  link: string;
  isShared?: boolean;
  projectInfo?: ProjectObj;
  labelInfo?: LabelObj;
  filterInfo?: TodoFilterObj;
}

const TodoFavouritesItem: React.FC<TodoFavourtiesItemProps> = (
  props: TodoFavourtiesItemProps
) => {
  const {
    isShared,
    icon,
    label,
    count,
    link,
    projectInfo,
    labelInfo,
    filterInfo,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [visible, setVisible] = React.useState<boolean>(false);

  /** navigates to the respective link on click */
  const navigateHandler = () => history.push(link);

  /**
   * handles the enter key request
   * @param {React.KeyboardEvent} event - the keyboard event
   */
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigateHandler();
    }
  };

  /** handles the closing of panel */
  const panelCloseHandler = () => {
    setVisible(false);
    disablePointerEventsOnMainLayout(false);
  };

  return (
    <div
      onClick={navigateHandler}
      onKeyPress={handleKeyPress}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={clsx({
        'TodoFavouritesItem-container': true,
        'TodoFavouritesItem-container--active': location.pathname === link,
      })}
      onContextMenu={(event: any) => {
        event.preventDefault();
        if (projectInfo || labelInfo || filterInfo) {
          setVisible(true);
          disablePointerEventsOnMainLayout(true);
        }
      }}
    >
      <div>{icon}</div>
      <div className="TodoFavouritesItem-label">
        {label}{' '}
        {isShared && (
          <i className="fas fa-share-alt TodoFavouritesItem-share"></i>
        )}
      </div>
      <div>{count}</div>
      {(projectInfo || labelInfo || filterInfo) && (
        <div
          className={clsx({
            'TodoFavouritesItem-more-btn-container': true,
            'TodoFavouritesItem-more-btn-container--active': visible,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            overlay={
              projectInfo ? (
                <ProjectOptionsPanel
                  projectInfo={projectInfo}
                  panelCloseHandler={panelCloseHandler}
                />
              ) : labelInfo ? (
                <TodoLabelOptionsPanel
                  labelInfo={labelInfo}
                  panelCloseHandler={panelCloseHandler}
                />
              ) : (
                <TodoFilterOptionsPanel
                  filterInfo={filterInfo as TodoFilterObj}
                  panelCloseHandler={panelCloseHandler}
                />
              )
            }
            placement="bottomRight"
            trigger={['click']}
            visible={visible}
            onVisibleChange={(requestedVisibility: boolean) => {
              setVisible(requestedVisibility);
              disablePointerEventsOnMainLayout(requestedVisibility);
            }}
          >
            <Button
              className="TodoFavouritesItem-more-btn"
              shape="circle"
              size="small"
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default TodoFavouritesItem;
