import { Button } from 'antd';
import React from 'react';
import './TodoLabelFavouriteButton.scss';
import { connect } from 'react-redux';
import { LabelObj, setLabel } from '../../store/ducks/labels';

/** interface to describe TodoLabelFavouriteButton props */
interface TodoLabelFavouriteButtonProps {
  labelInfo: LabelObj;
  setLabelActionCreator: typeof setLabel;
  closeHandler: () => void;
}

/** Placeholders subject to Favourite flag */
const ADD_TO_FAVOURITE_LABEL = 'Add to favourites';
const REMOVE_FROM_FAVOURITE_LABEL = 'Remove from favourites';

const TodoLabelFavouriteButton: React.FC<TodoLabelFavouriteButtonProps> = (
  props: TodoLabelFavouriteButtonProps
) => {
  const { labelInfo, setLabelActionCreator, closeHandler } = props;

  /** manages the favourite toogle request */
  const favouriteHandler = () => {
    setLabelActionCreator({
      ...labelInfo,
      synced: false,
      isFavourite: !labelInfo.isFavourite,
    });
    closeHandler();
  };

  return (
    <Button
      onClick={favouriteHandler}
      icon={
        labelInfo.isFavourite ? (
          <i className="fas fa-heart-broken"></i>
        ) : (
          <i className="fas fa-heart"></i>
        )
      }
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="TodoLabelOptionsPanel-item"
    >
      {labelInfo.isFavourite
        ? REMOVE_FROM_FAVOURITE_LABEL
        : ADD_TO_FAVOURITE_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setLabelActionCreator: setLabel,
};

/** connect TodoLabelFavouriteButton to the redux store */
const ConnectedTodoLabelFavouriteButton = connect(
  null,
  mapDispatchToProps
)(TodoLabelFavouriteButton);

export default ConnectedTodoLabelFavouriteButton;
