import { Tooltip } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import { getDateFormat, is12HourFormat } from '../../store/ducks/settings';
import './TaskCompletionDateTag.scss';

interface TaskCompletionDateTagProps {
  dueDate: string;
  dueTime: string;
  dateFormat: string;
  use12HourFormat: boolean;
}

const INTERNAL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const TaskCompletionDateTag: React.FC<TaskCompletionDateTagProps> = (
  props: TaskCompletionDateTagProps
) => {
  const { dueDate, dueTime, dateFormat, use12HourFormat } = props;
  const completeDate = moment(
    dueDate + ' ' + dueTime,
    INTERNAL_DATE_TIME_FORMAT
  );
  return dueDate && dueDate !== '' ? (
    <Tooltip
      placement="bottom"
      title={completeDate.format(
        use12HourFormat ? dateFormat + ' hh:mm A' : dateFormat + ' HH:mm'
      )}
    >
      <div
        className={clsx({
          'TaskCompletionDateTag-container': true,
          'TaskCompletionDateTag-this-week': moment().isSame(
            completeDate,
            'week'
          ),
          'TaskCompletionDateTag-today': moment().isSame(completeDate, 'day'),
          'TaskCompletionDateTag-tomorrow': moment().isSame(
            moment(dueDate + ' ' + dueTime, INTERNAL_DATE_TIME_FORMAT).subtract(
              1,
              'day'
            ),
            'day'
          ),
          'TaskCompletionDateTag-due': moment().isAfter(completeDate),
        })}
      >
        {moment().isSame(completeDate, 'day')
          ? completeDate.fromNow()
          : completeDate.calendar(null, {
              sameElse: function (now) {
                return (this as any).isSame(now, 'year')
                  ? 'D MMM, hh:mm A'
                  : 'D MMM YYYY, hh:mm A';
              },
              lastWeek: '[Last] ddd,  hh:ss a',
              nextWeek: 'ddd,  hh:ss a',
              nextDay: '[Tomorrow], hh:ss a',
              lastDay: '[Yesterday], hh:ss a',
            })}
      </div>
    </Tooltip>
  ) : null;
};

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  dateFormat: string;
  use12HourFormat: boolean;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    dateFormat: getDateFormat(state),
    use12HourFormat: is12HourFormat(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TaskCompletionDateTag to the redux store */
const ConnectedTaskCompletionDateTag = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskCompletionDateTag);

export default ConnectedTaskCompletionDateTag;
