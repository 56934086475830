import { Button, Divider } from 'antd';
import React from 'react';
import './TodoFilterOptionsPanel.scss';
import { TodoFilterObj } from '../../../../store/ducks/todoFilters';
import ConnectedAddTodoFilter from '../../../../containers/addTodoFilter/AddTodoFilter';
import ConnectedTodoFilterFavouriteButton from '../../../../containers/todoFilterFavouriteButton/TodoFilterFavouriteButton';
import ConnectedTodoFilterDeleteButton from '../../../../containers/todoFilterDeleteButton/TodoFilterDeleteButton';

/** interface to describe TodoFilterOptionsPanel props */
interface TodoFilterOptionsPanelProps {
  filterInfo: TodoFilterObj;
  panelCloseHandler: () => void;
}

const TodoFilterOptionsPanel: React.FC<TodoFilterOptionsPanelProps> = (
  props: TodoFilterOptionsPanelProps
) => {
  const { filterInfo, panelCloseHandler } = props;

  /** states */

  /** manages the visiblity of modal */
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  /** handlers */

  /** handles the request of closing modal */
  const modalCloseHandler = () => setIsModalOpen(false);

  /** handles the request of opening modal */
  const modalOpenHandler = () => setIsModalOpen(true);

  /** handles the edit request on project */
  const editHandler = () => {
    modalOpenHandler();
    panelCloseHandler();
  };

  return (
    <div className="TodoFilterOptionsPanel-container">
      <ConnectedTodoFilterFavouriteButton
        filterInfo={filterInfo}
        closeHandler={panelCloseHandler}
      />
      <Button
        onClick={editHandler}
        icon={<i className="fas fa-user-edit"></i>}
        className="TodoFilterOptionsPanel-item"
      >
        Edit Filter
      </Button>
      <Divider type="horizontal" />
      <ConnectedTodoFilterDeleteButton
        filterInfo={filterInfo}
        closeHandler={panelCloseHandler}
      />
      <ConnectedAddTodoFilter
        initialUserInput={filterInfo}
        visible={isModalOpen}
        closeHandler={modalCloseHandler}
      />
    </div>
  );
};

/** allow default import */
export default TodoFilterOptionsPanel;
