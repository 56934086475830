import { Avatar, Button, Dropdown, Input, message, Tooltip } from 'antd';
import React from 'react';
import './AddTask.scss';
import TodoCalendarPicker from '../../components/todo/todoCalendarPicker/TodoCalendarPicker';
import ConnectedTodoProjectDropdownPicker from '../todoProjectDropdownPicker/TodoProjectDropdownPicker';
import TodoPriorityDropdown from '../../components/todo/todoPriorityDropdown/TodoPriorityDropdown';
import { connect } from 'react-redux';
import {
  fixTaskOrders,
  getNewTasksOrderIndex,
  setTask,
  TaskObj,
  TimeReminderObj,
} from '../../store/ducks/tasks';
import {
  LOW_PRIORITY_VALUE,
  MIDDLE_PRIORITY_VALUE,
  NO_PRIORITY_VALUE,
  TOP_PRIORITY_VALUE,
} from '../../components/todo/todoPriorityDropdown/constants';
import moment from 'moment';
import { Store } from 'redux';
import TaskReminderPicker from '../../components/reminders/taskReminderPicker/TaskReminderPicker';
import ConnectedTodoLabelDropdownPicker from '../todoLabelDropdownPicker/TodoLabelDropdownPicker';
import ConnectedTodoTaskProjectTag from '../todoTaskProjectTag/TodoTaskProjectTag';
import ConnectedTodoTaskLabelTag from '../todoTaskLabelTag/TodoTaskLabelTag';
import clsx from 'clsx';
import {
  disablePointerEventsOnMainLayout,
  disablePointerEventsOnTaskSider,
  generateNewUUID,
  transferSubTaskListByParent,
} from '../../services/utility';
import {
  getDefaultNextWeekDay,
  is12HourFormat,
} from '../../store/ducks/settings';
import lodash from 'lodash';
import { getProjectById, ProjectObj } from '../../store/ducks/projects';
import ConnectedTodoAssigneeDropdownPicker from '../todoAssigneeDropdownPicker/TodoAssigneeDropdownPicker';
import {
  CollaboratorObj,
  getCollaboratorById,
} from '../../store/ducks/collaborators';
import { syncService } from '../../services/syncService';
import TodoActivityTypeDropdownPicker from '../../components/todo/todoActivityTypeDropdownPicker/TodoActivityTypeDropdownPicker';
import TodoTaskActivityTag from '../../components/activities/todoTaskActivityTag/TodoTaskActivityTag';
import { INTERNAL_DATE_TIME_FORMAT } from '../../configs/constants';
import { MEETING_ACTIVTY_TYPE } from '../../configs/activityTypes';

/** type definition */

/** interface to describe AddTask props */
interface AddTaskProps {
  taskInfo?: Partial<TaskObj>;
  projectId: string;
  sectionId: string;
  use12HourFormat: boolean;
  defaultNextWeekDay: string;
  autoSave?: boolean;
  getOrderIndex: (projectId: string, sectionId: string) => number;
  setTaskActionCreator: typeof setTask;
  postSuccessHandler?: () => void;
  postCloseHandler?: () => void;
  getProjectInfoById: (projectId: string) => ProjectObj | null;
  getAssigneeInfoById: (assigneeId: string) => CollaboratorObj | null;
  submitLabel?: string;
  fixTaskOrdersActionCreator: typeof fixTaskOrders;
}

/** constants */

/** string format of the date */
const DATE_FORMAT = 'YYYY-MM-DD';

/** the initial user input of add task */
const INITIAL_USER_INPUT: TaskObj = {
  id: '',
  title: '',
  projectId: '',
  sectionId: '',
  description: '',
  dueDate: '',
  dueTime: '12:00',
  priority: NO_PRIORITY_VALUE,
  parent: '',
  order: -1,
  expanded: true,
  completed: false,
  labels: [],
  reminders: [],
  synced: false,
  deleted: false,
  assignedTo: '',
  isRecurring: 0,
  referenceId: null,
  recurrenceId: null,
  isExists: 1,
  recurringScheduleDate: null,
  commentsCount: 0,
  activityType: '',
  endDate: '',
  endTime: '12:00',
};

const AddTask: React.FC<AddTaskProps> = (props: AddTaskProps) => {
  const {
    setTaskActionCreator,
    defaultNextWeekDay,
    projectId,
    sectionId,
    taskInfo,
    submitLabel,
    use12HourFormat,
    autoSave,
    getProjectInfoById,
    getAssigneeInfoById,
    fixTaskOrdersActionCreator,
    postSuccessHandler,
    postCloseHandler,
  } = props;

  const presetTaskInfo = taskInfo ? taskInfo : {};

  const roundedUp = Math.ceil(moment().minute() / 15) * 15;
  const defaultTaskCompletionTime = moment()
    .minute(roundedUp)
    .second(0)
    .format('HH:mm');

  const modifiedInitialUserInput: TaskObj = {
    ...INITIAL_USER_INPUT,
    dueTime: defaultTaskCompletionTime,
    ...presetTaskInfo,
    projectId,
    sectionId,
  };

  /** manages the text area reference */
  const textAreaRef = React.useRef<any>(null);

  // component states

  /** general */

  /** manages the user inputs */
  const [userInput, setUserInput] = React.useState<TaskObj>(
    modifiedInitialUserInput
  );

  /** calendar picker*/

  /** manages the calendar temporary date input state */
  const [selectedCalendarDate, setSelectedCalendarDate] = React.useState<
    moment.Moment
  >(
    userInput.dueDate === '' ? moment() : moment(userInput.dueDate, DATE_FORMAT)
  );

  /** manages the calendar temporary time input state */
  const [selectedCalendarTime, setSelectedCalendarTime] = React.useState<
    string
  >(userInput.dueDate === '' ? defaultTaskCompletionTime : userInput.dueTime);

  /** manages the calendar temporary  end date input state */
  const [selectedCalendarEndDate, setSelectedCalendarEndDate] = React.useState<
    moment.Moment
  >(
    userInput.endDate === '' ? moment() : moment(userInput.endDate, DATE_FORMAT)
  );

  /** manages the calendar temporary  end time input state */
  const [selectedCalendarEndTime, setSelectedCalendarEndTime] = React.useState<
    string
  >(userInput.endDate === '' ? defaultTaskCompletionTime : userInput.endTime);

  /** manages the calendar picker visibility */
  const [calendarVisible, setCalendarVisible] = React.useState<boolean>(false);

  /** projects */

  /** manages the projects picker visibility */
  const [projectsDropdownVisible, setProjectsDropdownVisible] = React.useState<
    boolean
  >(false);

  /** manages the projects label visibility */
  const [labelsDropdownVisible, setLabelsDropdownVisible] = React.useState<
    boolean
  >(false);

  /** priority */

  /** manages the priority picker visibility */
  const [priorityDropdownVisible, setPriorityDropdownVisible] = React.useState<
    boolean
  >(false);

  /** assignee */

  /** manages the assignee picker visibility */
  const [assigneeDropdownVisible, setAssigneeDropdownVisible] = React.useState<
    boolean
  >(false);

  /** activity type */

  /** manages the assignee picker visibility */
  const [
    activityTypeDropdownVisible,
    setActivityTypeDropdownVisible,
  ] = React.useState<boolean>(false);

  /** reminders */

  /** manages the reminders picker visibility */
  const [reminderVisible, setReminderVisible] = React.useState<boolean>(false);

  // cycles

  /** resets user input handler on change of project id or section id */
  React.useEffect(() => {
    setUserInput(modifiedInitialUserInput);
  }, [
    projectId,
    sectionId,
    taskInfo?.id,
    taskInfo?.dueDate,
    taskInfo?.dueTime,
  ]);

  // handlers

  /** general */

  /**
   * onSumitHandler handles the task create request
   */
  const onSubmitHandler = () => {
    if (
      userInput.title === '' ||
      JSON.stringify(modifiedInitialUserInput) == JSON.stringify(userInput)
    ) {
      return;
    }
    if (userInput.id !== '' && userInput.projectId !== projectId) {
      transferSubTaskListByParent(userInput.id, userInput.projectId);
    }
    setTaskActionCreator({
      ...userInput,
      synced: false,
      id: userInput.id === '' ? generateNewUUID() : userInput.id,
      parent: userInput.projectId !== projectId ? '' : userInput.parent,
      order: userInput.projectId !== projectId ? -1 : userInput.order,
    });
    fixTaskOrdersActionCreator(
      modifiedInitialUserInput.projectId,
      modifiedInitialUserInput.sectionId,
      userInput.parent
    );
    if (userInput.projectId !== projectId) {
      fixTaskOrdersActionCreator(userInput.projectId, '', '');
    }
    message.success('Task saved sucessfully');

    setUserInput(modifiedInitialUserInput);

    /** if task is non exisiting, sync immediately */
    syncService();

    if (postSuccessHandler) {
      postSuccessHandler();
    }
  };

  /** task title */

  /**
   * onTitleChange updates the title of user input on change
   * @param {React.ChangeEvent<HTMLInputElement>} event - the onchange event
   */
  const onTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput({ ...userInput, title: event.target.value.replace('\n', '') });
  };

  /** onBlur autosaves the title of task if autosave is true */
  const onTitleBlurHandler = () => {
    if (autoSave) {
      if (userInput.title === '') {
        setUserInput({ ...userInput, title: modifiedInitialUserInput.title });
      } else {
        setTaskActionCreator({
          ...userInput,
          synced: false,
        });
        syncService();
      }
    }
  };

  /** calendar picker */

  /** resets the calendar date */
  const resetCalendarDate = () => {
    setUserInput({
      ...userInput,
      dueDate: '',
      dueTime: defaultTaskCompletionTime,
    });
    setCalendarVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        dueDate: '',
        dueTime: defaultTaskCompletionTime,
      });
      syncService();
    }
  };

  /**
   * onDueDateChange updates the due date on change
   * @param {moment.Moment} selectedDate - the selected date to set
   * @param {string} selectedTime - the selected time to set
   */
  const onDueDateChange = (
    selectedDate: moment.Moment,
    selectedTime: string,
    selectedEndDate: moment.Moment,
    selectedEndTime: string
  ) => {
    setUserInput({
      ...userInput,
      dueDate: selectedDate.format(DATE_FORMAT),
      dueTime: selectedTime,
      endDate:
        userInput.activityType === MEETING_ACTIVTY_TYPE.value
          ? selectedEndDate.format(DATE_FORMAT)
          : '',
      endTime: selectedEndTime,
    });
    setCalendarVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        dueDate: selectedDate.format(DATE_FORMAT),
        dueTime: selectedTime,
        endDate:
          userInput.activityType === MEETING_ACTIVTY_TYPE.value
            ? selectedEndDate.format(DATE_FORMAT)
            : '',
        endTime: selectedEndTime,
      });
      syncService();
    }
  };

  /**
   * calendarCancelHandler handles the cancel request of calendar dropdown
   */
  const calendarCancelHandler = () => {
    setCalendarVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
  };

  /**
   * sets the calendar picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onCalendarVisibleChange = (visible: boolean) => {
    setCalendarVisible(visible);
    setSelectedCalendarDate(
      userInput.dueDate === ''
        ? moment()
        : moment(userInput.dueDate, DATE_FORMAT)
    );
    setSelectedCalendarTime(userInput.dueTime);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /** reminder picker */

  /**
   * onRemindersChange updates the reminders on change
   * @param {Array<TimeReminderObj>} reminders - the reminders to set
   */
  const onRemindersChange = (reminders: Array<TimeReminderObj>) => {
    setUserInput({ ...userInput, reminders });
    setReminderVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        reminders,
      });
      syncService();
    }
  };

  /**
   * reminderCancelHandler handles the cancel request of reminder dropdown
   */
  const reminderCancelHandler = () => {
    setReminderVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
  };

  /**
   * sets the reminder picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onReminderVisibleChange = (visible: boolean) => {
    if (visible && userInput.dueDate === '') {
      message.info('Please select a due date first');
      return;
    }
    setReminderVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /** projects picker */

  /**
   * sets the project id on change
   * @param {string} selectedProjectId - the selected project id
   */
  const onProjectIdChange = (selectedProjectId: string) => {
    setUserInput({
      ...userInput,
      projectId: selectedProjectId,
      /** change the section id if project is switched */
      sectionId: selectedProjectId !== projectId ? '' : sectionId,
      assignedTo: getProjectInfoById(selectedProjectId)?.sharedWith.includes(
        userInput.assignedTo
      )
        ? userInput.assignedTo
        : '',
    });
    setProjectsDropdownVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        projectId: selectedProjectId,
        /** change the section id if project is switched */
        sectionId: selectedProjectId !== projectId ? '' : sectionId,
        assignedTo: getProjectInfoById(selectedProjectId)?.sharedWith.includes(
          userInput.assignedTo
        )
          ? userInput.assignedTo
          : '',
      });
      fixTaskOrdersActionCreator(
        modifiedInitialUserInput.projectId,
        modifiedInitialUserInput.sectionId,
        userInput.parent
      );
      if (selectedProjectId !== projectId) {
        fixTaskOrdersActionCreator(selectedProjectId, '', '');
      }
      syncService();
    }
  };

  /**
   * sets the projects picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onProjectsDropdownVisibleChange = (visible: boolean) => {
    setProjectsDropdownVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /** labels picker */

  /**
   * sets the labels on change
   * @param {string[]} selectedLabelIds - the selected label ids
   */
  const onLabelsChange = (selectedLabelIds: string[]) => {
    setUserInput({ ...userInput, labels: selectedLabelIds });
    setLabelsDropdownVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        labels: selectedLabelIds,
      });
      syncService();
    }
  };

  /**
   * sets the labels picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onLabelsDropdownVisibleChange = (visible: boolean) => {
    setLabelsDropdownVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /** priority picker */

  /**
   * sets the priority on change
   * @param {string} selectedPriority - the selected priority
   */
  const onPriorityChange = (selectedPriority: string) => {
    setUserInput({ ...userInput, priority: selectedPriority });
    setPriorityDropdownVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        priority: selectedPriority,
      });
      syncService();
    }
  };

  /**
   * sets the priority picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onPriorityDropdownVisibleChange = (visible: boolean) => {
    setPriorityDropdownVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  const getPickedDateLabel = (
    interestedDueDate: string,
    interestedDueTime: string
  ) => {
    const completeDate = moment(
      interestedDueDate + ' ' + interestedDueTime,
      INTERNAL_DATE_TIME_FORMAT
    );
    return moment().isSame(completeDate, 'day')
      ? completeDate.fromNow()
      : completeDate.calendar(null, {
          sameElse: function (now) {
            return (this as any).isSame(now, 'year')
              ? 'D MMM, hh:mm A'
              : 'D MMM YYYY, hh:mm A';
          },
        });
  };

  /** assignee picker */

  /**
   * sets the assignee on change
   * @param {string} selectedAssignee - the selected assignee
   */
  const onAssigneeChange = (selectedAssignee: string) => {
    setUserInput({ ...userInput, assignedTo: selectedAssignee });
    setAssigneeDropdownVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        assignedTo: selectedAssignee,
      });
      syncService();
    }
  };

  /**
   * sets the assignee picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onAssigneeDropdownVisibleChange = (visible: boolean) => {
    setAssigneeDropdownVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /** activity type picker */

  /**
   * sets the activity id on change
   * @param {string} selectedActivityType - the selected activity type
   */
  const onActivityTypeChange = (selectedActivityType: string) => {
    setUserInput({ ...userInput, activityType: selectedActivityType });
    setActivityTypeDropdownVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        activityType: selectedActivityType,
        endDate:
          selectedActivityType === MEETING_ACTIVTY_TYPE.value
            ? userInput.endDate
            : '',
      });
      syncService();
    }
  };

  /**
   * sets the activity type picker visibility
   * @param {boolean} visible - the requested visibility on the picker
   */
  const onActivityTypeDropdownVisibleChange = (visible: boolean) => {
    setActivityTypeDropdownVisible(visible);
    disablePointerEventsOnMainLayout(visible);
    disablePointerEventsOnTaskSider(visible);
  };

  /**
   * sets the recurring status
   * @param {0 | 1} requestedStatus - the requested recurring status
   */
  const onRecurringChange = (requestedStatus: 0 | 1) => {
    setUserInput({ ...userInput, isRecurring: requestedStatus });

    if (autoSave) {
      setTaskActionCreator({
        ...userInput,
        synced: false,
        isRecurring: requestedStatus,
      });
      syncService();
    }
  };

  const selectedProjectInfo = getProjectInfoById(userInput.projectId) || null;
  const assignee = getAssigneeInfoById(userInput.assignedTo);

  /** opens the reminder picker */
  const openReminderHandler = () => {
    setReminderVisible(true);
    disablePointerEventsOnMainLayout(true);
    disablePointerEventsOnTaskSider(true);
  };

  /** focuses the text area on div click */
  const setFocusOnTextArea = () => {
    textAreaRef.current?.focus();
  };

  return (
    <div className="AddTask-container">
      <div onClick={setFocusOnTextArea} className="AddTask-input-container">
        <div style={{ position: 'relative' }}>
          <div>
            <Input.TextArea
              ref={textAreaRef}
              value={userInput.title}
              autoSize
              placeholder="Write your task here"
              onChange={onTitleChange}
              onPressEnter={onSubmitHandler}
              onBlur={onTitleBlurHandler}
              maxLength={250}
              tabIndex={0}
            />
          </div>
          <div
            className="AddTask-calendar-container"
            onClick={(event: React.MouseEvent) => event.stopPropagation()}
          >
            <div>
              <Dropdown
                overlay={
                  <TodoCalendarPicker
                    taskType={userInput.activityType}
                    taskId={userInput.id}
                    opened={calendarVisible}
                    defaultNextWeekDay={defaultNextWeekDay}
                    selectedTime={selectedCalendarTime}
                    timeChangeHandler={(requestedTime: string) =>
                      setSelectedCalendarTime(requestedTime)
                    }
                    selectedEndTime={selectedCalendarEndTime}
                    endTimeChangeHandler={(requestedTime: string) =>
                      setSelectedCalendarEndTime(requestedTime)
                    }
                    use12HourFormat={use12HourFormat}
                    selectedDate={selectedCalendarDate}
                    selectedEndDate={selectedCalendarEndDate}
                    selectedDateChangeHandler={setSelectedCalendarDate}
                    selectedEndDateChangeHandler={setSelectedCalendarEndDate}
                    confirmHandler={onDueDateChange}
                    cancelHandler={calendarCancelHandler}
                    resetCalendarDate={resetCalendarDate}
                    openReminderHandler={openReminderHandler}
                    onRecurringChange={onRecurringChange}
                  />
                }
                placement="bottomRight"
                trigger={['click']}
                visible={calendarVisible}
                onVisibleChange={onCalendarVisibleChange}
              >
                <Tooltip title="Add Due Date" placement="bottom">
                  <Button>
                    <i className="fas fa-calendar-alt"></i>
                  </Button>
                </Tooltip>
              </Dropdown>
            </div>
          </div>
          <div className="AddTask-meta-container">
            <div className="AddTask-meta-tags">
              <ConnectedTodoTaskProjectTag id={userInput.projectId} />
              {userInput.labels.map((iterlabel: string, index: number) => (
                <ConnectedTodoTaskLabelTag
                  id={iterlabel}
                  key={iterlabel + '-label-' + index}
                />
              ))}
            </div>
            {!calendarVisible && userInput.dueDate && (
              <div className="AddTask-meta-date">
                {getPickedDateLabel(userInput.dueDate, userInput.dueTime)}
              </div>
            )}
            {calendarVisible && selectedCalendarDate && (
              <div className="AddTask-meta-date">
                {getPickedDateLabel(
                  selectedCalendarDate.format(DATE_FORMAT),
                  selectedCalendarTime
                )}
              </div>
            )}
            {userInput.activityType !== '' && (
              <TodoTaskActivityTag title={userInput.activityType} />
            )}
          </div>
        </div>
      </div>
      <div className="AddTask-options-container">
        {!(
          (getProjectInfoById(
            taskInfo?.projectId ? taskInfo.projectId : ''
          ) as any)?.sharedWith.length > 1
        ) && (
          <div className="AddTask-options-item">
            <Dropdown
              overlay={
                <ConnectedTodoProjectDropdownPicker
                  selected={userInput.projectId}
                  selectedHandler={onProjectIdChange}
                />
              }
              placement="bottomLeft"
              trigger={['click']}
              visible={projectsDropdownVisible}
              onVisibleChange={onProjectsDropdownVisibleChange}
            >
              <Tooltip title="Select a project" placement="bottom">
                <Button>
                  <i className="fas fa-tasks"></i>
                </Button>
              </Tooltip>
            </Dropdown>
          </div>
        )}

        <div className="AddTask-options-item">
          <Dropdown
            overlay={
              <ConnectedTodoLabelDropdownPicker
                selected={userInput.labels}
                selectedHandler={onLabelsChange}
              />
            }
            placement="bottomLeft"
            trigger={['click']}
            visible={labelsDropdownVisible}
            onVisibleChange={onLabelsDropdownVisibleChange}
          >
            <Tooltip title="Add label(s)" placement="bottom">
              <Button>
                <i className="fas fa-tag"></i>
                <span className="AddTask-labels-count">
                  {userInput.labels.length > 0 && userInput.labels.length}
                </span>
              </Button>
            </Tooltip>
          </Dropdown>
        </div>

        <div className="AddTask-options-item">
          <Dropdown
            overlay={
              <TodoPriorityDropdown
                selected={userInput.priority}
                selectedHandler={onPriorityChange}
              />
            }
            placement="bottomLeft"
            trigger={['click']}
            visible={priorityDropdownVisible}
            onVisibleChange={onPriorityDropdownVisibleChange}
          >
            <Tooltip
              title={
                <div className="AddTask-priority-tooltip">
                  Set the priority - <span>Top</span>, <span>Middle</span>,{' '}
                  <span>Low</span>, <span>No</span>
                </div>
              }
              placement="bottom"
            >
              <Button>
                <i
                  className={clsx({
                    fas: true,
                    'fa-flag': true,
                    'AddTask-top-priority':
                      userInput.priority === TOP_PRIORITY_VALUE,
                    'AddTask-middle-priority':
                      userInput.priority === MIDDLE_PRIORITY_VALUE,
                    'AddTask-low-priority':
                      userInput.priority === LOW_PRIORITY_VALUE,
                    'AddTask-no-priority':
                      userInput.priority === NO_PRIORITY_VALUE,
                  })}
                ></i>
              </Button>
            </Tooltip>
          </Dropdown>
        </div>

        <div className="AddTask-options-item">
          <Dropdown
            overlay={
              <TaskReminderPicker
                visible={reminderVisible}
                scheduledDate={
                  userInput.dueDate !== ''
                    ? moment(
                        userInput.dueDate + ' ' + userInput.dueTime,
                        INTERNAL_DATE_TIME_FORMAT
                      )
                    : null
                }
                reminders={userInput.reminders}
                remindersChangeHandler={onRemindersChange}
                cancelHandler={reminderCancelHandler}
              />
            }
            trigger={['click']}
            placement="bottomLeft"
            visible={reminderVisible}
            onVisibleChange={onReminderVisibleChange}
          >
            <Tooltip title="Add reminder(s)" placement="bottom">
              <Button>
                <i className="fas fa-stopwatch"></i>{' '}
                <span className="AddTask-reminders-count">
                  {lodash.filter(userInput.reminders, { deleted: false })
                    .length > 0 &&
                    lodash.filter(userInput.reminders, { deleted: false })
                      .length}
                </span>
              </Button>
            </Tooltip>
          </Dropdown>
        </div>

        <div className="AddTask-options-item">
          <Dropdown
            overlay={
              <TodoActivityTypeDropdownPicker
                selected={userInput.activityType}
                selectedHandler={onActivityTypeChange}
              />
            }
            trigger={['click']}
            placement="bottomLeft"
            visible={activityTypeDropdownVisible}
            onVisibleChange={onActivityTypeDropdownVisibleChange}
          >
            <Tooltip title="Select a task type" placement="bottom">
              <Button>
                <i className="fas fa-chalkboard-teacher"></i>
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
        {(selectedProjectInfo as any)?.sharedWith?.length > 1 && (
          <div className="AddTask-options-item">
            <Dropdown
              overlay={
                <ConnectedTodoAssigneeDropdownPicker
                  collaboratorIds={selectedProjectInfo?.sharedWith || []}
                  selected={userInput.assignedTo}
                  selectedHandler={onAssigneeChange}
                />
              }
              trigger={['click']}
              placement="bottomLeft"
              visible={assigneeDropdownVisible}
              onVisibleChange={onAssigneeDropdownVisibleChange}
            >
              <Tooltip title="Select an assignee" placement="bottom">
                <div className="AddTask-user-plus">
                  {assignee ? (
                    <Avatar size="small" src={assignee?.avatar}>
                      <div>{assignee?.name?.slice(0, 2)}</div>
                    </Avatar>
                  ) : (
                    <i className="fas fa-user-plus"></i>
                  )}
                </div>
              </Tooltip>
            </Dropdown>
          </div>
        )}
        <div className="AddTask-options-space" />
        {postCloseHandler && !autoSave && (
          <Button
            onClick={postCloseHandler}
            className="AddTask-btn AddTask-cancel-btn"
            type="primary"
          >
            Cancel
          </Button>
        )}
        {!autoSave && (
          <Button
            onClick={onSubmitHandler}
            className="AddTask-btn"
            type="primary"
            disabled={
              userInput.title === '' ||
              JSON.stringify(modifiedInitialUserInput) ==
                JSON.stringify(userInput)
            }
          >
            {submitLabel ? submitLabel : 'ADD TASK'}
          </Button>
        )}
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  getOrderIndex: (projectId: string, sectionId: string) => number;
  defaultNextWeekDay: string;
  use12HourFormat: boolean;
  getProjectInfoById: (projectId: string) => ProjectObj | null;
  getAssigneeInfoById: (assigneeId: string) => CollaboratorObj | null;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    getOrderIndex: (projectId: string, sectionId: string) =>
      getNewTasksOrderIndex(state, projectId, sectionId),

    use12HourFormat: is12HourFormat(state),
    getProjectInfoById: (projectId: string): ProjectObj | null =>
      getProjectById(state, projectId),
    defaultNextWeekDay: getDefaultNextWeekDay(state),
    getAssigneeInfoById: (assigneeId: string): CollaboratorObj | null =>
      getCollaboratorById(state, assigneeId),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTaskActionCreator: setTask,
  fixTaskOrdersActionCreator: fixTaskOrders,
};

/** connect AddTask to the redux store */
const ConnectedAddTask = connect(mapStateToProps, mapDispatchToProps)(AddTask);

/** allow import as default component*/
export default ConnectedAddTask;
