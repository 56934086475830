import React from 'react';
import './ProjectPicker.scss';
import { Button, Dropdown } from 'antd';
import { EllipsisOutlined, CheckOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { ProjectObj } from '../../../../store/ducks/projects';
import ConnectedTodoProjectDropdownPicker from '../../../../containers/todoProjectDropdownPicker/TodoProjectDropdownPicker';

/** interface to describe project picker props */
interface ProjectPickerProps {
  excludedProjectIds: [string];
  selectedProject: string;
  projectList: ProjectObj[];
  maxHeight: number;
  projectChangeHandler: (project: string) => void;
}

/** the project picker component */
const ProjectPicker: React.FC<ProjectPickerProps> = (
  props: ProjectPickerProps
) => {
  const {
    excludedProjectIds,
    projectList,
    selectedProject,
    projectChangeHandler,
    maxHeight,
  } = props;

  /** state */

  /** manages the visibility of the dropdown picker */
  const [visible, setVisible] = React.useState<boolean>(false);

  /**
   * onChangeHandler calls the projectChangeHandler on project change
   * @param {React.MouseEvent} event - the react mouse event
   */
  const onChangeHandler = (event: React.MouseEvent) => {
    if (selectedProject === (event.currentTarget as any).name) {
      projectChangeHandler('');
    } else {
      projectChangeHandler((event.currentTarget as any).name);
    }
  };

  /**
   * onVisibleChange sets the requested visibility of project dropdown picker
   * @param {boolean} requestedVisibility - the visibility that is to be set
   */
  const onVisibleChange = (requestedVisibility: boolean) => {
    setVisible(requestedVisibility);
  };

  /**
   * handles the dropdown select/deselect of project id
   * @param {string} requestedId - the requested project id to be set
   */
  const onDropdownChangeHandler = (requestedId: string) => {
    projectChangeHandler(requestedId);
    setVisible(false);
  };

  return (
    <div className="ProjectPicker-container">
      {projectList.slice(0, 10).map((project: ProjectObj, index: number) => (
        <Button
          key={'folder-btn-' + index}
          name={project.id}
          onClick={onChangeHandler}
          className={clsx({
            'ant-btn-selected': selectedProject === project.id,
          })}
        >
          {selectedProject === project.id && <CheckOutlined />} {project.title}
        </Button>
      ))}
      {projectList.length === 0 && (
        <div className="ProjectPicker-empty-projects">
          You have no projects yet
        </div>
      )}
      {projectList.length > 10 && (
        <Dropdown
          overlay={
            <ConnectedTodoProjectDropdownPicker
              excludedProjectIds={excludedProjectIds}
              maxHeight={maxHeight}
              selected={selectedProject}
              selectedHandler={onDropdownChangeHandler}
            />
          }
          placement="bottomRight"
          trigger={['click']}
          visible={visible}
          onVisibleChange={onVisibleChange}
        >
          <Button className="ProjectPicker-more-btn" shape="circle">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

/** the default export */
export default ProjectPicker;
