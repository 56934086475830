/** the day recurrence unit type */
export const DAY_UNIT = 'day';
export type DAY_UNIT = typeof DAY_UNIT;

/** the week recurrence unit type */
export const WEEK_UNIT = 'week';
export type WEEK_UNIT = typeof WEEK_UNIT;

/** the month recurrence unit type */
export const MONTH_UNIT = 'month';
export type MONTH_UNIT = typeof MONTH_UNIT;

/** the year recurrence unit type */
export const YEAR_UNIT = 'year';
export type YEAR_UNIT = typeof YEAR_UNIT;

export const RECURRENCE_UNIT_OPTIONS = [
  DAY_UNIT,
  WEEK_UNIT,
  MONTH_UNIT,
  YEAR_UNIT,
];
export type RECURRRENCE_UNIT_OPTIONS = typeof RECURRENCE_UNIT_OPTIONS;
