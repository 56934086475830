import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';
import lodash from 'lodash';
import moment from 'moment';

/** reminder types */

/** time reminder types */
export const TIME_REMINDER_TYPE = 'TIME_REMINDER';
export type TIME_REMINDER_TYPE = typeof TIME_REMINDER_TYPE;

/** location reminder types */
export const LOCATION_REMINDER_TYPE = 'LOCATION_REMINDER';
export type LOCATION_REMINDER_TYPE = typeof LOCATION_REMINDER_TYPE;

/** interface of Time Reminder object */
export interface TimeReminderObj {
  type: TIME_REMINDER_TYPE;
  before: number;
  deleted: boolean;
  id: string;
}

/** interface for task object */
export interface TaskObj {
  id: string;
  title: string;
  projectId: string;
  sectionId: string;
  priority: string;
  description: string;
  dueDate: string;
  dueTime: string;
  endDate: string;
  endTime: string;
  parent: string;
  order: number;
  expanded: boolean;
  completed: boolean;
  labels: string[];
  reminders: TimeReminderObj[];
  synced: boolean;
  deleted: boolean;
  assignedTo: string;
  isRecurring: 0 | 1;
  referenceId: string | null;
  recurrenceId: string | null;
  isExists: 0 | 1;
  recurringScheduleDate: string | null;
  commentsCount: number;
  activityType: string;
}

/** The reducer name */
export const reducerName = 'tasks';

// actions
/** action types */
export const SET_TASKS = 'virtunus/reducer/tasks/SET_TASKS';
export const SET_TASK = 'virtunus/reducer/tasks/SET_TASK';
export const UPDATE_TASKS = 'virtunus/reducer/tasks/UPDATE_TASKS';
export const FIX_TASK_ORDERS = 'virtunus/reducer/tasks/FIX_TASK_ORDERS';
export const DELETE_TASKS_BASED_ON_PROJECTS =
  'virtunus/reducer/tasks/DELETE_TASKS_BASED_ON_PROJECTS';
export const DELETE_TASKS_BASED_ON_LABELS =
  'virtunus/reducer/tasks/DELETE_TASKS_BASED_ON_LABELS';
export const DELETE_TASKS_BASED_ON_SECTIONS =
  'virtunus/reducer/tasks/DELETE_TASKS_BASED_ON_SECTIONS';
export const SET_TASKS_SYNCED_STATUS =
  'virtunus/reducer/tasks/SET_TASKS_SYNCED_STATUS';
export const REMOVE_USER_ASSIGNED_TASKS =
  'virtunus/reducer/tasks/REMOVE_USER_ASSIGNED_TASKS';
export const REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE =
  'virtunus/reducer/tasks/REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE';
export const INCREASE_COMMENT_COUNT =
  'virtunus/reducer/tasks/INCREASE_COMMENT_COUNT';
export const DECREASE_COMMENT_COUNT =
  'virtunus/reducer/tasks/DECREASE_COMMENT_COUNT';
export const SET_RECURRING = 'virtunus/reducer/tasks/SET_RECURRING';
export const REMOVE_SYNCED_COMPLETED_TASKS =
  'virtunus/reducer/tasks/REMOVE_SYNCED_COMPLETED_TASKS';

/** interface for SET_TASKS action */
export interface SetTasksAction extends AnyAction {
  tasks: TaskObj[];
  type: typeof SET_TASKS;
}

/** interface for SET_TASK action */
export interface SetTaskAction extends AnyAction {
  task: TaskObj;
  type: typeof SET_TASK;
}

/** interface for UPDATE_TASKS action */
export interface UpdateTasksAction extends AnyAction {
  tasks: TaskObj[];
  type: typeof UPDATE_TASKS;
}

/** interface for FIX_TASK_ORDERS action */
export interface FixTaskOrdersAction extends AnyAction {
  parentId: string;
  type: typeof FIX_TASK_ORDERS;
}

/** interface for DELETE_TASKS_BASED_ON_PROJECTS action */
export interface DeleteTasksBasedOnProjectsAction extends AnyAction {
  projectIds: string[];
  type: typeof DELETE_TASKS_BASED_ON_PROJECTS;
  status: boolean;
}

/** interface for DELETE_TASKS_BASED_ON_LABELS action */
export interface DeleteTasksBasedOnLabelsAction extends AnyAction {
  labelIds: string[];
  type: typeof DELETE_TASKS_BASED_ON_LABELS;
}

/** interface for DELETE_TASKS_BASED_ON_SECTIONS action */
export interface DeleteTasksBasedOnSectionsAction extends AnyAction {
  sectionIds: string[];
  type: typeof DELETE_TASKS_BASED_ON_SECTIONS;
}

/** interface for SET_TASKS_SYNCED_STATUS action */
export interface SetTasksSyncedStatusAction extends AnyAction {
  status: boolean;
  type: typeof SET_TASKS_SYNCED_STATUS;
  ids: string[];
}

/** interface for REMOVE_USER_ASSIGNED_TASKS action */
export interface RemoveUserAssignedTasksAction extends AnyAction {
  type: typeof REMOVE_USER_ASSIGNED_TASKS;
  userId: string;
  projectId: string;
}

/** interface for REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE action */
export interface RemoveNonExistingTasksBasedOnDate extends AnyAction {
  type: typeof REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE;
  dueDate: string;
}

/** interface for INCREASE_COMMENT_COUNT action */
export interface IncreaseCommentCountAction extends AnyAction {
  type: typeof INCREASE_COMMENT_COUNT;
  taskId: string;
}

/** interface for DECREASE_COMMENT_COUNT action */
export interface DecreaseCommentCountAction extends AnyAction {
  type: typeof DECREASE_COMMENT_COUNT;
  taskId: string;
}

/** interface for SET_RECURRING action */
export interface SetRecurringAction extends AnyAction {
  status: number;
  type: typeof SET_RECURRING;
  ids: string[];
}

/** interface for REMOVE_SYNCED_COMPLETED_TASKS action */
export interface RemoveSyncedCompletedTasksAction extends AnyAction {
  type: typeof REMOVE_SYNCED_COMPLETED_TASKS;
}

/** Create type for task reducer actions */
export type TasksActionTypes =
  | SetTasksAction
  | SetTaskAction
  | UpdateTasksAction
  | FixTaskOrdersAction
  | DeleteTasksBasedOnProjectsAction
  | DeleteTasksBasedOnLabelsAction
  | DeleteTasksBasedOnSectionsAction
  | SetTasksSyncedStatusAction
  | RemoveUserAssignedTasksAction
  | RemoveNonExistingTasksBasedOnDate
  | IncreaseCommentCountAction
  | DecreaseCommentCountAction
  | SetRecurringAction
  | RemoveSyncedCompletedTasksAction
  | AnyAction;

// action creators

/** set tasks action creator
 * @param {TaskObj[]} tasks - an array of task items
 * @returns {SetTasksAction} - an action to set tasks in store
 */
export const setTasks = (tasks: TaskObj[]): SetTasksAction => {
  return {
    tasks,
    type: SET_TASKS,
  };
};

/** set task action creator
 * @param {TaskObj} task - a task object
 * @returns {SetTaskAction} - an action to set task in store
 */
export const setTask = (task: TaskObj): SetTaskAction => ({
  task,
  type: SET_TASK,
});

/** update tasks action creator
 * @param {TaskObj[]} tasks - an array of task items
 * @returns {UpdateTasksAction} - an action to update tasks in store
 */
export const updateTasks = (tasks: TaskObj[]): UpdateTasksAction => {
  const ids = tasks.map((task: TaskObj) => task.id);
  return {
    tasks,
    ids,
    type: UPDATE_TASKS,
  };
};

/**
 * set the task orders correctly
 * @param {string} projectId - the project id
 * @param {string} sectionId - the section id
 * @param {string} parentId - the parent task id
 * @returns {FixTaskOrdersAction} - an action to fix the tasks order given the parent task id
 */
export const fixTaskOrders = (
  projectId: string,
  sectionId: string,
  parentId: string
): FixTaskOrdersAction => ({
  projectId,
  sectionId,
  parentId,
  type: FIX_TASK_ORDERS,
});

/**
 * delete the tasks based on the project ids
 * @param {string[]} projectIds - the project ids
 * @returns {DeleteTasksBasedOnProjectsAction} - an action to delete tasks based on project ids
 */
export const deleteTasksBasedOnProjects = (
  projectIds: string[],
  status?: boolean
): DeleteTasksBasedOnProjectsAction => ({
  projectIds,
  type: DELETE_TASKS_BASED_ON_PROJECTS,
  status: status || false,
});

/**
 * delete the tasks based on the label ids
 * @param {string[]} labelIds - the label ids
 * @returns {DeleteTasksBasedOnLabelsAction} - an action to delete tasks based on label ids
 */
export const deleteTasksBasedOnLabels = (
  labelIds: string[]
): DeleteTasksBasedOnLabelsAction => ({
  labelIds,
  type: DELETE_TASKS_BASED_ON_LABELS,
});

/**
 * delete the tasks based on the section ids
 * @param {string[]} sectionIds - the section ids
 * @returns {DeleteTasksBasedOnSectionsAction} - an action to delete tasks based on section ids
 */
export const deleteTasksBasedOnSections = (
  sectionIds: string[]
): DeleteTasksBasedOnSectionsAction => ({
  sectionIds,
  type: DELETE_TASKS_BASED_ON_SECTIONS,
});

/** set tasks synced status action creator
 * @param {string[]} ids - an array of task ids
 * @param {boolean} status - the synced status to set
 * @returns {SetTasksSyncedStatusAction} - an action to set tasks synced status in store
 */
export const setTasksSyncedStatus = (
  ids: string[],
  status: boolean
): SetTasksSyncedStatusAction => ({
  status,
  type: SET_TASKS_SYNCED_STATUS,
  ids,
});

/**
 * unassignes the assigned tasks of specified user in a project
 * @param {string} userId - the user id
 * @param {string} projectId = the project id
 * @returns {RemoveUserAssignedTasksAction} - an action to unassign tasks of specified user
 */
export const removeUserAssignedTasks = (
  userId: string,
  projectId: string
): RemoveUserAssignedTasksAction => ({
  type: REMOVE_USER_ASSIGNED_TASKS,
  userId,
  projectId,
});

/**
 * removes non existing tasks based on due date
 * @param {string} dueDate - the requested due date
 * @returns {RemoveNonExistingTasksBasedOnDate} - an action to remove non existing tasks based on due date
 */
export const removeNonExistingTasksBasedOnDate = (
  dueDate: string
): RemoveNonExistingTasksBasedOnDate => ({
  type: REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE,
  dueDate,
});

/**
 * increases the task comment count based on id
 * @param {string} taskId - the task id
 * @returns {IncreaseCommentCountAction} - an action to increase comment count based on task id
 */
export const increaseCommentCount = (
  taskId: string
): IncreaseCommentCountAction => ({
  type: INCREASE_COMMENT_COUNT,
  taskId,
});

/**
 * decreases the task comment count based on id
 * @param {string} taskId - the task id
 * @returns {DecreaseCommentCountAction} - an action to decrease comment count based on task id
 */
export const decreaseCommentCount = (
  taskId: string
): DecreaseCommentCountAction => ({
  type: DECREASE_COMMENT_COUNT,
  taskId,
});

/**
 * sets the recurring status on given tasks
 * @param  {string[]} ids - the task ids
 * @param {number} status - the status to set
 * @returns {SetRecurringAction} - an action to set the status of recurring
 */
export const setRecurring = (
  ids: string[],
  status: number
): SetRecurringAction => ({
  status,
  type: SET_RECURRING,
  ids,
});

/**
 * removes the synced completed tasks
 * @returns {RemoveSyncedCompletedTasksAction} - an action to remove synced completed tasks
 */
export const removeSyncedCompletedTasks = (): RemoveSyncedCompletedTasksAction => ({
  type: REMOVE_SYNCED_COMPLETED_TASKS,
});

// helpers

/**
 * checks whether the parent task is completed or not
 * @param tasksList - the store tasks list
 * @param requestedTaskId - the requested task id
 * @returns {boolean} - true if the initial parent task is completed or not
 */
const isParentCompleted = (
  tasksList: TaskObj[],
  requestedTaskId: string
): boolean => {
  const immediateParent = lodash.find(tasksList, { id: requestedTaskId });
  if (immediateParent && immediateParent.parent !== '') {
    return isParentCompleted(tasksList, immediateParent.parent);
  } else if (immediateParent && immediateParent.parent === '') {
    return immediateParent.completed;
  } else {
    return true; /** if parent not found, it is assumed to be completed */
  }
};

// the reducer

/** interface for tasks state in redux store */
type TasksState = TaskObj[];

/** Create an immutable tasks state */
export type ImmutableTasksState = SeamlessImmutable.ImmutableArray<TasksState>;

/** initial tasks state */
const initialState: ImmutableTasksState = SeamlessImmutable([]);

/** the tasks reducer function */
export default function reducer(
  state: ImmutableTasksState = initialState,
  action: TasksActionTypes
): ImmutableTasksState {
  switch (action.type) {
    case SET_TASKS:
      return SeamlessImmutable(action.tasks);
    case SET_TASK:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) => iterateTask.id !== action.task.id
        ),
        action.task,
      ]);
    case UPDATE_TASKS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) => !action.ids.includes(iterateTask.id)
        ),
        ...action.tasks,
      ]);
    case FIX_TASK_ORDERS:
      return SeamlessImmutable([
        ...lodash.filter(
          state as any,
          (iterateTask: TaskObj) =>
            !(
              iterateTask.parent === action.parentId &&
              iterateTask.projectId === action.projectId &&
              iterateTask.sectionId === action.sectionId
            ) || iterateTask.deleted === true
        ),
        ...lodash.map(
          lodash.orderBy(
            lodash.filter(
              state,
              (iterateTask: TaskObj) =>
                iterateTask.parent === action.parentId &&
                iterateTask.projectId === action.projectId &&
                iterateTask.sectionId === action.sectionId &&
                iterateTask.deleted === false
            ),
            ['completed', 'order'],
            ['asc', 'asc']
          ),
          (iterateTask: TaskObj, index: number) => ({
            ...iterateTask,
            synced:
              index === iterateTask.order && iterateTask.synced ? true : false,
            order: index,
          })
        ),
      ]);
    case DELETE_TASKS_BASED_ON_PROJECTS:
      return SeamlessImmutable([
        ...lodash.filter(
          state.asMutable({ deep: true }) as any,
          (iterateTask: TaskObj) =>
            !action.projectIds.includes(iterateTask.projectId) ||
            iterateTask.deleted === true
        ),
        ...lodash.map(
          lodash.filter(
            state.asMutable({ deep: true }),
            (iterateTask: TaskObj) =>
              action.projectIds.includes(iterateTask.projectId) &&
              iterateTask.deleted === false
          ),
          (iterateTask: TaskObj) => ({
            ...iterateTask,
            deleted: true,
            synced: action.status,
          })
        ),
      ]);
    case DELETE_TASKS_BASED_ON_LABELS:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return lodash.intersection(action.labelIds, iterateTask.labels)
            .length > 0 && iterateTask.deleted === false
            ? {
                ...iterateTask,
                synced: false,
                labels: lodash.difference(iterateTask.labels, action.labelIds),
              }
            : iterateTask;
        }) as any
      );
    case DELETE_TASKS_BASED_ON_SECTIONS:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return action.sectionIds.includes(iterateTask.sectionId) &&
            iterateTask.deleted === false
            ? {
                ...iterateTask,
                synced: false,
                deleted: true,
              }
            : iterateTask;
        }) as any
      );
    case SET_TASKS_SYNCED_STATUS:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return action.ids.includes(iterateTask.id)
            ? { ...iterateTask, synced: action.status }
            : iterateTask;
        }) as any
      );
    case REMOVE_USER_ASSIGNED_TASKS:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return iterateTask.assignedTo === action.userId &&
            iterateTask.projectId === action.projectId
            ? { ...iterateTask, synced: true, assignedTo: '' }
            : iterateTask;
        }) as any
      );
    case REMOVE_NON_EXISTING_TASKS_BASED_ON_DATE:
      return SeamlessImmutable(
        lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) =>
            !(
              iterateTask.isExists === 0 &&
              iterateTask.dueDate === action.dueDate
            )
        )
      ) as any;
    case INCREASE_COMMENT_COUNT:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return iterateTask.id === action.taskId
            ? { ...iterateTask, commentsCount: iterateTask.commentsCount + 1 }
            : iterateTask;
        }) as any
      );
    case DECREASE_COMMENT_COUNT:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return iterateTask.id === action.taskId
            ? {
                ...iterateTask,
                commentsCount:
                  iterateTask.commentsCount - 1 > 0
                    ? iterateTask.commentsCount - 1
                    : 0,
              }
            : iterateTask;
        }) as any
      );
    case SET_RECURRING:
      return SeamlessImmutable(
        lodash.map(state, (iterateTask: TaskObj) => {
          return action.ids.includes(iterateTask.id)
            ? {
                ...iterateTask,
                isRecurring: action.status,
              }
            : iterateTask;
        }) as any
      );
    case REMOVE_SYNCED_COMPLETED_TASKS:
      return SeamlessImmutable(
        lodash.filter(
          state.asMutable({ deep: true }),
          (iterateTask: TaskObj) =>
            !(
              iterateTask.completed &&
              iterateTask.synced &&
              isParentCompleted(
                state.asMutable({ deep: true }) as any,
                iterateTask.id
              )
            )
        )
      ) as any;
    default:
      return state;
  }
}

// selectors

/** returns the tasks list
 * @param {Partial<Store>} state - the redux store
 * @return { TaskObj[] } - the existing tasks
 */
export function getTasks(state: Partial<Store>): TaskObj[] {
  return (state as any)[reducerName];
}

/** returns the tasks list length
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing tasks length
 */
export function getTasksLength(state: Partial<Store>): number {
  return (state as any)[reducerName].length;
}

/** returns the next available order of new task
 * @param {Partial<Store>} state - the redux store
 * @param {projectId} string - project id
 * @param {sectionId} string - section id
 * @return { number } - the new available order index
 */
export function getNewTasksOrderIndex(
  state: Partial<Store>,
  projectId: string,
  sectionId: string
): number {
  return lodash.filter((state as any)[reducerName], { projectId, sectionId })
    .length;
}

/** returns the tasks related to project id and section id
 * @param {Partial<Store>} state - the redux store
 * @param {projectId} string - project id
 * @param {sectionId} string - section id
 * @return { TaskObj } - the tasks list related to project id and section id
 */
export function getProjectAndSectionBasedTasks(
  state: Partial<Store>,
  projectId: string,
  sectionId: string
): TaskObj[] {
  return lodash.filter((state as any)[reducerName], { projectId, sectionId });
}

/** returns the pending tasks count present in the project
 * @param {Partial<Store>} state - the redux store
 * @param {number} projectId - the requested project id
 * @return { number } - the existing pending tasks count
 */
export function getPendingTasksCountByProjectId(
  state: Partial<Store>,
  projectId: string
): number {
  return lodash.filter((state as any)[reducerName], {
    projectId,
    deleted: false,
    completed: false,
    isExists: 1,
  }).length;
}

/** returns the tasks present with the label id
 * @param {Partial<Store>} state - the redux store
 * @param {number} labelId - the requested label id
 * @return { number } - the existing tasks
 */
export function getTasksByLabelId(
  state: Partial<Store>,
  labelId: string
): TaskObj[] {
  return lodash.filter((state as any)[reducerName], (iterTask: TaskObj) =>
    iterTask.labels.includes(labelId)
  );
}

/** returns the pending tasks count present with the label id
 * @param {Partial<Store>} state - the redux store
 * @param {number} labelId - the requested label id
 * @return { number } - the existing pending tasks count
 */
export function getPendingTasksCountByLabelId(
  state: Partial<Store>,
  labelId: string
): number {
  return lodash.filter(
    (state as any)[reducerName],
    (iterTask: TaskObj) =>
      iterTask.labels.includes(labelId) &&
      iterTask.deleted === false &&
      iterTask.completed === false &&
      iterTask.isExists === 1
  ).length;
}

/** returns the task obj given task id if exists; otherwise, null
 * @param {Partial<Store>} state - the redux store
 * @param {string} taskId - the task id
 * @return { ProjectObj | null } - the existing task or null
 */
export function getTaskById(state: Partial<Store>, taskId: string): TaskObj {
  return lodash.find((state as any)[reducerName], { id: taskId }) || null;
}

/** returns the tasks satisfies the iterator
 * @param {Partial<Store>} state - the redux store
 * @param {(taskItem: TaskObj) => boolean} iterator - the requested iterator
 * @return { number } - the existing tasks
 */
export function getTasksByIterator(
  state: Partial<Store>,
  iterator: (taskItem: TaskObj) => boolean
): TaskObj[] {
  return lodash.filter((state as any)[reducerName], iterator);
}

/** returns the pending tasks count of today
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing pending tasks count
 */
export function getPendingTasksCountofToday(state: Partial<Store>): number {
  return lodash.filter(
    (state as any)[reducerName],
    (iterTask: TaskObj) =>
      moment(iterTask.dueDate).isSame(moment(), 'day') &&
      iterTask.deleted === false &&
      iterTask.completed === false &&
      iterTask.isExists === 1
  ).length;
}

/** returns the pending tasks count of tomorrow
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing pending tasks count
 */
export function getPendingTasksCountofTomorrow(state: Partial<Store>): number {
  return lodash.filter(
    (state as any)[reducerName],
    (iterTask: TaskObj) =>
      moment(iterTask.dueDate).isSame(moment().add(1, 'day'), 'day') &&
      iterTask.deleted === false &&
      iterTask.completed === false &&
      iterTask.isExists === 1
  ).length;
}

/** returns the overdue tasks count
 * @param {Partial<Store>} state - the redux store
 * @return { number } - the existing overdue tasks count
 */
export function getOverdueTasksCount(state: Partial<Store>): number {
  return lodash.filter(
    (state as any)[reducerName],
    (iterTask: TaskObj) =>
      moment(iterTask.dueDate).isBefore(moment(), 'day') &&
      iterTask.deleted === false &&
      iterTask.completed === false
  ).length;
}

/** returns the search tasks count
 * @param {Partial<Store>} state - the redux store
 * @param {string} keyword - the search keyword
 * @return { number } - the existing search tasks count
 */
export function getSearchTasksCount(
  state: Partial<Store>,
  keyword: string
): number {
  return lodash.filter(
    (state as any)[reducerName],
    (iterTask: TaskObj) =>
      iterTask.title.includes(keyword) &&
      iterTask.deleted === false &&
      iterTask.completed === false
  ).length;
}

/**
 * returns the list of unsynced tasks
 * @param {Partial<Store>} state - the redux store
 * @returns {TaskObj[]} - the list of unsynced tasks
 */
export function getUnsyncedTasks(state: Partial<Store>): TaskObj[] {
  return lodash.filter((state as any)[reducerName], { synced: false });
}
