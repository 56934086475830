import React from 'react';
import './ColorPicker.scss';
import { Checkbox, Button, Dropdown } from 'antd';
import { ChromePicker } from 'react-color';

/** the default color options */
export const DEFAULT_COLORS: Array<string> = [
  '#02aef0',
  '#1cbbb7',
  '#f26e7c',
  '#fcaf5c',
  '#f06eaa',
  '#8460aa',
  '#aed374',
  '#468bcc',
  '#a1410e',
  '#eb2c8c',
  '#87824b',
  '#998677',
  '#b7b7b7',
  '#ffad01',
];

/** interface to describe ColorPicker props */
interface ColorPicker {
  selectedColor: string;
  colorChangeHandler: (color: string) => void;
}

/** the color picker component */
const ColorPicker: React.FC<ColorPicker> = (props: ColorPicker) => {
  const { selectedColor, colorChangeHandler } = props;

  /** manages the visibility of the dropdown picker */
  const [visible, setVisible] = React.useState<boolean>(false);

  /**
   * onChangeHandler calls the colorChangeHandler on color change
   * @param event - the click event
   */
  const onChangeHandler = (event: any) => {
    colorChangeHandler(event.target.value);
  };

  /**
   * onChangeDropdownHandler handles the dropdown color change request
   * @param {any} color - the color object of the react color
   */
  const onChangeDropdownHandler = (color: any) => {
    colorChangeHandler(color.hex);
  };

  /**
   * onVisibleChange sets the requested visibility of project dropdown picker
   * @param {boolean} requestedVisibility - the visibility that is to be set
   */
  const onVisibleChange = (requestedVisibility: boolean) => {
    setVisible(requestedVisibility);
  };

  return (
    <div className="ColorPicker-container">
      {DEFAULT_COLORS.map((color: string, index: number) => (
        <Checkbox
          checked={selectedColor === color}
          value={color}
          onClick={onChangeHandler}
          key={'cp-' + index}
          style={{ '--cp-background': color } as any}
          tabIndex={0}
        />
      ))}
      {!DEFAULT_COLORS.includes(selectedColor) && selectedColor !== '' && (
        <Checkbox
          checked={true}
          value={selectedColor}
          onClick={onChangeHandler}
          style={{ '--cp-background': selectedColor } as any}
        />
      )}
      <Dropdown
        overlay={
          <ChromePicker
            className="ColorPicker-chrome-picker"
            color={selectedColor}
            onChangeComplete={onChangeDropdownHandler}
          />
        }
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        <Button className="ColorPicker-more-btn" shape="circle">
          {visible ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-ellipsis-h"></i>
          )}
        </Button>
      </Dropdown>
    </div>
  );
};

/** the default export */
export default ColorPicker;
