import React from 'react';
import { useDropzone } from 'react-dropzone';
import './FileInput.scss';

/** interface to describe FileInput props */
interface FileInputProps {
  addFilesHandler: (files: any) => void;
}

const FileInput: React.FC<FileInputProps> = (props: FileInputProps) => {
  const { addFilesHandler } = props;

  /** propagates the event of any file selected */
  const onDropAccepted = (files: any) => {
    addFilesHandler(files);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click behavior
    noClick: true,
    onDropAccepted,
  });

  return (
    <div className="FileInput-container">
      <div {...getRootProps({ className: 'FileInput-dropzone-container' })}>
        <div className="FileInput-dropzone-inner">
          <input {...getInputProps()} />
          <p>Drag and drop files here or browse</p>
          <button
            className="FileInput-computer-btn"
            type="button"
            onClick={open}
          >
            Computer
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileInput;
