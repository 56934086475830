import { TaskTimeReminderOption } from './TaskReminderSelect';

export const REMINDER_OPTIONS: TaskTimeReminderOption[] = [
  {
    label: '5 min before',
    value: 5,
  },
  { label: '15 min before', value: 15 },
  { label: '45 min before', value: 45 },
  { label: '1 hour before', value: 60 },
  { label: '1 day before', value: 1440 },
];
