import { Button, Divider } from 'antd';
import React from 'react';
import './ProjectOptionsPanel.scss';
import ConnectedAddProject from '../../../../containers/addProject/AddProject';
import { ProjectObj } from '../../../../store/ducks/projects';
import TodoProjectFavouriteButton from '../../../../containers/todoProjectFavouriteButton/todoProjectFavouriteButton';
import ConnectedTodoProjectDeleteButton from '../../../../containers/todoProjectDeleteButton/TodoProjectDeleteButton';
import ConnectedShareProject from '../../../../containers/shareProject/ShareProject';

/** interface to describe ProjectOptionsPanel props */
interface ProjectOptionsPanelProps {
  projectInfo?: ProjectObj;
  panelCloseHandler: () => void;
  isTasksHidden?: boolean;
  toggleTaskVisibility?: () => void;
}

const ProjectOptionsPanel: React.FC<ProjectOptionsPanelProps> = (
  props: ProjectOptionsPanelProps
) => {
  const {
    projectInfo,
    panelCloseHandler,
    isTasksHidden,
    toggleTaskVisibility,
  } = props;

  /** states */

  /** manages the visiblity of modal */
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  /** manages the visiblity of share modal */
  const [isShareModalOpen, setIsShareModalOpen] = React.useState<boolean>(
    false
  );

  /** handlers */

  /** handles the request of closing modal */
  const modalCloseHandler = () => setIsModalOpen(false);

  /** handles the request of opening modal */
  const modalOpenHandler = () => setIsModalOpen(true);

  /** handles the edit request on project */
  const editHandler = () => {
    modalOpenHandler();
    panelCloseHandler();
  };

  /** handles the request of closing share modal */
  const shareModalCloseHandler = () => setIsShareModalOpen(false);

  /** handles the request of opening share modal */
  const shareModalOpenHandler = () => setIsShareModalOpen(true);

  /** handles the share request on project */
  const shareHandler = () => {
    shareModalOpenHandler();
    panelCloseHandler();
  };

  const visibilityHandler = () => {
    panelCloseHandler();
    toggleTaskVisibility && toggleTaskVisibility();
  };

  return (
    <div
      className="ProjectOptionsPanel-container"
      onContextMenu={(event: any) => {
        event.stopPropagation();
      }}
      onDoubleClick={(event: any) => {
        event.stopPropagation();
      }}
    >
      {projectInfo && (
        <TodoProjectFavouriteButton
          projectInfo={projectInfo}
          closeHandler={panelCloseHandler}
        />
      )}
      {projectInfo && (
        <Button
          onClick={shareHandler}
          icon={<i className="fas fa-share-alt"></i>}
          className="ProjectOptionsPanel-item"
        >
          Share Project
        </Button>
      )}
      {projectInfo && (
        <Button
          onClick={editHandler}
          icon={<i className="fas fa-user-edit"></i>}
          className="ProjectOptionsPanel-item"
        >
          Edit Project
        </Button>
      )}
      {toggleTaskVisibility && (
        <React.Fragment>
          {projectInfo && <Divider type="horizontal" />}
          <Button
            onClick={visibilityHandler}
            icon={
              isTasksHidden ? (
                <i className="fas fa-eye"></i>
              ) : (
                <i className="fas fa-eye-slash"></i>
              )
            }
            className="ProjectOptionsPanel-item ProjectOptionsPanel-tasks-visibility"
          >
            {isTasksHidden ? 'Show Completed Tasks' : 'Hide Completed Tasks'}
          </Button>
        </React.Fragment>
      )}
      {projectInfo && <Divider type="horizontal" />}
      {projectInfo && (
        <ConnectedTodoProjectDeleteButton
          projectInfo={projectInfo}
          closeHandler={panelCloseHandler}
        />
      )}
      {projectInfo && (
        <ConnectedAddProject
          initialUserInput={projectInfo}
          visible={isModalOpen}
          closeHandler={modalCloseHandler}
        />
      )}
      {projectInfo && (
        <ConnectedShareProject
          projectInfo={projectInfo}
          visible={isShareModalOpen}
          closeHandler={shareModalCloseHandler}
        />
      )}
    </div>
  );
};

/** allow default import */
export default ProjectOptionsPanel;
