import { Button, Divider } from 'antd';
import React from 'react';
import './TodoLabelOptionsPanel.scss';
import { LabelObj } from '../../../../store/ducks/labels';
import ConnectedAddTodoLabel from '../../../../containers/addTodoLabel/AddTodoLabel';
import ConnectedTodoLabelFavouriteButton from '../../../../containers/todoLabelFavouriteButton/TodoLabelFavouriteButton';
import ConnectedTodoLabelDeleteButton from '../../../../containers/todoLabelDeleteButton/TodoLabelDeleteButton';

/** interface to describe TodoLabelOptionsPanel props */
interface TodoLabelOptionsPanelProps {
  labelInfo: LabelObj;
  panelCloseHandler: () => void;
}

const TodoLabelOptionsPanel: React.FC<TodoLabelOptionsPanelProps> = (
  props: TodoLabelOptionsPanelProps
) => {
  const { labelInfo, panelCloseHandler } = props;

  /** states */

  /** manages the visiblity of modal */
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  /** handlers */

  /** handles the request of closing modal */
  const modalCloseHandler = () => setIsModalOpen(false);

  /** handles the request of opening modal */
  const modalOpenHandler = () => setIsModalOpen(true);

  /** handles the edit request on project */
  const editHandler = () => {
    modalOpenHandler();
    panelCloseHandler();
  };

  return (
    <div className="TodoLabelOptionsPanel-container">
      <ConnectedTodoLabelFavouriteButton
        labelInfo={labelInfo}
        closeHandler={panelCloseHandler}
      />
      <Button
        onClick={editHandler}
        icon={<i className="fas fa-user-edit"></i>}
        className="TodoLabelOptionsPanel-item"
      >
        Edit Label
      </Button>
      <Divider type="horizontal" />
      <ConnectedTodoLabelDeleteButton
        labelInfo={labelInfo}
        closeHandler={panelCloseHandler}
      />
      <ConnectedAddTodoLabel
        initialUserInput={labelInfo}
        visible={isModalOpen}
        closeHandler={modalCloseHandler}
      />
    </div>
  );
};

/** allow default import */
export default TodoLabelOptionsPanel;
