import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './Todo.scss';
import TodoProjectContent from '../../../containers/todoProjectContent/TodoProjectContent';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';

const Todo: React.FC = () => {
  return <TodoProjectContent projectId="" />;
};

export default withTaskSider(withTodoLayout(Todo));
