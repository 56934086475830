import React from 'react';
import './TodoPriorityDropdown.scss';
import { CheckOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { PRIORITY_LIST } from './constants';

/** types */

/** interface to describe a priority obj */
export interface PriorityObj {
  value: string;
  label: string;
  img: string;
}

/** interface to describe TodoPriorityDropdown component props */
interface TodoPriorityDropdownProps {
  selected: string;
  selectedHandler: (selected: string) => void;
}

const TodoPriorityDropdown: React.FC<TodoPriorityDropdownProps> = (
  props: TodoPriorityDropdownProps
) => {
  const { selected, selectedHandler } = props;

  /** handlers */

  /**
   * sets the clicked value as selected value
   * @param {React.MouseEvent} event - the mouse on click event
   */
  const setSelectedValue = (event: React.MouseEvent) => {
    selectedHandler((event.target as any).id);
  };

  return (
    <div className="TodoPriorityDropdown-container">
      {PRIORITY_LIST.map((priorityItem, index) => (
        <div
          key={'priority-item-' + index}
          className={clsx({
            'TodoPriorityDropdown-priority-item': true,
            'TodoPriorityDropdown-priority-item-selected':
              priorityItem.value === selected,
          })}
          id={priorityItem.value}
          onClick={setSelectedValue}
        >
          <div
            className="TodoPriorityDropdown-box"
            style={{
              background: `url(${priorityItem.img})`,
              backgroundSize: `cover`,
            }}
          />
          <div className="TodoPriorityDropdown-priority-item-label">
            {priorityItem.label}
          </div>
          <div>{selected === priorityItem.value && <CheckOutlined />}</div>
        </div>
      ))}
    </div>
  );
};

/** allow import as default component */
export default TodoPriorityDropdown;
