import { Button } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import RecurrenceTimePicker from '../recurrenceTimePicker/RecurrenceTimePicker';
import { WEEK_OPTIONS } from './constants';
import './RecurrenceWeekPicker.scss';

export interface RecurrenceWeekValue {
  [key: string]: moment.Moment[];
}

interface RecurrenceWeekPickerProps {
  values: RecurrenceWeekValue;
  onChangeHandler: (requested: RecurrenceWeekValue) => void;
}

const RecurrenceWeekPicker: React.FC<RecurrenceWeekPickerProps> = (
  props: RecurrenceWeekPickerProps
) => {
  const { values, onChangeHandler } = props;

  const addHandler = (weekKey: string) => {
    onChangeHandler({ ...values, [weekKey]: [...values[weekKey], moment()] });
  };

  const onChangeWrapper = (weekKey: string, requested: moment.Moment[]) => {
    if (requested.length) {
      onChangeHandler({ ...values, [weekKey]: requested });
    } else {
      onChangeHandler(lodash.omit(values, weekKey));
    }
  };

  const toggleOccurence = (event: React.MouseEvent) => {
    if (Object.keys(values).includes(event.currentTarget.id)) {
      onChangeHandler(lodash.omit(values, event.currentTarget.id));
    } else {
      onChangeHandler({ ...values, [event.currentTarget.id]: [moment()] });
    }
  };

  return (
    <div className="RecurrenceWeekPicker-container">
      <div className="RecurrenceWeekPicker-header">
        {Object.keys(WEEK_OPTIONS).map((iterWeekKey) => (
          <div
            id={iterWeekKey}
            className={clsx({
              'RecurrenceWeekPicker-toggler': true,
              'RecurrenceWeekPicker-toggler-active': Object.keys(
                values
              ).includes(iterWeekKey),
            })}
            key={iterWeekKey}
            onClick={toggleOccurence}
          >
            {(WEEK_OPTIONS as any)[iterWeekKey].substring(0, 3)}
          </div>
        ))}
      </div>
      {Object.keys(WEEK_OPTIONS).map(
        (iterWeekKey: string) =>
          Object.keys(values).includes(iterWeekKey) && (
            <div className="RecurrenceWeekPicker-row">
              <div className="RecurrenceWeekPicker-label">
                {(WEEK_OPTIONS as any)[iterWeekKey]}
              </div>
              <div className="RecurrenceWeekPicker-time-container">
                {values[iterWeekKey].map(
                  (iterTimeUnit: moment.Moment, index: number) => (
                    <RecurrenceTimePicker
                      values={values[iterWeekKey]}
                      onChangeHandler={(requested: moment.Moment[]) =>
                        onChangeWrapper(iterWeekKey, requested)
                      }
                      index={index}
                      key={'daily' + index}
                    />
                  )
                )}

                <Button
                  onClick={() => addHandler(iterWeekKey)}
                  className="RecurrenceWeekPicker-plus"
                  type="text"
                  shape="circle"
                  icon={<i className="fas fa-plus" />}
                  size="small"
                />
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default RecurrenceWeekPicker;
