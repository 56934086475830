import {
  LOCAL_GENERAL_SETTINGS_ENDPOINT,
  LOCAL_INBOX_ENDPOINT,
} from '../../configs/endpoints';
import { MenuOption, MenuDivider } from './MenuMorePanel';

// Menu Option Types
export const MENU_OPTION = 'MENU_OPTION';
export const MENU_DIVIDER = 'MENU_DIVIDER';

/** Menu Options List */
export const MENU_OPTIONS: Array<MenuOption | MenuDivider> = [
  {
    label: 'Sync',
    link: '/#',
    type: MENU_OPTION,
  },
  {
    label: 'Settings',
    link: LOCAL_GENERAL_SETTINGS_ENDPOINT,
    type: MENU_OPTION,
  },
  {
    label: 'Statistics',
    link: '/#',
    type: MENU_OPTION,
  },
  { type: MENU_DIVIDER },
  {
    label: 'Home',
    link: LOCAL_INBOX_ENDPOINT,
    type: MENU_OPTION,
  },
  {
    label: 'Premium Account',
    link: '/#',
    type: MENU_OPTION,
  },
  {
    label: 'Help Center',
    link: '/#',
    type: MENU_OPTION,
  },
  {
    label: 'Feedback',
    link: '/#',
    type: MENU_OPTION,
  },
  {
    type: MENU_DIVIDER,
  },
];
