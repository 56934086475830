import React from 'react';
import './TodoSider.scss';
import TodoFavourites from '../../../containers/todofavourites/TodoFavourites';
import Projects from '../../../containers/projects/Projects';
import TodoLabels from '../../../containers/todoLabels/TodoLabels';
// import TodoFilters from '../../../containers/todoFilters/TodoFilters';
import TodoActivityTypes from '../todoActivityTypes/TodoActivityTypes';

const TodoSider: React.FC = () => {
  return (
    <React.Fragment>
      <TodoFavourites />
      <Projects />
      <TodoLabels />
      <TodoActivityTypes />
      {/* Todo: Enable filters when required */}
      {/* <TodoFilters /> */}
    </React.Fragment>
  );
};

export default TodoSider;
