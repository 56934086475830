import React from 'react';
import withTodoLayout from '../../../hocs/withTodoLayout/withTodoLayout';
import './TodoUpcomingPage.scss';
import withTaskSider from '../../../hocs/withTaskSider/withTaskSider';
import moment from 'moment';
import { generateMonths } from '../../../services/utility';
import MonthSection from '../monthSection/MonthSection';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const TodoUpcomingPage: React.FC = () => {
  const location = useLocation();
  const [navigationInterval, setNavigationInterval] = React.useState<string>(
    'daily'
  );

  const onNavigationIntervalChange = (requestedInterval: string) => {
    setNavigationInterval(requestedInterval);
  };

  /** retrieve the task id from url */
  const taskId = (queryString.parse(location.search)['task'] as string) || '';

  const changeDayWiseSticky = () => {
    (document as any)
      .querySelectorAll('.DaySection-container')
      .forEach((iter: any) => {
        if (iter?.getBoundingClientRect()?.top <= 220) {
          (document as any)
            .querySelectorAll('.DaySection-container')
            .forEach((iter: any) => {
              iter.classList.remove('DaySection-sticky');
            });
          iter.classList.add('DaySection-sticky');
        } else {
          iter.classList.remove('DaySection-sticky');
        }
      });
  };

  const changeMonthWiseSticky = () => {
    (document as any)
      .querySelectorAll('.MonthSection-header')
      .forEach((iter: any) => {
        if (iter?.getBoundingClientRect()?.top <= 220) {
          (document as any)
            .querySelectorAll('.MonthSection-header')
            .forEach((iter: any) => {
              iter.classList.remove('MonthSection-sticky');
            });
          iter.classList.add('MonthSection-sticky');
        } else {
          iter.classList.remove('MonthSection-sticky');
        }
      });
  };

  const handleStickyChange = () => {
    changeMonthWiseSticky();
    changeDayWiseSticky();
  };

  React.useEffect(() => {
    (document as any)
      .querySelector('.TodoUpcomingPage-container')
      .addEventListener('scroll', handleStickyChange);
    setTimeout(() => {
      changeMonthWiseSticky();
      changeDayWiseSticky();
    }, 300);

    // cleanup the scroll listener
    return () => {
      (document as any)
        .querySelector('.TodoUpcomingPage-container')
        .removeEventListener('scroll', handleStickyChange);
    };
  }, []);

  const startDate = moment();
  const endDate = moment().add(24, 'month');

  return taskId !== ' ' ? (
    <div className="TodoUpcomingPage-container">
      {generateMonths(startDate, endDate).map((iterDate: string) => (
        <MonthSection
          key={'month-' + iterDate}
          currentDate={iterDate}
          startDate={startDate.format('YYYY-MM-DD')}
          endDate={endDate.format('YYYY-MM-DD')}
          navigationInterval={navigationInterval}
          onNavigationIntervalChange={onNavigationIntervalChange}
        />
      ))}
    </div>
  ) : null;
};

export default withTaskSider(withTodoLayout(TodoUpcomingPage));
