import {
  Avatar,
  Button,
  Checkbox,
  Comment,
  Dropdown,
  message,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { Store } from 'redux';
import {
  LOW_PRIORITY_VALUE,
  MIDDLE_PRIORITY_VALUE,
  NO_PRIORITY_VALUE,
  TOP_PRIORITY_VALUE,
} from '../../components/todo/todoPriorityDropdown/constants';
import {
  completeSubTaskListByParent,
  getChildItemsFromParent,
  getParentsFromChild,
} from '../../services/utility';
import {
  AttachmentObj,
  CommentObj,
  getComments,
  setComments,
} from '../../store/ducks/comments';
import {
  fixTaskOrders,
  getTaskById,
  getTasks,
  setTask,
  TaskObj,
  updateTasks,
} from '../../store/ducks/tasks';
import ConnectedAddTask from '../addTask/AddTask';
import ConnectedDetailedTasksList from '../detailedTasksList/DetailedTasksList';
import ConnectedTaskViewItem from '../taskViewItem/TaskViewItem';
import lodash from 'lodash';
import './TodoDetailedTask.scss';
import moment from 'moment';
import { getSessionUserInfo } from '../../store/ducks/session';
import { axioService, GET } from '../../services/axioService';
import { SERVER_COMMENTS_ENDPOINT } from '../../configs/endpoints';
import { useHistory } from 'react-router-dom';
import TodoCommentDelete from '../todoCommentDelete/TodoCommentDelete';
import TodoTaskOptionsPanel from '../../components/todo/todoTaskOptionsPanel/TodoTaskOptionsPanel';
import ConnectedAddTaskComment from '../addTaskComment/AddTaskComment';
import { MEETING_ACTIVTY_TYPE } from '../../configs/activityTypes';

import MeetingEvent from '../meetingEvent/MeetingEvent';
import MeetingAgenda from '../../components/activities/meetingAgenda/MeetingAgenda';
import Attachment from '../../components/activities/attachment/Attachment';
import MeetingWorkflow from '../../components/activities/meetingWorkflow/MeetingWorkflow';
import MeetingSummary from '../../components/activities/meetingSummary/MeetingSummary';

interface TodoDetailedTaskProps {
  id: string;
  activeTabId: string;
  taskInfo: TaskObj | null;
  taskLevel: number;
  comments: CommentObj[];
  userInfo: any;
  childrenTasks: TaskObj[];
  setTaskActionCreator: typeof setTask;
  setCommentsActionCreator: typeof setComments;
  updateTasksActionCreator: typeof updateTasks;
  fixTaskOrdersActionCreator: typeof fixTaskOrders;
}

const TodoDetailedTask: React.FC<TodoDetailedTaskProps> = (
  props: TodoDetailedTaskProps
) => {
  const {
    id,
    activeTabId,
    taskInfo,
    taskLevel,
    comments,
    userInfo,
    childrenTasks,
    updateTasksActionCreator,
    setCommentsActionCreator,
    setTaskActionCreator,
    fixTaskOrdersActionCreator,
  } = props;

  const history = useHistory();

  const { TabPane } = Tabs;

  const [loading, setLoading] = React.useState<boolean>(false);
  /** manages the visibility of task edit panel */
  const [editVisible, setEditVisible] = React.useState<boolean>(true);
  /** manages the visibility of task dropdown panel */
  const [dropdownVisible, setDropdownVisible] = React.useState<boolean>(false);
  /** manages the visibility of add task panel */
  const [subtaskAddVisible, setSubtaskAddVisible] = React.useState<boolean>(
    false
  );

  /** manages the visibility of task description edit panel */
  const [descriptionEditVisible, setDescriptionEditVisible] = React.useState<
    boolean
  >(false);

  /** triggers rendering by adding to the counter */
  const [counter, setCounter] = React.useState<number>(0);

  const incrementCounter = () => {
    setCounter(counter + 1);
  };

  React.useEffect(() => {
    setLoading(true);
    const fetchComments = async () => {
      if (id && id !== '') {
        try {
          const response = await axioService(
            GET,
            SERVER_COMMENTS_ENDPOINT,
            {
              task_id: id,
            },
            true
          );

          const tmpComments = response.data.data.map(
            (iterComment: any): CommentObj => ({
              id: iterComment.id,
              title: iterComment.content,
              taskId: id,
              commenter: iterComment.commenter,
              attachments: iterComment.attachments || [],
              timestamp: moment(iterComment.updated_at).format(
                'YYYY-MM-DD HH:mm'
              ),
            })
          );

          setCommentsActionCreator(tmpComments);
          setLoading(false);
        } catch (exception) {
          setLoading(false);
          console.error(exception);
        }
      }
    };
    setTimeout(() => {
      fetchComments();
    }, 1000);
  }, [id]);

  /** requests to complete the task */
  const onComplete = (event: any): void => {
    event.stopPropagation();

    setTaskActionCreator({
      ...(taskInfo as TaskObj),
      synced: false,
      completed: event.target.checked,
    });
    if (event.target.checked) {
      completeSubTaskListByParent((taskInfo as TaskObj).id);
    }

    fixTaskOrdersActionCreator(
      (taskInfo as TaskObj).projectId,
      (taskInfo as TaskObj).sectionId,
      (taskInfo as TaskObj).parent
    );
  };

  /** opens the edit input  */
  const editRequestHandler = () => setEditVisible(true);

  /** opens the description edit input  */
  const descriptionEditRequestHandler = () => setDescriptionEditVisible(true);

  const saveDescription = (event: any) => {
    if (event.currentTarget.textContent !== taskInfo?.description) {
      setTaskActionCreator({
        ...(taskInfo as TaskObj),
        synced: false,
        description: event.currentTarget.textContent || '',
      });
      message.success('Task description saved');
    }
    setDescriptionEditVisible(false);
  };

  /** prevents bubbling of unwanted events */
  const stopEventBubbling = (event: React.MouseEvent) =>
    event.stopPropagation();

  /** closes the sub task add panel */
  const subtaskAddCloseHandler = () => setSubtaskAddVisible(false);

  /** opens the sub task add panel */
  const subtaskAddOpenHandler = () => setSubtaskAddVisible(true);

  /** handles the delete request of task */
  const deleteRequestHandler = () => {
    if (taskInfo) {
      setTaskActionCreator({
        ...(taskInfo as TaskObj),
        synced: false,
        deleted: true,
      });

      /** warning: syncing sub tasks deletion as well */
      const tmpSubTasks = childrenTasks.map((iterTask: TaskObj) => ({
        ...iterTask,
        synced: false,
        deleted: true,
      }));
      updateTasksActionCreator(tmpSubTasks);

      fixTaskOrdersActionCreator(
        taskInfo.projectId,
        taskInfo.sectionId,
        taskInfo.parent
      );

      history.push({ search: '' });
    }
  };

  const onTaskAssigned = (assignedId: string): void => {
    setTaskActionCreator({
      ...(taskInfo as TaskObj),
      synced: false,
      assignedTo: assignedId,
    });
  };

  const onTabClick = (key: string) => {
    history.push({ search: `?task=${id}&tab=${key}` });
  };

  const onTaskDatesChangeHandler = (
    startDate: string,
    endDate: string,
    startTime: string,
    endTime: string
  ) => {
    setTaskActionCreator({
      ...(taskInfo as TaskObj),
      synced: false,
      dueDate: startDate,
      dueTime: startTime,
      endDate: endDate,
      endTime: endTime,
    });
  };

  const onRecurringChange = (requestedStatus: 0 | 1) => {
    setTaskActionCreator({
      ...(taskInfo as TaskObj),
      synced: false,
      isRecurring: requestedStatus,
    });
  };

  // eslint-disable-next-line no-constant-condition
  return taskInfo ? (
    <div className="TodoDetailedTask-container">
      <div className="TodoDetailedTask-task-body">
        <div className="TodoDetailedTask-main-body">
          <div className="TodoDetailedTask-upper-body">
            <div
              className={clsx({
                'TodoDetailedTask-task-head': true,
                'TodoDetailedTask-task-head-edit': editVisible,
              })}
            >
              <div className="TodoDetailedTask-task-head-checkbox">
                <Checkbox
                  onClick={stopEventBubbling}
                  checked={taskInfo.completed}
                  onChange={onComplete}
                  className={clsx({
                    'top-priority': taskInfo.priority === TOP_PRIORITY_VALUE,
                    'middle-priority':
                      taskInfo.priority === MIDDLE_PRIORITY_VALUE,
                    'low-priority': taskInfo.priority === LOW_PRIORITY_VALUE,
                    'no-priority': taskInfo.priority === NO_PRIORITY_VALUE,
                  })}
                />
              </div>
              {!editVisible ? (
                <ConnectedTaskViewItem
                  onTaskAssigned={onTaskAssigned}
                  taskId={taskInfo.id}
                />
              ) : (
                <ConnectedAddTask
                  taskInfo={taskInfo}
                  projectId={taskInfo.projectId}
                  sectionId={taskInfo.sectionId}
                  submitLabel={'Save'}
                  autoSave={true}
                />
              )}
              <div
                className={clsx({
                  'TodoDetailedTask-more-btn-container': true,
                  'TodoDetailedTask-more-btn-container-active': dropdownVisible,
                })}
                onClick={stopEventBubbling}
              >
                <Dropdown
                  overlay={
                    <div className="TodoDetailedTask-options-btn">
                      <TodoTaskOptionsPanel
                        taskInfo={taskInfo}
                        panelCloseHandler={() => setDropdownVisible(false)}
                        editRequestHandler={editRequestHandler}
                        deleteRequestHandler={deleteRequestHandler}
                      />
                    </div>
                  }
                  placement="bottomRight"
                  trigger={['click']}
                  visible={dropdownVisible}
                  onVisibleChange={(requestedVisibility: boolean) =>
                    setDropdownVisible(requestedVisibility)
                  }
                >
                  <Button
                    className="TaskItem-more-btn"
                    shape="circle"
                    size="small"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="TodoDetailedTask-details-container">
              <div className="TodoDetailedTask-details-title">
                <div>Description</div>
              </div>
              <div
                className="TodoDetailedTask-details-body"
                contentEditable={true}
                onBlur={saveDescription}
                onFocus={descriptionEditRequestHandler}
              >
                {taskInfo.description === '' && !descriptionEditVisible ? (
                  <span style={{ color: '#bfbfbf' }}>Write a description</span>
                ) : (
                  taskInfo.description
                )}
              </div>
            </div>
          </div>
          <Tabs
            activeKey={activeTabId}
            defaultActiveKey="1"
            onTabClick={onTabClick}
          >
            <TabPane tab="Sub Tasks" key="1">
              <div className="TodoDetailedTask-subtasks-container">
                <div className="TodoDetailedTask-subtasks-title">
                  {taskLevel < 5 && (
                    <div
                      className="TodoDetailedTask-subtasks-add-subtask"
                      onClick={subtaskAddOpenHandler}
                    >
                      <i className="fas fa-plus"></i> Add Sub Task
                    </div>
                  )}
                </div>
                <div className="TodoDetailedTask-subtasks-body">
                  {subtaskAddVisible && taskLevel < 5 && (
                    <ConnectedAddTask
                      taskInfo={{
                        id: '',
                        parent: taskInfo.id,
                        order: -1,
                        projectId: taskInfo.projectId,
                        sectionId: taskInfo.sectionId,
                      }}
                      projectId={taskInfo.projectId}
                      sectionId={taskInfo.sectionId}
                      postCloseHandler={subtaskAddCloseHandler}
                      postSuccessHandler={subtaskAddCloseHandler}
                    />
                  )}
                  <ConnectedDetailedTasksList
                    taskId={taskInfo.id}
                    projectId={taskInfo.projectId}
                    sectionId={taskInfo.sectionId}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="Comments" key="2">
              <div className="TodoDetailedTask-comments-container">
                <Spin spinning={loading}>
                  <div className="TodoDetailedTask-comments-body">
                    {comments.map((iterComment: CommentObj, index: number) => (
                      <Comment
                        key={`comment-${index}`}
                        author={
                          <span className="TodoDetailedTask-comment-author">
                            {iterComment.commenter?.name}
                          </span>
                        }
                        avatar={
                          userInfo.avatar ? (
                            <Avatar
                              src={iterComment.commenter?.avatar || ''}
                              alt={iterComment.commenter?.name || ''}
                            />
                          ) : (
                            <Avatar style={{ textTransform: 'uppercase' }}>
                              {userInfo?.name?.substring(0, 2)}
                            </Avatar>
                          )
                        }
                        content={
                          <div>
                            <p>{iterComment.title}</p>
                            <div>
                              {iterComment.attachments.map(
                                (
                                  iterAttachment: AttachmentObj,
                                  index: number
                                ) => (
                                  <div
                                    className="TodoDetailedTask-attachment-tag"
                                    key={iterComment.id + '-comment-' + index}
                                  >
                                    <a
                                      href={iterAttachment.download_url}
                                      // eslint-disable-next-line react/jsx-no-target-blank
                                      target="_blank"
                                    >
                                      <i className="fas fa-paperclip"></i>
                                      {iterAttachment.name}
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                            <TodoCommentDelete
                              taskId={taskInfo?.id || ''}
                              id={iterComment.id}
                              title={iterComment.title}
                            />
                          </div>
                        }
                        datetime={
                          <Tooltip
                            title={moment().format('YYYY-MM-DD HH:mm:ss')}
                          >
                            <span>
                              {moment(iterComment?.timestamp).fromNow()}
                            </span>
                          </Tooltip>
                        }
                      />
                    ))}
                    {comments.length === 0 && (
                      <div className="TodoDetailedTask-empty-comments">
                        No comments to display
                      </div>
                    )}
                  </div>
                </Spin>
              </div>
              <div className="TodoDetailedTask-add-comment">
                <ConnectedAddTaskComment
                  taskId={taskInfo.id}
                ></ConnectedAddTaskComment>
              </div>
            </TabPane>
            {taskInfo.activityType === MEETING_ACTIVTY_TYPE.value && (
              <React.Fragment>
                <TabPane tab="Meeting" key="3">
                  {activeTabId === '3' && (
                    <MeetingEvent
                      incrementCounter={incrementCounter}
                      taskId={taskInfo.id}
                      meetingDate={taskInfo.dueDate || ''}
                      meetingTime={taskInfo.dueTime || ''}
                      meetingEndDate={taskInfo.endDate || ''}
                      meetingEndTime={taskInfo.endTime || ''}
                      meetingDurationChangeHandler={onTaskDatesChangeHandler}
                      taskTitle={taskInfo.title || ''}
                      taskDescription={taskInfo.description || ''}
                      onRecurringChange={onRecurringChange}
                    />
                  )}
                </TabPane>
                <TabPane tab="Agenda" key="4">
                  {activeTabId === '4' && (
                    <MeetingAgenda taskId={taskInfo.id} counter={counter} />
                  )}
                </TabPane>
                <TabPane tab="Attachment" key="5">
                  {activeTabId === '5' && <Attachment taskId={taskInfo.id} />}
                </TabPane>
                <TabPane tab="Workflow" key="6">
                  {activeTabId === '6' && (
                    <MeetingWorkflow taskId={taskInfo.id} />
                  )}
                </TabPane>
                <TabPane tab="Summary" key="7">
                  {activeTabId === '7' && (
                    <MeetingSummary
                      taskId={taskInfo.id}
                      projectId={taskInfo.projectId}
                      sectionId={taskInfo.sectionId}
                      counter={counter}
                    />
                  )}
                </TabPane>
              </React.Fragment>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  ) : (
    <div className="TodoDetailedTask-container">
      <div className="TodoDetailedTask-task-body">
        <div className="TodoDetailedTask-not-found">
          <div>
            <img src="/undraw_lost.svg" width="400" alt="" />
          </div>
          <div className="TodoDetailedTask-not-found-title">Ooops!</div>
          <div className="TodoDetailedTask-not-found-text">
            No existing task found.
          </div>
        </div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  taskInfo: TaskObj;
  taskLevel: number;
  comments: CommentObj[];
  userInfo: any;
  childrenTasks: TaskObj[];
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TodoDetailedTaskProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const allTasks = lodash.filter(getTasks(state), { deleted: false });
  const result = {
    taskInfo: getTaskById(state, parentProps.id),
    taskLevel: getParentsFromChild(getTasks(state), parentProps.id, []).length,
    comments: lodash.filter(getComments(state), { taskId: parentProps.id }),
    userInfo: getSessionUserInfo(state),
    childrenTasks: getChildItemsFromParent(allTasks, parentProps.id, []),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setTaskActionCreator: setTask,
  setCommentsActionCreator: setComments,
  fixTaskOrdersActionCreator: fixTaskOrders,
  updateTasksActionCreator: updateTasks,
};

/** connect TodoDetailedTask to the redux store */
const ConnectedTodoDetailedTask = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoDetailedTask);

export default ConnectedTodoDetailedTask;
