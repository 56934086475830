import React from 'react';
import './withTodoLayout.scss';
import TodoHeader from '../../containers/todoHeader/TodoHeader';
import TodoSider from '../../components/todo/todoSider/TodoSider';
import clsx from 'clsx';
import { useLocation } from 'react-router';

/**
 * withTodoLayout hoc method wraps the passing component with todo header and sider
 * @param {React.ComponentType<ComponentPropsType>} Component - a component that needs the todo layout
 * @returns a component containing all the todo layout components as well as the passed component
 */
function withTodoLayout<ComponentPropsType>(
  Component: React.ComponentType<ComponentPropsType>
) {
  return (props: ComponentPropsType) => {
    {
      const location = useLocation();
      const [isSiderOpen, setIsSiderOpen] = React.useState<boolean>(false);
      const toggleSiderPanel = () => setIsSiderOpen(!isSiderOpen);

      /** close the sider on location changes */
      React.useEffect(() => {
        setIsSiderOpen(false);
      }, [location]);

      return (
        <div className="withTodoLayout-layout-container">
          <div className="withTodoLayout-header">
            <TodoHeader
              isSiderOpen={isSiderOpen}
              toggleSiderPanel={toggleSiderPanel}
            />
          </div>
          <div className="withTodoLayout-content-background">
            <div
              className={clsx({
                'withTodoLayout-sider': true,
                'withTodoLayout-sider-open': isSiderOpen,
              })}
            >
              <div className="withTodoLayout-sider-inner">
                <TodoSider />
              </div>
            </div>
            <div className="withTodoLayout-content">
              <Component {...props} />
            </div>
          </div>
        </div>
      );
    }
  };
}

export default withTodoLayout;
