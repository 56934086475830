import DatePicker from 'react-datepicker';
import React from 'react';
import format from 'date-fns/format';
import { toInteger } from 'lodash';
import './TimePicker.scss';

interface TimePickerProps {
  selectedTime: string;
  use12HoursFormat: boolean;
  timeChangeHandler: (requestedTime: string) => void;
}

const TimePicker: React.FC<TimePickerProps> = (props: TimePickerProps) => {
  const { selectedTime, use12HoursFormat, timeChangeHandler } = props;
  const [hours, minutes] = selectedTime.split(':');

  const selected = new Date();

  selected.setHours(toInteger(hours as string), toInteger(minutes as string));

  const onChangeHandler = (date: Date | null) => {
    if (date) {
      timeChangeHandler(format(date, 'HH:mm'));
    } else {
      timeChangeHandler(format(new Date(), 'HH:mm'));
    }
  };

  return (
    <div>
      <DatePicker
        className="TimePicker-date"
        selected={selected}
        onChange={onChangeHandler}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeFormat={use12HoursFormat ? 'h:mm aa' : 'HH:mm'}
        dateFormat={use12HoursFormat ? 'h:mm aa' : 'HH:mm'}
      />
    </div>
  );
};

export default TimePicker;
