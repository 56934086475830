import { Spin } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Store } from 'redux';
import Loading from '../../components/loading/Loading';
import { TODO_APP } from '../../configs/constants';
import { ACCOUNT_APP_LOGIN_ENDPOINT } from '../../configs/endpoints';
import { isAuthenticated } from '../../store/ducks/session';

const AccountRedirect = () => {
  React.useEffect(() => {
    window.location.href = `${ACCOUNT_APP_LOGIN_ENDPOINT}?app=${TODO_APP}&redirect=${
      window.location.pathname + window.location.search
    }`;
  }, []);
  return <div />;
};

const PrivateRoute: React.FC = ({
  component: Component,
  isAuthenticated,
  loading,
  ...rest
}: any) => (
  <Route
    {...rest}
    // tslint:disable-next-line: jsx-no-lambda
    render={(props: any) =>
      isAuthenticated === true ? (
        <Spin indicator={<Loading />} spinning={loading}>
          <Component {...props} />
        </Spin>
      ) : (
        <AccountRedirect />
      )
    }
  />
);

/** connect the component to the store */

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>, parentProps: any): any => {
  const result = {
    ...parentProps,
    isAuthenticated: isAuthenticated(state),
  };
  return result;
};

/** connect SignIn to the redux store */
const ConnectedPrivateRoute = connect(mapStateToProps, {})(PrivateRoute);

export default ConnectedPrivateRoute;
