import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from 'redux-state-sync';
import SeamlessImmutable from 'seamless-immutable';
import { loadState, saveState } from '../services/cookieStorageSync';
import session, { reducerName as sessionReducer } from './ducks/session';
import projects, { reducerName as projectsReducer } from './ducks/projects';
import sections, { reducerName as sectionsReducer } from './ducks/sections';
import tasks, { reducerName as tasksReducer } from './ducks/tasks';
import labels, { reducerName as labelsReducer } from './ducks/labels';
import todoFilters, {
  reducerName as todoFiltersReducer,
} from './ducks/todoFilters';
import comments, { reducerName as commentsReducer } from './ducks/comments';
import settings, { reducerName as settingsReducer } from './ducks/settings';
import notifications, {
  reducerName as notificationsReducer,
} from './ducks/notifications';
import collaborators, {
  reducerName as collaboratorsReducer,
} from './ducks/collaborators';
import meeting, { reducerName as meetingReducer } from './ducks/meeting';

// reducers
/** Initial reducers in the reducer registry */
const defaultReducers: any = {};

/** Add session reducer to registry */
defaultReducers[sessionReducer] = session;
/** Add projects reducer to registry */
defaultReducers[projectsReducer] = projects;
/** Add sections reducer to registry */
defaultReducers[sectionsReducer] = sections;
/** Add tasks reducer to registry */
defaultReducers[tasksReducer] = tasks;
/** Add labels reducer to registry */
defaultReducers[labelsReducer] = labels;
/** Add todoFilters reducer to registry */
defaultReducers[todoFiltersReducer] = todoFilters;
/** Add comments reducer to registry */
defaultReducers[commentsReducer] = comments;
/** Add settings reducer to registry */
defaultReducers[settingsReducer] = settings;
/** Add notifications reducer to registry */
defaultReducers[notificationsReducer] = notifications;
/** Add collaborators reducer to registry */
defaultReducers[collaboratorsReducer] = collaborators;
/** Add meeting reducer to registry */
defaultReducers[meetingReducer] = meeting;

/** Create reducers from default reducers obj */
const reducers = withReduxStateSync(
  combineReducers(defaultReducers) as any,
  (state: any) => SeamlessImmutable(state)
);

// middleware
const config = {};
const middlewares = [createStateSyncMiddleware(config)];

/** Create composer for redux dev tools */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store
/** The initial store */
const persistedState = loadState();
const immutablePersistedState = SeamlessImmutable(persistedState);
const store = createStore(
  reducers,
  immutablePersistedState,
  composeEnhancers(applyMiddleware(...middlewares))
);
/** Keep saving the store on local storage */
store.subscribe(() => {
  saveState({
    [sessionReducer]: (store as any).getState()[sessionReducer],
  });
});
initStateWithPrevTab(store);

export default store;
