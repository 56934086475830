import React from 'react';
import { Menu, Button } from 'antd';
import './MenuMorePanel.scss';
import { MENU_OPTIONS, MENU_OPTION, MENU_DIVIDER } from './constants';
import { connect } from 'react-redux';
import { resetSession } from '../../store/ducks/session';
import { Link, useHistory } from 'react-router-dom';
import { LOCAL_LOGOUT_ENDPOINT } from '../../configs/endpoints';
import { TODO_APP } from '../../configs/constants';
import UpcomingBadge from '../../components/upcomingBadge/UpcomingBadge';
import { syncService } from '../../services/syncService';

/** inteface to describe Menu Option Object */
export interface MenuOption {
  label: string;
  link: string;
  type: typeof MENU_OPTION;
}

/** interface to describe Menu Divider Object */
export interface MenuDivider {
  type: typeof MENU_DIVIDER;
}

/** interface to describe logout props */
interface MenuMorePanelProps {
  resetSessionActionCreator: typeof resetSession;
}

/** Menu Option Panel Component */
const MenuMorePanel: React.FC<MenuMorePanelProps> = () => {
  const history = useHistory();

  const handleSignOut = () => {
    history.push(`${LOCAL_LOGOUT_ENDPOINT}?app=${TODO_APP}`);
  };

  /** requests a sync */
  const requestSync = () => {
    syncService();
  };

  return (
    <Menu className="MenuMorePanel-panel-container">
      {MENU_OPTIONS.map((menu_opt, index) =>
        menu_opt.type === MENU_OPTION ? (
          index === 0 ? (
            <Menu.Item key={'menu-opt-' + index} onClick={requestSync}>
              {menu_opt.label}
            </Menu.Item>
          ) : (
            <Menu.Item key={'menu-opt-' + index}>
              <Link to={menu_opt.link}>{menu_opt.label}</Link>
              {[2, 5, 6, 7].includes(index) && <UpcomingBadge />}
            </Menu.Item>
          )
        ) : (
          <Menu.Divider key={'menu-opt-' + index} />
        )
      )}
      <Menu.Item onClick={handleSignOut}>Sign Out</Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        Apply <UpcomingBadge />
      </Menu.Item>
      <div className="MenuMorePanel-apply-container">
        <Button>
          <i className="fab fa-apple"></i>
        </Button>
        <Button>
          <i className="fab fa-android"></i>
        </Button>
        <Button>
          <i className="fab fa-windows"></i>
        </Button>
      </div>
      <Menu.Divider />
      <Menu.Item>
        View Changelog <UpcomingBadge />
      </Menu.Item>
    </Menu>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  resetSessionActionCreator: resetSession,
};

/** connect MenuMorePanel to the redux store */
const ConnectedMenuMorePanel = connect(null, mapDispatchToProps)(MenuMorePanel);

export default ConnectedMenuMorePanel;
