import { Input } from 'antd';
import React from 'react';
import './TodoActivityTypeDropdownPicker.scss';
import lodash from 'lodash';
import clsx from 'clsx';
import { ActivityType, ACTIVITY_TYPES } from '../../../configs/activityTypes';

/** types */

/** interface to describe TodoActivityTypeDropdownPicker props */
interface TodoActivityTypeDropdownPickerProps {
  selected: string; // the selected activity
  selectedHandler: (selected: string) => void; // the onchange selction handler
}

/** constants */

/** the search text on the search input placeholder */
const SEARCH_PLACEHOLDER = 'Search Task Type';

const TodoActivityTypeDropdownPicker: React.FC<TodoActivityTypeDropdownPickerProps> = (
  props: TodoActivityTypeDropdownPickerProps
) => {
  /** props */

  const { selected, selectedHandler } = props;

  /** component states */

  /** manages the search input text */
  const [search, setSearch] = React.useState<string>('');
  /** manages the filtered list based on search input */
  const [filteredList, setFilteredList] = React.useState<ActivityType[]>([]);

  /** cycles */

  /** filters the actual list based on changes of search input */
  React.useEffect(() => {
    setFilteredList(
      search === ''
        ? ACTIVITY_TYPES
        : [
            ...lodash.filter(ACTIVITY_TYPES, (activity: ActivityType) =>
              activity.label.toLowerCase().startsWith(search.toLowerCase())
            ),
            ...lodash.filter(
              ACTIVITY_TYPES,
              (activity: ActivityType) =>
                !activity.label
                  .toLowerCase()
                  .startsWith(search.toLowerCase()) &&
                activity.label.toLowerCase().includes(search.toLowerCase())
            ),
          ]
    );
  }, [search]);

  /** handlers */

  /**
   * sets search text based on user search input
   * @param {React.ChangeEvent<HTMLInputElement>} event - the html input onchange event
   */
  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  /**
   * selects/ deselects the activity
   * @param {React.MouseEvent} event - the onclick mouse event
   */
  const setSelectedId = (event: React.MouseEvent) => {
    if (selected === (event.target as any).id) {
      selectedHandler('');
    } else {
      selectedHandler((event.target as any).id);
    }
  };

  /** rendered jsx */
  return (
    <div className="TodoActivityTypeDropdownPicker-container">
      <div className="TodoActivityTypeDropdownPicker-search-container">
        <Input
          placeholder={SEARCH_PLACEHOLDER}
          value={search}
          onChange={searchHandler}
          prefix={<i className="fas fa-search"></i>}
        />
      </div>
      <div className="TodoActivityTypeDropdownPicker-body-container">
        {filteredList.map((activityItem, index) => (
          <div
            key={'activity-item-' + index}
            className={clsx({
              'TodoActivityTypeDropdownPicker-activity-item': true,
              'TodoActivityTypeDropdownPicker-activity-item-selected':
                activityItem.value === selected,
              'TodoActivityTypeDropdownPicker-activity-item-disabled':
                activityItem.value !== 'meeting',
            })}
            id={activityItem.value}
            onClick={setSelectedId}
          >
            <i
              className={
                activityItem.icon + ' TodoActivityTypeDropdownPicker-icon'
              }
            />
            <div className="TodoActivityTypeDropdownPicker-activity-item-label">
              {activityItem.label}
            </div>
            <div>
              {selected === activityItem.value && (
                <i className="fas fa-check"></i>
              )}
            </div>
          </div>
        ))}
        {filteredList.length === 0 && (
          <div className="TodoActivityTypeDropdownPicker-no-activities">
            No task types to display
          </div>
        )}
      </div>
    </div>
  );
};

/** all import as default component */
export default TodoActivityTypeDropdownPicker;
