import { Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import ConnectedUpcomingDayBody from '../../../containers/upcomingDayBody/UpcomingDayBody';
import './DaySection.scss';

/** interface to describe the month section props */
interface DaySectionProps {
  currentDate: string;
  monthDate: string;
}

const DaySection: React.FC<DaySectionProps> = (props: DaySectionProps) => {
  const { currentDate } = props;

  /** manages the visibility of day body */
  const [visible, setVisible] = React.useState<boolean>(false);
  /** manages the visibility of upper add task */
  const [topTaskVisible, setTopTaskVisible] = React.useState<boolean>(false);
  /** manages the visibility of bottom add task */
  const [bottomTaskVisible, setBottomTaskVisible] = React.useState<boolean>(
    false
  );

  /** sets the requested visibility of top add task */
  const onTopTaskVisibilityChange = (requstedVisibility: boolean) =>
    setTopTaskVisible(requstedVisibility);

  /** sets the requested visibility of bottom add task */
  const onBottomTaskVisibilityChange = (requstedVisibility: boolean) =>
    setBottomTaskVisible(requstedVisibility);

  /** opens the top task */
  const openAddTaskHander = () => setTopTaskVisible(true);

  const observer = new IntersectionObserver(
    function (entries) {
      /** if top section header gets out of view point */
      if (entries[0].intersectionRatio === 0) {
        setVisible(false);
        setTopTaskVisible(false);
        setBottomTaskVisible(false);
      } else if (entries[0].intersectionRatio >= 0.3) {
        setVisible(true);
      }
    },
    {
      threshold: [0, 0.3],
    }
  );

  React.useEffect(() => {
    observer.observe((document as any).querySelector('#day_' + currentDate));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div id={'day_' + currentDate} className="DaySection-container">
      <div id={'DaySection-' + currentDate} className="DaySection-header">
        <div className="DaySection-day">{moment(currentDate).format('DD')}</div>
        <div className="DaySection-month">
          {moment(currentDate).format('MMMM YYYY')}
        </div>
        <div className="DaySection-others">
          <div onClick={openAddTaskHander} className="DaySection-add-task">
            <i className="fas fa-plus" />
            Add Task
          </div>
        </div>
      </div>
      <Spin spinning={!visible}>
        <div className="DaySection-body">
          {visible && (
            <ConnectedUpcomingDayBody
              topAddTaskVisibility={topTaskVisible}
              bottomAddTaskVisibility={bottomTaskVisible}
              onTopAddTaskVisibilityChange={onTopTaskVisibilityChange}
              onBottomAddTaskVisibilityChange={onBottomTaskVisibilityChange}
              currentDate={currentDate}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

/** the default export */
export default DaySection;
