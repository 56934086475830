import React from 'react';
import './AttachmentInputView.scss';

/** interface to describe AttachmentInputView props */
interface AttachmentInputViewProps {
  name: string;
  size: string;
  index: number;
  deleteByIndex: (index: number) => void;
}

const AttachmentInputView: React.FC<AttachmentInputViewProps> = (
  props: AttachmentInputViewProps
) => {
  const { name, size, index, deleteByIndex } = props;

  /** handles the delete request of attachment */
  const deleteHandler = () => {
    deleteByIndex(index);
  };

  return (
    <div className="AttachmentInputView-container">
      <i className="fas fa-file" />
      <div className="AttachmentInputView-title">
        {name} - {size} bytes{' '}
      </div>
      <i
        onClick={deleteHandler}
        className="fas fa-trash AttachmentInputView-delete"
      />
    </div>
  );
};

export default AttachmentInputView;
