import React from 'react';
import { Button, Upload } from 'antd';
import './UploadToolbar.scss';
import clsx from 'clsx';
import { axioService, POST } from '../../../services/axioService';
import { v4 as uuid } from 'uuid';
import { SERVER_FILE_UPLOAD_ENDPOINT } from '../../../configs/endpoints';

export interface FileObj {
  uid: string;
  name: string;
  status: string;
  url: string;
  response?: any;
}

interface UploadToolbarProps {
  fileList: FileObj[];
  setFileList: (requestedFiles: FileObj[]) => void;
}

const UploadToolbar: React.FC<UploadToolbarProps> = (
  props: UploadToolbarProps
) => {
  const { fileList, setFileList } = props;

  const handleChange = (info: any) => {
    let modifiedFileList = [...info.fileList];

    modifiedFileList = modifiedFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(modifiedFileList);
  };

  const customApiHandler = async ({ onSuccess, onError, file }: any) => {
    try {
      const request = new FormData();
      request.append('visibility', 'private');
      request.append('attachment', file);

      const response: any = await axioService(
        POST,
        SERVER_FILE_UPLOAD_ENDPOINT,
        request,
        true
      );

      onSuccess({
        uid: uuid(),
        name: response.data?.name,
        status: 'done',
        url: response.data?.url || '',
      });
    } catch (exception) {
      console.error(exception);
      onError();
    }
  };

  const Uploadprops = {
    customRequest: customApiHandler,
    multiple: true,
    onChange: handleChange,
  };

  return (
    <Upload
      {...Uploadprops}
      fileList={fileList as any}
      className={clsx({
        'UploadToolbar-container': fileList.length > 0,
      })}
    >
      <Button type="text" className="UploadToolbar-btn">
        <i className="fas fa-paperclip"></i>
      </Button>
    </Upload>
  );
};

export default UploadToolbar;
