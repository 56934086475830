import lodash from 'lodash';
import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';

export interface AttachmentObj {
  name: string;
  url: string;
  download_url: string;
}

/** interface for comment object */
export interface CommentObj {
  id: string;
  taskId: string;
  title: string;
  timestamp: string;
  attachments: AttachmentObj[];
  commenter: any;
}

/** The reducer name */
export const reducerName = 'comments';

// actions
/** action types */
export const SET_COMMENTS = 'virtunus/reducer/comments/SET_COMMENTS';
/** action types */
export const SET_COMMENT = 'virtunus/reducer/comments/SET_COMMENT';
/** action types */
export const DELETE_COMMENT = 'virtunus/reducer/comments/DELETE_COMMENT';

/** interface for SET_COMMENTS action */
export interface SetCommentsAction extends AnyAction {
  comments: CommentObj[];
  type: typeof SET_COMMENTS;
}

/** interface for SET_COMMENT action */
export interface SetCommentAction extends AnyAction {
  comment: CommentObj;
  type: typeof SET_COMMENT;
}

/** interface for DELETE_COMMENT action */
export interface DeleteCommentAction extends AnyAction {
  id: string;
  type: typeof DELETE_COMMENT;
}

/** Create type for comments reducer actions */
export type CommentsActionTypes =
  | SetCommentsAction
  | SetCommentAction
  | DeleteCommentAction
  | AnyAction;

// action creators

/** set comments action creator
 * @param {CommentObj[]} comments - the comments to set
 * @returns {SetCommentsAction} - an action to set comments in store
 */
export const setComments = (comments: CommentObj[]): SetCommentsAction => ({
  comments,
  type: SET_COMMENTS,
});

/** set comment action creator
 * @param {CommentObj} comment - the comment to set
 * @returns {SetCommentAction} - an action to set comment in store
 */
export const setComment = (comment: CommentObj): SetCommentAction => ({
  comment,
  type: SET_COMMENT,
});

/** delete comment action creator
 * @param {string} id - the comment id to delete
 * @returns {DeleteCommentAction} - an action to delete comment in store
 */
export const deleteComment = (id: string): DeleteCommentAction => ({
  id,
  type: DELETE_COMMENT,
});

// the reducer

/** interface for comments state in redux store */
type CommentsState = CommentObj[];

/** Create an immutable session state */
export type ImmutableCommentsState = SeamlessImmutable.ImmutableArray<
  CommentsState
>;

/** initial comments state */
const initialState: ImmutableCommentsState = SeamlessImmutable([]);

/** the comments reducer function */
export default function reducer(
  state: ImmutableCommentsState = initialState,
  action: CommentsActionTypes
): ImmutableCommentsState {
  switch (action.type) {
    case SET_COMMENTS:
      return SeamlessImmutable(action.comments);
    case SET_COMMENT:
      return SeamlessImmutable([
        ...state.asMutable({ deep: true }),
        action.comment,
      ]);
    case DELETE_COMMENT:
      return SeamlessImmutable(
        lodash.filter(
          state.asMutable({ deep: true }) as any,
          (iterComment: CommentObj) => iterComment.id !== action.id
        )
      );
    default:
      return state;
  }
}

// selectors

/** returns the comments list
 * @param {Partial<Store>} state - the redux store
 * @return { CommentObj[] } - the existing comments
 */
export function getComments(state: Partial<Store>): CommentObj[] {
  return (state as any)[reducerName];
}
