import { Button } from 'antd';
import moment from 'moment';
import React from 'react';
import RecurrenceTimePicker from '../recurrenceTimePicker/RecurrenceTimePicker';
import './RecurrenceDayPicker.scss';

interface RecurrenceDayPickerProps {
  values: moment.Moment[];
  onChangeHandler: (requested: moment.Moment[]) => void;
}

const RecurrenceDayPicker: React.FC<RecurrenceDayPickerProps> = (
  props: RecurrenceDayPickerProps
) => {
  const { values, onChangeHandler } = props;

  const addHandler = () => {
    onChangeHandler([...values, moment()]);
  };

  return (
    <div className="RecurrenceDayPicker-row">
      <div className="RecurrenceDayPicker-label">Start Time</div>
      <div className="RecurrenceDayPicker-container">
        {values.map((iterTimeUnit: moment.Moment, index: number) => (
          <RecurrenceTimePicker
            values={values}
            onChangeHandler={onChangeHandler}
            index={index}
            key={'daily' + index}
          />
        ))}

        <Button
          onClick={addHandler}
          type="text"
          shape="circle"
          className="RecurrenceDayPicker-plus"
          icon={<i className="fas fa-plus" />}
          size="small"
        />
      </div>
    </div>
  );
};

export default RecurrenceDayPicker;
