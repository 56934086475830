import React from 'react';
import { Modal, Input, Checkbox, Button } from 'antd';
import './AddTodoLabel.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { Store } from 'redux';
import {
  getNewLabelOrderbyLabelId,
  LabelObj,
  setLabel,
} from '../../store/ducks/labels';
import LabelColorPicker, {
  DEFAULT_COLORS,
} from '../../components/todo/labels/colorPicker/LabelColorPicker';
import { v4 as uuid } from 'uuid';

/** interface to describe AddTodoLabel props */
interface AddTodoLabelProps {
  visible: boolean;
  currentAddIndex: number;
  setLabelActionCreator: typeof setLabel;
  closeHandler: () => void;
  initialUserInput?: Partial<LabelObj>;
}

// constants
/** the modal title */
const MODAL_TITLE = 'Add Label';
/** the default user input object */
const DEFAULT_USER_INPUT: LabelObj = {
  id: '',
  title: '',
  color: DEFAULT_COLORS[0],
  parent: '',
  expanded: true,
  order: 0,
  isHidden: false,
  isFavourite: false,
  synced: false,
  deleted: false,
};

/** the add todo lable component */
const AddTodoLabel: React.FC<AddTodoLabelProps> = (
  props: AddTodoLabelProps
) => {
  const {
    visible,
    currentAddIndex,
    setLabelActionCreator,
    closeHandler,
    initialUserInput,
  } = props;

  /** creates a custom initial user input based on intialUserInput prop; otherwise default is used */
  const modifiedInitialUserInput = initialUserInput
    ? { ...DEFAULT_USER_INPUT, ...initialUserInput }
    : DEFAULT_USER_INPUT;

  const inputRef = React.useRef<any>(null);

  // React states
  /** manages the user inputs */
  const [userInput, setUserInput] = React.useState<LabelObj>(
    modifiedInitialUserInput
  );

  // React cycles
  React.useEffect(() => {
    setUserInput(modifiedInitialUserInput);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  }, [initialUserInput, visible]);

  // confirm handler
  /** add label submit handler */
  const okHandler = async () => {
    if (userInput.title === '') return;
    let requestedLabelData = {
      ...userInput,
      synced: false,
      order:
        initialUserInput && initialUserInput.order !== undefined
          ? initialUserInput.order
          : currentAddIndex,
    };
    if (requestedLabelData.id === '') {
      requestedLabelData = {
        ...requestedLabelData,
        id: uuid(),
      };
    }
    setLabelActionCreator(requestedLabelData);
    setUserInput(modifiedInitialUserInput);
    setTimeout(() => closeHandler(), 100);
  };

  // on change handlers
  /**
   * titleChangeHandler updates user's title state on change
   * @param {React.ChangeEvent<HTMLInputElement>} event - the on change event
   */
  const titleChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput({ ...userInput, title: event.target.value });
  };

  /**
   * colorChangeHandler updates user's color choice
   * @param {string} color - the selected color
   */
  const colorChangeHandler = (color: string) => {
    setUserInput({ ...userInput, color });
  };

  /**
   * hiddenChangeHandler updates the label's hidden flag
   * @param {CheckboxChangeEvent} event - the checkbox event handler
   */
  const hiddenChangeHandler = (event: CheckboxChangeEvent) => {
    setUserInput({ ...userInput, isHidden: event.target.checked });
  };

  /**
   * favouriteChangeHandler updates the label's favourite flag
   * @param {CheckboxChangeEvent} event - the checkbox event handler
   */
  const favouriteChangeHandler = (event: CheckboxChangeEvent) => {
    setUserInput({ ...userInput, isFavourite: event.target.checked });
  };

  return (
    <Modal
      title={MODAL_TITLE}
      visible={visible}
      onOk={okHandler}
      onCancel={closeHandler}
      width={600}
      footer={[
        <Button key="back" onClick={closeHandler}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={userInput.title === '' || userInput.color === ''}
          onClick={okHandler}
        >
          Done
        </Button>,
      ]}
    >
      <div className="AddTodoLabel-title-container">
        <Input
          value={userInput.title}
          size="large"
          placeholder="Label Name"
          onChange={titleChangeHandler}
          onPressEnter={okHandler}
          ref={inputRef}
        />
      </div>
      <div className="AddTodoLabel-colorpicker-container">
        <h4>Label Color</h4>
        <div className="AddTodoLabel-colorpicker-section">
          <LabelColorPicker
            selectedColor={userInput.color}
            colorChangeHandler={colorChangeHandler}
          />
        </div>
      </div>
      <div className="AddTodoLabel-extra-container">
        <Checkbox
          className="hide-display"
          checked={userInput.isHidden}
          onChange={hiddenChangeHandler}
        >
          Keep Hidden
        </Checkbox>
        <Checkbox
          checked={userInput.isFavourite}
          onChange={favouriteChangeHandler}
        >
          Add to Favourites
        </Checkbox>
      </div>
    </Modal>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  currentAddIndex: number;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    currentAddIndex: getNewLabelOrderbyLabelId(state, ''),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setLabelActionCreator: setLabel,
};

/** connect AddTodoLabel to the redux store */
const ConnectedAddTodoLabel = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTodoLabel);

/** the default export */
export default ConnectedAddTodoLabel;
