import moment from 'moment';
import {
  TaskObj,
  TimeReminderObj,
  TIME_REMINDER_TYPE,
} from '../../store/ducks/tasks';
import { ServerTaskObj, ServerTimeReminderObj } from './data';

/**
 * transforms the server task structure to local task structure
 * @param {ServerTaskObj} taskObj - the task obj
 * @returns {TaskObj} - the local task structure
 */
export const transformTaskFromServer = (taskObj: ServerTaskObj): TaskObj => {
  return {
    id: taskObj.id,
    title: taskObj.title,
    description: taskObj.description || '',
    /** TODO: remove ss from due date when becomes available */
    dueDate: taskObj.due_date
      ? moment(taskObj.due_date).format('YYYY-MM-DD')
      : '',
    dueTime: taskObj.due_date
      ? moment(taskObj.due_date).format('HH:mm')
      : '12:00',
    endDate: taskObj.end_date
      ? moment(taskObj.end_date).format('YYYY-MM-DD')
      : '',
    endTime: taskObj.end_date
      ? moment(taskObj.end_date).format('HH:mm')
      : '12:00',
    projectId: taskObj.project_id || '',
    sectionId: taskObj.section_id || '',
    parent: taskObj.parent_id || '',
    completed: taskObj.is_completed ? true : false,
    expanded: taskObj.is_expanded ? true : false,
    reminders:
      taskObj.reminders?.map(
        (iterReminder: ServerTimeReminderObj): TimeReminderObj => ({
          id: iterReminder.id,
          deleted: false,
          before: iterReminder.before,
          type: TIME_REMINDER_TYPE,
        })
      ) || [],
    priority: taskObj.priority ? taskObj.priority.toString() : '0',
    labels: taskObj.labels || [],
    order: taskObj.order,
    synced: true,
    deleted: false,
    assignedTo: taskObj.assigned_to?.toString() || '',
    isRecurring: taskObj.is_recurring,
    referenceId: taskObj.reference_id || null,
    recurrenceId: taskObj.recurrence_id || null,
    isExists: taskObj.is_exists,
    recurringScheduleDate: taskObj.recurring_schedule_date || null,
    commentsCount: taskObj.comments_count || 0,
    activityType: taskObj.type || '',
  };
};

export const transformTaskToServer = (taskObj: TaskObj): ServerTaskObj => {
  return {
    id: taskObj.id,
    title: taskObj.title,
    description: taskObj.description,
    /** TODO: add time when time is implemented */
    due_date:
      taskObj.dueDate !== ''
        ? taskObj.dueDate + ' ' + taskObj.dueTime + ':00'
        : null,
    end_date:
      taskObj.endDate !== ''
        ? taskObj.endDate + ' ' + taskObj.endTime + ':00'
        : null,
    project_id: taskObj.projectId === '' ? null : taskObj.projectId,
    section_id: taskObj.sectionId === '' ? null : taskObj.sectionId,
    priority: parseInt(taskObj.priority),
    labels: taskObj.labels,
    order: taskObj.order,
    parent_id: taskObj.parent === '' ? null : taskObj.parent,
    /** TODO: add reminders when time is implemented */
    reminders: (taskObj.reminders as TimeReminderObj[]).map(
      (iterReminder: TimeReminderObj) => ({
        type: 'time' as any,
        delete: (iterReminder.deleted ? 1 : 0) as any,
        before: iterReminder.before,
        id: iterReminder.id,
      })
    ),
    is_completed: (taskObj.completed ? 1 : 0) as any,
    is_expanded: (taskObj.expanded ? 1 : 0) as any,
    delete: (taskObj.deleted ? 1 : 0) as any,
    assigned_to: taskObj.assignedTo !== '' ? taskObj.assignedTo : null,
    is_recurring: taskObj.isRecurring,
    reference_id: taskObj.referenceId,
    recurrence_id: taskObj.recurrenceId,
    is_exists: taskObj.isExists,
    recurring_schedule_date: taskObj.recurringScheduleDate,
    type: taskObj.activityType !== '' ? taskObj.activityType : null,
  };
};
