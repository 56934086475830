import lodash from 'lodash';
import { AnyAction, Store } from 'redux';
import SeamlessImmutable from 'seamless-immutable';

/** interface for collaborator object */
export interface CollaboratorObj {
  id: string;
  name: string;
  email: string;
  mobile: string;
  avatar: string;
}

/** The reducer name */
export const reducerName = 'collaborators';

// actions
/** action types */
export const SET_COLLABORATORS =
  'virtunus/reducer/collaborators/SET_COLLABORATORS';
/** action types */
export const SET_COLLABORATOR =
  'virtunus/reducer/collaborators/SET_COLLABORATOR';
/** action types */
export const DELETE_COLLABORATOR =
  'virtunus/reducer/collaborators/DELETE_COLLABORATOR';

/** interface for SET_COLLABORATORS action */
export interface SetCollaboratorsAction extends AnyAction {
  collaborators: CollaboratorObj[];
  type: typeof SET_COLLABORATORS;
}

/** interface for SET_COLLABORATOR action */
export interface SetCollaboratorAction extends AnyAction {
  collaborator: CollaboratorObj;
  type: typeof SET_COLLABORATOR;
}

/** interface for DELETE_COLLABORATOR action */
export interface DeleteCollaboratorAction extends AnyAction {
  id: string;
  type: typeof DELETE_COLLABORATOR;
}

/** Create type for collaborators reducer actions */
export type CollaboratorsActionTypes =
  | SetCollaboratorsAction
  | SetCollaboratorAction
  | DeleteCollaboratorAction
  | AnyAction;

// action creators

/** set collaborators action creator
 * @param {CollaboratorObj[]} collaborators - the collaborators to set
 * @returns {SetCollaboratorsAction} - an action to set collaborators in store
 */
export const setCollaborators = (
  collaborators: CollaboratorObj[]
): SetCollaboratorsAction => ({
  collaborators,
  type: SET_COLLABORATORS,
});

/** set collaborator action creator
 * @param {CollaboratorObj} collaborator - the collaborator to set
 * @returns {SetCollaboratorAction} - an action to set collaborator in store
 */
export const setCollaborator = (
  collaborator: CollaboratorObj
): SetCollaboratorAction => ({
  collaborator,
  type: SET_COLLABORATOR,
});

/** delete collaborator action creator
 * @param {string} id - the collaborator id to delete
 * @returns {DeleteCollaboratorAction} - an action to delete collaborator in store
 */
export const deleteCollaborator = (id: string): DeleteCollaboratorAction => ({
  id,
  type: DELETE_COLLABORATOR,
});

// the reducer

/** interface for collaborators state in redux store */
type CollaboratorsState = CollaboratorObj[];

/** Create an immutable session state */
export type ImmutableCollaboratorsState = SeamlessImmutable.ImmutableArray<
  CollaboratorsState
>;

/** initial collaborators state */
const initialState: ImmutableCollaboratorsState = SeamlessImmutable([]);

/** the collaborators reducer function */
export default function reducer(
  state: ImmutableCollaboratorsState = initialState,
  action: CollaboratorsActionTypes
): ImmutableCollaboratorsState {
  switch (action.type) {
    case SET_COLLABORATORS:
      return SeamlessImmutable(action.collaborators);
    case SET_COLLABORATOR:
      return SeamlessImmutable([
        ...state.asMutable({ deep: true }),
        action.collaborator,
      ]);
    case DELETE_COLLABORATOR:
      return SeamlessImmutable(
        lodash.filter(
          state.asMutable({ deep: true }) as any,
          (iterCollaborator: CollaboratorObj) =>
            iterCollaborator.id !== action.id
        )
      );
    default:
      return state;
  }
}

// selectors

/** returns the collaborators list
 * @param {Partial<Store>} state - the redux store
 * @return { CollaboratorObj[] } - the existing collaborators
 */
export function getCollaborators(state: Partial<Store>): CollaboratorObj[] {
  return (state as any)[reducerName];
}

/** returns the collaborator by id
 * @param {Partial<Store>} state - the redux store
 * @param {string} id - the requested id
 * @return { CollaboratorObj | null } - the existing collaborators
 */
export function getCollaboratorById(
  state: Partial<Store>,
  id: string
): CollaboratorObj | null {
  return lodash.find((state as any)[reducerName], { id }) || null;
}
