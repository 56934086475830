import { Button, Modal } from 'antd';
import React from 'react';
import './TodoFilterDeleteButton.scss';
import { connect } from 'react-redux';
import {
  fixTodoFilterOrders,
  setTodoFilter,
  TodoFilterObj,
} from '../../store/ducks/todoFilters';

/** interface to describe TodoFilterDeleteButton props */
interface TodoFilterDeleteButtonProps {
  filterInfo: TodoFilterObj;
  setTodoFilterActionCreator: typeof setTodoFilter;
  closeHandler: () => void;
  fixTodoFilterOrdersActionCreator: typeof fixTodoFilterOrders;
}

/** Placeholders subject to delete */
const DELETE_TODO_FILTER_LABEL = 'Delete Filter';

const TodoFilterDeleteButton: React.FC<TodoFilterDeleteButtonProps> = (
  props: TodoFilterDeleteButtonProps
) => {
  const {
    filterInfo,
    setTodoFilterActionCreator,
    closeHandler,
    fixTodoFilterOrdersActionCreator,
  } = props;
  const { confirm } = Modal;

  /** manages the favourite toogle request */
  const okHandler = () => {
    setTodoFilterActionCreator({
      ...filterInfo,
      synced: false,
      deleted: true,
    });

    /** fix the order after filter deletion */
    fixTodoFilterOrdersActionCreator(filterInfo.parent);
  };

  const config = {
    className: 'TodoFilterDeleteButton-modal-container',
    title: (
      <div className="TodoFilterDeleteButton-modal-title">Delete filter</div>
    ),
    content: (
      <div>
        Are you sure you want to delete the filter{' '}
        <span style={{ color: 'var(--theme-primary)' }}>
          {' '}
          {filterInfo.title}
        </span>{' '}
        ?<br />
      </div>
    ),
    width: 500,
    onOk() {
      okHandler();
    },
  };

  const requestHandler = () => {
    closeHandler();
    confirm(config);
  };

  return (
    <Button
      onClick={requestHandler}
      icon={<i className="fas fa-trash"></i>}
      /**
       * css classname exception naming
       *
       * reason: to keep the options panel style consistent with a single class
       */
      className="TodoFilterOptionsPanel-item"
    >
      {DELETE_TODO_FILTER_LABEL}
    </Button>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  setTodoFilterActionCreator: setTodoFilter,
  fixTodoFilterOrdersActionCreator: fixTodoFilterOrders,
};

/** connect TodoFilterDeleteButton to the redux store */
const ConnectedTodoFilterDeleteButton = connect(
  null,
  mapDispatchToProps
)(TodoFilterDeleteButton);

export default ConnectedTodoFilterDeleteButton;
