import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_COMMENTS_ENDPOINT } from '../../configs/endpoints';
import { axioService, DELETE } from '../../services/axioService';
import { deleteComment } from '../../store/ducks/comments';
import { decreaseCommentCount } from '../../store/ducks/tasks';
import './TodoCommentDelete.scss';

/** inteface to describe the TodoCommentDelete props */
interface TodoCommentDeleteProps {
  taskId: string;
  title: string;
  id: string;
  deleteCommentActionCreator: typeof deleteComment;
  decreaseCommentCountActionCreator: typeof decreaseCommentCount;
}

const TodoCommentDelete: React.FC<TodoCommentDeleteProps> = (
  props: TodoCommentDeleteProps
) => {
  const {
    id,
    title,
    taskId,
    deleteCommentActionCreator,
    decreaseCommentCountActionCreator,
  } = props;
  const { confirm } = Modal;

  /** handles the delete request of comment */
  const deleteRequestHandler = async () => {
    try {
      await axioService(DELETE, SERVER_COMMENTS_ENDPOINT + '/' + id, {}, true);
      deleteCommentActionCreator(id);
      decreaseCommentCountActionCreator(taskId);
    } catch (exception) {
      console.error(exception);
    }
  };

  /** creates the modal configuration related to delete comment */
  const config = {
    className: 'TodoDetailedTask-modal-container',
    title: <div className="TodoDetailedTask-modal-title">Delete Comment</div>,
    content: (
      <div>
        Are you sure you want to delete the comment{' '}
        <span style={{ color: 'var(--theme-primary)' }}>{title}</span> ?<br />
      </div>
    ),
    width: 500,
    onOk() {
      deleteRequestHandler();
    },
  };

  return (
    <div
      onClick={() => confirm(config)}
      className="TodoCommentDelete-container"
    >
      <i className="fas fa-trash"></i>
    </div>
  );
};

/** connect the component to the store */

/** map props to actions */
const mapDispatchToProps = {
  deleteCommentActionCreator: deleteComment,
  decreaseCommentCountActionCreator: decreaseCommentCount,
};

/** connect TodoCommentDelete to the redux store */
const ConnectedTodoCommentDelete = connect(
  null,
  mapDispatchToProps
)(TodoCommentDelete);

export default ConnectedTodoCommentDelete;
