import { Button, Collapse, message } from 'antd';
import React from 'react';
import { MEETING_ACTIVTY_TYPE } from '../../../configs/activityTypes';
import { SERVER_TASK_TYPES_DETAILS_ENDPOINT } from '../../../configs/endpoints';
import { axioService, GET, POST } from '../../../services/axioService';
import { FileObj } from '../../todo/uploadToolbar/UploadToolbar';
import MeetingAgendaItem from '../meetingAgendaItem/MeetingAgendaItem';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import './MeetingAgenda.scss';
import { stateToHTML } from 'draft-js-export-html';

/** interface to describe the agenda state item */
export interface AgendaStateItem {
  title: string;
  description: EditorState;
  attachments: FileObj[];
}

/** initial agenda state */
export const INITIAL_AGENDA_STATE = [
  { title: '', description: EditorState.createEmpty(), attachments: [] },
];

/** interface to describe MeetingAgenda props */
interface MeetingAgendaProps {
  taskId: string;
  counter: number;
}

const MeetingAgenda: React.FC<MeetingAgendaProps> = (
  props: MeetingAgendaProps
) => {
  const { taskId, counter } = props;
  const { Panel } = Collapse;

  /** manages the agenda state */
  const [agendaState, setAgendaState] = React.useState<AgendaStateItem[]>(
    INITIAL_AGENDA_STATE
  );

  const [activeKey, setActiveKey] = React.useState<string[]>([]);

  /** cycles */

  /** fetch event data from server */
  React.useEffect(() => {
    const fetchAgendaData = async () => {
      try {
        const response = await axioService(
          GET,
          SERVER_TASK_TYPES_DETAILS_ENDPOINT,
          { type: MEETING_ACTIVTY_TYPE.value, task_id: taskId },
          true
        );
        if (response.data.objectives && response.data.objectives.length > 0) {
          setAgendaState(
            response.data.objectives.map((iter: any) => ({
              ...iter,
              description:
                (response.data.event?.description &&
                  EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(iter?.description as any) as any
                    )
                  )) ||
                EditorState.createEmpty(),
            }))
          );
        }
      } catch (Exception) {
        console.error(Exception);
      }
    };
    fetchAgendaData();
  }, [counter]);

  /** handlers */

  /** agenda state change handler */
  const onAgendaChange = (modifiedState: AgendaStateItem[]) => {
    setAgendaState(modifiedState);
  };

  /** remove agenda item handler */
  const removeAgendaItem = async (index: number) => {
    const modifiedState = [...agendaState];
    modifiedState.splice(index, 1);
    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          objectives:
            modifiedState.length === 0 ? INITIAL_AGENDA_STATE : modifiedState,
        },
        true
      );
      message.success('Saved');
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
    if (modifiedState.length === 0) {
      setAgendaState(INITIAL_AGENDA_STATE);
    } else {
      setAgendaState(modifiedState);
    }
  };

  /** add agenda handler */
  const addAgenda = () => {
    setAgendaState([...agendaState, ...INITIAL_AGENDA_STATE]);
  };

  /** commit agenda state to server hadler */
  const commitHandler = async () => {
    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: taskId,
          objectives: agendaState.map((iter) => ({
            ...iter,
            description: stateToHTML(
              iter.description.getCurrentContent()
            ).toString(),
          })),
        },
        true
      );
      message.success('Saved');
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
  };

  const onActiveKeyChange = (requested: string[]) => setActiveKey(requested);

  return (
    <div className="MeetingAgenda-container">
      <Collapse activeKey={activeKey} onChange={onActiveKeyChange as any}>
        {agendaState.map((iterAgendaItem: AgendaStateItem, index: number) => (
          <Panel
            showArrow={false}
            header={
              <div className="MeetingAgenda-header-title">
                {iterAgendaItem.title === '' || !iterAgendaItem.title ? (
                  <span>Agenda Title</span>
                ) : (
                  iterAgendaItem.title
                )}
              </div>
            }
            key={index}
            extra={
              <Button
                type="text"
                shape="circle"
                size="small"
                onClick={() => removeAgendaItem(index)}
              >
                <i className="fas fa-times" />
              </Button>
            }
          >
            <MeetingAgendaItem
              index={index}
              agendaState={agendaState}
              onAgendaChange={onAgendaChange}
              onBlurHandler={commitHandler}
            />
          </Panel>
        ))}
      </Collapse>
      <Button className="MeetingAgenda-add-btn" type="text" onClick={addAgenda}>
        <i className="fas fa-plus" /> Add Agenda
      </Button>
    </div>
  );
};

/** export default meeting agenda component */
export default MeetingAgenda;
