import { Button, Dropdown, Input } from 'antd';
import clsx from 'clsx';
import React from 'react';
import './TasksSection.scss';
import TasksList from '../projectTasksList/ProjectTasksList';
import {
  fixSectionOrders,
  getSectionById,
  SectionObj,
  setSection,
} from '../../store/ducks/sections';
import ConnectedAddTaskSection from '../addTaskSection/AddTaskSection';
import ConnectedAddTask from '../addTask/AddTask';
import { connect } from 'react-redux';
import { Store } from 'redux';
import TodoSectionOptionsPanel from '../../components/todo/sections/todoSectionOptionsPanel/TodoSectionOptionsPanel';
import { deleteTasksBasedOnSections } from '../../store/ducks/tasks';
import { SORT_ORDER, SORT_TYPE } from '../../configs/constants';
import {
  disablePointerEventsOnMainLayout,
  disablePointerEventsOnTaskSider,
} from '../../services/utility';

/** types */

/** interface to describe TasksSection props */
interface TasksSectionProps {
  section: SectionObj;
  projectId: string;
  sectionInfo: SectionObj;
  sortType: SORT_TYPE;
  sortOrder: SORT_ORDER;
  hideCompletedTasks: boolean;
  setSectionActionCreator: typeof setSection;
  deleteTasksBasedOnSectionsActionCreator: typeof deleteTasksBasedOnSections;
  fixSectionOrdersActionCreator: typeof fixSectionOrders;
}

const TasksSection: React.FC<TasksSectionProps> = (
  props: TasksSectionProps
) => {
  const {
    section,
    projectId,
    sectionInfo,
    sortType,
    sortOrder,
    hideCompletedTasks,
    setSectionActionCreator,
    deleteTasksBasedOnSectionsActionCreator,
    fixSectionOrdersActionCreator,
  } = props;
  const sectionEditInput = React.useRef<any>(null);

  // React component states

  /** manages the add task visibility */
  const [addTaskVisible, setAddTaskVisible] = React.useState<boolean>(false);
  /** manages the edit section visibility */
  const [editSectionVisible, setEditSectionVisible] = React.useState<boolean>(
    false
  );
  const [sectionDropdownVisible, setSectionDropdownVisible] = React.useState<
    boolean
  >(false);

  /** sets focus on the section edit input */
  React.useEffect(() => {
    if (sectionEditInput.current) {
      sectionEditInput.current.focus();
    }
  }, [editSectionVisible]);

  /**
   * onExpandHandler toggles the expanded panel
   */
  const onExpandHandler = () => {
    setSectionActionCreator({
      ...sectionInfo,
      synced: false,
      expanded: !sectionInfo.expanded,
    });
  };

  /**
   * saves the edited section request
   * @param {React.ChangeEvent<HTMLInputElement>} event - the input event
   */
  const saveSectionName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditSectionVisible(false);
    disablePointerEventsOnMainLayout(false);
    disablePointerEventsOnTaskSider(false);
    if (event.currentTarget.value !== '') {
      setSectionActionCreator({
        ...sectionInfo,
        synced: false,
        title: event.currentTarget.value,
      });
    }
  };

  /**
   * sets the visibility of section dropdown
   * @param {boolean} visible - the visibility to set
   */
  const onSectionDropdownVisibleChange = (visible: boolean) => {
    setSectionDropdownVisible(visible);
  };

  /** opens the edit section */
  const openEditTaskSection = () => {
    setEditSectionVisible(true);
    disablePointerEventsOnMainLayout(true);
    disablePointerEventsOnTaskSider(true);
  };

  /** handles the edit request of section */
  const editRequestHandler = () => {
    setSectionDropdownVisible(false);
    openEditTaskSection();
  };

  /** handles the delete request of a section */
  const deleteRequestHandler = () => {
    /** deletes the requested sections */
    setSectionActionCreator({
      ...sectionInfo,
      synced: false,
      deleted: true,
    });

    /** fixes the existing sections order */
    fixSectionOrdersActionCreator(sectionInfo.projectId);

    /** deletes section related tasks */
    deleteTasksBasedOnSectionsActionCreator([sectionInfo.id]);
  };

  return (
    <div className="TasksSection-container">
      <div className="TasksSection-header">
        <div
          onClick={onExpandHandler}
          className={clsx({
            'TasksSection-expand-icon': true,
            'TasksSection-expand-icon-active': sectionInfo.expanded,
          })}
        >
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="TasksSection-label-container">
          <div className="TasksSection-label">
            {!editSectionVisible ? (
              <div onClick={openEditTaskSection}>{sectionInfo.title}</div>
            ) : (
              <Input
                defaultValue={sectionInfo.title}
                ref={sectionEditInput}
                onBlur={saveSectionName}
                onPressEnter={saveSectionName as any}
              />
            )}
          </div>
        </div>
        <div className="TasksSection-add-icon">
          <Button
            onClick={() => setAddTaskVisible(true)}
            type="text"
            size="small"
            className={clsx({
              'TasksSection-add-icon-disabled': addTaskVisible,
            })}
          >
            <i className="fas fa-plus"></i> Add Task
          </Button>
        </div>
        <div className="TasksSection-more-icon">
          <Dropdown
            overlay={
              <TodoSectionOptionsPanel
                sectionInfo={sectionInfo}
                closeHandler={() => setSectionDropdownVisible(false)}
                editRequestHandler={editRequestHandler}
                deleteRequestHandler={deleteRequestHandler}
              />
            }
            placement="bottomRight"
            trigger={['click']}
            visible={sectionDropdownVisible}
            onVisibleChange={onSectionDropdownVisibleChange}
          >
            <Button
              shape="circle"
              size="small"
              icon={<i className="fas fa-ellipsis-h"></i>}
            />
          </Dropdown>
        </div>
      </div>
      <div
        onMouseEnter={() =>
          (document.getElementById(
            'todo-project-sections'
          ) as any).style.setProperty('--sections-drag-handler', 0)
        }
        onMouseLeave={() =>
          (document.getElementById(
            'todo-project-sections'
          ) as any).style.setProperty('--sections-drag-handler', 1)
        }
        className={clsx({
          'TasksSection-body': true,
          'TasksSection-body-hidden': !sectionInfo.expanded,
        })}
      >
        {addTaskVisible && (
          <div className="TasksSection-add-task">
            <ConnectedAddTask
              projectId={projectId}
              sectionId={section.id}
              postSuccessHandler={() => setAddTaskVisible(false)}
              postCloseHandler={() => setAddTaskVisible(false)}
            />
          </div>
        )}
        <TasksList
          projectId={projectId}
          sectionId={section.id}
          sortType={sortType}
          sortOrder={sortOrder}
          hideCompletedTasks={hideCompletedTasks}
        />
        <ConnectedAddTaskSection
          requestedOrder={sectionInfo.order + 0.5}
          projectId={projectId}
        />
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  sectionInfo: SectionObj;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<
    Omit<TasksSectionProps, keyof DispatchedStateProps>,
    keyof typeof mapDispatchToProps
  >
): DispatchedStateProps => {
  const result = {
    sectionInfo: getSectionById(state, parentProps.section.id) as SectionObj,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSectionActionCreator: setSection,
  deleteTasksBasedOnSectionsActionCreator: deleteTasksBasedOnSections,
  fixSectionOrdersActionCreator: fixSectionOrders,
};

/** connect TasksSection to the redux store */
const ConnectedTasksSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksSection);

/** allow import as default component */
export default ConnectedTasksSection;
