/** interface to describe the activity type object */
export interface ActivityType {
  label: string;
  value: string;
  icon: string;
}

/** meeting activity type */
export const MEETING_ACTIVTY_TYPE: ActivityType = {
  label: 'Meeting',
  value: 'meeting',
  icon: 'fas fa-users',
};

/** travel activity type */
export const TRAVEL_ACTIVTY_TYPE: ActivityType = {
  label: 'Travel',
  value: 'travel',
  icon: 'fas fa-umbrella-beach',
};

/** exercise activity type */
export const EXERCISE_ACTIVTY_TYPE: ActivityType = {
  label: 'Exercise',
  value: 'exercise',
  icon: 'fas fa-dumbbell',
};

/** medicine activity type */
export const MEDICINE_ACTIVTY_TYPE: ActivityType = {
  label: 'Medicine',
  value: 'medicine',
  icon: 'fas fa-capsules',
};

/** prayer activity type */
export const PRAYER_ACTIVTY_TYPE: ActivityType = {
  label: 'Prayer',
  value: 'prayer',
  icon: 'fas fa-pray',
};

/** shopping activity type */
export const SHOPPING_ACTIVTY_TYPE: ActivityType = {
  label: 'Shopping',
  value: 'shopping',
  icon: 'fas fa-shopping-bag',
};

/** daily meal activity type */
export const DAILY_MEAL_ACTIVTY_TYPE: ActivityType = {
  label: 'Daily meal',
  value: 'daily_meal',
  icon: 'fas fa-concierge-bell',
};

/** sports activity type */
export const SPORTS_ACTIVTY_TYPE: ActivityType = {
  label: 'Sports',
  value: 'sports',
  icon: 'fas fa-swimmer',
};

/** music activity type */
export const MUSIC_ACTIVTY_TYPE: ActivityType = {
  label: 'music',
  value: 'music',
  icon: 'fas fa-music',
};

/** plantation activity type */
export const PLANTATION_ACTIVTY_TYPE: ActivityType = {
  label: 'Plantation',
  value: 'plantation',
  icon: 'fas fa-seedling',
};

/** list of activity types present in the system  */
export const ACTIVITY_TYPES: ActivityType[] = [
  MEETING_ACTIVTY_TYPE,
  TRAVEL_ACTIVTY_TYPE,
  EXERCISE_ACTIVTY_TYPE,
  MEDICINE_ACTIVTY_TYPE,
  PRAYER_ACTIVTY_TYPE,
  SHOPPING_ACTIVTY_TYPE,
  DAILY_MEAL_ACTIVTY_TYPE,
  SPORTS_ACTIVTY_TYPE,
  MUSIC_ACTIVTY_TYPE,
  PLANTATION_ACTIVTY_TYPE,
];
