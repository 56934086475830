import { Card, Statistic } from 'antd';
import React from 'react';
import './MeetingCard.scss';

interface MeetingCardProps {
  icon: string;
  value: number;
  color: string;
  title: string;
}

const MeetingCard: React.FC<MeetingCardProps> = (props: MeetingCardProps) => {
  const { value, color, title, icon } = props;
  return (
    <Card className="MeetingCard-card">
      <div className="MeetingCard-container">
        <i style={{ color }} className={icon} />
        <Statistic
          title={title}
          value={value}
          precision={0}
          valueStyle={{ color }}
          suffix="This month"
        />
      </div>
    </Card>
  );
};

export default MeetingCard;
