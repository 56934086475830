import React from 'react';
import { Button, Image } from 'antd';
import './AttachmentItem.scss';

interface AttachmentItemProps {
  src: string;
  name: string;
  size: string;
  removeHandler: () => void;
}

const AttachmentItem: React.FC<AttachmentItemProps> = (
  props: AttachmentItemProps
) => {
  const { src, name, size, removeHandler } = props;
  return (
    <div className="AttachmentItem-container">
      <Button
        onClick={removeHandler}
        className="AttachmentItem-delete"
        size="small"
        shape="circle"
      >
        <i className="fas fa-times" />
      </Button>
      <div className="AttachmentItem-pic-body">
        <Image
          width={150}
          height={100}
          src={src}
          fallback="/file-regular.svg"
        />
      </div>
      <a href={src} download target="blank">
        <div className="AttachmentItem-title">{name}</div>
      </a>
      <div className="AttachmentItem-size">{size}</div>
    </div>
  );
};

export default AttachmentItem;
