import React from 'react';
import ConnectedIteratorBasedTaskList from '../iteratorBasedTaskList/IteratorBasedTaskList';
import { getOverdueTasksCount, TaskObj } from '../../store/ducks/tasks';
import './TodoTodayContent.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { Store } from 'redux';
import clsx from 'clsx';
import { Select } from 'antd';
import {
  ASC_SORT,
  DEFAULT_SORT,
  DESC_SORT,
  DUE_DATE_SORT,
  SORTING_OPTIONS,
  SORT_TYPE,
} from '../../configs/constants';
import lodash from 'lodash';

interface TodoTodayContentProps {
  overdueTasksCount: number;
}

const TodoTodayContent: React.FC<TodoTodayContentProps> = (
  props: TodoTodayContentProps
) => {
  const { overdueTasksCount } = props;
  const { Option } = Select;

  /** manages the sorting type */
  const [sortType, setSortType] = React.useState<SORT_TYPE>(DUE_DATE_SORT);

  /** manages the sorting order */
  const [sortOrder, setSortOrder] = React.useState<ASC_SORT | DESC_SORT>(
    ASC_SORT
  );

  /**
   * sets the requested sort type
   * @param {SORT_TYPE} requestedSortType - the request the sort type
   */
  const onSortTypeChange = (requestedSortType: SORT_TYPE) => {
    if (requestedSortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    }
    setSortType(requestedSortType);
  };

  /** toggles the sorting order */
  const onSortOrderChange = () => {
    if (sortType === DEFAULT_SORT) {
      setSortOrder(ASC_SORT);
    } else {
      setSortOrder(sortOrder === ASC_SORT ? DESC_SORT : ASC_SORT);
    }
  };

  const todayIterator = (taskItem: TaskObj) => {
    const taskDate = moment(taskItem.dueDate);
    return taskDate.isSame(moment(), 'day') && taskItem.isExists === 1;
  };

  const overdueIterator = (taskItem: TaskObj) => {
    const taskDate = moment(taskItem.dueDate);
    return taskDate.isBefore(moment(), 'day') && taskItem.isExists === 1;
  };

  return (
    <div className="TodoTodayContent-container">
      <div className="TodoTodayContent-title-container">
        <h2 className="TodoTodayContent-today-title">
          Today{' '}
          <span className="TodoTodayContent-date-title">
            {' '}
            {moment().format('ddd D MMM')}{' '}
          </span>
        </h2>
        <div className="TodoTodayContent-sort-container">
          <div
            className={clsx({
              'TodoTodayContent-sort-order': true,
              'TodoTodayContent-sort-order-enabled': sortType !== DEFAULT_SORT,
            })}
            onClick={onSortOrderChange}
          >
            <i
              className={
                sortOrder === ASC_SORT
                  ? 'fas fa-sort-amount-down'
                  : 'fas fa-sort-amount-up'
              }
            ></i>
          </div>
          <div>
            <Select
              dropdownClassName="TodoTodayContent-sort-dropdown"
              value={sortType}
              bordered={false}
              onChange={onSortTypeChange}
              suffixIcon={<i className="fas fa-sort-down" />}
            >
              {lodash
                .without(SORTING_OPTIONS, DEFAULT_SORT)
                .map((iterOption, index) => (
                  <Option key={'sort' + index} value={iterOption}>
                    {iterOption}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      </div>
      {overdueTasksCount > 0 && (
        <React.Fragment>
          <div className="TodoTodayContent-overdue-section-title">Overdue</div>
          <ConnectedIteratorBasedTaskList
            iterator={overdueIterator}
            preventExpanded={true}
            sortOrder={sortOrder}
            sortType={sortType}
          />
          <div className="TodoTodayContent-today-section-title">Today</div>
        </React.Fragment>
      )}
      <ConnectedIteratorBasedTaskList
        iterator={todayIterator}
        preventExpanded={true}
        sortOrder={sortOrder}
        sortType={sortType}
      />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  overdueTasksCount: number;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    overdueTasksCount: getOverdueTasksCount(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoTodayContent to the redux store */
const ConnectedTodoTodayContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoTodayContent);

export default ConnectedTodoTodayContent;
