/** global constants */

/** apps */

/** TODO app name */
export const TODO_APP = process.env.REACT_APP_LOCAL_NAME || 'todo';

/** TODO sorting options */

/** Default sort */
export const DEFAULT_SORT = 'custom sort';
export type DEFAULT_SORT = typeof DEFAULT_SORT;

/** Alphabetical sort */
export const ALPHA_SORT = 'alphabetically';
export type ALPHA_SORT = typeof ALPHA_SORT;

/** priority sort */
export const PRIORITY_SORT = 'priority';
export type PRIORITY_SORT = typeof PRIORITY_SORT;

/** due date sort */
export const DUE_DATE_SORT = 'due date';
export type DUE_DATE_SORT = typeof DUE_DATE_SORT;

/** sort types  */
export type SORT_TYPE =
  | DEFAULT_SORT
  | ALPHA_SORT
  | DUE_DATE_SORT
  | PRIORITY_SORT;

/** sort options */
export const SORTING_OPTIONS = [
  DEFAULT_SORT,
  ALPHA_SORT,
  PRIORITY_SORT,
  DUE_DATE_SORT,
];

/** sorting orders */

/** ascending sort */
export const ASC_SORT = 'asc';
export type ASC_SORT = typeof ASC_SORT;

/** descending sort */
export const DESC_SORT = 'desc';
export type DESC_SORT = typeof DESC_SORT;

/** sort order type */
export type SORT_ORDER = ASC_SORT | DESC_SORT;

/** internal date time format */
export const INTERNAL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

/** string format of the date */
export const DATE_FORMAT = 'YYYY-MM-DD';

/** string format of the time */
export const TIME_FORMAT = 'HH:mm';
