import React from 'react';
import './TodoActivityTypeItem.scss';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActivityType,
  MEETING_ACTIVTY_TYPE,
} from '../../configs/activityTypes';
import { LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT } from '../../configs/endpoints';
import { Button, Dropdown } from 'antd';
import { Store } from 'redux';
import { getMeetingCountObj } from '../../store/ducks/meeting';
import { connect } from 'react-redux';
import UpcomingBadge from '../../components/upcomingBadge/UpcomingBadge';

export interface TodoActivityTypeItemProps {
  activityType: ActivityType;
  path: number;
  count: number;
}

const TodoActivityTypeItem: React.FC<TodoActivityTypeItemProps> = (
  props: TodoActivityTypeItemProps
) => {
  const { activityType, path, count } = props;
  const history = useHistory();
  const location = useLocation();

  /** manages the visibility of activity more panel */
  const [visible, setVisible] = React.useState<boolean>(false);

  /** handles the navigation */
  const navigationHandler = () =>
    history.push(LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT + activityType.value);

  /** handles the keyboard enter request */
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      navigationHandler();
    }
  };

  return (
    <div
      className={clsx({
        'TodoActivityTypeItem-container': true,
        'TodoActivityTypeItem-container--active':
          location.pathname ===
          LOCAL_PRE_ACTIVITY_TYPE_ENDPOINT + activityType.value,
        'TodoActivityTypeItem-container--first': path === 1,
        'TodoActivityTypeItem-container--second': path === 2,
        'TodoActivityTypeItem-container--third': path === 3,
        'TodoActivityTypeItem-container--fourth': path === 4,
        'TodoActivityTypeItem-container--disabled':
          activityType.value !== MEETING_ACTIVTY_TYPE.value,
      })}
      onClick={navigationHandler}
      onKeyPress={handleKeyPress}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <i className={activityType.icon + ' TodoActivityTypeItem-tag'}></i>
      <div
        className={clsx({
          'TodoActivityTypeItem-label': true,
          'TodoActivityTypeItem-label--first': path === 1,
          'TodoActivityTypeItem-label--second': path === 2,
          'TodoActivityTypeItem-label--third': path === 3,
          'TodoActivityTypeItem-label--fourth': path === 4,
        })}
      >
        {activityType.label}
        {activityType.value !== MEETING_ACTIVTY_TYPE.value && <UpcomingBadge />}
      </div>
      <div className="TodoActivityTypeItem-count">{count}</div>
      <div
        className={clsx({
          'TodoActivityTypeItem-more-btn-container': true,
          'TodoActivityTypeItem-more-btn-container--active': visible,
        })}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown
            overlay={<div />}
            placement="bottomRight"
            trigger={['click']}
            visible={visible}
            onVisibleChange={(requestedVisibility: boolean) =>
              setVisible(requestedVisibility)
            }
          >
            <Button
              className="TodoActivityTypeItem-more-btn"
              shape="circle"
              size="small"
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  count: number;
}

/** Map props to state  */
const mapStateToProps = (
  state: Partial<Store>,
  parentProps: Omit<TodoActivityTypeItemProps, keyof DispatchedStateProps>
): DispatchedStateProps => {
  const result = {
    count:
      parentProps.activityType.value === MEETING_ACTIVTY_TYPE.value
        ? getMeetingCountObj(state).total
        : 0,
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect TodoActivityTypeItem to the redux store */
const ConnectedTodoActivityTypeItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(TodoActivityTypeItem);

export default ConnectedTodoActivityTypeItem;
