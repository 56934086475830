import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './withTaskSider.scss';
import queryString from 'query-string';
import clsx from 'clsx';
import TodoDetailedTask from '../../containers/todoDetailedTask/TodoDetailedTask';
import ConnectedTodoDetailedTaskBreadcrumb from '../../containers/todoDetailedTaskBreadcrumb/TodoDetailedTaskBreadcrumb';
import { syncService } from '../../services/syncService';

/**
 * withTaskSider hoc method wraps the passing component with task sider
 * @param {React.ComponentType<ComponentPropsType>} Component - a component that needs the task sider
 * @returns a component containing task sider with the passed component
 */
function withTaskSider<ComponentPropsType>(
  Component: React.ComponentType<ComponentPropsType>
) {
  return (props: ComponentPropsType) => {
    {
      const location = useLocation();
      const history = useHistory();

      /** retrieve the task id from url */
      const taskId =
        (queryString.parse(location.search)['task'] as string) || '';
      const activeTabId =
        (queryString.parse(location.search)['tab'] as string) || '1';

      /** manages the visibility of overlay */
      const [visible, setVisible] = React.useState<boolean>(false);
      /** manages the visibility of sider */
      const [open, setOpen] = React.useState<boolean>(false);

      React.useEffect(() => {
        if (taskId === '') {
          setOpen(false);
          /** allow animation to take place */
          setTimeout(() => setVisible(false), 400);
        } else {
          syncService();
          setVisible(true);
          /** allow animation to take place */
          setTimeout(() => setOpen(true), 100);
        }
      }, [taskId]);

      /** closes the sider */
      const closeSider = () => {
        history.push({ search: '' });
      };

      /** prevent event bubbling */
      const preventCloseSider = (event: React.MouseEvent) => {
        event.stopPropagation();
      };

      return (
        <React.Fragment>
          {visible && (
            <div onClick={closeSider} className="withTaskSider-container">
              <div className="withTaskSider-inner-container">
                <div
                  onClick={preventCloseSider}
                  className={clsx({
                    'withTaskSider-sider': true,
                    'withTaskSider-sider-open': open,
                  })}
                >
                  <div className="withTaskSider-sider-content">
                    <div className="withTaskSider-header">
                      <ConnectedTodoDetailedTaskBreadcrumb id={taskId} />
                      <div
                        className="withTaskSider-cancel"
                        onClick={closeSider}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                    </div>
                    <div className="withTaskSider-body">
                      <TodoDetailedTask id={taskId} activeTabId={activeTabId} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Component {...props} />
        </React.Fragment>
      );
    }
  };
}

export default withTaskSider;
