import { Button, Select } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import RecurrenceTimePicker from '../recurrenceTimePicker/RecurrenceTimePicker';
import { RECURRENCE_MONTH_OPTIONS } from './constants';
import './RecurrenceMonthPicker.scss';

export interface RecurrenceMonthValue {
  key: string;
  value: moment.Moment[];
}

interface RecurrenceMonthPickerProps {
  values: Array<RecurrenceMonthValue | null>;
  onChangeHandler: (requested: Array<RecurrenceMonthValue | null>) => void;
}

const RecurrenceMonthPicker: React.FC<RecurrenceMonthPickerProps> = (
  props: RecurrenceMonthPickerProps
) => {
  const { Option } = Select;
  const { values, onChangeHandler } = props;

  const onAddHandler = (index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index + 1, 0, null);
    onChangeHandler(tmpValues);
  };

  const onDeleteHandler = (index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1);
    if (tmpValues.length) {
      onChangeHandler(tmpValues);
    } else {
      onChangeHandler([null]);
    }
  };

  const onSelectHandler = (
    monthKey: string | null,
    index: number,
    requested: null | moment.Moment[] = null
  ) => {
    if (monthKey) {
      const tmpValues = [...values];
      tmpValues.splice(index, 1, {
        key: monthKey,
        value: requested ? requested : [moment()],
      });
      onChangeHandler(tmpValues);
    }
  };

  const onChangeWrapper = (
    monthKey: string,
    index: number,
    requested: moment.Moment[]
  ) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1, { key: monthKey, value: requested });
    onChangeHandler(tmpValues);
    if (requested.length === 0) {
      onDeleteHandler(index);
    }
  };

  const onAddTimeHandler = (monthKey: string, index: number) => {
    const tmpValues = [...values];
    tmpValues.splice(index, 1, {
      key: monthKey,
      value: values[index]
        ? [...(values[index] as any)['value'], moment()]
        : [moment()],
    });
    onChangeHandler(tmpValues);
  };

  return (
    <div className="RecurrenceMonthPicker-container">
      {values.map((iterMonthItem, index) =>
        iterMonthItem ? (
          <div key={`month` + index} className="RecurrenceMonthPicker-row">
            <div className="RecurrenceMonthPicker-label">
              <Select
                value={iterMonthItem['key']}
                onChange={(requested) =>
                  onSelectHandler(requested, index, iterMonthItem['value'])
                }
                className="RecurrenceMonthPicker-unit"
                dropdownClassName="RecurrenceMonthPicker-month-dropdown"
                suffixIcon={<i className="fas fa-sort-down" />}
              >
                {Object.keys(RECURRENCE_MONTH_OPTIONS).map(
                  (iterMonthOption: string) => (
                    <Option
                      className="RecurrenceMonthPicker-month-option"
                      key={'recurrence-' + iterMonthOption}
                      value={iterMonthOption}
                      disabled={
                        lodash.find(values, {
                          key: iterMonthOption,
                        })
                          ? true
                          : false
                      }
                    >
                      {(RECURRENCE_MONTH_OPTIONS as any)[iterMonthOption]}
                      {iterMonthItem['key'] === iterMonthOption && (
                        <i className="fas fa-check" />
                      )}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <div className="RecurrenceMonthPicker-time-container">
              {iterMonthItem['value'].map(
                (iterTimeUnit: moment.Moment, timeIndex: number) => (
                  <RecurrenceTimePicker
                    values={iterMonthItem.value}
                    onChangeHandler={(requested) =>
                      onChangeWrapper(iterMonthItem.key, index, requested)
                    }
                    index={timeIndex}
                    key={'daily' + timeIndex}
                  />
                )
              )}
              <Button
                onClick={() => onAddTimeHandler(iterMonthItem.key, index)}
                className="RecurrenceMonthPicker-plus"
                type="text"
                shape="circle"
                icon={<i className="fas fa-plus" />}
                size="small"
              />
            </div>
            <Button
              className="RecurrenceMonthPicker-add-div"
              onClick={() => onAddHandler(index)}
              icon={<i className="fas fa-plus" />}
              size="small"
            />
            <Button
              className="RecurrenceMonthPicker-minus-div"
              onClick={() => onDeleteHandler(index)}
              icon={<i className="fas fa-minus" />}
              size="small"
            />
          </div>
        ) : (
          <div key={`month` + index} className="RecurrenceMonthPicker-row">
            <div className="RecurrenceMonthPicker-label">
              <Select
                placeholder="Select Day"
                onChange={(requested: string) =>
                  onSelectHandler(requested, index)
                }
                className="RecurrencePicker-unit"
                suffixIcon={<i className="fas fa-sort-down" />}
              >
                {Object.keys(RECURRENCE_MONTH_OPTIONS).map(
                  (iterMonthOption: string) => (
                    <Option
                      className="RecurrenceMonthPicker-month-option"
                      key={'recurrence-' + iterMonthOption}
                      value={iterMonthOption}
                      disabled={
                        lodash.find(values, {
                          key: iterMonthOption,
                        })
                          ? true
                          : false
                      }
                    >
                      {(RECURRENCE_MONTH_OPTIONS as any)[iterMonthOption]}
                    </Option>
                  )
                )}
              </Select>
            </div>
            <Button
              className="RecurrenceMonthPicker-add-div"
              onClick={() => onAddHandler(index)}
              icon={<i className="fas fa-plus" />}
              size="small"
            />
            <Button
              className="RecurrenceMonthPicker-minus-div"
              onClick={() => onDeleteHandler(index)}
              icon={<i className="fas fa-minus" />}
              size="small"
            />
          </div>
        )
      )}
    </div>
  );
};

export default RecurrenceMonthPicker;
