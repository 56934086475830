import React from 'react';
import './Projects.scss';
import withFolderLayout from '../../hocs/withFolderLayout/withFolderLayout';
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import ProjectItem from '../projectItem/ProjectItem';
import { Button } from 'antd';
import AddProject from '../addProject/AddProject';
import {
  getFlatDataFromTree,
  getTreeDataFromFlat,
} from '../../services/treeConverter';
import {
  ProjectObj,
  getProjects,
  updateProjects,
} from '../../store/ducks/projects';
import { Store } from 'antd/lib/form/interface';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { customTreeNodeComparator } from '../../services/utility';

/** interface to describe the Projects props */
interface ProjectsProps {
  isModalOpen: boolean;
  flatTreeData: ProjectObj[];
  updateProjectsActionCreator: typeof updateProjects;
  modalOpenHandler: () => void;
  modalCloseHandler: () => void;
}

const Projects: React.FC<ProjectsProps> = (props: ProjectsProps) => {
  const {
    flatTreeData,
    updateProjectsActionCreator,
    isModalOpen,
    modalOpenHandler,
    modalCloseHandler,
  } = props;

  /** Get the tree data from flat data */
  const treeData = getTreeDataFromFlat(flatTreeData);

  /**
   * handles the changed request on tree data
   * @param {any} requestTreeData - the changed tree data
   */
  const treeChangeHandler = (requestTreeData: any) => {
    let modifiedRows: any = lodash.differenceWith(
      getFlatDataFromTree(requestTreeData),
      flatTreeData,
      customTreeNodeComparator
    );
    modifiedRows = modifiedRows.map((iterProj: ProjectObj) => ({
      ...iterProj,
      synced: false,
    }));
    updateProjectsActionCreator(modifiedRows);
  };

  return (
    <div className="Projects-container">
      {treeData.length !== 0 ? (
        <SortableTree
          isVirtualized={false}
          treeData={treeData}
          scaffoldBlockPxWidth={7}
          generateNodeProps={({ node, path }) => ({
            title: (
              <ProjectItem
                projectId={node.id}
                path={path.length}
                label={node.title as string}
                color={node.color}
              />
            ),
          })}
          maxDepth={4}
          onChange={treeChangeHandler}
        />
      ) : (
        <div className="Projects-empty-container">You have no projects</div>
      )}
      <div className="Projects-add-container">
        <Button
          className="Projects-add-btn"
          icon={<i className="fas fa-plus"></i>}
          onClick={modalOpenHandler}
        >
          Add New Project
        </Button>
      </div>
      <AddProject visible={isModalOpen} closeHandler={modalCloseHandler} />
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  flatTreeData: ProjectObj[];
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    flatTreeData: lodash.filter(getProjects(state), { deleted: false }),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  updateProjectsActionCreator: updateProjects,
};

/** connect Projects to the redux store */
const ConnectedProjects = connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);

export default withFolderLayout(
  ConnectedProjects,
  'Projects',
  'Add new project'
);
