import moment from 'moment';
import React from 'react';
import { GOOGLE_MEET_MEETING, ZOOM_MEETING } from '../meetingEvent/constants';
import { useHistory } from 'react-router-dom';
import { convertFromHTML, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import './MeetingDashboardItem.scss';
import { Avatar, Button, message } from 'antd';
import lodash from 'lodash';
import {
  ATTEND_MEETING,
  ATTEND_MEETING_MAYBE,
  ATTEND_MEETING_NO,
  ATTEND_MEETING_PENDING,
  ATTEND_MEETING_YES,
} from './constant';
import { getSessionUserInfo } from '../../store/ducks/session';
import { Store } from 'redux';
import { connect } from 'react-redux';
import { axioService, POST } from '../../services/axioService';
import { SERVER_TASK_TYPES_DETAILS_ENDPOINT } from '../../configs/endpoints';
import { MEETING_ACTIVTY_TYPE } from '../../configs/activityTypes';

export interface AttendMeetingObj {
  name: string;
  value: number | null;
  color: string;
}
interface MeetingDashboardItemProps {
  meeting: any;
  index: number;
  meetingChangeHandler: (requestedMeeting: any, requestedIndex: number) => void;
  userInfo: any;
}

const MeetingDashboardItem: React.FC<MeetingDashboardItemProps> = (
  props: MeetingDashboardItemProps
) => {
  const { meeting, userInfo, index, meetingChangeHandler } = props;
  const history = useHistory();

  const [status, setStatus] = React.useState<number | null>(
    lodash.find(meeting?.event?.attendees || [], {
      email: userInfo.email,
    }).is_going || null
  );

  const openModal = () => {
    history.push({ search: `task=${meeting.task_id}&tab=7` });
  };

  const stopEventBubbling = (event: React.MouseEvent) =>
    event.stopPropagation();

  const changeStatus = async (requestedStatus: number | null) => {
    const modifiedInviteeList =
      meeting?.event?.attendees?.map((iter: any) =>
        iter.email === userInfo.email
          ? {
              ...iter,
              is_going: requestedStatus,
            }
          : iter
      ) || [];

    try {
      await axioService(
        POST,
        SERVER_TASK_TYPES_DETAILS_ENDPOINT,
        {
          type: MEETING_ACTIVTY_TYPE.value,
          task_id: meeting.task_id,
          event: {
            ...meeting.event,
            attendees: modifiedInviteeList,
          },
        },
        true
      );

      meetingChangeHandler(
        {
          ...meeting,
          event: { ...meeting.event, people: modifiedInviteeList },
        },
        index
      );
      setStatus(requestedStatus);
    } catch (Exception) {
      console.error(Exception);
      message.error('Failed to save');
    }
  };

  const noOfYes = lodash.filter(meeting?.event?.attendees || [], {
    is_going: 1,
  }).length;

  const noOfNo = lodash.filter(meeting?.event?.attendees || [], {
    is_going: 0,
  }).length;

  const noOfMaybe = lodash.filter(
    meeting?.event?.attendees || [],
    (iter) =>
      iter?.event?.attendees?.is_going !== 0 &&
      iter?.event?.attendees?.is_going !== 1 &&
      iter?.event?.attendees?.is_going !== 2
  ).length;

  return (
    <div className="MeetingDashboardItem-container">
      <div className="MeetingDashboardItem-header">
        <div onClick={openModal} className="MeetingDashboardItem-header-title">
          {meeting?.event?.name || ''}
        </div>
        <div className="MeetingDashboardItem-header-subtitle">
          {moment(meeting?.scheduled_at || '').format('dddd, MMM  DD YYYY')}{' '}
          <span>|</span> {moment(meeting?.scheduled_at || '').fromNow()}
        </div>
      </div>
      <div className="MeetingDashboardItem-body">
        <div className="MeetingDashboardItem-row">
          <div className="MeetingDashboardItem-icon">
            <i className="far fa-clock" />
          </div>
          <div className="MeetingDashboardItem-item">
            <div className="MeetingDashboardItem-item-title">
              {moment(meeting?.scheduled_at || '').format('hh:mm a')}
            </div>
            <div className="MeetingDashboardItem-item-subtitle">
              Meeting Time
            </div>
          </div>
        </div>
        {meeting?.event?.type_info?.medium === ZOOM_MEETING.value && (
          <div className="MeetingDashboardItem-row">
            <div className="MeetingDashboardItem-icon">
              <img width={18} src={ZOOM_MEETING.url} alt="" />
            </div>
            <div className="MeetingDashboardItem-item">
              <div className="MeetingDashboardItem-item-title">
                <a
                  onClick={stopEventBubbling}
                  href={
                    meeting?.event?.type_info?.medium_properties?.link || ''
                  }
                  target="blank"
                >
                  Join with Zoom
                </a>
              </div>
              <div className="MeetingDashboardItem-item-subtitle">
                {meeting?.event?.type_info?.medium_properties?.link ||
                  'No link found'}
              </div>
            </div>
          </div>
        )}
        {meeting?.event?.type_info?.medium === GOOGLE_MEET_MEETING.value && (
          <div className="MeetingDashboardItem-row">
            <div className="MeetingDashboardItem-icon">
              <img width={18} src={GOOGLE_MEET_MEETING.url} alt="" />
            </div>
            <div className="MeetingDashboardItem-item">
              <div className="MeetingDashboardItem-item-title">
                <a
                  onClick={stopEventBubbling}
                  href={
                    meeting?.event?.type_info?.medium_properties?.link || ''
                  }
                  target="blank"
                >
                  Join with Google Meet
                </a>
              </div>
              <div className="MeetingDashboardItem-item-subtitle">
                {meeting?.event?.type_info?.medium_properties?.link ||
                  'No link found'}
              </div>
            </div>
          </div>
        )}
        <div className="MeetingDashboardItem-row">
          <div className="MeetingDashboardItem-avatars">
            <div className="MeetingDashboardItem-icon">
              <i className="fas fa-user-friends" />
            </div>
            <div className="MeetingDashboardItem-grow">
              {meeting?.event?.attendees?.length || 'No'} participants
              <div className="MeetingDashboardItem-item-subtitle">
                {noOfYes} yes, {noOfNo} no, {noOfMaybe} awaiting
              </div>
            </div>
            <div>
              <Avatar.Group maxCount={4}>
                {lodash.map(
                  meeting?.event?.attendees || [],
                  (iterCollaborator: any, iterCollaboratorIndex: number) => {
                    return (
                      <Avatar
                        key={`collaborator-${iterCollaboratorIndex}`}
                        src={iterCollaborator?.avatar}
                      >
                        <div>{iterCollaborator?.name?.slice(0, 2)}</div>
                      </Avatar>
                    );
                  }
                )}
              </Avatar.Group>
            </div>
          </div>
        </div>
        <div className="MeetingDashboardItem-row">
          <div className="MeetingDashboardItem-icon">
            <i className="far fa-sticky-note"></i>
          </div>
          <div className="MeetingDashboardItem-item">
            <div className="MeetingDashboardItem-item-title">
              <Editor
                readOnly={true}
                editorState={EditorState.createWithContent(
                  ContentState.createFromBlockArray(
                    convertFromHTML(meeting?.event?.description || '') as any
                  )
                )}
                toolbar={{
                  options: [],
                }}
                placeholder="No description is found"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="MeetingDashboardItem-footer">
        <div className="MeetingDashboardItem-footer-title">Going ?</div>
        <div>
          <Button
            onClick={() => changeStatus(ATTEND_MEETING_YES.value)}
            type="text"
          >
            {ATTEND_MEETING_YES.name}
          </Button>
        </div>
        <div>
          <Button
            type="text"
            onClick={() => changeStatus(ATTEND_MEETING_NO.value)}
          >
            {ATTEND_MEETING_NO.name}
          </Button>
        </div>
        <div>
          <Button
            type="text"
            onClick={() => changeStatus(ATTEND_MEETING_MAYBE.value)}
          >
            {ATTEND_MEETING_MAYBE.name}
          </Button>
        </div>
        <div
          className="MeetingDashboardItem-footer-status"
          style={{
            background:
              lodash.find(ATTEND_MEETING, { value: status })?.color ||
              ATTEND_MEETING_PENDING.color,
          }}
        >
          {lodash.find(ATTEND_MEETING, { value: status })?.name ||
            ATTEND_MEETING_PENDING.name}
        </div>
      </div>
    </div>
  );
};

/** connect the component with the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: any;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect MeetingDashboardItem to the redux store */
const ConnectedMeetingDashboardItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingDashboardItem);

export default ConnectedMeetingDashboardItem;
